import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280 1280">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M843.000000,1281.000000 
	C562.000000,1281.000000 281.500000,1281.000000 1.000000,1281.000000 
	C1.000000,854.333313 1.000000,427.666656 1.000000,1.000000 
	C427.666656,1.000000 854.333313,1.000000 1281.000000,1.000000 
	C1281.000000,427.666656 1281.000000,854.333313 1281.000000,1281.000000 
	C1135.166626,1281.000000 989.333313,1281.000000 843.000000,1281.000000 
M609.070923,313.069305 
	C609.070923,313.069305 609.164612,313.086761 608.633789,313.253937 
	C608.458069,313.567291 608.282349,313.880646 608.094604,314.082916 
	C608.094604,314.082916 608.179749,314.155334 607.412842,314.051239 
	C603.016174,314.963470 601.446838,318.070312 601.076538,322.011383 
	C601.076538,322.011383 601.171082,322.122498 600.458557,322.114075 
	C599.999634,322.813721 599.540710,323.513367 598.927673,324.689667 
	C598.482544,324.866058 598.037415,325.042419 596.785278,325.128632 
	C595.866577,326.433594 594.947937,327.738525 594.028320,329.602234 
	C594.046936,329.787537 594.065613,329.972839 593.631287,330.102295 
	C593.631287,330.102295 593.176331,330.068878 592.394531,330.124847 
	C588.786377,334.133575 588.920044,340.329468 593.039551,343.163177 
	C593.039551,343.163177 593.415710,343.156219 593.565552,343.863922 
	C594.690796,345.647766 595.815979,347.431641 596.823792,349.871246 
	C596.564331,351.369446 596.304871,352.867645 596.045349,354.365845 
	C597.364685,353.917358 598.684021,353.468872 600.518372,353.010559 
	C600.689819,353.001953 600.861328,352.993347 601.661804,353.175537 
	C603.485474,354.733765 605.309143,356.291992 606.978394,358.434265 
	C606.979736,358.635132 606.981079,358.836029 607.053467,359.731018 
	C607.350403,361.066528 607.647339,362.402039 607.526306,363.924103 
	C607.526306,363.924103 607.082825,364.037476 606.328796,364.007141 
	C604.916931,364.826111 603.505066,365.645081 601.863831,366.555603 
	C601.863831,366.555603 601.646729,366.437683 600.817566,366.187500 
	C597.221313,368.145508 593.625061,370.103546 589.661377,372.511871 
	C589.114014,372.683807 588.566650,372.855743 587.634216,372.518158 
	C583.628723,372.863190 581.243958,374.850677 581.000000,379.000549 
	C581.000000,379.000549 581.036560,379.027557 580.228149,378.985352 
	C577.202820,378.977448 575.320801,379.925720 576.501282,383.105957 
	C576.501282,383.105957 576.478271,383.434784 575.825623,383.365845 
	C575.552856,383.907654 575.280090,384.449432 574.743225,385.569489 
	C573.540833,386.732452 572.338379,387.895386 570.419617,389.046570 
	C569.314087,390.403625 568.208557,391.760681 567.004456,393.012207 
	C567.004456,393.012207 567.120239,393.098297 566.403992,393.118225 
	C565.939941,394.084808 565.475891,395.051392 564.850952,396.479736 
	C564.662964,396.767822 564.401794,396.952423 563.309204,397.075409 
	C562.639954,398.246429 561.970703,399.417419 561.254578,400.862946 
	C561.254578,400.862946 561.011536,400.998962 560.531494,401.193909 
	C560.364929,401.485199 560.198425,401.776489 559.859375,402.521851 
	C559.657288,402.797729 559.387878,402.965729 558.346497,403.045959 
	C557.240112,404.399597 556.133667,405.753204 554.888977,407.323608 
	C554.888977,407.323608 554.631836,407.328430 553.831726,407.164093 
	C552.987061,408.217651 552.142395,409.271179 551.024109,410.065796 
	C551.024109,410.065796 551.318726,410.300507 550.562378,410.148499 
	C549.399414,411.816986 548.236450,413.485504 546.828308,415.687103 
	C546.240295,416.460999 545.652283,417.234924 544.323853,418.096283 
	C540.797913,422.249969 540.162964,426.619873 542.837585,430.677826 
	C542.978699,431.102783 543.119812,431.527740 543.008545,432.446747 
	C542.941284,432.618317 542.874023,432.789856 542.341064,433.488556 
	C542.813416,436.024689 543.285767,438.560822 543.855591,441.620209 
	C534.485596,445.147156 525.036865,448.620392 515.677124,452.318939 
	C514.311279,452.858673 513.370239,454.473450 511.833099,455.979523 
	C511.308746,456.143433 510.784424,456.307312 509.959717,455.879883 
	C509.329010,456.284088 508.698303,456.688263 507.988708,457.047729 
	C507.988708,457.047729 508.073547,457.072418 507.454163,457.034149 
	C506.971436,457.361938 506.488739,457.689697 505.568268,458.366577 
	C505.299347,458.824219 505.030396,459.281830 504.188507,460.011169 
	C502.668823,461.102692 501.149170,462.194214 498.827576,463.163147 
	C498.332275,463.901764 497.836975,464.640350 497.123596,465.030792 
	C497.123596,465.030792 497.372589,465.357635 496.627472,465.165314 
	C494.764862,466.809692 492.902283,468.454041 490.901123,470.596527 
	C490.458221,470.818848 490.015320,471.041138 488.798065,471.128082 
	C484.753510,473.673767 480.206451,475.677399 476.762115,478.870514 
	C467.770752,487.206146 459.321930,496.123993 450.528046,504.677368 
	C447.509583,507.613312 444.109863,510.157227 440.208130,513.029846 
	C428.042816,519.568542 418.725555,532.677673 422.346649,547.800171 
	C422.983887,550.461487 420.902557,554.984253 426.406769,556.003052 
	C426.406769,556.003052 426.894653,555.933838 427.138611,556.634888 
	C429.776062,561.345337 432.413513,566.055725 435.005554,571.639526 
	C435.977783,572.187378 436.950012,572.735291 438.557037,573.336182 
	C440.087921,574.520081 441.618774,575.704041 442.591156,577.220947 
	C443.986450,581.586548 446.440704,584.704712 452.109711,584.965820 
	C454.433533,586.919312 456.757355,588.872803 459.027283,591.622253 
	C459.315216,597.073303 462.983368,597.374329 467.561218,597.063965 
	C467.838898,597.211365 468.024841,597.434998 468.086884,598.459534 
	C472.513367,604.344177 477.957916,608.824097 485.598022,610.898987 
	C485.598022,610.898987 486.006073,610.995728 486.068665,611.688538 
	C487.094116,612.420715 488.119568,613.152893 489.825500,614.019470 
	C494.817719,617.684570 499.809937,621.349731 504.992737,625.600586 
	C505.388794,626.041748 505.784821,626.482910 506.143707,627.583923 
	C506.778198,628.000244 507.412689,628.416504 508.627319,629.126526 
	C509.756165,630.129089 510.885010,631.131653 512.246887,632.521301 
	C512.448425,632.760010 512.700500,632.918335 513.000000,633.000000 
	C513.000000,633.000000 513.004089,632.996765 513.099304,633.683960 
	C513.834656,634.078430 514.570007,634.472839 515.960815,635.025024 
	C521.640808,639.683044 527.320801,644.341125 533.091492,649.678894 
	C533.810242,650.083496 534.528931,650.488098 535.641235,650.949097 
	C535.641235,650.949097 535.981689,651.154358 536.005127,651.884033 
	C537.056091,652.564270 538.107117,653.244568 539.784363,654.037781 
	C540.857971,655.025757 541.931641,656.013794 543.085510,657.664551 
	C543.772522,658.070618 544.459534,658.476746 545.078735,658.947205 
	C545.078735,658.947205 545.067871,658.854370 545.317627,659.410889 
	C545.871704,659.609558 546.425842,659.808228 547.583984,660.222412 
	C548.732300,661.125549 549.880554,662.028687 551.093018,663.631226 
	C553.524841,665.032471 555.956604,666.433777 558.719849,667.835754 
	C558.719849,667.835754 558.998474,668.015198 559.068665,668.737183 
	C560.195923,669.428467 561.323181,670.119812 562.745728,670.759827 
	C562.745728,670.759827 563.018188,670.884583 563.063660,671.620850 
	C564.633545,674.140503 565.258179,677.899292 570.251770,676.565247 
	C570.895508,676.920166 571.539307,677.275085 572.133118,678.368164 
	C576.096130,681.711914 579.943237,685.206787 584.054138,688.357605 
	C588.313293,691.622070 592.448425,695.539429 597.314819,697.411743 
	C605.846558,700.694580 615.045837,702.343811 622.155396,708.657227 
	C623.204773,709.589172 624.358398,712.312988 624.163879,712.457520 
	C619.167603,716.168152 623.722778,718.197083 625.726807,719.986694 
	C629.977173,723.782288 634.393799,727.518311 639.222107,730.502380 
	C643.553040,733.179138 647.490845,737.516418 653.248840,736.827698 
	C653.606628,736.375854 653.964417,735.924011 654.322205,735.472168 
	C651.846436,732.661621 649.370728,729.851135 646.802673,726.366821 
	C645.917480,724.767334 645.032227,723.167847 644.620667,722.424133 
	C646.107056,720.566284 647.079041,719.351440 648.621643,717.859131 
	C649.407166,717.623474 650.192688,717.387756 651.112122,717.828369 
	C654.761230,717.523743 658.410339,717.219116 662.864136,716.963806 
	C663.667725,716.870850 664.471313,716.777893 665.852539,716.772827 
	C666.230164,716.868408 666.607727,716.963989 667.088867,717.735413 
	C668.961121,719.127991 670.833313,720.520630 672.425354,721.704834 
	C670.471436,724.632812 668.697754,727.290833 666.210144,730.115356 
	C665.246399,732.523560 664.282654,734.931824 663.216187,737.596741 
	C670.121521,736.166138 691.566711,720.511963 695.950806,713.817627 
	C694.283813,713.258423 692.707092,712.729553 690.571777,712.013245 
	C691.799011,709.372437 692.851196,707.108276 694.210999,704.323730 
	C695.049622,703.821533 695.888306,703.319336 697.434875,702.873596 
	C697.944275,702.224487 698.453735,701.575439 699.426697,700.541565 
	C700.222290,699.986084 701.017944,699.430603 702.496765,698.905579 
	C702.998108,698.275146 703.499451,697.644775 704.524658,696.724182 
	C705.250977,696.416687 705.977295,696.109192 707.529846,695.930908 
	C708.785828,695.665833 710.107117,695.568420 711.287048,695.108337 
	C718.380127,692.342834 725.675537,689.954895 732.430786,686.513245 
	C737.032288,684.169006 740.862915,680.311890 745.651245,676.833069 
	C750.644775,673.481689 755.638367,670.130249 761.365051,666.862122 
	C761.889954,666.192566 762.414795,665.523010 763.453003,664.429138 
	C773.880859,656.201416 784.308716,647.973694 795.433350,639.833496 
	C795.927429,639.187500 796.421509,638.541504 797.265442,637.370300 
	C798.416992,636.485779 799.568481,635.601196 801.422485,634.823792 
	C801.900818,634.162415 802.379150,633.500977 803.169250,632.405029 
	C803.512512,631.995544 803.855774,631.586121 804.834839,631.078857 
	C806.213318,630.034912 807.591858,628.991028 809.700012,627.959290 
	C814.206726,624.706604 818.784363,621.546387 823.191895,618.164368 
	C825.613342,616.306335 827.748047,614.074585 830.104980,611.501648 
	C830.092834,611.329407 830.080750,611.157166 830.544739,610.885437 
	C830.842102,610.749573 831.152466,610.657349 832.250793,610.810364 
	C833.516785,609.546570 834.782776,608.282715 836.261841,606.793335 
	C836.261841,606.793335 836.561951,606.714722 837.303833,606.863831 
	C838.529053,605.567322 839.754333,604.270813 841.000000,603.000000 
	C841.000000,603.000000 840.974792,602.979248 841.649902,602.986328 
	C842.356018,602.232483 843.062134,601.478577 843.865601,600.888306 
	C843.865601,600.888306 843.758423,600.716553 844.429626,600.853943 
	C844.969421,600.255615 845.509277,599.657349 846.432922,598.609802 
	C847.182312,598.006592 847.931641,597.403381 849.451172,596.913818 
	C850.970276,595.600464 852.489380,594.287048 854.423462,592.551270 
	C854.960083,591.737427 855.496704,590.923645 856.642761,590.046021 
	C857.364441,589.667419 858.086060,589.288818 859.508606,588.956482 
	C860.001160,588.284546 860.493713,587.612610 861.316711,586.568970 
	C861.564087,586.340942 861.845520,586.168579 862.758301,586.064026 
	C863.154602,585.667908 863.550903,585.271790 864.343323,584.409668 
	C865.207214,583.913086 866.071106,583.416504 867.037659,583.243408 
	C867.138062,583.297119 867.238464,583.350830 867.338867,583.404602 
	C867.198730,583.248352 867.058533,583.092102 867.016846,582.264221 
	C866.993591,581.823425 866.970276,581.382629 867.481567,580.560852 
	C868.617554,579.964783 869.753540,579.368713 871.248718,579.361755 
	C871.986694,579.382690 872.724731,579.403625 873.462708,579.424561 
	C873.293823,578.595947 873.125000,577.767273 873.157776,576.282349 
	C880.239685,572.885742 884.481384,566.928162 888.141113,560.209595 
	C888.346558,560.022095 888.586731,559.907288 889.503052,559.896606 
	C889.774109,558.958435 890.045105,558.020325 890.327332,556.361816 
	C890.509705,555.921692 890.692078,555.481506 891.434998,554.658997 
	C893.333313,546.412659 892.343994,538.208374 890.944641,529.583130 
	C890.944641,529.583130 890.921021,529.138916 890.993835,528.355042 
	C890.914978,524.295349 888.643433,522.069824 884.988037,521.005920 
	C884.988037,521.005920 884.928345,521.096558 884.929321,520.373596 
	C883.188599,518.685669 881.447815,516.997681 879.931458,515.191040 
	C879.931458,515.191040 879.689026,515.266235 879.846924,514.540649 
	C878.110840,511.253571 876.233215,508.140930 871.450500,507.896698 
	C871.181702,507.712463 871.002686,507.463593 870.905273,506.455780 
	C870.219910,506.015991 869.534546,505.576202 868.990234,505.011169 
	C868.990234,505.011169 868.866699,505.153748 868.859375,504.415222 
	C868.089355,504.027679 867.319397,503.640167 866.025330,503.180389 
	C865.663086,502.826233 865.300781,502.472076 864.931580,501.389435 
	C863.306824,500.251556 861.682068,499.113647 859.509644,497.745209 
	C858.817566,497.371826 858.125427,496.998444 857.290771,496.101562 
	C857.181458,495.756073 857.072144,495.410583 856.923096,494.344971 
	C855.624573,493.560272 854.325989,492.775604 852.383789,491.771484 
	C849.224854,488.909241 846.065918,486.047028 842.900391,482.473053 
	C841.980713,481.930054 841.060974,481.387085 839.977051,480.176300 
	C834.687012,476.082001 829.396973,471.987701 823.943604,467.211823 
	C822.037598,463.708588 821.267761,458.655762 814.985229,460.151062 
	C811.756104,458.581268 808.527039,457.011444 805.116211,455.495087 
	C805.116211,455.495087 804.941650,455.421692 804.760620,454.681122 
	C801.126404,452.767426 797.207458,451.243500 793.926453,448.846405 
	C789.295044,445.462738 784.380859,444.401428 778.854919,445.134430 
	C778.854919,445.134430 778.839539,445.162140 779.017090,444.414673 
	C779.657959,443.272614 780.688599,442.187378 780.865051,440.977631 
	C781.347656,437.668274 781.470459,434.306427 781.670654,430.196533 
	C781.705017,429.169067 781.739319,428.141632 782.406006,426.623444 
	C781.986694,424.398468 781.567322,422.173492 780.705566,419.679962 
	C780.442932,419.459656 780.180298,419.239349 779.990234,418.366882 
	C779.371643,417.228729 778.753052,416.090576 778.606384,414.564453 
	C776.484375,410.904297 775.228821,406.458405 769.694519,405.289185 
	C769.115723,404.555603 768.536926,403.822052 768.018250,402.346375 
	C764.965759,399.257660 761.913269,396.168945 758.411743,393.024048 
	C758.119812,392.880493 757.826233,392.877075 757.257324,392.462341 
	C756.820312,392.055298 756.383301,391.648285 755.810730,390.616730 
	C755.293518,390.287628 754.776306,389.958527 753.995911,389.148712 
	C753.614868,388.544312 753.233826,387.939880 752.950928,386.605530 
	C751.982483,385.978394 751.013977,385.351227 749.656494,384.766632 
	C749.656494,384.766632 749.267334,384.725677 749.247559,384.136322 
	C748.803162,383.497162 748.358765,382.858002 747.787659,381.665558 
	C747.474243,381.481567 747.160767,381.297546 746.958740,381.141693 
	C746.958740,381.141693 746.846985,381.168304 746.906616,380.417419 
	C746.176086,377.892822 745.286621,375.553131 741.944580,376.035004 
	C741.944580,376.035004 741.913086,376.108551 741.761414,375.590607 
	C741.448853,375.443237 741.136292,375.295868 740.950134,375.179504 
	C740.950134,375.179504 740.821472,375.199127 740.858887,374.473541 
	C737.405090,371.559082 733.975220,368.592255 728.303284,369.034027 
	C727.857361,369.062653 727.411438,369.091248 726.509033,368.692993 
	C726.028198,368.299225 725.547363,367.905487 725.022095,366.828766 
	C723.009521,363.901581 717.694824,363.431213 718.620422,357.745941 
	C719.165222,357.374786 719.709961,357.003662 721.081970,356.858887 
	C724.931824,356.795807 726.794678,354.736084 727.299927,350.397217 
	C729.531189,347.263977 731.762390,344.130768 734.568176,340.502869 
	C734.432312,338.331818 734.296448,336.160797 733.976379,333.371216 
	C733.863464,332.736115 733.750549,332.101044 733.823120,330.714142 
	C730.848633,327.505890 727.874146,324.297607 724.994019,320.998962 
	C724.994019,320.998962 724.911316,321.099548 724.927795,320.425873 
	C724.280334,319.960388 723.632874,319.494904 722.995728,319.003662 
	C722.995728,319.003662 722.970642,319.015625 722.972351,318.321533 
	C721.638733,317.224091 720.305176,316.126617 719.000000,315.000000 
	C719.000000,315.000000 718.970886,315.028229 718.942932,314.368256 
	C718.278503,313.938965 717.614014,313.509705 716.972900,313.023102 
	C716.972900,313.023102 716.918823,313.053284 716.918640,312.393616 
	C716.277954,311.935303 715.637268,311.476959 714.997253,311.002533 
	C714.997253,311.002533 714.980957,311.005127 714.951782,310.274658 
	C713.971680,307.950500 713.209106,305.340454 709.776733,306.306885 
	C709.776733,306.306885 709.746948,306.275391 709.644104,305.674713 
	C709.280396,305.561584 708.916748,305.448456 707.929138,305.181030 
	C706.594910,304.505829 705.260681,303.830627 703.652771,302.601318 
	C703.266174,302.550598 702.879578,302.499847 702.167297,302.430023 
	C702.167297,302.430023 701.910217,302.229126 701.641052,301.651642 
	C701.244202,301.618835 700.847412,301.586029 700.121216,301.513123 
	C700.121216,301.513123 699.876221,301.289368 699.642639,300.693054 
	C699.247375,300.637878 698.852173,300.582703 698.193848,300.495819 
	C698.193848,300.495819 698.047058,300.275299 697.694153,299.612061 
	C691.291931,297.740814 684.934143,295.689331 678.463989,294.093292 
	C676.109497,293.512543 673.487183,294.017426 670.316101,294.146271 
	C669.870239,294.078217 669.424377,294.010162 668.517700,293.358734 
	C666.789856,292.911346 664.994690,291.908264 663.345276,292.108093 
	C653.698792,293.276978 643.909424,294.027435 634.542053,296.396729 
	C630.263855,297.478790 624.139709,297.177338 621.657227,303.562531 
	C621.103821,303.706024 620.550476,303.849487 619.631836,303.524017 
	C618.447693,304.441559 617.263550,305.359100 615.759766,306.825470 
	C613.905334,308.572510 612.050964,310.319580 609.660095,312.234680 
	C609.468323,312.544312 609.276550,312.853943 609.070923,313.069305 
M629.604736,780.308411 
	C633.028320,780.308411 636.451843,780.308411 640.380127,780.308411 
	C640.380127,794.364990 640.380127,807.605957 640.380127,820.882568 
	C644.507690,820.882568 648.108459,820.882568 652.295898,820.882568 
	C652.295898,807.244263 652.295898,793.843933 652.295898,779.987244 
	C656.472168,779.987244 660.064087,779.987244 663.718628,779.987244 
	C664.223450,776.993347 664.650391,774.461487 665.078003,771.925598 
	C652.497375,771.925598 640.565369,771.925598 628.075928,772.011475 
	C628.148987,772.679382 628.222046,773.347351 628.505371,774.793640 
	C628.424866,775.857971 628.344360,776.922302 627.752441,778.212402 
	C628.065430,778.829346 628.378418,779.446228 629.604736,780.308411 
M938.349487,773.699707 
	C938.349487,789.410950 938.349487,805.122253 938.349487,821.141052 
	C944.374451,821.141052 949.513245,821.140991 954.652039,821.140991 
	C959.906921,821.140991 965.161804,821.141052 970.618896,821.141052 
	C970.618896,817.880554 970.618896,815.289917 970.618896,812.286804 
	C967.089050,812.286804 963.635925,812.286804 960.182800,812.286804 
	C949.330994,812.286865 949.329956,812.286804 950.154663,801.510986 
	C950.165466,801.369446 950.338562,801.240295 950.694763,800.746277 
	C955.181213,800.746277 959.933228,800.746277 964.881226,800.746277 
	C965.333313,797.534424 965.740540,794.640991 966.229736,791.165466 
	C963.515076,791.165466 961.395691,791.168457 959.276306,791.164978 
	C949.281189,791.148438 949.281189,791.147766 950.448792,780.418091 
	C956.638916,780.418091 962.915588,780.418091 969.470398,780.778564 
	C969.660828,780.236084 969.851257,779.693542 969.955322,778.257507 
	C969.955322,776.155823 969.955322,774.054138 969.955322,772.175171 
	C958.945679,772.175171 948.480713,772.175171 937.568298,772.084229 
	C937.458313,772.188171 937.348267,772.292175 937.238281,772.396118 
	C937.445618,772.549805 937.652893,772.703491 938.349487,773.699707 
M313.066956,809.854309 
	C313.630920,810.229248 314.194885,810.604187 315.159515,811.731873 
	C315.785492,821.416626 318.848846,823.459167 329.763062,821.299194 
	C329.506531,820.185913 329.362732,819.039673 328.979950,817.979675 
	C323.880768,803.859497 318.631927,789.791809 313.705719,775.611938 
	C312.479584,772.082458 310.478577,771.209717 307.096344,771.347412 
	C303.589447,771.490234 300.580353,771.249939 299.042114,775.712891 
	C294.936920,787.623352 290.414062,799.394104 285.863892,811.145630 
	C281.565063,822.247925 281.456940,822.208435 293.409729,821.871155 
	C293.714966,821.862549 294.014526,821.652710 294.217407,821.574829 
	C295.647888,817.503296 297.052368,813.505676 298.528442,809.304321 
	C303.419891,809.304321 308.216522,809.304321 313.066956,809.854309 
M788.675903,772.263184 
	C788.483459,772.712097 788.123901,773.160645 788.122925,773.609985 
	C788.090820,788.385742 788.059814,803.161743 788.161072,817.936829 
	C788.168213,818.978027 789.188110,820.012207 790.699646,821.117065 
	C794.132141,821.117065 797.564575,821.117065 801.612915,821.117065 
	C801.612915,813.792480 801.612915,807.079956 801.612915,800.367493 
	C802.082153,800.142944 802.551331,799.918457 803.020569,799.693909 
	C804.413513,801.260254 806.182617,802.630066 807.124207,804.432251 
	C809.359680,808.710632 811.008545,813.298828 813.298767,817.543701 
	C814.242615,819.293152 816.035767,821.361511 817.775330,821.734863 
	C820.928406,822.411560 824.330750,821.926636 829.187256,821.926636 
	C824.749939,813.309509 820.799194,805.637329 816.609070,797.500183 
	C816.692932,797.422485 817.637146,796.499390 818.632568,795.635315 
	C823.025330,791.822083 823.673950,786.954895 822.576599,781.614746 
	C821.414429,775.959412 817.299011,773.022217 812.158691,772.517517 
	C804.598450,771.775391 796.938965,772.043884 788.675903,772.263184 
M377.193481,801.821289 
	C377.149475,804.229553 377.105469,806.637817 376.533997,809.497375 
	C376.788635,811.342407 377.043274,813.187500 377.547943,815.906616 
	C377.697113,817.511658 377.846283,819.116699 378.003693,820.810242 
	C381.717194,820.810242 385.105072,820.810242 388.966492,820.810242 
	C387.977356,804.129761 387.009766,787.812378 386.036377,771.397827 
	C383.780945,771.397827 381.937439,771.646912 380.188416,771.346252 
	C376.491455,770.710876 374.685181,772.627502 373.506470,775.699646 
	C369.527985,786.069275 365.539398,796.434998 361.273071,807.534851 
	C357.639099,798.127075 354.035706,789.441345 350.932159,780.580627 
	C347.917297,771.972961 345.366119,770.090515 337.497925,771.930908 
	C337.052185,772.035217 336.627502,772.229370 336.420349,772.302002 
	C335.372925,788.442932 334.338593,804.382812 333.276611,820.748413 
	C337.215393,820.748413 340.935059,820.748413 345.506653,820.748413 
	C345.506653,813.140869 345.506653,805.611145 345.506653,798.081360 
	C349.439972,804.911743 352.627869,811.674011 355.240082,818.651794 
	C356.950439,823.220520 360.495880,822.013916 363.493195,821.759033 
	C364.726196,821.654297 366.161346,819.640015 366.851318,818.184631 
	C369.704010,812.167175 372.306671,806.031189 375.522156,800.156616 
	C376.019531,800.415833 376.516907,800.675110 377.193481,801.821289 
M1136.535767,771.525452 
	C1134.539551,771.488159 1132.536499,771.542847 1130.548828,771.392517 
	C1127.402222,771.154480 1126.109619,772.511292 1125.036499,775.580627 
	C1121.724121,785.054749 1117.879639,794.344360 1114.142456,803.665833 
	C1113.821533,804.466187 1112.768066,804.972778 1111.929199,805.729187 
	C1108.273804,796.543945 1104.662964,788.016418 1101.484497,779.330566 
	C1098.309937,770.654785 1096.707275,769.668762 1087.216919,772.222229 
	C1086.412231,788.320740 1085.605103,804.467407 1084.793945,820.692383 
	C1089.145508,820.692383 1092.407593,820.692383 1095.880493,820.692383 
	C1096.371704,813.573120 1096.846558,806.690125 1097.321533,799.807129 
	C1100.403198,804.416016 1102.569336,808.973938 1104.262451,813.701294 
	C1107.274536,822.110718 1107.568604,822.424255 1116.602417,821.665222 
	C1119.963867,813.983032 1123.340820,806.265747 1126.717651,798.548462 
	C1127.135620,798.683167 1127.553467,798.817810 1127.971313,798.952454 
	C1127.971313,804.984131 1127.816162,811.022827 1128.091919,817.041931 
	C1128.152832,818.371948 1129.579224,820.563049 1130.652466,820.746521 
	C1133.645020,821.258301 1136.782227,820.924377 1139.972656,820.924377 
	C1139.269043,805.778687 1138.599609,791.367554 1138.460571,776.519287 
	C1138.065552,774.997375 1137.670410,773.475403 1136.535767,771.525452 
M466.293182,785.197937 
	C464.382660,782.510071 462.399109,779.870605 460.576294,777.124512 
	C456.343933,770.748535 454.914825,770.178955 447.711243,772.166687 
	C447.711243,788.432129 447.711243,804.635315 447.711243,820.831360 
	C451.737610,820.831360 455.320160,820.831360 459.201599,820.831360 
	C459.201599,811.418274 459.201599,802.297974 459.201599,792.769287 
	C464.204376,800.440247 469.464630,807.353149 473.412842,814.948120 
	C476.245758,820.397583 480.008514,821.804260 485.284180,820.810913 
	C486.491669,820.583557 488.326630,818.883301 488.340057,817.841187 
	C488.537811,802.487549 488.476227,787.130615 488.476227,771.415039 
	C484.962677,771.415039 482.140137,771.597900 479.350677,771.371887 
	C475.221832,771.037476 475.373260,773.486816 475.435883,776.437317 
	C475.589020,783.652222 475.485840,790.872498 475.485840,799.197205 
	C472.157593,794.128601 469.425385,789.967712 466.293182,785.197937 
M403.215942,818.445129 
	C404.076935,818.931274 404.935242,819.422302 405.799377,819.902771 
	C415.250580,825.158508 430.508331,820.884338 435.952423,811.660278 
	C440.310028,804.277100 440.260620,796.527283 439.006897,788.394104 
	C436.225555,770.351318 417.448456,768.248047 406.216095,773.511169 
	C391.729004,780.299316 389.639984,807.866028 403.215942,818.445129 
M564.107910,771.650269 
	C558.157471,771.650269 552.207031,771.650269 546.140381,771.650269 
	C546.140381,788.453186 546.140381,804.994629 546.140381,821.855469 
	C552.749695,821.855469 559.124023,822.496582 565.289246,821.637329 
	C569.629639,821.032288 574.381653,819.234314 577.728271,816.483032 
	C585.427185,810.153748 586.546753,801.164368 585.345947,791.757507 
	C583.767578,779.392639 577.396484,773.121582 564.107910,771.650269 
M1034.192017,785.105042 
	C1033.887695,790.776062 1032.961670,796.504883 1033.481445,802.099365 
	C1033.876221,806.347778 1035.306274,810.977600 1037.698120,814.461060 
	C1042.327271,821.203369 1053.156494,824.296753 1060.768066,821.783752 
	C1063.935425,820.737976 1067.521729,819.627380 1069.773926,817.393372 
	C1076.565674,810.656860 1079.278076,801.981628 1077.767090,792.649414 
	C1076.914062,787.381226 1075.247559,781.572266 1072.053711,777.487488 
	C1063.987549,767.171204 1040.300903,768.947876 1034.192017,785.105042 
M218.504333,821.491943 
	C224.805542,821.616516 231.283325,822.704346 237.357559,821.590820 
	C243.252457,820.510132 250.156540,819.783936 253.688873,813.482727 
	C258.509064,804.884033 259.338562,795.713013 256.685547,786.295654 
	C255.025085,780.401489 251.231171,774.979675 245.667755,773.646118 
	C236.988861,771.565613 227.801743,771.605225 218.028442,770.669739 
	C218.028442,788.238464 218.028442,804.509277 218.504333,821.491943 
M537.149719,805.326782 
	C536.915161,798.671753 537.405396,791.885376 536.229675,785.401001 
	C535.183228,779.629089 530.834351,774.607361 525.181946,773.462219 
	C517.000244,771.804810 508.447906,771.932129 500.043488,771.493347 
	C499.271027,771.453003 497.714478,772.826599 497.719147,773.535767 
	C497.794342,784.953430 498.086426,796.369507 498.277740,807.786743 
	C498.349426,812.066406 498.289368,816.348206 498.289368,820.890503 
	C506.160400,820.890503 513.526001,821.557312 520.691895,820.648682 
	C525.227478,820.073608 530.480957,818.924622 533.148865,814.056519 
	C534.553711,811.493164 535.696045,808.785828 537.149719,805.326782 
M696.780029,821.075989 
	C700.673706,821.388550 704.567383,821.701111 709.114136,822.066101 
	C702.987732,805.316895 697.209778,789.966431 691.814453,774.482666 
	C690.302979,770.144897 687.187988,771.695312 684.486023,771.438293 
	C681.950623,771.197205 679.749756,771.177917 678.732117,774.579041 
	C677.161682,779.827698 674.973694,784.897766 672.938110,789.999817 
	C668.895325,800.132507 664.777893,810.235474 660.606934,820.559692 
	C674.077271,821.077332 673.184204,823.394470 676.334167,810.406006 
	C681.644348,810.406006 687.223633,809.402466 692.056519,810.865051 
	C694.200806,811.514038 694.837280,817.145325 696.780029,821.075989 
M587.993164,811.375244 
	C586.727722,814.416565 585.462280,817.457825 584.016846,820.931702 
	C587.521545,820.931702 590.561157,821.260925 593.474243,820.796692 
	C594.997253,820.554077 597.065430,819.206177 597.572815,817.861450 
	C600.518555,810.054749 600.386292,810.004822 608.470459,810.008850 
	C609.634888,810.009399 610.832092,810.186401 611.957520,809.977173 
	C615.277161,809.359924 616.462891,811.193054 617.286316,813.990723 
	C619.639893,821.986816 619.703491,821.977356 627.679138,821.916260 
	C628.956970,821.906433 630.233948,821.772278 631.462524,821.698486 
	C631.533325,821.076599 631.690979,820.704529 631.591125,820.423950 
	C626.097595,804.977539 620.527100,789.558289 615.103638,774.087463 
	C613.488281,769.479675 609.794373,771.701416 607.070618,771.620056 
	C605.529236,771.573914 603.065796,773.143677 602.491394,774.576477 
	C597.672363,786.596375 593.195007,798.753235 587.993164,811.375244 
M773.282349,791.132080 
	C770.036316,791.132080 766.790283,791.132080 763.556763,791.132080 
	C763.556763,787.249451 763.556763,784.145020 763.556763,780.386353 
	C770.025513,780.386353 776.235718,780.386353 782.461548,780.386353 
	C782.461548,777.121399 782.461548,774.528137 782.461548,771.829346 
	C771.975647,771.829346 761.781921,771.829346 751.815308,771.829346 
	C751.815308,788.470642 751.815308,804.678162 751.815308,820.845215 
	C762.663940,820.845215 773.068970,820.845215 783.648560,820.845215 
	C783.648560,817.936096 783.648560,815.359741 783.648560,812.286743 
	C780.119446,812.286743 776.827026,812.286804 773.534546,812.286804 
	C770.253235,812.286804 766.971924,812.286743 763.593750,812.286743 
	C763.593750,807.936768 763.593750,804.191833 763.593750,799.967224 
	C768.463623,799.967224 773.048645,799.967224 777.994568,799.967224 
	C778.429626,796.857422 778.804321,794.178650 779.230530,791.131958 
	C777.132080,791.131958 775.660034,791.131958 773.282349,791.132080 
M743.898193,810.142944 
	C745.193787,798.796631 743.560669,796.016113 732.904724,791.180359 
	C731.097290,790.360046 729.008789,789.661194 727.745361,788.273315 
	C726.346252,786.736389 725.745728,784.472534 724.797363,782.525208 
	C726.959351,781.755920 729.203613,780.192261 731.263672,780.405701 
	C734.258484,780.716248 737.120544,782.308044 740.256897,783.431396 
	C741.562073,780.871765 742.805420,778.433472 744.018982,776.053528 
	C737.157349,771.469116 728.905640,769.936279 722.245850,771.976196 
	C716.785461,773.648865 712.760742,776.944092 711.784241,782.850037 
	C710.799683,788.804443 712.667786,793.762268 718.033142,796.930115 
	C721.172424,798.783569 724.474976,800.366882 727.741821,801.996155 
	C730.831177,803.536926 732.388916,805.832581 731.215393,809.272156 
	C729.954773,812.966919 726.888428,813.244263 723.837097,812.273865 
	C720.446411,811.195618 717.230774,809.567017 713.998352,808.203064 
	C712.822815,810.042297 711.359741,811.571777 710.855164,813.370178 
	C710.477417,814.716553 710.794128,817.175537 711.723999,817.831421 
	C723.078125,825.840088 736.430115,822.875061 743.898193,810.142944 
M843.225342,788.954712 
	C841.624084,784.059937 840.128235,779.126282 838.344238,774.299072 
	C837.922058,773.156555 836.605652,771.659790 835.573975,771.553223 
	C832.199341,771.204773 828.766296,771.422424 824.830566,771.422424 
	C825.406189,773.905945 825.633301,775.673340 826.224243,777.309265 
	C830.510193,789.175171 834.839661,801.025635 839.215881,812.858643 
	C842.318115,821.246887 842.360535,821.237549 851.613220,820.917053 
	C852.254272,820.894836 852.888550,820.675476 853.461060,820.560730 
	C859.444153,804.257996 865.373352,788.102295 871.501526,771.404358 
	C868.918335,771.404358 867.387146,771.635193 865.951538,771.362732 
	C860.839172,770.392639 858.705933,772.662781 857.351318,777.517334 
	C854.785339,786.713196 851.471924,795.700500 848.290833,805.312988 
	C846.586670,799.791870 845.029785,794.747620 843.225342,788.954712 
M931.307007,778.807739 
	C933.558350,777.242188 932.791992,775.748962 930.990112,774.643921 
	C922.212158,769.261047 907.109558,769.182861 900.432617,780.034668 
	C895.772461,787.608582 894.929688,795.990601 896.708618,804.608582 
	C897.805847,809.923950 899.826416,814.822388 904.572266,818.035034 
	C913.121643,823.822266 921.926147,823.145203 930.863403,819.256531 
	C930.377380,811.396118 930.186890,811.241150 923.141174,812.716675 
	C916.309204,814.147583 912.124939,812.283936 911.023376,805.528259 
	C910.013123,799.332275 910.290161,792.756042 911.097290,786.488708 
	C911.727844,781.593018 918.153259,778.523315 922.036194,781.353577 
	C926.536743,784.633850 928.367371,781.955872 931.307007,778.807739 
M995.065369,789.727051 
	C995.054932,794.409607 994.642090,799.134583 995.129211,803.764771 
	C995.845581,810.574402 998.777832,816.767883 1005.327393,819.410645 
	C1013.273254,822.616882 1021.661133,822.132996 1030.165527,819.372925 
	C1029.166870,816.443542 1028.290039,813.871094 1027.349854,811.113342 
	C1025.335327,811.728149 1023.664673,812.324890 1021.950928,812.745789 
	C1014.962585,814.462219 1010.290283,811.423157 1008.991150,804.392273 
	C1008.781677,803.258545 1008.225525,802.122681 1008.291931,801.015442 
	C1008.595337,795.953918 1008.372681,790.720886 1009.640381,785.891052 
	C1011.372986,779.290222 1017.223450,778.740662 1025.559570,784.044800 
	C1026.808594,782.408325 1027.762085,780.245972 1029.420166,779.190369 
	C1032.249390,777.389343 1030.470703,776.308289 1029.228394,775.147888 
	C1024.550415,770.778015 1011.934143,769.865234 1006.340149,773.180542 
	C1000.244263,776.793152 996.937927,782.261841 995.065369,789.727051 
M889.015198,810.478027 
	C889.015198,797.582153 889.015198,784.686279 889.015198,771.676819 
	C884.740967,771.676819 880.997742,771.676819 877.254761,771.676819 
	C877.254761,788.279663 877.254761,804.607056 877.254761,820.963623 
	C881.276306,820.963623 884.881531,820.963623 889.015015,820.963623 
	C889.015015,817.691650 889.015015,814.579773 889.015198,810.478027 
M265.785522,796.499878 
	C265.785522,804.586548 265.785522,812.673218 265.785522,820.824097 
	C270.049652,820.824097 273.759888,820.824097 277.589539,820.824097 
	C277.589539,804.150146 277.589539,787.808105 277.589539,771.735779 
	C273.503754,771.735779 269.774689,771.735779 265.785522,771.735779 
	C265.785522,779.912598 265.785522,787.706238 265.785522,796.499878 
M984.895691,821.286682 
	C988.808411,818.959229 990.123413,815.396301 988.180115,812.085327 
	C985.996765,808.365479 982.490601,808.247253 979.100586,809.570679 
	C974.527649,811.355957 975.588257,815.968567 976.494385,818.882874 
	C977.327942,821.564087 980.675110,823.490540 984.895691,821.286682 
M783.443909,823.484070 
	C783.443909,823.484070 783.520142,823.443726 783.443909,823.484070 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M1137.930176,776.956421 
	C1138.599609,791.367554 1139.269043,805.778687 1139.972656,820.924377 
	C1136.782227,820.924377 1133.645020,821.258301 1130.652466,820.746521 
	C1129.579224,820.563049 1128.152832,818.371948 1128.091919,817.041931 
	C1127.816162,811.022827 1127.971313,804.984131 1127.971313,798.952454 
	C1127.553467,798.817810 1127.135620,798.683167 1126.717651,798.548462 
	C1123.340820,806.265747 1119.963867,813.983032 1116.602417,821.665222 
	C1107.568604,822.424255 1107.274536,822.110718 1104.262451,813.701294 
	C1102.569336,808.973938 1100.403198,804.416016 1097.321533,799.807129 
	C1096.846558,806.690125 1096.371704,813.573120 1095.880493,820.692383 
	C1092.407593,820.692383 1089.145508,820.692383 1084.793945,820.692383 
	C1085.605103,804.467407 1086.412231,788.320740 1087.216919,772.222229 
	C1096.707275,769.668762 1098.309937,770.654785 1101.484497,779.330566 
	C1104.662964,788.016418 1108.273804,796.543945 1111.929199,805.729187 
	C1112.768066,804.972778 1113.821533,804.466187 1114.142456,803.665833 
	C1117.879639,794.344360 1121.724121,785.054749 1125.036499,775.580627 
	C1126.109619,772.511292 1127.402222,771.154480 1130.548828,771.392517 
	C1132.536499,771.542847 1134.539551,771.488159 1136.887695,772.107666 
	C1137.469849,774.112000 1137.700073,775.534241 1137.930176,776.956421 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M377.061462,809.046082 
	C377.105469,806.637817 377.149475,804.229553 377.238953,801.006714 
	C377.183624,798.910583 377.082794,797.629150 376.981964,796.347656 
	C376.693787,796.356262 376.405609,796.364929 376.117432,796.373596 
	C375.745331,797.561951 375.373260,798.750305 375.001160,799.938721 
	C372.306671,806.031189 369.704010,812.167175 366.851318,818.184631 
	C366.161346,819.640015 364.726196,821.654297 363.493195,821.759033 
	C360.495880,822.013916 356.950439,823.220520 355.240082,818.651794 
	C352.627869,811.674011 349.439972,804.911743 345.506653,798.081360 
	C345.506653,805.611145 345.506653,813.140869 345.506653,820.748413 
	C340.935059,820.748413 337.215393,820.748413 333.276611,820.748413 
	C334.338593,804.382812 335.372925,788.442932 336.420349,772.302002 
	C336.627502,772.229370 337.052185,772.035217 337.497925,771.930908 
	C345.366119,770.090515 347.917297,771.972961 350.932159,780.580627 
	C354.035706,789.441345 357.639099,798.127075 361.273071,807.534851 
	C365.539398,796.434998 369.527985,786.069275 373.506470,775.699646 
	C374.685181,772.627502 376.491455,770.710876 380.188416,771.346252 
	C381.937439,771.646912 383.780945,771.397827 386.036377,771.397827 
	C387.009766,787.812378 387.977356,804.129761 388.966492,820.810242 
	C385.105072,820.810242 381.717194,820.810242 378.003693,820.810242 
	C377.846283,819.116699 377.697113,817.511658 377.562317,815.106445 
	C377.404938,812.552856 377.233215,810.799500 377.061462,809.046082 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M466.493164,785.502441 
	C469.425385,789.967712 472.157593,794.128601 475.485840,799.197205 
	C475.485840,790.872498 475.589020,783.652222 475.435883,776.437317 
	C475.373260,773.486816 475.221832,771.037476 479.350677,771.371887 
	C482.140137,771.597900 484.962677,771.415039 488.476227,771.415039 
	C488.476227,787.130615 488.537811,802.487549 488.340057,817.841187 
	C488.326630,818.883301 486.491669,820.583557 485.284180,820.810913 
	C480.008514,821.804260 476.245758,820.397583 473.412842,814.948120 
	C469.464630,807.353149 464.204376,800.440247 459.201599,792.769287 
	C459.201599,802.297974 459.201599,811.418274 459.201599,820.831360 
	C455.320160,820.831360 451.737610,820.831360 447.711243,820.831360 
	C447.711243,804.635315 447.711243,788.432129 447.711243,772.166687 
	C454.914825,770.178955 456.343933,770.748535 460.576294,777.124512 
	C462.399109,779.870605 464.382660,782.510071 466.493164,785.502441 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M402.939575,818.199463 
	C389.639984,807.866028 391.729004,780.299316 406.216095,773.511169 
	C417.448456,768.248047 436.225555,770.351318 439.006897,788.394104 
	C440.260620,796.527283 440.310028,804.277100 435.952423,811.660278 
	C430.508331,820.884338 415.250580,825.158508 405.799377,819.902771 
	C404.935242,819.422302 404.076935,818.931274 402.939575,818.199463 
M420.677643,812.588074 
	C422.329987,810.633118 425.229706,808.783569 425.405609,806.703796 
	C425.981232,799.897522 426.024567,792.962769 425.362213,786.166077 
	C424.951660,781.953430 421.380371,779.849060 417.058075,779.808533 
	C412.475342,779.765564 409.663544,782.555481 409.238922,786.650696 
	C408.616547,792.652893 408.268005,798.843140 409.051483,804.788635 
	C409.908112,811.289368 413.252289,813.179138 420.677643,812.588074 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M564.557129,771.690125 
	C577.396484,773.121582 583.767578,779.392639 585.345947,791.757507 
	C586.546753,801.164368 585.427185,810.153748 577.728271,816.483032 
	C574.381653,819.234314 569.629639,821.032288 565.289246,821.637329 
	C559.124023,822.496582 552.749695,821.855469 546.140381,821.855469 
	C546.140381,804.994629 546.140381,788.453186 546.140381,771.650269 
	C552.207031,771.650269 558.157471,771.650269 564.557129,771.690125 
M569.753235,783.857483 
	C566.712524,779.969177 562.669189,779.737915 558.197632,780.336609 
	C558.197632,791.250732 558.197632,801.783936 558.197632,812.275330 
	C566.551392,813.503052 570.476196,811.023071 572.174866,803.368408 
	C573.611816,796.892944 573.203308,790.538452 569.753235,783.857483 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M1034.371826,784.733765 
	C1040.300903,768.947876 1063.987549,767.171204 1072.053711,777.487488 
	C1075.247559,781.572266 1076.914062,787.381226 1077.767090,792.649414 
	C1079.278076,801.981628 1076.565674,810.656860 1069.773926,817.393372 
	C1067.521729,819.627380 1063.935425,820.737976 1060.768066,821.783752 
	C1053.156494,824.296753 1042.327271,821.203369 1037.698120,814.461060 
	C1035.306274,810.977600 1033.876221,806.347778 1033.481445,802.099365 
	C1032.961670,796.504883 1033.887695,790.776062 1034.371826,784.733765 
M1064.642456,803.710144 
	C1064.402100,797.952026 1064.653931,792.116150 1063.760376,786.461243 
	C1063.085327,782.190613 1059.716553,779.739929 1055.102173,779.808289 
	C1050.559326,779.875610 1047.775757,782.639648 1047.298828,786.720032 
	C1046.632935,792.418335 1046.378662,798.274780 1046.913818,803.972839 
	C1047.458862,809.776794 1051.047363,812.831360 1055.726807,813.021667 
	C1060.144531,813.201355 1062.706665,810.707581 1064.642456,803.710144 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M218.266388,821.135986 
	C218.028442,804.509277 218.028442,788.238464 218.028442,770.669739 
	C227.801743,771.605225 236.988861,771.565613 245.667755,773.646118 
	C251.231171,774.979675 255.025085,780.401489 256.685547,786.295654 
	C259.338562,795.713013 258.509064,804.884033 253.688873,813.482727 
	C250.156540,819.783936 243.252457,820.510132 237.357559,821.590820 
	C231.283325,822.704346 224.805542,821.616516 218.266388,821.135986 
M244.753220,790.239868 
	C242.124664,781.241821 238.821243,779.007812 230.539886,780.738159 
	C230.539886,791.239502 230.539886,801.764709 230.539886,812.240723 
	C238.964615,813.572266 243.478271,810.776978 244.734726,802.973755 
	C245.357849,799.103760 244.894196,795.058716 244.753220,790.239868 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M537.053345,805.735168 
	C535.696045,808.785828 534.553711,811.493164 533.148865,814.056519 
	C530.480957,818.924622 525.227478,820.073608 520.691895,820.648682 
	C513.526001,821.557312 506.160400,820.890503 498.289368,820.890503 
	C498.289368,816.348206 498.349426,812.066406 498.277740,807.786743 
	C498.086426,796.369507 497.794342,784.953430 497.719147,773.535767 
	C497.714478,772.826599 499.271027,771.453003 500.043488,771.493347 
	C508.447906,771.932129 517.000244,771.804810 525.181946,773.462219 
	C530.834351,774.607361 535.183228,779.629089 536.229675,785.401001 
	C537.405396,791.885376 536.915161,798.671753 537.053345,805.735168 
M510.970856,780.423950 
	C510.970856,790.999634 510.970856,801.575317 510.970856,812.184204 
	C518.403198,813.691162 523.115784,811.155029 524.547180,803.963318 
	C525.345459,799.952515 525.366699,795.652832 524.952515,791.562866 
	C524.099915,783.142700 520.363037,780.126221 510.970856,780.423950 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M789.321777,771.881897 
	C796.938965,772.043884 804.598450,771.775391 812.158691,772.517517 
	C817.299011,773.022217 821.414429,775.959412 822.576599,781.614746 
	C823.673950,786.954895 823.025330,791.822083 818.632568,795.635315 
	C817.637146,796.499390 816.692932,797.422485 816.609070,797.500183 
	C820.799194,805.637329 824.749939,813.309509 829.187256,821.926636 
	C824.330750,821.926636 820.928406,822.411560 817.775330,821.734863 
	C816.035767,821.361511 814.242615,819.293152 813.298767,817.543701 
	C811.008545,813.298828 809.359680,808.710632 807.124207,804.432251 
	C806.182617,802.630066 804.413513,801.260254 803.020569,799.693909 
	C802.551331,799.918457 802.082153,800.142944 801.612915,800.367493 
	C801.612915,807.079956 801.612915,813.792480 801.612915,821.117065 
	C797.564575,821.117065 794.132141,821.117065 790.118835,820.621704 
	C789.465942,804.044861 789.393860,787.963379 789.321777,771.881897 
M801.294250,783.824890 
	C801.294250,786.724487 801.294250,789.624146 801.294250,792.461731 
	C808.264893,792.810425 810.152039,791.546631 810.493774,786.798645 
	C810.783875,782.768433 809.355164,780.054443 804.954224,779.449402 
	C802.094177,779.056213 800.892944,780.081726 801.294250,783.824890 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M696.469116,820.795471 
	C694.837280,817.145325 694.200806,811.514038 692.056519,810.865051 
	C687.223633,809.402466 681.644348,810.406006 676.334167,810.406006 
	C673.184204,823.394470 674.077271,821.077332 660.606934,820.559692 
	C664.777893,810.235474 668.895325,800.132507 672.938110,789.999817 
	C674.973694,784.897766 677.161682,779.827698 678.732117,774.579041 
	C679.749756,771.177917 681.950623,771.197205 684.486023,771.438293 
	C687.187988,771.695312 690.302979,770.144897 691.814453,774.482666 
	C697.209778,789.966431 702.987732,805.316895 709.114136,822.066101 
	C704.567383,821.701111 700.673706,821.388550 696.469116,820.795471 
M688.867493,795.759583 
	C687.679688,792.263428 686.491821,788.767273 684.945862,784.217041 
	C683.074768,790.040833 681.548401,794.791687 679.948547,799.771301 
	C683.449951,799.771301 686.589722,799.771301 690.004578,799.771301 
	C689.622253,798.411743 689.359131,797.476257 688.867493,795.759583 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M588.303955,811.122070 
	C593.195007,798.753235 597.672363,786.596375 602.491394,774.576477 
	C603.065796,773.143677 605.529236,771.573914 607.070618,771.620056 
	C609.794373,771.701416 613.488281,769.479675 615.103638,774.087463 
	C620.527100,789.558289 626.097595,804.977539 631.591125,820.423950 
	C631.690979,820.704529 631.533325,821.076599 631.462524,821.698486 
	C630.233948,821.772278 628.956970,821.906433 627.679138,821.916260 
	C619.703491,821.977356 619.639893,821.986816 617.286316,813.990723 
	C616.462891,811.193054 615.277161,809.359924 611.957520,809.977173 
	C610.832092,810.186401 609.634888,810.009399 608.470459,810.008850 
	C600.386292,810.004822 600.518555,810.054749 597.572815,817.861450 
	C597.065430,819.206177 594.997253,820.554077 593.474243,820.796692 
	C590.561157,821.260925 587.521545,820.931702 584.016846,820.931702 
	C585.462280,817.457825 586.727722,814.416565 588.303955,811.122070 
M605.946228,792.410095 
	C605.153381,794.829773 604.360535,797.249451 603.537415,799.761475 
	C607.184570,799.761475 610.187927,799.761475 613.640930,799.761475 
	C611.900696,794.482849 610.334595,789.732300 608.452698,784.024170 
	C607.458008,787.229126 606.776855,789.423828 605.946228,792.410095 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M313.013184,809.304321 
	C308.216522,809.304321 303.419891,809.304321 298.528442,809.304321 
	C297.052368,813.505676 295.647888,817.503296 294.217407,821.574829 
	C294.014526,821.652710 293.714966,821.862549 293.409729,821.871155 
	C281.456940,822.208435 281.565063,822.247925 285.863892,811.145630 
	C290.414062,799.394104 294.936920,787.623352 299.042114,775.712891 
	C300.580353,771.249939 303.589447,771.490234 307.096344,771.347412 
	C310.478577,771.209717 312.479584,772.082458 313.705719,775.611938 
	C318.631927,789.791809 323.880768,803.859497 328.979950,817.979675 
	C329.362732,819.039673 329.506531,820.185913 329.763062,821.299194 
	C318.848846,823.459167 315.785492,821.416626 314.999054,811.047119 
	C314.230133,810.009644 313.621674,809.656982 313.013184,809.304321 
M302.825134,795.543335 
	C302.402527,796.907166 301.979919,798.270935 301.495941,799.832764 
	C305.026031,799.832764 308.057434,799.832764 311.515045,799.832764 
	C309.860291,794.594910 308.338562,789.778137 306.540100,784.085449 
	C305.178131,788.214355 304.103790,791.471130 302.825134,795.543335 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M773.735168,791.132019 
	C775.660034,791.131958 777.132080,791.131958 779.230530,791.131958 
	C778.804321,794.178650 778.429626,796.857422 777.994568,799.967224 
	C773.048645,799.967224 768.463623,799.967224 763.593750,799.967224 
	C763.593750,804.191833 763.593750,807.936768 763.593750,812.286743 
	C766.971924,812.286743 770.253235,812.286804 773.534546,812.286804 
	C776.827026,812.286804 780.119446,812.286743 783.648560,812.286743 
	C783.648560,815.359741 783.648560,817.936096 783.648560,820.845215 
	C773.068970,820.845215 762.663940,820.845215 751.815308,820.845215 
	C751.815308,804.678162 751.815308,788.470642 751.815308,771.829346 
	C761.781921,771.829346 771.975647,771.829346 782.461548,771.829346 
	C782.461548,774.528137 782.461548,777.121399 782.461548,780.386353 
	C776.235718,780.386353 770.025513,780.386353 763.556763,780.386353 
	C763.556763,784.145020 763.556763,787.249451 763.556763,791.132080 
	C766.790283,791.132080 770.036316,791.132080 773.735168,791.132019 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M743.824951,810.544067 
	C736.430115,822.875061 723.078125,825.840088 711.723999,817.831421 
	C710.794128,817.175537 710.477417,814.716553 710.855164,813.370178 
	C711.359741,811.571777 712.822815,810.042297 713.998352,808.203064 
	C717.230774,809.567017 720.446411,811.195618 723.837097,812.273865 
	C726.888428,813.244263 729.954773,812.966919 731.215393,809.272156 
	C732.388916,805.832581 730.831177,803.536926 727.741821,801.996155 
	C724.474976,800.366882 721.172424,798.783569 718.033142,796.930115 
	C712.667786,793.762268 710.799683,788.804443 711.784241,782.850037 
	C712.760742,776.944092 716.785461,773.648865 722.245850,771.976196 
	C728.905640,769.936279 737.157349,771.469116 744.018982,776.053528 
	C742.805420,778.433472 741.562073,780.871765 740.256897,783.431396 
	C737.120544,782.308044 734.258484,780.716248 731.263672,780.405701 
	C729.203613,780.192261 726.959351,781.755920 724.797363,782.525208 
	C725.745728,784.472534 726.346252,786.736389 727.745361,788.273315 
	C729.008789,789.661194 731.097290,790.360046 732.904724,791.180359 
	C743.560669,796.016113 745.193787,798.796631 743.824951,810.544067 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M843.349121,789.329102 
	C845.029785,794.747620 846.586670,799.791870 848.290833,805.312988 
	C851.471924,795.700500 854.785339,786.713196 857.351318,777.517334 
	C858.705933,772.662781 860.839172,770.392639 865.951538,771.362732 
	C867.387146,771.635193 868.918335,771.404358 871.501526,771.404358 
	C865.373352,788.102295 859.444153,804.257996 853.461060,820.560730 
	C852.888550,820.675476 852.254272,820.894836 851.613220,820.917053 
	C842.360535,821.237549 842.318115,821.246887 839.215881,812.858643 
	C834.839661,801.025635 830.510193,789.175171 826.224243,777.309265 
	C825.633301,775.673340 825.406189,773.905945 824.830566,771.422424 
	C828.766296,771.422424 832.199341,771.204773 835.573975,771.553223 
	C836.605652,771.659790 837.922058,773.156555 838.344238,774.299072 
	C840.128235,779.126282 841.624084,784.059937 843.349121,789.329102 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M938.015747,772.175171 
	C948.480713,772.175171 958.945679,772.175171 969.955322,772.175171 
	C969.955322,774.054138 969.955322,776.155823 969.797485,778.872437 
	C969.490479,779.797546 969.341370,780.107849 969.192261,780.418091 
	C962.915588,780.418091 956.638916,780.418091 950.448792,780.418091 
	C949.281189,791.147766 949.281189,791.148438 959.276306,791.164978 
	C961.395691,791.168457 963.515076,791.165466 966.229736,791.165466 
	C965.740540,794.640991 965.333313,797.534424 964.881226,800.746277 
	C959.933228,800.746277 955.181213,800.746277 950.694763,800.746277 
	C950.338562,801.240295 950.165466,801.369446 950.154663,801.510986 
	C949.329956,812.286804 949.330994,812.286865 960.182800,812.286804 
	C963.635925,812.286804 967.089050,812.286804 970.618896,812.286804 
	C970.618896,815.289917 970.618896,817.880554 970.618896,821.141052 
	C965.161804,821.141052 959.906921,821.140991 954.652039,821.140991 
	C949.513245,821.140991 944.374451,821.141052 938.349487,821.141052 
	C938.349487,805.122253 938.349487,789.410950 938.293762,773.141357 
	C938.238037,772.583069 938.015747,772.175171 938.015747,772.175171 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M931.061157,779.063477 
	C928.367371,781.955872 926.536743,784.633850 922.036194,781.353577 
	C918.153259,778.523315 911.727844,781.593018 911.097290,786.488708 
	C910.290161,792.756042 910.013123,799.332275 911.023376,805.528259 
	C912.124939,812.283936 916.309204,814.147583 923.141174,812.716675 
	C930.186890,811.241150 930.377380,811.396118 930.863403,819.256531 
	C921.926147,823.145203 913.121643,823.822266 904.572266,818.035034 
	C899.826416,814.822388 897.805847,809.923950 896.708618,804.608582 
	C894.929688,795.990601 895.772461,787.608582 900.432617,780.034668 
	C907.109558,769.182861 922.212158,769.261047 930.990112,774.643921 
	C932.791992,775.748962 933.558350,777.242188 931.061157,779.063477 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M995.100952,789.299194 
	C996.937927,782.261841 1000.244263,776.793152 1006.340149,773.180542 
	C1011.934143,769.865234 1024.550415,770.778015 1029.228394,775.147888 
	C1030.470703,776.308289 1032.249390,777.389343 1029.420166,779.190369 
	C1027.762085,780.245972 1026.808594,782.408325 1025.559570,784.044800 
	C1017.223450,778.740662 1011.372986,779.290222 1009.640381,785.891052 
	C1008.372681,790.720886 1008.595337,795.953918 1008.291931,801.015442 
	C1008.225525,802.122681 1008.781677,803.258545 1008.991150,804.392273 
	C1010.290283,811.423157 1014.962585,814.462219 1021.950928,812.745789 
	C1023.664673,812.324890 1025.335327,811.728149 1027.349854,811.113342 
	C1028.290039,813.871094 1029.166870,816.443542 1030.165527,819.372925 
	C1021.661133,822.132996 1013.273254,822.616882 1005.327393,819.410645 
	C998.777832,816.767883 995.845581,810.574402 995.129211,803.764771 
	C994.642090,799.134583 995.054932,794.409607 995.100952,789.299194 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M628.263855,777.986633 
	C628.344360,776.922302 628.424866,775.857971 628.567322,774.185425 
	C628.630615,773.026611 628.632019,772.476135 628.633362,771.925598 
	C640.565369,771.925598 652.497375,771.925598 665.078003,771.925598 
	C664.650391,774.461487 664.223450,776.993347 663.718628,779.987244 
	C660.064087,779.987244 656.472168,779.987244 652.295898,779.987244 
	C652.295898,793.843933 652.295898,807.244263 652.295898,820.882568 
	C648.108459,820.882568 644.507690,820.882568 640.380127,820.882568 
	C640.380127,807.605957 640.380127,794.364990 640.380127,780.308411 
	C636.451843,780.308411 633.028320,780.308411 629.170349,779.910889 
	C628.578613,779.004395 628.421265,778.495544 628.263855,777.986633 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M781.735779,430.965393 
	C781.470459,434.306427 781.347656,437.668274 780.865051,440.977631 
	C780.688599,442.187378 779.657959,443.272614 778.573486,444.796112 
	C777.894043,445.200897 777.658203,445.224213 777.502625,444.831543 
	C778.109192,442.427216 778.635498,440.438904 779.161804,438.450623 
	C777.093994,439.427155 775.796326,440.845001 774.483582,442.248779 
	C772.330872,444.550690 770.168213,446.843414 767.900330,448.770386 
	C768.548706,446.916473 769.306458,445.432190 770.158936,443.762299 
	C767.154602,442.675568 764.600586,441.751770 761.842102,440.533081 
	C759.761536,439.822205 757.885376,439.406219 755.859131,438.683655 
	C753.483887,437.249725 751.258789,436.122345 749.004456,434.675201 
	C748.957520,433.927673 748.939697,433.499878 748.952881,432.750000 
	C748.528076,431.599396 748.072388,430.770966 747.482117,429.540588 
	C746.248962,426.620209 745.118835,424.114807 744.071716,421.575134 
	C743.777649,420.861786 743.336792,419.599518 743.625183,419.348053 
	C747.167603,416.259003 744.400085,412.532471 744.845520,408.777069 
	C744.596130,406.908936 744.292358,405.419281 744.188110,403.662170 
	C746.999023,405.763184 750.186951,407.746246 752.087891,410.589691 
	C755.004517,414.952515 757.914368,418.412048 763.769531,417.606812 
	C763.899292,418.586884 764.012268,419.236511 764.135864,420.267761 
	C766.803040,426.420746 769.459595,432.192078 772.473450,438.739502 
	C773.949707,435.791443 774.689026,434.315155 775.428345,432.838867 
	C776.746643,433.776733 778.064941,434.714600 779.749939,435.913300 
	C780.447571,434.175049 781.091675,432.570221 781.735779,430.965393 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M889.015137,810.973022 
	C889.015015,814.579773 889.015015,817.691650 889.015015,820.963623 
	C884.881531,820.963623 881.276306,820.963623 877.254761,820.963623 
	C877.254761,804.607056 877.254761,788.279663 877.254761,771.676819 
	C880.997742,771.676819 884.740967,771.676819 889.015198,771.676819 
	C889.015198,784.686279 889.015198,797.582153 889.015137,810.973022 
z"
          />
          <path
            fill="#0D0D0D"
            opacity="1.000000"
            stroke="none"
            d="
M265.785522,795.999878 
	C265.785522,787.706238 265.785522,779.912598 265.785522,771.735779 
	C269.774689,771.735779 273.503754,771.735779 277.589539,771.735779 
	C277.589539,787.808105 277.589539,804.150146 277.589539,820.824097 
	C273.759888,820.824097 270.049652,820.824097 265.785522,820.824097 
	C265.785522,812.673218 265.785522,804.586548 265.785522,795.999878 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M646.895020,727.040588 
	C649.370728,729.851135 651.846436,732.661621 654.322205,735.472168 
	C653.964417,735.924011 653.606628,736.375854 653.248840,736.827698 
	C647.490845,737.516418 643.553040,733.179138 639.222107,730.502380 
	C634.393799,727.518311 629.977173,723.782288 625.726807,719.986694 
	C623.722778,718.197083 619.167603,716.168152 624.163879,712.457520 
	C624.358398,712.312988 623.204773,709.589172 622.155396,708.657227 
	C615.045837,702.343811 605.846558,700.694580 597.314819,697.411743 
	C592.448425,695.539429 588.313293,691.622070 584.054138,688.357605 
	C579.943237,685.206787 576.096130,681.711914 572.527222,678.025879 
	C573.892517,677.886780 574.863770,678.090027 575.917847,678.593628 
	C577.005737,678.972656 578.010925,679.051331 579.299805,679.149719 
	C580.086365,679.401245 580.589050,679.632996 581.237000,680.194946 
	C582.636353,681.316833 583.841370,682.692749 585.152771,682.802979 
	C592.216248,683.396606 598.088013,686.788025 603.427429,690.877014 
	C612.147156,697.554749 620.390869,704.850769 629.002808,711.674744 
	C631.327759,713.516907 634.255554,714.598328 636.999695,716.218689 
	C637.092773,716.408447 637.305786,716.773621 637.203735,717.100830 
	C635.868103,718.007507 634.634521,718.586975 633.266052,719.229736 
	C635.773560,721.548950 637.951965,724.124268 640.665649,725.865173 
	C642.314758,726.923157 644.790588,726.692444 646.895020,727.040588 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M668.978516,293.942108 
	C669.424377,294.010162 669.870239,294.078217 670.761841,294.462311 
	C672.223938,295.142303 673.251282,295.843658 674.254578,295.810791 
	C678.241028,295.680298 681.669189,295.906311 684.781433,299.391083 
	C686.389465,301.191528 690.143921,301.074982 692.944702,302.248901 
	C692.928894,303.521820 692.896973,304.370361 692.493896,305.157745 
	C689.384705,305.881134 686.646729,306.665741 683.770325,307.490021 
	C683.509216,308.503113 683.189392,309.744019 682.476929,310.981445 
	C678.808533,310.610229 675.534058,309.966125 672.256714,309.951263 
	C668.878235,309.935944 665.501770,310.601562 662.115234,310.839142 
	C659.748169,311.005188 657.365784,310.952332 654.725525,310.798676 
	C652.418762,308.767822 650.376770,306.935516 648.252319,304.727600 
	C647.108887,302.796661 646.047852,301.241333 644.933533,299.405121 
	C644.633484,298.190765 644.386719,297.257294 644.548584,296.208496 
	C652.964294,295.376160 660.971375,294.659119 668.978516,293.942108 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M426.894653,555.933838 
	C426.894653,555.933838 426.406769,556.003052 426.212830,555.587646 
	C426.099426,554.684387 426.180023,554.196594 426.260315,553.627563 
	C426.260040,553.546387 426.176178,553.099915 425.809540,552.928711 
	C424.968628,547.877563 424.494354,542.997559 424.287537,538.332581 
	C426.516388,540.761414 428.377228,543.081726 430.518524,545.105225 
	C430.983185,545.544373 432.454132,544.918640 433.461609,544.783325 
	C432.979584,543.904175 432.700287,542.761902 431.972809,542.201233 
	C430.509735,541.073730 428.802338,540.263245 426.341187,538.821228 
	C433.945801,536.901367 438.932861,533.914185 438.478333,526.065308 
	C439.517487,525.955627 440.210144,525.882019 441.263855,525.873779 
	C442.436188,525.303589 443.247498,524.668030 444.270569,523.778687 
	C446.658386,522.686584 448.834442,521.848267 450.993896,521.396606 
	C450.937561,522.433533 450.959045,523.094482 450.848419,523.732483 
	C449.981903,528.731506 450.894501,529.694214 455.855133,529.068481 
	C456.652130,528.968018 457.479645,529.109497 458.155396,529.479370 
	C456.061371,530.898804 454.170227,532.713440 452.134125,532.893921 
	C448.602905,533.207153 447.693878,534.366516 448.901703,537.597168 
	C449.415039,538.970093 449.599335,540.466003 449.967987,542.056396 
	C443.694763,547.492371 437.478882,552.878662 430.938721,558.310425 
	C429.374542,557.548584 428.134613,556.741211 426.894653,555.933838 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M800.719971,634.716614 
	C799.568481,635.601196 798.416992,636.485779 796.785156,637.683716 
	C795.782043,638.580078 795.259277,639.163025 794.736572,639.745972 
	C784.308716,647.973694 773.880859,656.201416 762.877563,664.693237 
	C761.745422,665.564514 761.188660,666.171631 760.631897,666.778809 
	C755.638367,670.130249 750.644775,673.481689 744.950562,676.965576 
	C740.497925,677.749878 736.745911,678.401611 733.182739,678.765381 
	C737.398132,675.968567 741.424805,673.459778 745.521606,670.938599 
	C745.591858,670.926147 745.719910,670.988831 745.995667,670.975708 
	C746.455444,670.963318 746.639526,670.964050 747.222046,670.980957 
	C749.564758,670.252747 751.939514,669.981567 753.373962,668.676758 
	C757.972656,664.493652 762.228027,659.932617 766.585815,655.487976 
	C766.711670,655.359619 766.501648,654.901794 766.409241,654.375610 
	C764.147949,654.758911 761.879272,655.143433 759.288696,655.582520 
	C760.061035,654.113525 760.574890,653.136169 761.369629,651.963867 
	C764.751770,649.503235 767.853149,647.237671 771.245667,644.884277 
	C772.089172,644.548767 772.641418,644.301086 773.178833,644.436646 
	C774.744690,649.513855 777.274963,646.460144 778.985901,645.237610 
	C784.449158,641.333862 789.690552,637.119751 795.373291,633.001709 
	C797.035950,631.989929 798.299133,630.936340 799.660339,630.029663 
	C801.536926,628.779663 803.358459,628.145203 804.199097,631.176697 
	C803.855774,631.586121 803.512512,631.995544 802.702637,632.665161 
	C801.730652,633.522400 801.225281,634.119507 800.719971,634.716614 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M486.006104,610.995728 
	C486.006073,610.995728 485.598022,610.898987 485.320923,610.571655 
	C484.654877,609.283569 484.265900,608.322815 484.253540,607.271973 
	C488.238708,608.358398 492.033264,610.992859 494.937805,606.219482 
	C499.020325,609.390015 503.004608,612.220703 507.080261,615.441772 
	C508.836487,616.220764 510.483948,616.729736 512.169800,616.973877 
	C516.061646,617.537415 519.974854,617.951660 523.874573,618.463562 
	C538.016968,620.320251 552.157410,622.192139 566.465454,624.113525 
	C566.812927,624.317993 566.966858,624.490845 567.058960,625.100159 
	C566.955200,629.094543 566.115906,631.285950 561.983887,628.739502 
	C560.950806,628.102783 559.451294,628.217407 558.163940,627.999451 
	C549.542725,626.540161 540.897827,625.207031 532.306519,623.589355 
	C525.163330,622.244263 518.100159,620.475769 510.962189,619.099182 
	C509.190033,618.757385 507.265381,619.206116 505.313751,619.296021 
	C506.425537,621.669800 507.217682,623.361206 507.610535,625.037415 
	C506.408203,625.019775 505.605194,625.017273 504.802155,625.014832 
	C499.809937,621.349731 494.817719,617.684570 489.410645,613.627197 
	C487.999237,612.488464 487.002655,611.742065 486.006104,610.995728 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M725.066589,367.511719 
	C725.547363,367.905487 726.028198,368.299225 726.792297,369.262085 
	C727.887817,370.101929 728.700134,370.372711 729.864746,370.663635 
	C732.485168,371.781677 734.753174,372.879639 737.002563,374.333130 
	C737.445801,377.226776 737.907654,379.764862 738.413025,382.541809 
	C731.618286,385.858673 735.812012,376.324188 731.194580,376.438507 
	C731.315918,378.165802 731.431641,379.813751 731.550232,381.501953 
	C730.365234,381.954407 729.180481,382.406738 727.663330,382.882782 
	C726.637207,383.379333 725.943604,383.852173 724.957275,384.413330 
	C723.489136,385.035400 722.313721,385.569122 721.075073,385.799622 
	C717.132751,386.262451 714.061584,384.708984 711.331970,382.145996 
	C711.250549,382.069550 711.010681,382.161713 710.677368,381.875427 
	C709.716370,380.535095 708.922607,379.494568 708.538757,378.344299 
	C710.848145,378.039337 712.747559,377.844086 714.832764,377.629730 
	C710.880249,372.576630 709.368103,372.505768 704.345581,376.895874 
	C703.135925,376.773560 702.282227,376.635895 700.741150,376.387421 
	C703.887024,373.736237 706.471313,371.558350 709.320557,369.366882 
	C709.760742,369.328278 709.935852,369.303253 710.486328,369.325195 
	C712.957947,367.642578 715.054016,365.912994 717.474426,364.361694 
	C720.221313,365.530548 722.643982,366.521118 725.066589,367.511719 
M720.985718,369.584564 
	C719.298218,370.078522 717.610779,370.572479 716.138794,371.003357 
	C718.134583,373.468567 719.521179,375.181396 721.069275,377.093597 
	C723.536804,374.610992 723.692383,372.417297 720.985718,369.584564 
M721.875916,380.499603 
	C718.667847,378.298615 718.232727,378.463013 716.474731,383.020996 
	C718.449463,382.337463 720.077148,381.774017 721.875916,380.499603 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M696.726990,702.817200 
	C695.888306,703.319336 695.049622,703.821533 693.712280,704.634888 
	C691.144409,705.943970 689.075256,706.941895 686.766235,707.663025 
	C684.315613,706.845764 682.011230,706.123718 681.501282,709.781982 
	C678.073425,709.773315 675.092529,709.730042 672.094604,709.364746 
	C673.084961,707.823669 674.092407,706.604736 675.485413,705.304321 
	C677.518066,704.840454 679.165100,704.458191 681.239014,703.976807 
	C680.258850,702.070862 679.608276,700.805664 679.239319,699.331421 
	C680.984192,698.694336 682.447327,698.266418 684.287292,697.842041 
	C686.142944,697.593689 687.621887,697.341919 689.505005,697.106689 
	C694.094421,697.888000 696.610779,695.504944 698.780212,692.439941 
	C700.552673,689.935791 702.350830,687.449768 704.513184,684.952576 
	C708.090881,684.054443 711.292969,683.159546 714.495117,682.264587 
	C714.713684,682.831421 714.932251,683.398193 715.150879,683.964966 
	C710.441589,685.768127 706.164246,687.901184 706.931641,694.228699 
	C706.834717,694.964539 706.769165,695.383118 706.703613,695.801697 
	C705.977295,696.109192 705.250977,696.416687 703.938110,696.877686 
	C702.838928,697.645874 702.326294,698.260498 701.813599,698.875122 
	C701.017944,699.430603 700.222290,699.986084 698.874023,700.770874 
	C697.789856,701.605896 697.258423,702.211548 696.726990,702.817200 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M733.637634,331.465942 
	C733.750549,332.101044 733.863464,332.736115 733.935425,334.038330 
	C733.772461,335.459106 733.650452,336.212799 733.146545,336.960815 
	C730.785583,337.362946 728.806580,337.770782 726.548462,337.961792 
	C724.797302,335.091705 723.654297,332.169128 721.763550,329.858398 
	C719.741028,327.386627 717.013672,325.491669 714.698792,322.991699 
	C713.265442,321.048798 711.727234,319.459198 710.012939,317.598145 
	C709.214172,316.257324 708.591553,315.187958 707.881287,313.873383 
	C707.524109,313.419861 707.254578,313.211548 706.654297,312.974701 
	C705.191223,312.364197 704.058899,311.782166 702.947571,310.843750 
	C703.302246,309.505066 703.635864,308.522736 704.617493,305.632416 
	C705.547119,312.618866 708.709290,310.558868 711.818115,309.795959 
	C713.074585,310.238220 714.027771,310.621674 714.980957,311.005127 
	C714.980957,311.005127 714.997253,311.002533 715.036255,311.318115 
	C715.689819,312.106903 716.304321,312.580078 716.918823,313.053284 
	C716.918823,313.053284 716.972900,313.023102 717.020142,313.351868 
	C717.701904,314.129822 718.336426,314.579010 718.970886,315.028229 
	C718.970886,315.028229 719.000000,315.000000 719.021729,315.352753 
	C720.352478,316.808868 721.661560,317.912262 722.970642,319.015625 
	C722.970642,319.015625 722.995728,319.003662 723.017639,319.330078 
	C723.663452,320.137543 724.287354,320.618561 724.911316,321.099548 
	C724.911316,321.099548 724.994019,320.998962 724.997437,321.381958 
	C727.879822,324.998627 730.758728,328.232269 733.637634,331.465942 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M581.091797,679.864746 
	C580.589050,679.632996 580.086365,679.401245 579.109619,678.836609 
	C577.702087,678.433533 576.768555,678.363342 575.835022,678.293213 
	C574.863770,678.090027 573.892517,677.886780 572.552185,677.656799 
	C571.539307,677.275085 570.895508,676.920166 569.868774,676.288940 
	C568.352844,674.615662 567.219910,673.218689 566.076904,671.452881 
	C564.449463,669.968018 562.832092,668.851868 561.125732,667.404236 
	C557.516052,663.601074 553.893799,660.223145 550.544312,656.593506 
	C549.681519,655.658508 549.676636,653.931946 549.618530,652.674866 
	C551.039978,652.506104 552.120483,652.231445 553.551270,651.958313 
	C556.286865,653.937134 558.672302,655.914429 560.806030,658.097961 
	C560.820557,659.155823 560.817566,660.357605 561.396423,660.802612 
	C565.325256,663.823364 569.359924,666.708740 573.425781,669.544128 
	C573.706299,669.739807 574.514587,669.178711 575.427002,669.008179 
	C580.219543,672.652100 584.662476,676.257385 589.075378,680.108398 
	C589.045349,680.354248 589.035645,680.849426 588.634949,680.877075 
	C585.853455,680.558044 583.472595,680.211426 581.091797,679.864746 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M549.277649,652.568970 
	C549.676636,653.931946 549.681519,655.658508 550.544312,656.593506 
	C553.893799,660.223145 557.516052,663.601074 561.068054,667.479126 
	C561.099365,667.885559 560.979736,668.025635 560.649170,668.023560 
	C559.878540,668.019348 559.438538,668.017273 558.998474,668.015198 
	C558.998474,668.015198 558.719849,667.835754 558.429993,667.504639 
	C555.769714,665.759705 553.399292,664.345764 551.028870,662.931824 
	C549.880554,662.028687 548.732300,661.125549 547.145142,659.847656 
	C546.160217,659.266785 545.614014,659.060547 545.067871,658.854370 
	C545.067871,658.854370 545.078735,658.947205 545.046997,658.605103 
	C544.345276,657.842590 543.675293,657.422180 543.005249,657.001770 
	C541.931641,656.013794 540.857971,655.025757 539.323853,653.706055 
	C537.902832,652.634338 536.942261,651.894348 535.981689,651.154358 
	C535.981689,651.154358 535.641235,650.949097 535.349731,650.610962 
	C534.372437,649.848267 533.686646,649.423706 533.000793,648.999207 
	C527.320801,644.341125 521.640808,639.683044 515.515381,634.648682 
	C514.381287,633.847168 513.692688,633.421997 513.004089,632.996765 
	C513.004089,632.996765 513.000000,633.000000 512.932373,632.760376 
	C512.670837,632.194519 512.387207,632.065674 512.013855,632.134216 
	C510.885010,631.131653 509.756165,630.129089 508.280151,628.686401 
	C507.348938,627.805542 506.764923,627.364807 506.180878,626.924072 
	C505.784821,626.482910 505.388794,626.041748 504.897461,625.307739 
	C505.605194,625.017273 506.408203,625.019775 507.803589,625.307617 
	C514.292603,629.371948 520.189331,633.150818 526.233459,637.238159 
	C528.804199,639.451172 531.434204,641.148376 533.602661,643.308777 
	C536.903259,646.597290 538.986755,651.468323 545.135681,650.343384 
	C546.340271,650.122986 547.888123,651.778564 549.277649,652.568970 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M543.260864,431.952728 
	C543.119812,431.527740 542.978699,431.102783 542.849182,429.959778 
	C543.437866,426.520264 544.014954,423.798767 544.995239,421.053162 
	C546.334351,420.772369 547.626648,420.820831 548.149170,420.210419 
	C552.091003,415.605743 557.052307,413.644775 563.266479,414.226074 
	C563.336548,416.110626 563.163330,417.772858 562.671082,419.634583 
	C560.431763,421.252930 558.511536,422.671722 556.198059,424.381073 
	C556.367981,430.303955 552.174316,434.241058 547.424316,437.794769 
	C545.863647,435.712616 544.562256,433.832672 543.260864,431.952728 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M563.023193,414.003754 
	C557.052307,413.644775 552.091003,415.605743 548.149170,420.210419 
	C547.626648,420.820831 546.334351,420.772369 545.104858,420.671875 
	C544.895569,419.546051 544.979919,418.777466 545.064331,418.008850 
	C545.652283,417.234924 546.240295,416.460999 547.267700,415.306976 
	C548.910950,413.384735 550.114807,411.842621 551.318726,410.300507 
	C551.318726,410.300507 551.024109,410.065796 551.489990,410.151459 
	C552.847900,409.267578 553.739868,408.298004 554.631836,407.328430 
	C554.631836,407.328430 554.888977,407.323608 555.302551,407.195190 
	C556.827759,405.719818 557.939453,404.372833 559.051147,403.025879 
	C559.387878,402.965729 559.657288,402.797729 560.209839,402.235229 
	C560.710632,401.632080 560.861084,401.315521 561.011536,400.998962 
	C561.011536,400.998962 561.254578,400.862946 561.570129,400.578613 
	C562.684692,399.543945 563.483704,398.793549 564.638550,398.056824 
	C565.466187,398.154083 566.166992,398.067505 566.374939,398.347260 
	C568.715576,401.496094 570.712708,402.255920 572.343994,397.774719 
	C572.490662,397.371582 573.426025,397.255585 573.986084,397.425110 
	C573.989319,398.681702 574.001587,399.519440 573.811401,400.632629 
	C572.724548,402.919922 571.840271,404.931702 570.602966,406.987457 
	C567.072571,408.559021 563.510925,409.688293 563.023193,414.003754 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M489.572418,471.263458 
	C490.015320,471.041138 490.458221,470.818848 491.297913,470.345215 
	C492.123840,470.038727 492.553009,469.983551 493.140137,470.257294 
	C494.518890,471.998901 496.490723,473.260193 496.812042,474.854218 
	C497.606476,478.795410 497.645050,482.888947 497.923431,487.272400 
	C493.744171,491.301666 489.662506,495.019165 485.468445,498.605164 
	C484.976318,499.025940 483.865265,498.722839 483.034119,498.370422 
	C484.433075,494.864532 485.839020,491.743500 487.280487,488.543701 
	C486.686035,488.103699 485.793243,487.442902 484.991089,486.459015 
	C489.716736,481.589111 489.863190,479.839508 486.018738,475.639587 
	C487.260406,473.964050 488.416412,472.613739 489.572418,471.263458 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M727.995789,382.859070 
	C729.180481,382.406738 730.365234,381.954407 731.550232,381.501953 
	C731.431641,379.813751 731.315918,378.165802 731.194580,376.438507 
	C735.812012,376.324188 731.618286,385.858673 738.413025,382.541809 
	C737.907654,379.764862 737.445801,377.226776 737.342590,374.335999 
	C738.741333,374.388550 739.781433,374.793823 740.821472,375.199127 
	C740.821472,375.199127 740.950134,375.179504 740.967773,375.418823 
	C741.294678,375.808289 741.603882,375.958405 741.913086,376.108521 
	C741.913086,376.108551 741.944580,376.035004 742.027588,376.389465 
	C743.689331,378.218719 745.268188,379.693512 746.846985,381.168304 
	C746.846985,381.168304 746.958740,381.141693 746.964966,381.402222 
	C747.285522,381.848083 747.599915,382.033478 747.914307,382.218872 
	C748.358765,382.858002 748.803162,383.497162 748.867493,384.552124 
	C744.705017,387.981232 746.115479,392.177490 745.620239,396.009064 
	C744.166809,395.674133 743.063049,395.349426 741.728882,394.977753 
	C741.498474,394.930786 741.027832,394.937561 740.986816,394.667908 
	C740.619629,393.840332 740.293518,393.282440 739.749146,392.513733 
	C739.330688,392.275543 739.130310,392.248199 738.736084,391.998413 
	C738.108459,391.121796 737.674683,390.467560 737.184692,389.468079 
	C734.084167,387.034943 731.039978,384.947021 727.995789,382.859070 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M574.013855,400.357117 
	C574.001587,399.519440 573.989319,398.681702 574.177490,397.132629 
	C574.626343,395.467041 574.874756,394.512817 574.789246,394.841370 
	C572.375366,394.005188 570.627380,393.399719 569.021606,392.511536 
	C569.821289,391.171997 570.478638,390.115173 571.135986,389.058350 
	C572.338379,387.895386 573.540833,386.732452 575.161133,385.211670 
	C575.878784,384.380859 576.178528,383.907806 576.478271,383.434753 
	C576.478271,383.434784 576.501282,383.105957 576.806030,383.186737 
	C578.419373,381.854187 579.727966,380.440857 581.036499,379.027527 
	C581.036560,379.027557 581.000000,379.000549 581.317505,378.928223 
	C582.573853,378.012817 583.512756,377.169739 584.764771,376.581360 
	C585.302979,378.339508 585.528076,379.842926 585.770264,381.461212 
	C586.746033,381.954987 587.902161,382.540039 589.583923,383.391083 
	C587.780029,384.835480 586.553345,385.817719 585.208130,386.894897 
	C585.691284,388.156403 586.151184,389.357269 586.524780,390.736938 
	C586.438416,390.915741 586.240356,391.260010 585.861938,391.143005 
	C583.806885,392.330994 582.130188,393.635986 580.326538,394.980469 
	C580.048340,395.121552 579.951538,395.260956 579.620117,395.643036 
	C577.558655,397.351044 575.786255,398.854095 574.013855,400.357117 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M707.116699,695.866333 
	C706.769165,695.383118 706.834717,694.964539 707.256348,694.119995 
	C713.276855,690.000610 718.966248,686.344055 724.575134,682.567993 
	C725.290588,682.086304 725.586121,680.981079 726.200317,680.031494 
	C726.324463,679.898193 726.661438,679.759949 727.094727,679.849670 
	C729.001770,679.971375 730.475525,680.003357 732.209351,679.952515 
	C732.662415,679.580139 732.855347,679.290527 733.022339,679.001343 
	C732.996399,679.001709 732.993958,679.053467 732.993958,679.053467 
	C736.745911,678.401611 740.497925,677.749878 744.644043,677.114624 
	C740.862915,680.311890 737.032288,684.169006 732.430786,686.513245 
	C725.675537,689.954895 718.380127,692.342834 711.287048,695.108337 
	C710.107117,695.568420 708.785828,695.665833 707.116699,695.866333 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M764.125244,419.886139 
	C764.012268,419.236511 763.899292,418.586884 763.855042,417.235168 
	C761.501648,413.630035 759.173523,410.641296 756.628540,407.850372 
	C754.473206,405.486725 752.066956,403.351898 750.083740,400.879120 
	C751.286255,398.796875 752.176697,396.951355 753.063965,395.529175 
	C753.656433,397.949860 754.252014,399.947235 754.847595,401.944611 
	C756.569519,400.368500 758.304932,398.806610 759.994690,397.196747 
	C760.187195,397.013336 760.123230,396.560822 760.447937,396.484863 
	C763.131226,398.853912 765.544678,400.971161 767.958130,403.088440 
	C768.536926,403.822052 769.115723,404.555603 769.978027,405.497589 
	C770.261536,405.705994 770.472046,405.971222 770.292236,406.255615 
	C767.249146,410.531403 767.340637,415.450378 765.621399,419.916504 
	C764.923828,419.921021 764.524536,419.903564 764.125244,419.886139 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M672.111694,709.686829 
	C675.092529,709.730042 678.073425,709.773315 681.468506,710.178833 
	C679.912903,711.400452 677.942993,712.259705 675.549316,713.133911 
	C671.715027,713.346191 668.304382,713.543579 664.656860,713.520752 
	C663.633118,714.505188 662.846313,715.709839 662.059448,716.914429 
	C658.410339,717.219116 654.761230,717.523743 651.336182,717.328979 
	C652.334167,716.465576 653.108032,716.101562 654.179382,715.788940 
	C654.821228,715.469543 655.165527,715.098877 655.509888,714.728149 
	C654.995667,714.540894 654.481506,714.353699 653.536621,714.099731 
	C647.820679,713.828979 642.535339,713.625061 636.932129,713.197937 
	C635.848755,712.302734 635.083374,711.630737 633.159851,709.941895 
	C637.352905,709.941895 640.101135,710.038330 642.840088,709.920532 
	C647.562622,709.717407 652.279541,709.381165 657.398560,709.080322 
	C658.865723,709.066284 659.932983,709.072632 661.272278,709.349304 
	C665.066711,709.641968 668.589233,709.664429 672.111694,709.686829 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M687.006104,707.939880 
	C689.075256,706.941895 691.144409,705.943970 693.558472,704.895081 
	C692.851196,707.108276 691.799011,709.372437 690.571777,712.013245 
	C692.707092,712.729553 694.283813,713.258423 695.950806,713.817627 
	C691.566711,720.511963 670.121521,736.166138 663.216187,737.596741 
	C664.282654,734.931824 665.246399,732.523560 666.656860,730.397827 
	C668.548279,731.010742 670.613342,732.123047 671.339050,731.547180 
	C676.877930,727.152588 682.155518,722.428711 688.362244,717.076172 
	C684.624512,716.425720 682.723694,716.094971 680.860962,715.570312 
	C680.899109,715.376404 681.015747,714.998779 681.365234,714.971191 
	C683.478455,712.609009 685.242249,710.274414 687.006104,707.939880 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M856.033325,590.109802 
	C855.496704,590.923645 854.960083,591.737427 853.865601,592.760620 
	C851.765442,594.246643 850.223206,595.523376 848.680969,596.800171 
	C847.931641,597.403381 847.182312,598.006592 845.917603,598.811646 
	C844.854370,599.581177 844.306396,600.148865 843.758423,600.716553 
	C843.758423,600.716553 843.865601,600.888306 843.482544,600.817383 
	C842.391235,601.490784 841.683044,602.235046 840.974854,602.979248 
	C840.974792,602.979248 841.000000,603.000000 840.650757,603.002625 
	C839.054993,604.241699 837.808472,605.478210 836.561951,606.714722 
	C836.561951,606.714722 836.261841,606.793335 835.816467,606.945190 
	C834.072693,608.267639 832.774292,609.438171 831.475830,610.608704 
	C831.152466,610.657349 830.842102,610.749573 830.094910,610.662170 
	C828.111267,609.951050 826.577454,609.463257 825.034607,608.600830 
	C825.008972,607.723877 824.992371,607.221680 824.942932,606.472229 
	C824.884399,605.893250 824.874695,605.560974 825.185303,605.039795 
	C826.596741,604.392517 827.720398,603.969360 828.807312,603.466797 
	C830.583130,602.645691 832.334961,601.772827 834.343689,600.729431 
	C835.437561,599.044800 836.284607,597.552429 837.325806,596.039001 
	C837.519958,596.017944 837.909424,595.987183 838.123047,596.260742 
	C841.555115,597.470886 842.513672,595.526245 842.974365,592.943726 
	C842.998474,592.971619 842.924744,592.968933 843.169434,592.894043 
	C843.640198,592.568787 843.866333,592.318359 844.254883,592.015991 
	C844.417358,591.964050 844.756714,591.929321 845.164612,591.954651 
	C847.577515,591.463928 849.563965,590.850830 851.593689,590.464478 
	C853.043579,590.188599 854.551392,590.217102 856.033325,590.109802 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M594.084229,330.158203 
	C594.065613,329.972839 594.046936,329.787537 594.330566,329.189270 
	C595.619324,327.590454 596.605835,326.404633 597.592285,325.218811 
	C598.037415,325.042419 598.482544,324.866058 599.331909,324.407288 
	C600.214417,323.457428 600.692749,322.789978 601.171082,322.122498 
	C601.171082,322.122498 601.076538,322.011383 601.412720,321.954102 
	C603.892517,319.316345 606.036133,316.735840 608.179749,314.155334 
	C608.179749,314.155334 608.094604,314.082916 608.372437,314.068024 
	C608.821716,313.730988 608.993164,313.408875 609.164612,313.086761 
	C609.164612,313.086761 609.070923,313.069305 609.351440,313.036865 
	C609.820129,312.691833 610.008301,312.379211 610.196533,312.066589 
	C612.050964,310.319580 613.905334,308.572510 616.243774,306.528320 
	C617.358643,306.114349 617.989441,305.997559 618.728516,305.870148 
	C618.836853,305.859528 619.018799,305.973694 618.953735,306.323608 
	C617.672668,309.054901 618.368530,310.226135 621.097412,310.232544 
	C621.121155,310.339539 621.077148,310.554291 620.859375,310.807953 
	C620.399963,311.655670 620.158325,312.249725 619.701721,313.043518 
	C619.010742,313.861145 618.534729,314.479034 617.741211,315.081543 
	C616.694702,315.467102 615.965637,315.868011 614.953552,316.339478 
	C614.444580,316.925385 614.218628,317.440735 613.653992,318.026062 
	C610.551575,320.736176 607.787842,323.376312 604.700684,326.164307 
	C600.946228,327.594177 597.515259,328.876190 594.084229,330.158203 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M984.590942,821.518921 
	C980.675110,823.490540 977.327942,821.564087 976.494385,818.882874 
	C975.588257,815.968567 974.527649,811.355957 979.100586,809.570679 
	C982.490601,808.247253 985.996765,808.365479 988.180115,812.085327 
	C990.123413,815.396301 988.808411,818.959229 984.590942,821.518921 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M489.185242,471.195770 
	C488.416412,472.613739 487.260406,473.964050 485.709229,475.759766 
	C482.889709,478.468323 480.465363,480.731506 477.701202,483.006836 
	C476.423126,483.997284 475.484833,484.975586 474.226196,485.985443 
	C471.588806,488.452362 469.271729,490.887756 466.656616,493.493408 
	C465.199829,494.741669 464.041107,495.819672 462.570679,496.937317 
	C461.859741,497.650299 461.460571,498.323639 460.753632,499.128571 
	C459.652893,500.060516 458.859863,500.860840 457.755981,501.625671 
	C452.106659,505.541351 446.768250,509.492523 441.293427,513.302856 
	C441.157043,513.161987 440.884308,512.880249 440.884308,512.880249 
	C444.109863,510.157227 447.509583,507.613312 450.528046,504.677368 
	C459.321930,496.123993 467.770752,487.206146 476.762115,478.870514 
	C480.206451,475.677399 484.753510,473.673767 489.185242,471.195770 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M830.012024,612.011292 
	C827.748047,614.074585 825.613342,616.306335 823.191895,618.164368 
	C818.784363,621.546387 814.206726,624.706604 809.385376,627.635376 
	C809.836182,626.396057 810.601562,625.480591 811.367004,624.565186 
	C811.223389,624.346497 811.079773,624.127808 810.936157,623.909119 
	C807.803528,625.205627 804.670959,626.502075 801.290100,627.901367 
	C801.556335,625.609192 801.717896,624.218201 801.973022,622.509033 
	C803.458618,620.497192 804.779480,619.349426 806.859985,621.551941 
	C807.520874,622.251587 809.431274,622.748840 810.079773,622.312622 
	C812.873535,620.433594 815.410950,618.173279 818.409424,616.032715 
	C819.803772,615.239441 820.830383,614.465271 821.856934,613.691101 
	C821.247131,613.119019 820.637390,612.546875 819.915039,611.757935 
	C819.599487,611.270996 819.336670,611.085754 819.009399,610.631958 
	C818.984131,609.575439 818.963501,608.872192 819.301147,608.143799 
	C823.110291,609.416138 826.561157,610.713745 830.012024,612.011292 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M711.514832,309.737152 
	C708.709290,310.558868 705.547119,312.618866 704.617493,305.632416 
	C703.635864,308.522736 703.302246,309.505066 702.618530,310.811829 
	C701.016113,311.436371 699.763855,311.736450 698.104675,312.029053 
	C696.118042,310.841553 694.538391,309.661469 693.139160,308.267487 
	C693.168030,307.108704 693.016479,306.163818 692.864990,305.218933 
	C692.896973,304.370361 692.928894,303.521820 693.113831,301.923279 
	C694.860229,300.873962 696.453674,300.574646 698.047058,300.275299 
	C698.047058,300.275299 698.193848,300.495819 698.509155,300.723969 
	C699.174988,301.064545 699.525635,301.176941 699.876221,301.289368 
	C699.876221,301.289368 700.121216,301.513123 700.478821,301.738464 
	C701.194336,302.052246 701.552307,302.140686 701.910217,302.229126 
	C701.910217,302.229126 702.167297,302.430023 702.510498,302.658752 
	C703.211243,302.976776 703.568848,303.066101 703.926453,303.155426 
	C705.260681,303.830627 706.594910,304.505829 708.359375,305.503906 
	C709.108704,305.976318 709.427856,306.125854 709.746948,306.275391 
	C709.746948,306.275391 709.776733,306.306885 709.829834,306.617065 
	C710.426880,307.863861 710.970825,308.800507 711.514832,309.737152 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M870.913452,507.150085 
	C871.002686,507.463593 871.181702,507.712463 871.794556,508.296875 
	C874.655396,510.886810 877.172180,513.076538 879.689026,515.266235 
	C879.689026,515.266235 879.931458,515.191040 879.832458,515.592712 
	C881.465088,517.695129 883.196716,519.395874 884.928345,521.096558 
	C884.928345,521.096558 884.988037,521.005920 884.989685,521.378662 
	C886.967896,524.213928 888.944458,526.676453 890.921021,529.138916 
	C890.921021,529.138916 890.944641,529.583130 890.870178,530.110596 
	C890.674072,530.833008 890.552490,531.027954 890.152466,531.081543 
	C886.965332,528.498718 884.056580,526.057312 881.046265,523.265137 
	C878.605957,521.959717 876.267273,521.005005 873.257568,519.776428 
	C872.271606,514.141113 870.379822,508.160828 863.465332,505.453796 
	C862.633972,505.128326 862.409851,503.252014 862.068909,501.924683 
	C862.233582,501.754883 862.562012,501.414337 862.846558,501.538025 
	C863.733521,501.813782 864.335999,501.965851 864.938477,502.117920 
	C865.300781,502.472076 865.663086,502.826233 866.407837,503.517639 
	C867.482483,504.287842 868.174561,504.720795 868.866699,505.153748 
	C868.866699,505.153748 868.990234,505.011169 868.979614,505.384583 
	C869.617065,506.222015 870.265259,506.686066 870.913452,507.150085 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M680.822876,715.764221 
	C682.723694,716.094971 684.624512,716.425720 688.362244,717.076172 
	C682.155518,722.428711 676.877930,727.152588 671.339050,731.547180 
	C670.613342,732.123047 668.548279,731.010742 667.013794,730.314575 
	C668.697754,727.290833 670.471436,724.632812 672.425354,721.704834 
	C670.833313,720.520630 668.961121,719.127991 667.405518,717.394165 
	C672.017639,717.481873 675.229858,722.747009 680.117615,720.532043 
	C679.837097,720.167542 679.556580,719.803040 679.276062,719.438538 
	C679.791687,718.213745 680.307312,716.989014 680.822876,715.764221 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M427.016632,556.284363 
	C428.134613,556.741211 429.374542,557.548584 431.082581,558.642944 
	C432.932129,560.186401 434.313568,561.442810 435.816803,562.972229 
	C437.116425,563.986816 438.294312,564.728455 439.535950,565.734985 
	C439.712830,566.349792 439.825897,566.699707 439.967194,567.396423 
	C442.377472,569.820435 444.759552,571.897583 447.324188,574.082275 
	C447.735077,574.365601 447.912567,574.582886 447.968506,575.207275 
	C447.475342,576.453552 447.052979,577.334290 446.313202,578.181152 
	C445.047089,577.727478 444.098389,577.307678 443.149658,576.887939 
	C441.618774,575.704041 440.087921,574.520081 438.096344,573.017578 
	C436.774078,572.054688 435.912537,571.410400 435.050964,570.766174 
	C432.413513,566.055725 429.776062,561.345337 427.016632,556.284363 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M765.919678,419.894531 
	C767.340637,415.450378 767.249146,410.531403 770.502441,406.547974 
	C773.306519,409.354736 775.720520,412.153564 778.134521,414.952423 
	C778.753052,416.090576 779.371643,417.228729 779.599854,418.651428 
	C776.713562,416.620239 775.305115,416.378418 773.064453,419.943298 
	C770.636230,423.806549 774.688293,424.580292 774.586792,426.979248 
	C771.900635,426.691223 769.628540,426.355133 767.356506,426.019043 
	C767.780396,425.482971 768.204346,424.946930 768.628235,424.410858 
	C767.725403,422.905426 766.822571,421.399994 765.919678,419.894531 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M586.611084,390.558105 
	C586.151184,389.357269 585.691284,388.156403 585.208130,386.894897 
	C586.553345,385.817719 587.780029,384.835480 589.583923,383.391083 
	C587.902161,382.540039 586.746033,381.954987 585.770264,381.461212 
	C585.528076,379.842926 585.302979,378.339508 584.915894,376.317505 
	C585.464050,375.482117 586.174255,375.165314 586.964478,375.178223 
	C587.781189,375.869965 588.517883,376.231934 589.254578,376.593872 
	C588.846802,375.736084 588.438965,374.878296 588.021057,373.772400 
	C588.010925,373.524292 588.019287,373.027710 588.019287,373.027710 
	C588.566650,372.855743 589.114014,372.683807 590.195496,372.270569 
	C591.421875,371.940399 592.114197,371.851593 592.884644,372.147766 
	C594.408325,373.323547 595.853882,374.114380 597.188110,374.844330 
	C595.807678,377.177002 594.333008,379.668976 592.835876,382.198853 
	C595.837585,382.787811 597.975403,383.207245 599.733154,383.729858 
	C595.105774,386.074738 590.858459,388.316437 586.611084,390.558105 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M765.621460,419.916504 
	C766.822571,421.399994 767.725403,422.905426 768.628235,424.410858 
	C768.204346,424.946930 767.780396,425.482971 767.356506,426.019043 
	C769.628540,426.355133 771.900635,426.691223 774.910645,426.990051 
	C776.441284,426.631348 777.233948,426.309875 778.311279,426.121399 
	C779.388123,426.493256 780.180176,426.732117 781.172668,427.006653 
	C781.373047,427.042328 781.773682,427.114166 781.773682,427.114166 
	C781.739319,428.141632 781.705017,429.169067 781.703247,430.580963 
	C781.091675,432.570221 780.447571,434.175049 779.749939,435.913300 
	C778.064941,434.714600 776.746643,433.776733 775.428345,432.838867 
	C774.689026,434.315155 773.949707,435.791443 772.473450,438.739502 
	C769.459595,432.192078 766.803040,426.420746 764.135864,420.267761 
	C764.524536,419.903564 764.923828,419.921021 765.621460,419.916504 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M887.753113,560.095947 
	C884.481384,566.928162 880.239685,572.885742 872.769043,576.576477 
	C872.005737,576.956116 871.631104,577.041687 870.747498,577.047974 
	C868.868896,576.897461 867.499329,576.826233 866.388916,576.511841 
	C867.766174,575.131226 868.884338,573.993652 870.297119,572.817871 
	C870.942322,572.341064 871.292908,571.902527 871.901611,571.401245 
	C872.487305,570.944885 872.815002,570.551331 873.458313,570.086670 
	C874.356567,569.152893 874.939209,568.290222 875.777893,567.309448 
	C876.488831,566.611633 876.943726,566.031921 877.722717,565.369324 
	C879.660278,563.753479 881.273865,562.220459 883.298279,560.726807 
	C885.057129,560.542786 886.405090,560.319336 887.753113,560.095947 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M726.827515,338.178589 
	C728.806580,337.770782 730.785583,337.362946 733.288696,337.343811 
	C733.873108,338.820862 733.933411,339.909210 733.993652,340.997528 
	C731.762390,344.130768 729.531189,347.263977 726.831665,350.818054 
	C724.582581,352.507507 722.801758,353.776123 720.865234,354.704468 
	C718.451294,354.218048 716.193176,354.071808 713.787781,353.626099 
	C712.549744,351.644409 711.458984,349.962158 710.368286,348.279907 
	C711.596436,347.724304 712.824585,347.168732 714.263855,346.517670 
	C714.975037,348.651459 715.468018,350.130463 715.750732,350.978699 
	C718.254700,350.636169 720.245300,350.363861 722.552734,350.029877 
	C724.575867,348.352814 726.282104,346.737457 728.275146,344.927734 
	C729.430725,343.409454 730.299438,342.085571 730.613220,341.607422 
	C729.000916,340.147156 727.914246,339.162872 726.827515,338.178589 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M777.422424,445.247559 
	C777.658203,445.224213 777.894043,445.200897 778.484680,445.169861 
	C778.839539,445.162140 778.854919,445.134430 779.061462,445.433075 
	C787.825867,448.961731 796.383789,452.191711 804.941650,455.421692 
	C804.941650,455.421692 805.116211,455.495087 805.378174,455.756531 
	C806.922302,456.926453 808.204590,457.834930 809.086182,458.816956 
	C807.465149,459.536255 806.244690,460.181946 804.725098,461.003540 
	C804.193909,461.812164 803.961853,462.444885 803.454224,462.934326 
	C802.338928,462.053619 800.669922,460.842957 800.780945,460.648315 
	C803.320190,456.199738 798.619080,456.546509 796.823486,454.605286 
	C793.442017,454.200531 790.263733,454.291321 787.168640,453.827423 
	C785.438538,453.568085 782.477966,452.465485 782.429565,451.609619 
	C782.223145,447.956696 779.719482,447.603271 777.182800,446.691711 
	C777.110535,446.318054 777.029114,446.151978 777.069092,446.023712 
	C777.153015,445.754456 777.300842,445.505096 777.422424,445.247559 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M424.020111,538.117615 
	C424.494354,542.997559 424.968628,547.877563 425.872681,553.074646 
	C426.302460,553.391785 426.260620,553.708740 426.260620,553.708740 
	C426.180023,554.196594 426.099426,554.684387 425.970581,555.542542 
	C420.902557,554.984253 422.983887,550.461487 422.346649,547.800171 
	C418.725555,532.677673 428.042816,519.568542 440.546204,512.955078 
	C440.884308,512.880249 441.157043,513.161987 441.168945,513.609375 
	C440.189972,514.891479 439.066681,515.612122 438.231262,516.580994 
	C434.675201,520.704956 431.204376,524.902405 427.399658,529.157471 
	C426.175415,531.149231 425.253937,533.056458 424.198975,535.340515 
	C424.050385,536.517395 424.035248,537.317505 424.020111,538.117615 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M668.748108,293.650421 
	C660.971375,294.659119 652.964294,295.376160 644.187622,296.120819 
	C642.222168,297.368591 641.026306,298.588715 639.827393,299.844299 
	C639.824280,299.879791 639.893799,299.895233 639.505859,299.789062 
	C635.534241,298.086975 632.249268,298.716248 628.887573,300.601501 
	C626.792419,301.776459 624.335754,302.306793 622.041321,303.126373 
	C624.139709,297.177338 630.263855,297.478790 634.542053,296.396729 
	C643.909424,294.027435 653.698792,293.276978 663.345276,292.108093 
	C664.994690,291.908264 666.789856,292.911346 668.748108,293.650421 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M801.879517,622.827209 
	C801.717896,624.218201 801.556335,625.609192 801.290100,627.901367 
	C804.670959,626.502075 807.803528,625.205627 810.936157,623.909119 
	C811.079773,624.127808 811.223389,624.346497 811.367004,624.565186 
	C810.601562,625.480591 809.836182,626.396057 809.020569,627.629272 
	C807.591858,628.991028 806.213318,630.034912 804.516968,631.127808 
	C803.358459,628.145203 801.536926,628.779663 799.660339,630.029663 
	C798.299133,630.936340 797.035950,631.989929 795.306274,632.676636 
	C792.041382,632.948364 789.199158,633.521484 786.263916,633.762695 
	C785.665894,632.434753 785.160889,631.438782 785.069214,630.303406 
	C789.299622,628.654785 793.148499,627.219604 796.919739,625.603333 
	C798.651550,624.861206 800.230957,623.763611 801.879517,622.827209 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M510.260071,456.471222 
	C510.784424,456.307312 511.308746,456.143433 512.425293,455.763306 
	C516.124390,454.495819 519.231262,453.444580 522.737244,452.578552 
	C525.376831,454.112152 527.617371,455.460571 529.479736,456.858093 
	C526.975281,457.728729 524.849121,458.550262 522.377319,459.384888 
	C519.406982,460.333191 516.782288,461.268372 513.887085,461.997742 
	C512.514404,460.198975 511.412140,458.606049 510.354248,456.872437 
	C510.398651,456.731750 510.260071,456.471222 510.260071,456.471222 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M788.998840,772.072510 
	C789.393860,787.963379 789.465942,804.044861 789.637573,820.588013 
	C789.188110,820.012207 788.168213,818.978027 788.161072,817.936829 
	C788.059814,803.161743 788.090820,788.385742 788.122925,773.609985 
	C788.123901,773.160645 788.483459,772.712097 788.998840,772.072510 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M596.941223,349.215485 
	C595.815979,347.431641 594.690796,345.647766 593.779175,343.456238 
	C594.321899,342.848846 594.651001,342.649139 595.311462,342.383881 
	C596.090393,342.284943 596.537964,342.251556 596.964844,342.533264 
	C597.273865,343.554138 597.603577,344.259918 598.115845,345.259827 
	C601.384216,348.336243 604.008484,352.037567 609.229370,351.225830 
	C611.041260,353.316132 612.662781,355.127197 614.010620,357.114258 
	C613.515259,357.885925 613.293640,358.481567 612.760620,359.127197 
	C611.955872,359.500061 611.462585,359.822937 610.694336,360.053406 
	C609.933899,359.662506 609.448303,359.364014 608.870544,358.772614 
	C608.229797,358.269867 607.681335,358.060059 607.132812,357.850250 
	C605.309143,356.291992 603.485474,354.733765 601.293091,352.810974 
	C600.617737,352.284576 600.311096,352.122742 599.843933,351.702454 
	C598.769409,350.701141 597.855286,349.958313 596.941223,349.215485 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M866.129761,576.754883 
	C867.499329,576.826233 868.868896,576.897461 870.740417,577.335693 
	C871.124756,578.059326 871.007202,578.416016 870.889526,578.772705 
	C869.753540,579.368713 868.617554,579.964783 866.866272,580.709106 
	C864.841675,581.578491 863.432434,582.299683 861.662476,583.045715 
	C857.579285,584.728821 853.856812,586.387207 850.047180,587.704712 
	C847.942322,587.175049 845.924622,586.986328 844.074219,586.513916 
	C846.567932,585.246155 848.894348,584.262146 851.594116,583.309814 
	C855.048828,581.781860 858.130310,580.222168 861.551697,578.574707 
	C863.304321,577.909607 864.717041,577.332275 866.129761,576.754883 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M753.067139,395.105865 
	C752.176697,396.951355 751.286255,398.796875 749.814758,400.709167 
	C748.145813,399.190369 747.057922,397.604828 745.969971,396.019287 
	C746.115479,392.177490 744.705017,387.981232 748.877380,384.846802 
	C749.267334,384.725677 749.656494,384.766632 749.991516,385.045624 
	C751.168640,385.994904 752.010681,386.665192 752.852783,387.335449 
	C753.233826,387.939880 753.614868,388.544312 754.052307,389.713837 
	C754.414246,390.834717 754.719788,391.390503 754.851929,392.218262 
	C754.145874,393.342072 753.613220,394.193909 753.069763,395.057983 
	C753.058899,395.070221 753.067139,395.105865 753.067139,395.105865 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M522.338196,452.393341 
	C519.231262,453.444580 516.124390,454.495819 512.625000,455.568909 
	C513.370239,454.473450 514.311279,452.858673 515.677124,452.318939 
	C525.036865,448.620392 534.485596,445.147156 543.855591,441.620209 
	C543.285767,438.560822 542.813416,436.024689 542.780518,433.522583 
	C544.295227,436.071686 545.370422,438.586731 546.443115,441.393646 
	C546.453918,442.072174 546.467102,442.458832 546.124878,442.861023 
	C541.866150,444.640228 537.962708,446.403931 533.733765,448.142365 
	C532.606750,448.426178 531.805298,448.735291 530.629089,449.010345 
	C527.615662,450.115326 524.976929,451.254333 522.338196,452.393341 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M697.870605,299.943665 
	C696.453674,300.574646 694.860229,300.873962 693.097717,301.498901 
	C690.143921,301.074982 686.389465,301.191528 684.781433,299.391083 
	C681.669189,295.906311 678.241028,295.680298 674.254578,295.810791 
	C673.251282,295.843658 672.223938,295.142303 671.097351,294.402649 
	C673.487183,294.017426 676.109497,293.512543 678.463989,294.093292 
	C684.934143,295.689331 691.291931,297.740814 697.870605,299.943665 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M680.860962,715.570312 
	C680.307312,716.989014 679.791687,718.213745 679.276062,719.438538 
	C679.556580,719.803040 679.837097,720.167542 680.117615,720.532043 
	C675.229858,722.747009 672.017639,717.481873 667.353699,717.056274 
	C666.607727,716.963989 666.230164,716.868408 665.477173,716.372620 
	C665.032471,715.228577 664.963135,714.484802 664.893799,713.740967 
	C668.304382,713.543579 671.715027,713.346191 675.810791,713.362793 
	C678.002502,714.050720 679.509155,714.524780 681.015747,714.998779 
	C681.015747,714.998779 680.899109,715.376404 680.860962,715.570312 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M646.848877,726.703735 
	C644.790588,726.692444 642.314758,726.923157 640.665649,725.865173 
	C637.951965,724.124268 635.773560,721.548950 633.266052,719.229736 
	C634.634521,718.586975 635.868103,718.007507 637.548584,717.194580 
	C641.023865,720.119080 644.574402,718.811646 648.050964,718.136597 
	C647.079041,719.351440 646.107056,720.566284 644.620667,722.424133 
	C645.032227,723.167847 645.917480,724.767334 646.848877,726.703735 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M648.336304,717.997864 
	C644.574402,718.811646 641.023865,720.119080 637.650574,716.867371 
	C637.305786,716.773621 637.092773,716.408447 637.044922,715.889038 
	C637.081360,714.720093 637.165710,714.070618 637.250061,713.421143 
	C642.535339,713.625061 647.820679,713.828979 653.504272,714.359863 
	C653.895691,715.037048 653.888794,715.387268 653.881958,715.737549 
	C653.108032,716.101562 652.334167,716.465576 651.269287,716.990784 
	C650.192688,717.387756 649.407166,717.623474 648.336304,717.997864 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M510.309845,457.013123 
	C511.412140,458.606049 512.514404,460.198975 513.844482,462.337067 
	C513.450684,463.940704 512.829163,464.999176 511.972198,466.458527 
	C513.618530,466.674500 514.819824,466.832092 515.892334,467.290222 
	C514.853088,468.364594 513.942688,469.138367 512.590698,469.874298 
	C509.807953,469.826874 507.466736,469.817261 505.104462,469.505371 
	C505.042572,468.801117 505.001740,468.399170 505.175476,467.722778 
	C506.810181,465.027344 508.230377,462.606354 510.209198,459.233032 
	C507.236755,459.509369 505.999115,459.624420 504.761475,459.739471 
	C505.030396,459.281830 505.299347,458.824219 506.092346,458.202057 
	C507.102142,457.715820 507.587860,457.394135 508.073547,457.072418 
	C508.073547,457.072418 507.988708,457.047729 508.402771,457.060089 
	C509.314514,457.052673 509.812164,457.032898 510.309845,457.013123 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M459.081177,590.826294 
	C456.757355,588.872803 454.433533,586.919312 451.665405,584.551819 
	C449.744446,582.476868 448.267792,580.815918 447.172302,579.075439 
	C452.002655,581.057190 456.451874,583.118652 461.104401,585.397949 
	C462.221283,586.350952 463.134827,587.086182 463.923889,588.185059 
	C463.439240,589.781921 463.079071,591.015076 462.391479,592.195068 
	C461.069763,591.703369 460.075470,591.264832 459.081177,590.826294 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M504.474976,459.875305 
	C505.999115,459.624420 507.236755,459.509369 510.209198,459.233032 
	C508.230377,462.606354 506.810181,465.027344 504.796936,467.729584 
	C501.783997,467.670624 499.364075,467.330414 497.011047,466.712982 
	C497.176147,466.076385 497.274353,465.717010 497.372589,465.357635 
	C497.372589,465.357635 497.123596,465.030792 497.568665,465.174316 
	C498.552338,464.640503 499.090942,463.963135 499.629517,463.285706 
	C501.149170,462.194214 502.668823,461.102692 504.474976,459.875305 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M804.851135,455.051392 
	C796.383789,452.191711 787.825867,448.961731 779.073853,445.431854 
	C784.380859,444.401428 789.295044,445.462738 793.926453,448.846405 
	C797.207458,451.243500 801.126404,452.767426 804.851135,455.051392 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M778.026550,425.988403 
	C777.233948,426.309875 776.441284,426.631348 775.324829,426.942017 
	C774.688293,424.580292 770.636230,423.806549 773.064453,419.943298 
	C775.305115,416.378418 776.713562,416.620239 779.563599,418.977509 
	C780.180298,419.239349 780.442932,419.459656 780.858887,420.213104 
	C780.942566,421.815521 780.872925,422.884796 780.467041,423.945312 
	C779.429321,424.620514 778.727966,425.304474 778.026550,425.988403 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M887.947144,560.152771 
	C886.405090,560.319336 885.057129,560.542786 883.273804,560.372925 
	C883.469482,558.241089 884.100464,556.502686 884.823853,554.509949 
	C884.991577,553.901978 885.066895,553.548340 885.386719,553.051758 
	C885.812317,552.395081 885.993469,551.881348 886.367798,551.186401 
	C886.737061,550.701416 886.913208,550.397583 887.382202,550.053467 
	C888.591553,549.696106 889.508057,549.379089 890.567444,549.419067 
	C890.765015,551.531189 890.819763,553.286316 890.874512,555.041382 
	C890.692078,555.481506 890.509705,555.921692 890.052124,556.910828 
	C889.471863,558.261536 889.166748,559.063293 888.861572,559.865051 
	C888.586731,559.907288 888.346558,560.022095 887.947144,560.152771 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M621.849243,303.344452 
	C624.335754,302.306793 626.792419,301.776459 628.887573,300.601501 
	C632.249268,298.716248 635.534241,298.086975 639.452637,300.089050 
	C639.162476,300.998383 638.537476,301.501587 637.607605,301.842224 
	C636.243347,301.404022 635.184082,301.128387 634.274292,300.891693 
	C633.718872,302.878296 633.261475,304.514191 632.470886,306.045746 
	C630.634277,305.021790 629.130798,304.102203 628.470764,303.698486 
	C625.068176,304.158966 622.451416,304.513123 619.891235,304.651642 
	C619.947754,304.436035 619.997070,303.992950 619.997070,303.992981 
	C620.550476,303.849487 621.103821,303.706024 621.849243,303.344452 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M594.980103,342.449463 
	C594.651001,342.649139 594.321899,342.848846 593.704285,343.102356 
	C593.415710,343.156219 593.039551,343.163177 592.887695,342.725098 
	C592.603577,339.510895 592.471252,336.734772 592.748901,333.964661 
	C594.584106,333.356415 595.969421,332.599121 597.450684,332.185059 
	C598.255371,331.960083 599.223206,332.319000 600.026123,332.734528 
	C598.953857,335.705963 597.973022,338.357422 596.996094,341.004456 
	C597.000000,341.000000 597.008728,340.991638 596.657776,340.997406 
	C595.864563,341.485260 595.422363,341.967377 594.980103,342.449463 
M593.756287,337.181335 
	C593.640808,337.019226 593.525330,336.857117 593.409851,336.694977 
	C593.433044,336.921906 593.456238,337.148865 593.756287,337.181335 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M483.876953,607.362000 
	C484.265900,608.322815 484.654877,609.283569 485.112732,610.546814 
	C477.957916,608.824097 472.513367,604.344177 468.418030,598.120972 
	C469.161346,597.865173 469.573578,597.947937 470.119049,598.340332 
	C474.793854,601.554016 479.335388,604.458008 483.876953,607.362000 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M850.134338,588.045654 
	C853.856812,586.387207 857.579285,584.728821 861.653809,583.384949 
	C862.014221,584.152039 862.022644,584.604614 862.000366,585.314209 
	C862.033447,585.731323 862.097229,585.891602 862.161072,586.051880 
	C861.845520,586.168579 861.564087,586.340942 860.812256,586.735107 
	C859.807800,587.570923 859.307739,588.240540 858.807678,588.910156 
	C858.086060,589.288818 857.364441,589.667419 856.338013,590.077881 
	C854.551392,590.217102 853.043579,590.188599 851.593689,590.464478 
	C849.563965,590.850830 847.577515,591.463928 845.318298,591.643433 
	C846.754211,590.219849 848.444275,589.132751 850.134338,588.045654 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M601.646729,366.437683 
	C601.646729,366.437683 601.863831,366.555603 602.346008,366.440857 
	C604.246399,365.563232 605.664612,364.800354 607.082825,364.037476 
	C607.082825,364.037476 607.526306,363.924103 608.051758,363.898010 
	C609.347046,363.530914 610.116943,363.189880 611.264343,362.896576 
	C616.070312,363.707764 612.619568,366.336975 612.492065,368.048706 
	C611.014099,367.684357 609.923767,367.236572 608.791870,367.067963 
	C606.226379,366.685791 605.856140,367.843964 606.985229,370.262207 
	C606.952576,371.122253 606.925232,371.613708 606.595337,371.919800 
	C604.396973,370.663361 602.501160,369.592255 600.669800,368.231934 
	C601.038391,367.441040 601.342590,366.939362 601.646729,366.437683 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M760.178162,396.233093 
	C760.123230,396.560822 760.187195,397.013336 759.994690,397.196747 
	C758.304932,398.806610 756.569519,400.368500 754.847595,401.944611 
	C754.252014,399.947235 753.656433,397.949860 753.063965,395.529175 
	C753.067139,395.105865 753.058899,395.070221 753.435120,395.070801 
	C755.051208,394.385529 756.291138,393.699677 757.531006,393.013855 
	C757.826233,392.877075 758.119812,392.880493 758.670532,393.344666 
	C759.345520,394.521240 759.761841,395.377167 760.178162,396.233093 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M568.879395,392.794250 
	C570.627380,393.399719 572.375366,394.005188 574.789246,394.841370 
	C574.874756,394.512817 574.626343,395.467041 574.186523,396.713745 
	C573.426025,397.255585 572.490662,397.371582 572.343994,397.774719 
	C570.712708,402.255920 568.715576,401.496094 566.374939,398.347260 
	C566.166992,398.067505 565.466187,398.154083 564.569946,397.807556 
	C564.119507,397.374237 564.093506,397.203888 564.067505,397.033569 
	C564.401794,396.952423 564.662964,396.767822 565.237427,396.140991 
	C566.122681,394.900909 566.621460,393.999603 567.120239,393.098297 
	C567.120239,393.098297 567.004456,393.012207 567.352783,393.030151 
	C568.093872,392.963470 568.486633,392.878845 568.879395,392.794250 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M824.106934,467.893402 
	C829.396973,471.987701 834.687012,476.082001 839.913330,480.682190 
	C839.849670,481.188080 839.561829,481.530640 839.238647,481.454651 
	C837.102661,480.154327 835.289856,478.929962 833.370850,477.400146 
	C832.569580,476.645203 831.874634,476.195740 831.054688,475.485840 
	C830.316650,474.772705 829.703613,474.320007 829.037598,473.618164 
	C828.682190,473.253601 828.379883,473.138245 827.890503,472.784393 
	C827.023315,472.178131 826.343140,471.810333 825.556152,471.152405 
	C824.812927,470.069855 824.176514,469.277466 823.681519,468.336914 
	C823.822876,468.188721 824.106934,467.893402 824.106934,467.893402 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M600.117493,332.414551 
	C599.223206,332.319000 598.255371,331.960083 597.450684,332.185059 
	C595.969421,332.599121 594.584106,333.356415 592.822754,333.570740 
	C592.716553,332.136841 592.946472,331.102875 593.176331,330.068878 
	C593.176331,330.068878 593.631287,330.102295 593.857788,330.130249 
	C597.515259,328.876190 600.946228,327.594177 604.678833,326.583496 
	C604.988098,327.975250 604.995667,329.095612 605.006348,330.478790 
	C605.016357,331.095734 605.023132,331.449921 604.615479,331.804626 
	C602.839844,332.008301 601.478638,332.211426 600.117493,332.414551 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M546.445557,441.101807 
	C545.370422,438.586731 544.295227,436.071686 543.013428,433.259003 
	C542.874023,432.789856 542.941284,432.618317 543.134705,432.199738 
	C544.562256,433.832672 545.863647,435.712616 547.687012,438.045959 
	C550.309631,437.684937 552.410278,436.870544 554.510986,436.056183 
	C554.817322,436.386627 555.123718,436.717102 555.430054,437.047577 
	C553.883423,438.391846 552.336853,439.736115 550.448608,441.079956 
	C548.886536,441.086975 547.666016,441.094391 546.445557,441.101807 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M842.906982,483.184784 
	C846.065918,486.047028 849.224854,488.909241 852.796082,492.149963 
	C853.456299,493.023895 853.704163,493.519379 853.572876,494.037079 
	C851.747986,493.597260 850.302307,493.135254 848.730835,492.439758 
	C847.509155,491.151093 846.413330,490.095917 845.144409,488.777435 
	C844.196350,487.953522 843.421326,487.392883 842.433838,486.555420 
	C841.775513,485.251923 841.329651,484.225189 841.218628,483.142700 
	C842.004578,483.119507 842.455811,483.152161 842.906982,483.184784 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M713.935059,353.925568 
	C716.193176,354.071808 718.451294,354.218048 720.694519,354.951721 
	C720.537964,355.903625 720.396362,356.268066 720.254761,356.632507 
	C719.709961,357.003662 719.165222,357.374786 718.263916,358.085754 
	C717.574829,358.806305 717.242249,359.187042 716.757751,359.488403 
	C716.605774,359.409058 716.281433,359.294739 716.115356,358.949677 
	C714.778931,357.592102 713.608521,356.579590 712.584717,355.319397 
	C713.132507,354.689667 713.533752,354.307617 713.935059,353.925568 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M815.213196,460.810425 
	C821.267761,458.655762 822.037598,463.708588 824.025269,467.552612 
	C824.106934,467.893402 823.822876,468.188721 823.353638,468.249084 
	C820.211304,465.925751 817.538269,463.542053 814.950195,461.069366 
	C815.035217,460.980377 815.213196,460.810425 815.213196,460.810425 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M830.058472,611.756470 
	C826.561157,610.713745 823.110291,609.416138 818.974121,608.114502 
	C817.859009,608.187500 817.429077,608.264526 816.946533,608.089783 
	C816.863220,607.669250 816.832581,607.500549 817.136475,607.122192 
	C819.940918,606.351135 822.411011,605.789673 824.881042,605.228271 
	C824.874695,605.560974 824.884399,605.893250 824.707153,606.654846 
	C824.228088,607.597351 823.951965,608.110107 823.675903,608.622864 
	C824.131836,608.740356 824.587708,608.857910 825.043640,608.975464 
	C826.577454,609.463257 828.111267,609.951050 829.856873,610.711914 
	C830.080750,611.157166 830.092834,611.329407 830.058472,611.756470 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M891.154785,554.850220 
	C890.819763,553.286316 890.765015,551.531189 890.672485,548.968506 
	C890.505554,545.735840 890.376343,543.310791 890.407715,540.480103 
	C890.464539,538.340942 890.360657,536.607422 890.396484,534.529236 
	C890.501099,533.197388 890.466003,532.210144 890.430908,531.222961 
	C890.552490,531.027954 890.674072,530.833008 890.935852,530.323120 
	C892.343994,538.208374 893.333313,546.412659 891.154785,554.850220 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M716.909729,359.567810 
	C717.242249,359.187042 717.574829,358.806305 718.286377,358.355103 
	C717.694824,363.431213 723.009521,363.901581 725.044312,367.170227 
	C722.643982,366.521118 720.221313,365.530548 717.371704,364.076538 
	C716.924194,362.248138 716.903748,360.883179 716.902527,359.525604 
	C716.921753,359.533020 716.909729,359.567810 716.909729,359.567810 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M592.785400,330.096863 
	C592.946472,331.102875 592.716553,332.136841 592.412781,333.564758 
	C592.471252,336.734772 592.603577,339.510895 592.704834,342.680786 
	C588.920044,340.329468 588.786377,334.133575 592.785400,330.096863 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M733.730347,331.090027 
	C730.758728,328.232269 727.879822,324.998627 724.950317,321.427155 
	C727.874146,324.297607 730.848633,327.505890 733.730347,331.090027 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M760.447937,396.484863 
	C759.761841,395.377167 759.345520,394.521240 758.895020,393.372742 
	C761.913269,396.168945 764.965759,399.257660 767.988220,402.717407 
	C765.544678,400.971161 763.131226,398.853912 760.447937,396.484863 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M446.791138,579.155029 
	C448.267792,580.815918 449.744446,582.476868 451.353302,584.454468 
	C446.440704,584.704712 443.986450,581.586548 442.870422,577.054443 
	C444.098389,577.307678 445.047089,577.727478 446.243744,578.435425 
	C446.591522,578.867371 446.691315,579.011169 446.791138,579.155029 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M740.840210,374.836334 
	C739.781433,374.793823 738.741333,374.388550 737.361206,373.980438 
	C734.753174,372.879639 732.485168,371.781677 729.794434,370.392639 
	C729.241516,369.751953 729.111328,369.402405 728.981140,369.052826 
	C733.975220,368.592255 737.405090,371.559082 740.840210,374.836334 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M459.054230,591.224243 
	C460.075470,591.264832 461.069763,591.703369 462.427124,592.171509 
	C462.790222,592.201050 462.817719,592.121216 462.863831,592.434814 
	C463.600372,593.189758 464.290802,593.631165 464.980774,594.044434 
	C464.980255,594.016296 464.927643,594.039307 465.056396,594.320435 
	C465.834351,595.381836 466.483551,596.161987 467.132721,596.942200 
	C462.983368,597.374329 459.315216,597.073303 459.054230,591.224243 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M601.232178,366.312592 
	C601.342590,366.939362 601.038391,367.441040 600.316406,368.287170 
	C597.534485,369.675323 595.170471,370.719025 592.806458,371.762756 
	C592.114197,371.851593 591.421875,371.940399 590.379211,372.045410 
	C593.625061,370.103546 597.221313,368.145508 601.232178,366.312592 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M778.370483,414.758423 
	C775.720520,412.153564 773.306519,409.354736 770.682251,406.263550 
	C770.472046,405.971222 770.261536,405.705994 770.265747,405.538239 
	C775.228821,406.458405 776.484375,410.904297 778.370483,414.758423 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M544.694092,418.052551 
	C544.979919,418.777466 544.895569,419.546051 544.701538,420.695984 
	C544.014954,423.798767 543.437866,426.520264 542.723633,429.647034 
	C540.162964,426.619873 540.797913,422.249969 544.694092,418.052551 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M607.796265,314.103302 
	C606.036133,316.735840 603.892517,319.316345 601.438416,322.022369 
	C601.446838,318.070312 603.016174,314.963470 607.796265,314.103302 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M809.486816,458.743378 
	C808.204590,457.834930 806.922302,456.926453 805.468994,455.729797 
	C808.527039,457.011444 811.756104,458.581268 815.099243,460.480743 
	C815.213196,460.810425 815.035217,460.980377 814.616333,461.006409 
	C812.627197,460.269409 811.057007,459.506409 809.486816,458.743378 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M879.767944,514.903442 
	C877.172180,513.076538 874.655396,510.886810 872.010132,508.387024 
	C876.233215,508.140930 878.110840,511.253571 879.767944,514.903442 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M596.882507,349.543365 
	C597.855286,349.958313 598.769409,350.701141 599.838745,351.967987 
	C599.997070,352.668121 600.000244,352.844269 600.003357,353.020386 
	C598.684021,353.468872 597.364685,353.917358 596.045349,354.365845 
	C596.304871,352.867645 596.564331,351.369446 596.882507,349.543365 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M587.826782,372.772949 
	C588.019287,373.027710 588.010925,373.524292 587.791992,373.852509 
	C587.275574,374.333221 587.046021,374.555817 586.884460,374.848480 
	C586.174255,375.165314 585.464050,375.482117 584.602783,376.062805 
	C583.512756,377.169739 582.573853,378.012817 581.331726,378.946228 
	C581.243958,374.850677 583.628723,372.863190 587.826782,372.772949 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M890.957397,528.746948 
	C888.944458,526.676453 886.967896,524.213928 884.947021,521.412231 
	C888.643433,522.069824 890.914978,524.295349 890.957397,528.746948 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M566.086975,671.821716 
	C567.219910,673.218689 568.352844,674.615662 569.585815,676.342407 
	C565.258179,677.899292 564.633545,674.140503 563.365601,671.277283 
	C564.473999,671.229736 565.280457,671.525696 566.086975,671.821716 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M711.818115,309.795959 
	C710.970825,308.800507 710.426880,307.863861 709.806213,306.614441 
	C713.209106,305.340454 713.971680,307.950500 714.966370,310.639893 
	C714.027771,310.621674 713.074585,310.238220 711.818115,309.795959 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M884.928833,520.735107 
	C883.196716,519.395874 881.465088,517.695129 879.720276,515.652100 
	C881.447815,516.997681 883.188599,518.685669 884.928833,520.735107 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M720.668335,356.745697 
	C720.396362,356.268066 720.537964,355.903625 720.850342,355.291931 
	C722.801758,353.776123 724.582581,352.507507 726.671265,351.114441 
	C726.794678,354.736084 724.931824,356.795807 720.668335,356.745697 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M551.060913,663.281494 
	C553.399292,664.345764 555.769714,665.759705 558.264282,667.504272 
	C555.956604,666.433777 553.524841,665.032471 551.060913,663.281494 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M497.000031,465.261475 
	C497.274353,465.717010 497.176147,466.076385 496.693359,466.758545 
	C495.199890,468.030334 494.091034,468.979370 492.982147,469.928406 
	C492.553009,469.983551 492.123840,470.038727 491.367188,470.096130 
	C492.902283,468.454041 494.764862,466.809692 497.000031,465.261475 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M610.886841,362.848877 
	C610.116943,363.189880 609.347046,363.530914 608.260742,363.804749 
	C607.647339,362.402039 607.350403,361.066528 607.348389,359.383118 
	C608.083069,359.045319 608.522949,359.055420 608.962769,359.065521 
	C609.448303,359.364014 609.933899,359.662506 610.679077,360.391846 
	C610.921448,361.498077 610.904175,362.173492 610.886841,362.848877 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M780.803345,423.954071 
	C780.872925,422.884796 780.942566,421.815521 781.080078,420.347382 
	C781.567322,422.173492 781.986694,424.398468 782.089844,426.868805 
	C781.773682,427.114166 781.373047,427.042328 781.236206,426.627808 
	C781.000732,425.460205 780.902039,424.707123 780.803345,423.954071 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M580.632324,379.006439 
	C579.727966,380.440857 578.419373,381.854187 576.807739,383.352661 
	C575.320801,379.925720 577.202820,378.977448 580.632324,379.006439 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M857.433350,496.625061 
	C858.125427,496.998444 858.817566,497.371826 859.879517,498.153625 
	C861.020203,499.512817 861.791138,500.463593 862.562012,501.414337 
	C862.562012,501.414337 862.233582,501.754883 861.740601,501.858215 
	C859.773315,500.365662 858.299011,498.769745 856.975708,497.035309 
	C857.126648,496.896790 857.433350,496.625061 857.433350,496.625061 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M566.076904,671.452881 
	C565.280457,671.525696 564.473999,671.229736 563.342834,670.909180 
	C563.018188,670.884583 562.745728,670.759827 562.478943,670.490295 
	C561.801331,669.489075 561.390564,668.757324 560.979736,668.025635 
	C560.979736,668.025635 561.099365,667.885559 561.156982,667.810669 
	C562.832092,668.851868 564.449463,669.968018 566.076904,671.452881 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M862.031067,585.057251 
	C862.022644,584.604614 862.014221,584.152039 862.014404,583.360168 
	C863.432434,582.299683 864.841675,581.578491 866.598999,580.899536 
	C866.970276,581.382629 866.993591,581.823425 867.007080,582.631836 
	C866.997253,582.999451 866.934998,582.919922 866.934998,582.919922 
	C866.071106,583.416504 865.207214,583.913086 863.822998,584.648743 
	C862.878784,584.944275 862.454956,585.000793 862.031067,585.057251 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M664.656860,713.520752 
	C664.963135,714.484802 665.032471,715.228577 665.188354,716.328613 
	C664.471313,716.777893 663.667725,716.870850 662.461792,716.939148 
	C662.846313,715.709839 663.633118,714.505188 664.656860,713.520752 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M376.797729,809.271729 
	C377.233215,810.799500 377.404938,812.552856 377.437317,814.669434 
	C377.043274,813.187500 376.788635,811.342407 376.797729,809.271729 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M849.066040,596.856995 
	C850.223206,595.523376 851.765442,594.246643 853.658081,592.971802 
	C852.489380,594.287048 850.970276,595.600464 849.066040,596.856995 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M746.876831,380.792847 
	C745.268188,379.693512 743.689331,378.218719 742.006470,376.429626 
	C745.286621,375.553131 746.176086,377.892822 746.876831,380.792847 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M862.846558,501.538025 
	C861.791138,500.463593 861.020203,499.512817 860.153320,498.268921 
	C861.682068,499.113647 863.306824,500.251556 864.935059,501.753662 
	C864.335999,501.965851 863.733521,501.813782 862.846558,501.538025 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M467.346985,597.003052 
	C466.483551,596.161987 465.834351,595.381836 465.428253,594.320740 
	C467.109467,595.370117 468.547638,596.700378 469.985809,598.030640 
	C469.573578,597.947937 469.161346,597.865173 468.434082,597.758667 
	C468.024841,597.434998 467.838898,597.211365 467.346985,597.003052 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M550.940552,410.224487 
	C550.114807,411.842621 548.910950,413.384735 547.390259,415.040405 
	C548.236450,413.485504 549.399414,411.816986 550.940552,410.224487 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M831.863281,610.709534 
	C832.774292,609.438171 834.072693,608.267639 835.709961,607.057983 
	C834.782776,608.282715 833.516785,609.546570 831.863281,610.709534 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M836.932861,606.789307 
	C837.808472,605.478210 839.054993,604.241699 840.640564,602.989746 
	C839.754333,604.270813 838.529053,605.567322 836.932861,606.789307 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M722.971497,318.668579 
	C721.661560,317.912262 720.352478,316.808868 719.007446,315.367310 
	C720.305176,316.126617 721.638733,317.224091 722.971497,318.668579 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M569.021606,392.511536 
	C568.486633,392.878845 568.093872,392.963470 567.402039,393.082886 
	C568.208557,391.760681 569.314087,390.403625 570.777832,389.052460 
	C570.478638,390.115173 569.821289,391.171997 569.021606,392.511536 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M558.698853,403.035889 
	C557.939453,404.372833 556.827759,405.719818 555.371704,407.086823 
	C556.133667,405.753204 557.240112,404.399597 558.698853,403.035889 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M1138.195312,776.737854 
	C1137.700073,775.534241 1137.469849,774.112000 1137.257568,772.321655 
	C1137.670410,773.475403 1138.065552,774.997375 1138.195312,776.737854 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M871.069153,579.067261 
	C871.007202,578.416016 871.124756,578.059326 871.249451,577.414917 
	C871.631104,577.041687 872.005737,576.956116 872.668213,576.904663 
	C873.125000,577.767273 873.293823,578.595947 873.462708,579.424561 
	C872.724731,579.403625 871.986694,579.382690 871.069153,579.067261 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M757.394165,392.738098 
	C756.291138,393.699677 755.051208,394.385529 753.445984,395.058563 
	C753.613220,394.193909 754.145874,393.342072 755.054443,392.166931 
	C755.737305,391.758453 755.909302,391.557648 755.946350,391.241272 
	C756.383301,391.648285 756.820312,392.055298 757.394165,392.738098 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M597.188782,325.173706 
	C596.605835,326.404633 595.619324,327.590454 594.331055,328.909882 
	C594.947937,327.738525 595.866577,326.433594 597.188782,325.173706 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M853.952087,494.014862 
	C853.704163,493.519379 853.456299,493.023895 853.117920,492.259674 
	C854.325989,492.775604 855.624573,493.560272 856.619263,494.689087 
	C855.886047,495.017914 855.456604,495.002655 854.884399,494.787567 
	C854.524536,494.333313 854.261353,494.142334 853.952087,494.014862 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M734.280945,340.750183 
	C733.933411,339.909210 733.873108,338.820862 733.670654,337.349518 
	C733.650452,336.212799 733.772461,335.459106 734.027466,334.347595 
	C734.296448,336.160797 734.432312,338.331818 734.280945,340.750183 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M486.037384,611.342163 
	C487.002655,611.742065 487.999237,612.488464 489.070435,613.559937 
	C488.119568,613.152893 487.094116,612.420715 486.037384,611.342163 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M375.261658,800.047668 
	C375.373260,798.750305 375.745331,797.561951 376.117432,796.373596 
	C376.405609,796.364929 376.693787,796.356262 376.981964,796.347656 
	C377.082794,797.629150 377.183624,798.910583 377.149353,800.563232 
	C376.516907,800.675110 376.019531,800.415833 375.261658,800.047668 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M554.231812,407.246277 
	C553.739868,408.298004 552.847900,409.267578 551.626831,410.280945 
	C552.142395,409.271179 552.987061,408.217651 554.231812,407.246277 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M606.705811,364.022339 
	C605.664612,364.800354 604.246399,365.563232 602.460693,366.395081 
	C603.505066,365.645081 604.916931,364.826111 606.705811,364.022339 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M619.814453,303.758484 
	C619.997070,303.992950 619.947754,304.436035 619.609558,304.711548 
	C619.054321,305.284973 618.837280,305.582855 618.620239,305.880737 
	C617.989441,305.997559 617.358643,306.114349 616.403625,306.253906 
	C617.263550,305.359100 618.447693,304.441559 619.814453,303.758484 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M560.649170,668.023560 
	C561.390564,668.757324 561.801331,669.489075 562.331299,670.515991 
	C561.323181,670.119812 560.195923,669.428467 559.033569,668.376221 
	C559.438538,668.017273 559.878540,668.019348 560.649170,668.023560 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M563.688354,397.054504 
	C564.093506,397.203888 564.119507,397.374237 564.214172,397.793884 
	C563.483704,398.793549 562.684692,399.543945 561.593506,400.441345 
	C561.970703,399.417419 562.639954,398.246429 563.688354,397.054504 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M752.901855,386.970490 
	C752.010681,386.665192 751.168640,385.994904 750.186035,385.024353 
	C751.013977,385.351227 751.982483,385.978394 752.901855,386.970490 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M535.993408,651.519165 
	C536.942261,651.894348 537.902832,652.634338 539.010742,653.649597 
	C538.107117,653.244568 537.056091,652.564270 535.993408,651.519165 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M889.182312,559.880859 
	C889.166748,559.063293 889.471863,558.261536 890.046570,557.270996 
	C890.045105,558.020325 889.774109,558.958435 889.182312,559.880859 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M566.762085,393.108276 
	C566.621460,393.999603 566.122681,394.900909 565.317871,395.910095 
	C565.475891,395.051392 565.939941,394.084808 566.762085,393.108276 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M435.028259,571.202881 
	C435.912537,571.410400 436.774078,572.054688 437.778931,572.991028 
	C436.950012,572.735291 435.977783,572.187378 435.028259,571.202881 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M842.903687,482.828918 
	C842.455811,483.152161 842.004578,483.119507 841.044189,482.937347 
	C840.210571,482.368744 839.886230,481.949707 839.561829,481.530640 
	C839.561829,481.530640 839.849670,481.188080 839.995483,481.016083 
	C841.060974,481.387085 841.980713,481.930054 842.903687,482.828918 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M841.312378,602.982788 
	C841.683044,602.235046 842.391235,601.490784 843.433899,600.735596 
	C843.062134,601.478577 842.356018,602.232483 841.312378,602.982788 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M716.918701,312.723450 
	C716.304321,312.580078 715.689819,312.106903 715.035950,311.326172 
	C715.637268,311.476959 716.277954,311.935303 716.918701,312.723450 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M755.878540,390.928986 
	C755.909302,391.557648 755.737305,391.758453 755.227844,391.894958 
	C754.719788,391.390503 754.414246,390.834717 754.183899,389.954224 
	C754.776306,389.958527 755.293518,390.287628 755.878540,390.928986 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M760.998474,666.820435 
	C761.188660,666.171631 761.745422,665.564514 762.620911,664.905457 
	C762.414795,665.523010 761.889954,666.192566 760.998474,666.820435 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M313.040100,809.579346 
	C313.621674,809.656982 314.230133,810.009644 314.798706,810.670715 
	C314.194885,810.604187 313.630920,810.229248 313.040100,809.579346 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M718.956909,314.698242 
	C718.336426,314.579010 717.701904,314.129822 717.008545,313.380524 
	C717.614014,313.509705 718.278503,313.938965 718.956909,314.698242 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M543.045410,657.333130 
	C543.675293,657.422180 544.345276,657.842590 545.080933,658.572876 
	C544.459534,658.476746 543.772522,658.070618 543.045410,657.333130 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M844.093994,600.785278 
	C844.306396,600.148865 844.854370,599.581177 845.725708,599.036255 
	C845.509277,599.657349 844.969421,600.255615 844.093994,600.785278 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M697.080933,702.845398 
	C697.258423,702.211548 697.789856,701.605896 698.642212,700.963257 
	C698.453735,701.575439 697.944275,702.224487 697.080933,702.845398 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M702.155151,698.890381 
	C702.326294,698.260498 702.838928,697.645874 703.676270,697.022827 
	C703.499451,697.644775 702.998108,698.275146 702.155151,698.890381 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M855.027222,494.987396 
	C855.456604,495.002655 855.886047,495.017914 856.639099,495.049133 
	C857.072144,495.410583 857.181458,495.756073 857.362061,496.363312 
	C857.433350,496.625061 857.126648,496.896790 856.678833,496.931488 
	C855.829773,496.306580 855.428528,495.647003 855.027222,494.987396 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M859.158142,588.933350 
	C859.307739,588.240540 859.807800,587.570923 860.647095,586.921021 
	C860.493713,587.612610 860.001160,588.284546 859.158142,588.933350 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M628.008179,778.099487 
	C628.421265,778.495544 628.578613,779.004395 628.713684,779.788208 
	C628.378418,779.446228 628.065430,778.829346 628.008179,778.099487 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M870.909363,506.802948 
	C870.265259,506.686066 869.617065,506.222015 868.909058,505.447205 
	C869.534546,505.576202 870.219910,506.015991 870.909363,506.802948 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M801.071167,634.770203 
	C801.225281,634.119507 801.730652,633.522400 802.546753,632.882446 
	C802.379150,633.500977 801.900818,634.162415 801.071167,634.770203 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M513.051697,633.340332 
	C513.692688,633.421997 514.381287,633.847168 515.187622,634.569824 
	C514.570007,634.472839 513.834656,634.078430 513.051697,633.340332 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M506.162292,627.254028 
	C506.764923,627.364807 507.348938,627.805542 507.990082,628.539551 
	C507.412689,628.416504 506.778198,628.000244 506.162292,627.254028 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M868.863037,504.784485 
	C868.174561,504.720795 867.482483,504.287842 866.669922,503.553772 
	C867.319397,503.640167 868.089355,504.027679 868.863037,504.784485 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M499.228546,463.224426 
	C499.090942,463.963135 498.552338,464.640503 497.677734,465.348389 
	C497.836975,464.640350 498.332275,463.901764 499.228546,463.224426 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M510.354248,456.872437 
	C509.812164,457.032898 509.314514,457.052673 508.442200,457.082458 
	C508.698303,456.688263 509.329010,456.284088 510.109894,456.175537 
	C510.260071,456.471222 510.398651,456.731750 510.354248,456.872437 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M533.046143,649.339050 
	C533.686646,649.423706 534.372437,649.848267 535.152954,650.582703 
	C534.528931,650.488098 533.810242,650.083496 533.046143,649.339050 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M728.642212,369.043427 
	C729.111328,369.402405 729.241516,369.751953 729.442078,370.372498 
	C728.700134,370.372711 727.887817,370.101929 727.020508,369.475525 
	C727.411438,369.091248 727.857361,369.062653 728.642212,369.043427 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M724.919556,320.762726 
	C724.287354,320.618561 723.663452,320.137543 723.012451,319.342957 
	C723.632874,319.494904 724.280334,319.960388 724.919556,320.762726 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M600.814819,322.118286 
	C600.692749,322.789978 600.214417,323.457428 599.408936,324.168976 
	C599.540710,323.513367 599.999634,322.813721 600.814819,322.118286 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M969.331299,780.598328 
	C969.341370,780.107849 969.490479,779.797546 969.840698,779.319214 
	C969.851257,779.693542 969.660828,780.236084 969.331299,780.598328 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M628.354614,771.968506 
	C628.632019,772.476135 628.630615,773.026611 628.462158,773.796204 
	C628.222046,773.347351 628.148987,772.679382 628.354614,771.968506 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M795.084961,639.789734 
	C795.259277,639.163025 795.782043,638.580078 796.610229,637.946350 
	C796.421509,638.541504 795.927429,639.187500 795.084961,639.789734 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M862.000366,585.314209 
	C862.454956,585.000793 862.878784,584.944275 863.624939,584.881714 
	C863.550903,585.271790 863.154602,585.667908 862.459717,586.057983 
	C862.097229,585.891602 862.033447,585.731323 862.000366,585.314209 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M576.151978,383.400299 
	C576.178528,383.907806 575.878784,384.380859 575.293213,384.922546 
	C575.280090,384.449432 575.552856,383.907654 576.151978,383.400299 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M507.763855,457.053284 
	C507.587860,457.394135 507.102142,457.715820 506.311249,458.027527 
	C506.488739,457.689697 506.971436,457.361938 507.763855,457.053284 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M608.870544,358.772614 
	C608.522949,359.055420 608.083069,359.045319 607.312866,359.036072 
	C606.981079,358.836029 606.979736,358.635132 607.055603,358.142273 
	C607.681335,358.060059 608.229797,358.269867 608.870544,358.772614 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M545.192749,659.132629 
	C545.614014,659.060547 546.160217,659.266785 546.843140,659.739868 
	C546.425842,659.808228 545.871704,659.609558 545.192749,659.132629 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M937.791992,772.129700 
	C938.015747,772.175171 938.238037,772.583069 938.049133,772.720154 
	C937.652893,772.703491 937.445618,772.549805 937.238281,772.396118 
	C937.348267,772.292175 937.458313,772.188171 937.791992,772.129700 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M783.482056,823.463867 
	C783.520142,823.443726 783.443909,823.484070 783.482056,823.463867 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M747.850952,381.942200 
	C747.599915,382.033478 747.285522,381.848083 746.909241,381.388123 
	C747.160767,381.297546 747.474243,381.481567 747.850952,381.942200 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M560.771484,401.096436 
	C560.861084,401.315521 560.710632,401.632080 560.296082,402.008240 
	C560.198425,401.776489 560.364929,401.485199 560.771484,401.096436 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M741.837280,375.849548 
	C741.603882,375.958405 741.294678,375.808289 740.904602,375.403320 
	C741.136292,375.295868 741.448853,375.443237 741.837280,375.849548 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M600.260864,353.015472 
	C600.000244,352.844269 599.997070,352.668121 599.999146,352.226471 
	C600.311096,352.122742 600.617737,352.284576 600.978638,352.715576 
	C600.861328,352.993347 600.689819,353.001953 600.260864,353.015472 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M608.899170,313.170349 
	C608.993164,313.408875 608.821716,313.730988 608.378479,314.123596 
	C608.282349,313.880646 608.458069,313.567291 608.899170,313.170349 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M609.928284,312.150635 
	C610.008301,312.379211 609.820129,312.691833 609.358398,313.083984 
	C609.276550,312.853943 609.468323,312.544312 609.928284,312.150635 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M709.695557,305.975037 
	C709.427856,306.125854 709.108704,305.976318 708.671326,305.581055 
	C708.916748,305.448456 709.280396,305.561584 709.695557,305.975037 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M703.789612,302.878357 
	C703.568848,303.066101 703.211243,302.976776 702.673340,302.668274 
	C702.879578,302.499847 703.266174,302.550598 703.789612,302.878357 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M701.775635,301.940369 
	C701.552307,302.140686 701.194336,302.052246 700.643494,301.758514 
	C700.847412,301.586029 701.244202,301.618835 701.775635,301.940369 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M699.759399,300.991211 
	C699.525635,301.176941 699.174988,301.064545 698.640625,300.739838 
	C698.852173,300.582703 699.247375,300.637878 699.759399,300.991211 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M512.130371,632.327759 
	C512.387207,632.065674 512.670837,632.194519 512.933960,632.758545 
	C512.700500,632.918335 512.448425,632.760010 512.130371,632.327759 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M866.957764,582.967651 
	C867.058533,583.092102 867.198730,583.248352 867.338867,583.404602 
	C867.238464,583.350830 867.138062,583.297119 866.986328,583.081665 
	C866.934998,582.919922 866.997253,582.999451 866.957764,582.967651 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M420.297211,812.697388 
	C413.252289,813.179138 409.908112,811.289368 409.051483,804.788635 
	C408.268005,798.843140 408.616547,792.652893 409.238922,786.650696 
	C409.663544,782.555481 412.475342,779.765564 417.058075,779.808533 
	C421.380371,779.849060 424.951660,781.953430 425.362213,786.166077 
	C426.024567,792.962769 425.981232,799.897522 425.405609,806.703796 
	C425.229706,808.783569 422.329987,810.633118 420.297211,812.697388 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M569.977051,784.174561 
	C573.203308,790.538452 573.611816,796.892944 572.174866,803.368408 
	C570.476196,811.023071 566.551392,813.503052 558.197632,812.275330 
	C558.197632,801.783936 558.197632,791.250732 558.197632,780.336609 
	C562.669189,779.737915 566.712524,779.969177 569.977051,784.174561 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M1064.542236,804.124634 
	C1062.706665,810.707581 1060.144531,813.201355 1055.726807,813.021667 
	C1051.047363,812.831360 1047.458862,809.776794 1046.913818,803.972839 
	C1046.378662,798.274780 1046.632935,792.418335 1047.298828,786.720032 
	C1047.775757,782.639648 1050.559326,779.875610 1055.102173,779.808289 
	C1059.716553,779.739929 1063.085327,782.190613 1063.760376,786.461243 
	C1064.653931,792.116150 1064.402100,797.952026 1064.542236,804.124634 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M244.837708,790.666382 
	C244.894196,795.058716 245.357849,799.103760 244.734726,802.973755 
	C243.478271,810.776978 238.964615,813.572266 230.539886,812.240723 
	C230.539886,801.764709 230.539886,791.239502 230.539886,780.738159 
	C238.821243,779.007812 242.124664,781.241821 244.837708,790.666382 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M511.377258,780.264587 
	C520.363037,780.126221 524.099915,783.142700 524.952515,791.562866 
	C525.366699,795.652832 525.345459,799.952515 524.547180,803.963318 
	C523.115784,811.155029 518.403198,813.691162 510.970856,812.184204 
	C510.970856,801.575317 510.970856,790.999634 511.377258,780.264587 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M801.303528,783.387146 
	C800.892944,780.081726 802.094177,779.056213 804.954224,779.449402 
	C809.355164,780.054443 810.783875,782.768433 810.493774,786.798645 
	C810.152039,791.546631 808.264893,792.810425 801.294250,792.461731 
	C801.294250,789.624146 801.294250,786.724487 801.303528,783.387146 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M688.981750,796.150146 
	C689.359131,797.476257 689.622253,798.411743 690.004578,799.771301 
	C686.589722,799.771301 683.449951,799.771301 679.948547,799.771301 
	C681.548401,794.791687 683.074768,790.040833 684.945862,784.217041 
	C686.491821,788.767273 687.679688,792.263428 688.981750,796.150146 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M606.020996,792.014282 
	C606.776855,789.423828 607.458008,787.229126 608.452698,784.024170 
	C610.334595,789.732300 611.900696,794.482849 613.640930,799.761475 
	C610.187927,799.761475 607.184570,799.761475 603.537415,799.761475 
	C604.360535,797.249451 605.153381,794.829773 606.020996,792.014282 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M302.927307,795.135620 
	C304.103790,791.471130 305.178131,788.214355 306.540100,784.085449 
	C308.338562,789.778137 309.860291,794.594910 311.515045,799.832764 
	C308.057434,799.832764 305.026031,799.832764 301.495941,799.832764 
	C301.979919,798.270935 302.402527,796.907166 302.927307,795.135620 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M744.791138,409.155579 
	C744.400085,412.532471 747.167603,416.259003 743.625183,419.348053 
	C743.336792,419.599518 743.777649,420.861786 744.071716,421.575134 
	C745.118835,424.114807 746.248962,426.620209 747.294434,429.847687 
	C747.210693,431.030792 747.180054,431.504822 746.792969,432.113586 
	C743.605408,433.490509 740.774292,434.732666 737.629517,436.005524 
	C736.925537,436.192749 736.535095,436.349304 735.811279,436.378601 
	C735.012268,436.314087 734.546692,436.376801 734.031189,435.999268 
	C733.990540,434.378326 733.999756,433.197601 734.096252,431.634369 
	C732.797119,429.147949 731.410828,427.044037 730.034058,424.627991 
	C730.018616,423.900330 729.993774,423.484741 730.349487,423.058350 
	C732.745239,423.469025 734.760376,423.890594 737.614807,424.487732 
	C735.211060,421.457642 733.555420,419.370544 731.554077,416.847717 
	C730.905334,419.311523 730.415833,421.170624 729.663696,422.820679 
	C727.381653,419.756927 725.362183,416.902161 723.342712,414.047424 
	C722.888306,414.187408 722.433899,414.327393 721.979492,414.467377 
	C722.358032,418.784271 723.699707,423.293427 722.885498,427.372253 
	C721.714050,433.240295 729.497742,434.297333 728.538269,440.167633 
	C725.474487,438.610291 722.811096,437.256470 719.976440,435.708557 
	C719.494690,435.386932 719.184143,435.259399 718.729004,434.841034 
	C717.381104,433.725555 716.177734,432.900940 714.856567,431.770142 
	C714.384888,430.669891 714.031067,429.875763 713.782959,428.676514 
	C714.589355,425.180725 715.290100,422.090027 716.268311,418.792419 
	C718.378052,413.643219 721.779114,411.745880 726.981567,413.020935 
	C729.686951,413.683960 732.497375,413.929138 735.188354,414.636963 
	C738.704285,415.561768 741.369568,415.468658 742.156799,411.029877 
	C742.290955,410.273163 743.876221,409.773834 744.791138,409.155579 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M744.845520,408.777100 
	C743.876221,409.773834 742.290955,410.273163 742.156799,411.029877 
	C741.369568,415.468658 738.704285,415.561768 735.188354,414.636963 
	C732.497375,413.929138 729.686951,413.683960 726.981567,413.020935 
	C721.779114,411.745880 718.378052,413.643219 715.931091,418.727661 
	C715.116089,418.071198 714.634155,417.223846 714.762451,416.482056 
	C715.425720,412.647766 716.244995,408.840485 717.125061,404.691406 
	C717.831299,402.586639 718.422302,400.814301 719.338806,398.899536 
	C720.808105,398.471832 721.951904,398.186554 723.404053,397.916504 
	C724.124146,397.933899 724.535889,397.936035 725.232422,397.957428 
	C725.703979,397.939453 725.890625,397.902222 726.440674,397.862915 
	C727.526978,397.924255 728.249939,397.987732 729.141113,398.365936 
	C733.229919,401.907227 737.433899,402.236298 741.919006,400.291473 
	C742.623352,401.757599 743.305969,402.843628 743.988647,403.929657 
	C744.292358,405.419281 744.596130,406.908936 744.845520,408.777100 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M744.188110,403.662170 
	C743.305969,402.843628 742.623352,401.757599 742.081055,400.011108 
	C742.134033,397.908691 742.046692,396.466705 741.959351,395.024719 
	C743.063049,395.349426 744.166809,395.674133 745.620239,396.009033 
	C747.057922,397.604828 748.145813,399.190369 749.502686,400.945862 
	C752.066956,403.351898 754.473206,405.486725 756.628540,407.850372 
	C759.173523,410.641296 761.501648,413.630035 763.838257,416.904724 
	C757.914368,418.412048 755.004517,414.952515 752.087891,410.589691 
	C750.186951,407.746246 746.999023,405.763184 744.188110,403.662170 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M762.046570,440.827942 
	C764.600586,441.751770 767.154602,442.675568 770.158936,443.762299 
	C769.306458,445.432190 768.548706,446.916473 767.893372,448.731873 
	C767.995789,449.062988 768.071472,449.042328 767.792053,448.937988 
	C767.170776,449.035339 766.828857,449.237061 766.487000,449.438782 
	C766.847717,449.693817 767.208435,449.948883 767.610596,450.347717 
	C767.651978,450.491486 767.611023,450.787842 767.315063,450.827332 
	C765.867310,451.245239 764.715515,451.623657 763.222900,451.855408 
	C761.515747,449.481171 760.149353,447.253632 758.884216,444.699402 
	C758.945190,443.920258 758.904907,443.467804 758.973145,442.676056 
	C760.070007,441.833801 761.058289,441.330872 762.046570,440.827942 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M737.943115,435.974854 
	C740.774292,434.732666 743.605408,433.490509 747.036316,432.212585 
	C748.064636,432.475250 748.493286,432.773682 748.921936,433.072113 
	C748.939697,433.499878 748.957520,433.927673 748.973572,435.113647 
	C749.178711,439.971924 747.889648,444.637939 753.057556,447.206055 
	C752.248535,448.058533 751.502441,448.542053 750.517944,448.809723 
	C749.437195,445.170746 746.807190,446.970093 744.945190,446.407715 
	C745.962036,444.111450 746.904541,442.149994 747.987854,439.895630 
	C744.164001,438.403046 741.053528,437.188965 737.943115,435.974854 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M753.120544,446.837128 
	C747.889648,444.637939 749.178711,439.971924 749.002747,435.433411 
	C751.258789,436.122345 753.483887,437.249725 755.827515,439.039581 
	C755.300659,441.198639 754.655273,442.695221 753.999878,444.473450 
	C753.700134,445.449066 753.410339,446.143097 753.120544,446.837128 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M767.611023,450.787842 
	C767.611023,450.787842 767.651978,450.491486 767.839478,450.099792 
	C768.041870,449.486206 768.056641,449.264282 768.071472,449.042328 
	C768.071472,449.042328 767.995789,449.062988 768.002747,449.101501 
	C770.168213,446.843414 772.330872,444.550690 774.483582,442.248779 
	C775.796326,440.845001 777.093994,439.427155 779.161804,438.450623 
	C778.635498,440.438904 778.109192,442.427216 777.502625,444.831543 
	C777.300842,445.505096 777.153015,445.754456 777.069092,446.023712 
	C777.029114,446.151978 777.110535,446.318054 776.882751,446.823120 
	C776.084534,447.814850 775.540527,448.450470 774.637146,449.080994 
	C772.055542,449.646515 769.833252,450.217194 767.611023,450.787842 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M754.009888,444.191833 
	C754.655273,442.695221 755.300659,441.198639 755.977661,439.346130 
	C757.885376,439.406219 759.761536,439.822205 761.842102,440.533051 
	C761.058289,441.330872 760.070007,441.833801 758.697144,442.718933 
	C757.874146,443.406281 757.435791,443.711426 756.623535,444.032043 
	C755.503113,444.095612 754.756470,444.143738 754.009888,444.191833 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M748.952881,432.750000 
	C748.493286,432.773682 748.064636,432.475250 747.392700,432.077820 
	C747.180054,431.504822 747.210693,431.030792 747.429016,430.249634 
	C748.072388,430.770966 748.528076,431.599396 748.952881,432.750000 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M636.932129,713.197937 
	C637.165710,714.070618 637.081360,714.720093 636.951782,715.699219 
	C634.255554,714.598328 631.327759,713.516907 629.002808,711.674744 
	C620.390869,704.850769 612.147156,697.554749 603.427429,690.877014 
	C598.088013,686.788025 592.216248,683.396606 585.152771,682.802979 
	C583.841370,682.692749 582.636353,681.316833 581.237000,680.194946 
	C583.472595,680.211426 585.853455,680.558044 588.795044,681.132324 
	C590.234680,681.312256 591.113403,681.264526 592.370850,681.212097 
	C593.507019,681.202454 594.264404,681.197449 595.184204,681.381714 
	C595.884277,681.375977 596.421875,681.180969 597.262451,680.943604 
	C597.763733,680.853760 597.961975,680.806152 598.449219,680.815247 
	C599.401611,680.597839 600.065125,680.323792 600.922729,679.840210 
	C601.431641,679.420593 601.746338,679.210388 602.380432,679.024292 
	C603.126587,679.026245 603.553284,679.004028 604.362854,678.991699 
	C605.511230,678.982910 606.276855,678.964233 607.225037,679.224670 
	C611.428345,684.639771 615.480042,689.751953 619.456970,694.921631 
	C621.490173,697.564575 623.973328,698.566772 627.447632,697.635315 
	C628.050659,697.926819 628.400757,698.081360 628.879150,698.563354 
	C631.943604,701.550171 635.042114,700.035217 638.033569,699.602539 
	C638.106323,701.318665 637.938171,703.710388 638.460327,703.871948 
	C642.490356,705.119385 646.646423,705.973694 650.793884,706.796265 
	C651.065735,706.850159 651.588867,705.637085 652.334534,704.999023 
	C654.115540,706.356750 655.557129,707.728760 656.998779,709.100769 
	C652.279541,709.381165 647.562622,709.717407 642.840088,709.920532 
	C640.101135,710.038330 637.352905,709.941895 633.159851,709.941895 
	C635.083374,711.630737 635.848755,712.302734 636.932129,713.197937 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M575.917847,678.593628 
	C576.768555,678.363342 577.702087,678.433533 578.825806,678.816833 
	C578.010925,679.051331 577.005737,678.972656 575.917847,678.593628 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M632.804138,306.150116 
	C633.261475,304.514191 633.718872,302.878296 634.274292,300.891693 
	C635.184082,301.128387 636.243347,301.404022 637.601746,302.203979 
	C637.968933,303.445831 638.037048,304.163361 638.083984,305.138000 
	C638.177979,305.689667 638.293091,305.984253 638.408203,306.278870 
	C638.615479,305.874084 638.822693,305.469299 639.356445,305.075958 
	C640.114136,305.146820 640.545288,305.206268 641.048218,305.594299 
	C642.995117,307.681702 644.872314,309.415863 647.371521,306.237183 
	C647.839661,305.726349 648.087158,305.414764 648.334717,305.103210 
	C650.376770,306.935516 652.418762,308.767822 654.871826,311.102234 
	C655.853516,312.780731 656.424316,313.957092 656.631470,315.118347 
	C654.716187,314.060547 653.164429,313.017853 652.421631,312.518738 
	C650.248230,313.482147 648.583252,314.425446 646.795776,314.956024 
	C644.927673,315.510620 642.941772,315.668213 640.713135,315.844025 
	C637.977722,313.724152 635.536560,311.759583 633.110107,309.427307 
	C633.018005,308.089783 632.911072,307.119965 632.804138,306.150116 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M656.995056,315.133484 
	C656.424316,313.957092 655.853516,312.780731 655.136475,311.300781 
	C657.365784,310.952332 659.748169,311.005188 662.115234,310.839142 
	C665.501770,310.601562 668.878235,309.935944 672.256714,309.951263 
	C675.534058,309.966125 678.808533,310.610229 682.705200,311.230743 
	C684.286682,312.055023 685.247131,312.626526 686.080811,313.581055 
	C682.389282,314.066376 678.825134,314.196960 675.259705,314.256622 
	C673.896240,314.279419 671.916687,313.535187 671.276672,314.184387 
	C669.089355,316.403015 667.372559,319.085449 665.188354,321.705475 
	C663.557434,321.752258 662.214417,321.684479 660.858032,321.283936 
	C659.561462,319.011932 658.278259,317.072693 656.995056,315.133484 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M686.207581,313.198059 
	C685.247131,312.626526 684.286682,312.055023 683.097900,311.234192 
	C683.189392,309.744019 683.509216,308.503113 683.770325,307.490021 
	C686.646729,306.665741 689.384705,305.881134 692.493774,305.157745 
	C693.016479,306.163818 693.168030,307.108704 692.849426,308.499512 
	C690.322021,310.363007 688.264832,311.780518 686.207581,313.198059 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M638.105103,304.880890 
	C638.037048,304.163361 637.968933,303.445831 637.906677,302.366577 
	C638.537476,301.501587 639.162476,300.998383 639.840576,300.195190 
	C639.893799,299.895233 639.824280,299.879791 640.160034,299.910217 
	C641.992798,299.855774 643.489807,299.770874 644.986877,299.686005 
	C646.047852,301.241333 647.108887,302.796661 648.252319,304.727600 
	C648.087158,305.414764 647.839661,305.726349 647.004517,306.117096 
	C644.603455,305.886108 642.789978,305.575928 640.976440,305.265747 
	C640.545288,305.206268 640.114136,305.146820 639.129517,305.007874 
	C638.576050,304.928345 638.105164,304.880890 638.105103,304.880890 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M644.933533,299.405121 
	C643.489807,299.770874 641.992798,299.855774 640.163086,299.874756 
	C641.026306,298.588715 642.222168,297.368591 643.779053,296.236145 
	C644.386719,297.257294 644.633484,298.190765 644.933533,299.405121 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M427.702454,529.072998 
	C431.204376,524.902405 434.675201,520.704956 438.231262,516.580994 
	C439.066681,515.612122 440.189972,514.891479 441.305298,513.750244 
	C446.768250,509.492523 452.106659,505.541351 457.694946,501.924500 
	C456.334564,507.064392 461.243713,505.927216 462.912781,506.973846 
	C469.443665,511.069305 474.534180,507.980957 475.255981,500.207886 
	C481.691559,498.717072 480.973511,493.957489 480.998657,489.150879 
	C482.367340,488.117432 483.633911,487.449768 484.900452,486.782104 
	C485.793243,487.442902 486.686035,488.103699 487.280487,488.543701 
	C485.839020,491.743500 484.433075,494.864532 482.781860,498.690033 
	C482.388794,501.263336 482.240936,503.132111 481.826904,505.143005 
	C481.338654,505.828247 481.116608,506.371429 480.611145,506.961792 
	C479.873383,507.323944 479.419067,507.638916 478.600006,507.998169 
	C476.133270,508.877777 473.908203,509.495880 471.958649,510.600311 
	C469.153259,512.189453 466.548279,514.132446 463.493683,515.991760 
	C459.089905,517.709351 455.050201,519.359680 451.010498,521.009949 
	C448.834442,521.848267 446.658386,522.686584 443.917603,523.765747 
	C442.536194,524.607178 441.719513,525.207825 440.902832,525.808472 
	C440.210144,525.882019 439.517487,525.955627 438.126770,526.153076 
	C434.958466,527.195435 432.488159,528.114014 429.632935,529.019836 
	C428.732819,529.029053 428.217651,529.051025 427.702454,529.072998 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M435.695038,562.699280 
	C434.313568,561.442810 432.932129,560.186401 431.406799,558.597412 
	C437.478882,552.878662 443.694763,547.492371 449.967987,542.056396 
	C449.599335,540.466003 449.415039,538.970093 448.901703,537.597168 
	C447.693878,534.366516 448.602905,533.207153 452.134125,532.893921 
	C454.170227,532.713440 456.061371,530.898804 458.425018,529.323608 
	C460.224487,527.550049 461.616669,526.272583 463.250000,525.038574 
	C463.491119,525.082031 463.981079,525.078003 463.970825,525.470276 
	C464.087433,527.157776 464.214325,528.453064 464.009430,529.926453 
	C463.129700,530.706970 462.273895,531.225342 462.083038,531.925110 
	C460.702484,536.986206 458.270355,539.454590 453.013641,536.102295 
	C452.672668,535.884888 451.854248,536.416138 451.293915,536.588074 
	C451.985321,541.813843 451.940552,541.779663 448.649292,546.093872 
	C448.109436,546.801514 448.160034,547.959656 447.718689,549.032104 
	C447.287079,549.409912 447.074524,549.665100 446.515015,550.004639 
	C445.797821,551.138000 445.427643,552.186890 444.874329,553.503662 
	C441.692474,556.747375 438.693756,559.723328 435.695038,562.699280 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M463.008850,524.995117 
	C461.616669,526.272583 460.224487,527.550049 458.562683,528.983276 
	C457.479645,529.109497 456.652130,528.968018 455.855133,529.068481 
	C450.894501,529.694214 449.981903,528.731506 450.848419,523.732483 
	C450.959045,523.094482 450.937561,522.433533 450.993896,521.396606 
	C455.050201,519.359680 459.089905,517.709351 463.534790,516.416382 
	C463.629578,519.514221 463.319214,522.254639 463.008850,524.995117 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M430.017853,529.032654 
	C432.488159,528.114014 434.958466,527.195435 437.780334,526.189148 
	C438.932861,533.914185 433.945801,536.901367 426.341187,538.821228 
	C428.802338,540.263245 430.509735,541.073730 431.972809,542.201233 
	C432.700287,542.761902 432.979584,543.904175 433.461609,544.783325 
	C432.454132,544.918640 430.983185,545.544373 430.518524,545.105225 
	C428.377228,543.081726 426.516388,540.761414 424.287537,538.332581 
	C424.035248,537.317505 424.050385,536.517395 424.502380,535.280151 
	C426.632080,532.906250 428.324982,530.969482 430.017853,529.032654 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M441.263855,525.873779 
	C441.719513,525.207825 442.536194,524.607178 443.705841,524.019470 
	C443.247498,524.668030 442.436188,525.303589 441.263855,525.873779 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M426.260315,553.627563 
	C426.260620,553.708740 426.302460,553.391785 426.239319,553.245850 
	C426.176178,553.099915 426.260040,553.546387 426.260315,553.627563 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M761.088745,652.158813 
	C760.574890,653.136169 760.061035,654.113525 759.288696,655.582520 
	C761.879272,655.143433 764.147949,654.758911 766.409241,654.375610 
	C766.501648,654.901794 766.711670,655.359619 766.585815,655.487976 
	C762.228027,659.932617 757.972656,664.493652 753.373962,668.676758 
	C751.939514,669.981567 749.564758,670.252747 747.154602,670.796021 
	C746.365784,670.726196 746.042847,670.857483 745.719910,670.988831 
	C745.719910,670.988831 745.591858,670.926147 745.203125,670.646301 
	C742.877075,670.245850 740.939819,670.125244 739.188843,669.729370 
	C741.345276,668.017395 743.532410,666.798889 745.240784,665.099243 
	C748.938965,661.419678 752.392090,657.493713 756.166565,653.398315 
	C757.954712,652.805054 759.521729,652.481934 761.088745,652.158813 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M761.369629,651.963867 
	C759.521729,652.481934 757.954712,652.805054 755.804932,653.476440 
	C754.485779,653.864990 753.749512,653.905334 753.018616,653.571045 
	C753.716492,650.110901 756.080078,647.017883 753.411499,643.943604 
	C758.607666,643.494324 763.375305,643.050659 768.504395,642.701904 
	C769.615356,642.764038 770.364868,642.731323 771.098877,643.078369 
	C771.040405,643.962769 770.997437,644.467407 770.954468,644.972046 
	C767.853149,647.237671 764.751770,649.503235 761.369629,651.963867 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M786.356934,634.094604 
	C789.199158,633.521484 792.041382,632.948364 794.950623,632.700317 
	C789.690552,637.119751 784.449158,641.333862 778.985901,645.237610 
	C777.274963,646.460144 774.744690,649.513855 773.260986,644.293213 
	C773.357971,643.766418 773.429810,643.442627 773.724182,643.307739 
	C775.451660,641.869934 776.884766,640.567017 778.498901,639.227295 
	C778.679932,639.190552 779.010681,639.026062 779.316528,639.011353 
	C780.246399,638.298645 780.870300,637.600647 781.811157,636.757324 
	C783.537781,635.772949 784.947327,634.933777 786.356934,634.094604 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M739.002502,670.004700 
	C740.939819,670.125244 742.877075,670.245850 745.132935,670.658691 
	C741.424805,673.459778 737.398132,675.968567 733.182739,678.765381 
	C732.993958,679.053467 732.996399,679.001709 732.794067,679.119690 
	C732.377563,679.503540 732.163391,679.769470 731.949219,680.035400 
	C730.475525,680.003357 729.001770,679.971375 727.211304,679.532349 
	C728.897888,677.122009 730.901306,675.118774 733.224731,672.967773 
	C735.364014,671.881592 737.183289,670.943115 739.002502,670.004700 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M773.429810,643.442627 
	C773.429810,643.442627 773.357971,643.766418 773.275818,643.909912 
	C772.641418,644.301086 772.089172,644.548767 771.245667,644.884277 
	C770.997437,644.467407 771.040405,643.962769 771.308472,642.880859 
	C772.165649,642.683228 772.797729,643.062927 773.429810,643.442627 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M745.995667,670.975708 
	C746.042847,670.857483 746.365784,670.726196 746.756104,670.779785 
	C746.639526,670.964050 746.455444,670.963318 745.995667,670.975708 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M526.085999,636.929688 
	C520.189331,633.150818 514.292603,629.371948 508.202881,625.322876 
	C507.217682,623.361206 506.425537,621.669800 505.313751,619.296021 
	C507.265381,619.206116 509.190033,618.757385 510.962189,619.099182 
	C518.100159,620.475769 525.163330,622.244263 532.306519,623.589355 
	C540.897827,625.207031 549.542725,626.540161 558.163940,627.999451 
	C559.451294,628.217407 560.950806,628.102783 561.983887,628.739502 
	C566.115906,631.285950 566.955200,629.094543 567.392090,625.206238 
	C569.181213,625.549011 570.601807,626.198364 572.019470,627.257080 
	C572.027893,628.754639 572.039307,629.842834 571.715698,630.943970 
	C570.586121,631.370361 569.139526,631.732483 569.102173,632.205750 
	C568.848389,635.420532 568.891785,638.658813 568.445923,641.882690 
	C557.108337,639.101868 546.154968,636.362915 535.226990,633.525757 
	C532.884583,632.917603 530.832336,632.297852 530.756592,635.930420 
	C528.974731,636.263062 527.530334,636.596375 526.085999,636.929688 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M506.988892,615.051392 
	C503.004608,612.220703 499.020325,609.390015 494.946808,606.216553 
	C494.857605,605.873718 494.866943,605.855286 495.205048,605.888916 
	C496.764404,605.153503 497.985626,604.384521 499.530457,603.458618 
	C500.266296,603.064697 500.678558,602.827820 501.506775,602.726685 
	C505.588593,603.333740 509.254456,603.804993 512.970154,604.576599 
	C514.002869,604.938110 514.985657,604.999329 516.357849,605.102783 
	C520.852417,605.986755 524.957581,606.828430 529.404053,607.854919 
	C531.999573,608.529663 534.253967,609.019531 536.663086,609.773376 
	C537.566956,610.132812 538.316101,610.228394 539.370056,610.506287 
	C540.140686,610.744080 540.606567,610.799500 541.221924,611.114746 
	C541.930176,611.424500 542.488892,611.474487 543.350220,611.701050 
	C544.113464,611.938965 544.574097,612.000305 545.247559,612.353271 
	C549.461792,613.948242 553.463318,615.251648 557.348877,616.779907 
	C557.453979,616.929688 557.675171,616.854553 558.273804,616.797119 
	C559.387024,616.976135 560.122864,617.137573 560.857666,617.578369 
	C560.927368,618.223633 560.997986,618.589539 560.986389,619.325928 
	C559.934509,620.075378 558.898743,620.898987 558.005066,620.766235 
	C545.808228,618.953979 533.657104,616.811340 521.435669,615.197205 
	C516.696350,614.571289 511.808868,615.066772 506.988892,615.051392 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M572.022461,626.847778 
	C570.601807,626.198364 569.181213,625.549011 567.427368,624.793518 
	C566.966858,624.490845 566.812927,624.317993 566.448120,623.778137 
	C566.071228,622.746521 565.878540,622.105591 565.786560,621.013428 
	C567.347534,619.697205 568.781738,618.141174 570.272583,618.084900 
	C577.261780,617.820862 584.268616,617.869141 591.264648,618.018372 
	C594.203430,618.081116 597.130981,618.669067 599.977783,619.427307 
	C599.908813,620.387634 599.925720,620.939026 599.693359,621.777832 
	C599.282166,623.998657 599.394287,626.012329 598.859375,627.836243 
	C598.519531,628.995056 597.218201,629.871887 596.177612,630.553955 
	C595.606262,628.842773 595.205383,627.452759 594.831543,625.786133 
	C594.540527,625.430603 594.222351,625.351685 593.493469,625.251831 
	C586.062622,625.769836 579.042542,626.308777 572.022461,626.847778 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M470.119049,598.340332 
	C468.547638,596.700378 467.109467,595.370117 465.299500,594.039551 
	C464.927643,594.039307 464.980255,594.016296 464.935852,593.705139 
	C464.200195,592.969727 463.508972,592.545471 462.817749,592.121216 
	C462.817719,592.121216 462.790222,592.201050 462.754578,592.224609 
	C463.079071,591.015076 463.439240,589.781921 464.227112,588.215820 
	C465.104919,588.270203 465.554962,588.657532 466.061737,589.406860 
	C466.938995,590.046021 467.759552,590.323303 468.842651,590.600037 
	C469.443542,590.687378 469.781921,590.775269 470.108521,591.228638 
	C471.776245,592.356750 473.455750,593.119324 475.331268,594.117310 
	C476.605408,594.962524 477.683533,595.572205 478.832794,596.512085 
	C479.643982,597.191223 480.384033,597.540161 481.354614,598.099670 
	C482.335205,598.679749 483.085327,599.049316 483.843628,599.738892 
	C484.882385,600.469543 485.912903,600.880188 487.302948,601.250610 
	C488.383362,601.238770 489.104187,601.267151 489.900299,601.602173 
	C491.528473,602.919983 493.081360,603.931091 494.704041,605.167603 
	C494.773865,605.392883 494.866943,605.855286 494.866943,605.855286 
	C494.866943,605.855286 494.857605,605.873718 494.848633,605.876709 
	C492.033264,610.992859 488.238708,608.358398 484.253540,607.271973 
	C479.335388,604.458008 474.793854,601.554016 470.119049,598.340332 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M565.685913,621.464600 
	C565.878540,622.105591 566.071228,622.746521 566.281250,623.722900 
	C552.157410,622.192139 538.016968,620.320251 523.874573,618.463562 
	C519.974854,617.951660 516.061646,617.537415 512.169800,616.973877 
	C510.483948,616.729736 508.836487,616.220764 507.080261,615.441711 
	C511.808868,615.066772 516.696350,614.571289 521.435669,615.197205 
	C533.657104,616.811340 545.808228,618.953979 558.005066,620.766235 
	C558.898743,620.898987 559.934509,620.075378 561.337402,619.332336 
	C562.240295,618.948547 562.709900,618.928894 563.405518,619.115234 
	C564.316284,620.035706 565.001099,620.750122 565.685913,621.464600 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M708.128845,378.454041 
	C708.922607,379.494568 709.716370,380.535095 710.408325,382.134644 
	C707.466370,385.057465 704.626282,387.421295 701.457153,389.886841 
	C698.820007,390.015991 696.511902,390.043396 694.101807,389.719727 
	C692.098694,388.499115 690.197571,387.629547 688.215576,386.401672 
	C688.092468,385.548096 688.050049,385.052765 688.081116,384.243835 
	C690.420349,381.247406 692.686157,378.564606 694.979858,375.578094 
	C695.092834,374.876709 695.177917,374.479065 695.594727,373.984222 
	C696.212463,371.914795 696.498535,369.942596 696.813843,367.735016 
	C696.843018,367.499664 696.965149,367.041382 697.331421,367.008698 
	C699.609619,367.832672 701.521484,368.689331 703.600891,369.621063 
	C705.251648,369.157288 706.926697,364.803925 709.055542,369.380493 
	C706.471313,371.558350 703.887024,373.736237 700.741150,376.387421 
	C702.282227,376.635895 703.135925,376.773560 704.585815,377.209229 
	C706.164307,377.822845 707.146545,378.138458 708.128845,378.454041 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M701.786194,389.785095 
	C704.626282,387.421295 707.466370,385.057465 710.575623,382.434448 
	C711.010681,382.161713 711.250549,382.069550 711.331970,382.145996 
	C714.061584,384.708984 717.132751,386.262451 720.934814,386.101105 
	C720.235291,387.786713 719.612793,388.867584 718.751709,390.101257 
	C717.868347,390.623260 717.223511,390.992493 716.175842,391.264313 
	C713.969116,391.550629 712.165222,391.934296 710.653687,392.255829 
	C710.380249,393.988403 710.188110,395.205658 709.645752,396.599579 
	C708.518494,396.839966 707.741394,396.903656 706.733887,396.690887 
	C702.254944,396.108917 701.939941,393.004425 701.786194,389.785095 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M709.320557,369.366882 
	C706.926697,364.803925 705.251648,369.157288 703.600891,369.621063 
	C701.521484,368.689331 699.609619,367.832672 697.130737,366.776001 
	C695.925659,365.807800 695.287598,365.039581 694.928711,364.007751 
	C696.872070,363.033813 698.536316,362.323517 700.619629,361.524628 
	C704.628052,361.087738 708.217346,360.739410 711.896606,360.726135 
	C714.453003,364.505859 710.961426,366.590637 710.110901,369.278229 
	C709.935852,369.303253 709.760742,369.328278 709.320557,369.366882 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M721.271484,369.829681 
	C723.692383,372.417297 723.536804,374.610992 721.069275,377.093597 
	C719.521179,375.181396 718.134583,373.468567 716.138794,371.003357 
	C717.610779,370.572479 719.298218,370.078522 721.271484,369.829681 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M710.486328,369.325195 
	C710.961426,366.590637 714.453003,364.505859 712.388062,360.594696 
	C714.122498,359.939026 715.455322,359.749817 716.802307,359.528320 
	C716.816406,359.496063 716.883301,359.518188 716.883301,359.518188 
	C716.903748,360.883179 716.924194,362.248138 717.047363,363.898254 
	C715.054016,365.912994 712.957947,367.642578 710.486328,369.325195 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M727.663330,382.882812 
	C731.039978,384.947021 734.084167,387.034943 736.767822,389.499176 
	C734.260071,389.062714 732.068176,388.348602 729.981323,387.402740 
	C728.571167,386.763550 727.309021,385.797913 725.811768,384.799500 
	C725.643005,384.620667 725.250000,384.325012 725.250000,384.325012 
	C725.943604,383.852173 726.637207,383.379333 727.663330,382.882812 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M708.538757,378.344299 
	C707.146545,378.138458 706.164307,377.822845 704.941772,377.193878 
	C709.368103,372.505768 710.880249,372.576630 714.832764,377.629730 
	C712.747559,377.844086 710.848145,378.039337 708.538757,378.344299 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M721.790405,380.855103 
	C720.077148,381.774017 718.449463,382.337463 716.474731,383.020996 
	C718.232727,378.463013 718.667847,378.298615 721.790405,380.855103 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M718.990234,389.948425 
	C719.612793,388.867584 720.235291,387.786713 720.998108,386.404358 
	C722.313721,385.569122 723.489136,385.035400 724.957275,384.413361 
	C725.250000,384.325012 725.643005,384.620667 725.756592,385.141357 
	C723.576843,387.090820 721.283569,388.519623 718.990234,389.948425 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M689.100769,697.090088 
	C687.621887,697.341919 686.142944,697.593689 684.063171,697.599731 
	C681.869629,695.700439 680.097046,694.180786 678.737610,692.353882 
	C677.508423,690.701904 676.730469,688.714233 675.781067,686.517822 
	C675.862305,685.443115 675.916016,684.725098 676.218994,683.815918 
	C674.852356,679.637939 672.446777,679.351196 667.611145,682.693115 
	C666.895264,682.792725 666.480408,682.804016 665.990540,682.436523 
	C664.450928,677.304749 665.831421,671.159668 668.919800,668.013611 
	C669.874023,669.331604 670.855896,670.687805 671.889587,672.329468 
	C673.285339,672.442505 674.629333,672.270020 676.375366,672.125366 
	C678.103699,672.328857 679.429932,672.504578 680.971558,672.943848 
	C683.459534,673.138367 685.731995,673.069336 688.433960,673.001099 
	C690.579895,673.058350 692.296509,673.114807 694.089844,673.386230 
	C694.380127,673.830444 694.636353,673.992249 694.994080,674.446594 
	C695.721802,676.912842 696.390686,679.019043 697.042480,681.543762 
	C697.052429,683.116699 696.755310,684.402039 697.160095,685.403992 
	C699.482727,691.153564 695.687439,693.492188 691.618225,695.734863 
	C690.783630,696.194763 689.940125,696.638733 689.100769,697.090088 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M726.076233,680.164734 
	C725.586121,680.981079 725.290588,682.086304 724.575134,682.567993 
	C718.966248,686.344055 713.276855,690.000610 707.287659,693.802734 
	C706.164246,687.901184 710.441589,685.768127 715.150879,683.964966 
	C714.932251,683.398193 714.713684,682.831421 714.495117,682.264587 
	C711.292969,683.159546 708.090881,684.054443 704.493652,684.641602 
	C704.098267,683.917725 704.097839,683.501648 704.375061,682.912903 
	C706.412415,681.038757 708.172119,679.337341 710.342590,677.732300 
	C711.586243,677.735229 712.419067,677.641785 713.627930,677.649292 
	C718.027954,678.555054 722.052124,679.359863 726.076233,680.164734 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M675.753479,686.874573 
	C676.730469,688.714233 677.508423,690.701904 678.737610,692.353882 
	C680.097046,694.180786 681.869629,695.700439 683.686401,697.596191 
	C682.447327,698.266418 680.984192,698.694336 678.827881,699.385864 
	C676.756287,699.706604 675.377808,699.763733 673.828979,699.597290 
	C672.777161,699.582397 671.895691,699.791199 670.710571,699.749878 
	C668.674988,694.180725 671.525330,690.462097 675.044800,686.864502 
	C675.280273,686.834167 675.753479,686.874573 675.753479,686.874573 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M673.999329,699.820923 
	C675.377808,699.763733 676.756287,699.706604 678.546204,699.594971 
	C679.608276,700.805664 680.258850,702.070862 681.239014,703.976807 
	C679.165100,704.458191 677.518066,704.840454 675.110046,705.230591 
	C671.545593,704.897339 668.557007,709.067200 666.084351,704.646118 
	C666.751221,703.767639 667.244690,703.134949 668.011780,702.410767 
	C669.194763,701.891418 670.104065,701.463440 671.358765,701.018799 
	C672.469177,700.608337 673.234253,700.214600 673.999329,699.820923 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M704.097534,683.085632 
	C704.097839,683.501648 704.098267,683.917725 704.118103,684.644775 
	C702.350830,687.449768 700.552673,689.935791 698.780212,692.439941 
	C696.610779,695.504944 694.094421,697.888000 689.505005,697.106689 
	C689.940125,696.638733 690.783630,696.194763 691.618225,695.734863 
	C695.687439,693.492188 699.482727,691.153564 697.160095,685.403992 
	C696.755310,684.402039 697.052429,683.116699 697.337280,681.669434 
	C698.769531,682.318237 699.889954,683.259888 701.181091,684.345032 
	C701.823425,684.067688 702.960449,683.576660 704.097534,683.085632 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M665.910950,704.891968 
	C668.557007,709.067200 671.545593,704.897339 674.724426,705.312073 
	C674.092407,706.604736 673.084961,707.823669 672.094604,709.364746 
	C668.589233,709.664429 665.066711,709.641968 661.397156,709.041626 
	C662.803711,707.273071 664.357300,706.082520 665.910950,704.891968 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M681.365234,714.971191 
	C679.509155,714.524780 678.002502,714.050720 676.234497,713.347900 
	C677.942993,712.259705 679.912903,711.400452 681.915527,710.144287 
	C682.011230,706.123718 684.315613,706.845764 686.766235,707.663025 
	C685.242249,710.274414 683.478455,712.609009 681.365234,714.971191 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M726.548462,337.961792 
	C727.914246,339.162872 729.000916,340.147156 730.613220,341.607422 
	C730.299438,342.085571 729.430725,343.409454 727.882080,344.893341 
	C724.799438,344.363647 722.396606,343.673920 719.613525,342.962952 
	C717.325562,341.608643 715.417847,340.275604 713.449463,338.536682 
	C712.873413,334.123993 712.358032,330.117218 711.937134,325.766174 
	C712.885742,324.729584 713.739868,324.037262 714.593933,323.344971 
	C717.013672,325.491669 719.741028,327.386627 721.763550,329.858398 
	C723.654297,332.169128 724.797302,335.091705 726.548462,337.961792 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M698.511597,312.036530 
	C699.763855,311.736450 701.016113,311.436371 702.597412,311.168213 
	C704.058899,311.782166 705.191223,312.364197 706.716492,313.234924 
	C707.395874,313.721954 707.682434,313.920258 707.968933,314.118561 
	C708.591553,315.187958 709.214172,316.257324 709.899902,317.970276 
	C708.382568,319.285980 706.242188,320.975220 705.313354,320.463837 
	C699.636841,317.338501 696.444702,319.857452 693.631226,324.878906 
	C690.232117,327.065369 687.135010,329.062988 683.711426,331.032288 
	C682.950317,330.974457 682.515747,330.944977 681.907104,330.687622 
	C681.139954,329.995331 680.546814,329.530945 680.337402,329.033936 
	C685.203003,327.240875 686.660706,323.154205 684.261536,319.616089 
	C690.469116,319.332520 695.075256,316.800659 698.511597,312.036530 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M693.933228,324.690125 
	C696.444702,319.857452 699.636841,317.338501 705.313354,320.463837 
	C706.242188,320.975220 708.382568,319.285980 710.076050,318.241699 
	C711.727234,319.459198 713.265442,321.048798 714.698792,322.991699 
	C713.739868,324.037262 712.885742,324.729584 711.599548,325.814850 
	C709.045227,328.776001 707.040039,329.528992 703.588379,327.698395 
	C701.335876,326.503723 697.300659,325.187988 695.773071,330.110809 
	C695.376465,330.540497 695.104248,330.743225 694.505798,330.880066 
	C694.097473,328.772858 694.015381,326.731476 693.933228,324.690125 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M707.881287,313.873383 
	C707.682434,313.920258 707.395874,313.721954 707.047241,313.263428 
	C707.254578,313.211548 707.524109,313.419861 707.881287,313.873383 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M595.021851,681.192383 
	C594.264404,681.197449 593.507019,681.202454 592.199341,680.918396 
	C590.801270,680.373779 589.953369,680.118164 589.105408,679.862549 
	C584.662476,676.257385 580.219543,672.652100 575.351318,668.671021 
	C570.303284,664.827332 565.680542,661.359497 561.057800,657.891724 
	C558.672302,655.914429 556.286865,653.937134 553.340088,651.707825 
	C551.510315,650.320007 550.241821,649.184143 548.966797,647.658447 
	C549.694885,643.652344 552.228394,643.685913 554.939331,644.475220 
	C557.388916,645.188416 559.763794,646.158142 562.182190,647.352295 
	C569.079163,653.369690 575.903687,659.127136 582.881653,664.692139 
	C585.769836,666.995483 588.986633,668.886841 592.043945,671.340210 
	C593.393616,674.506226 594.753357,677.297607 595.860718,680.193665 
	C595.412842,680.596375 595.217346,680.894409 595.021851,681.192383 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M548.973328,648.048340 
	C550.241821,649.184143 551.510315,650.320007 552.989929,651.706299 
	C552.120483,652.231445 551.039978,652.506104 549.618530,652.674866 
	C547.888123,651.778564 546.340271,650.122986 545.135681,650.343384 
	C538.986755,651.468323 536.903259,646.597290 533.602661,643.308777 
	C531.434204,641.148376 528.804199,639.451172 526.233459,637.238159 
	C527.530334,636.596375 528.974731,636.263062 531.084106,636.013062 
	C537.490540,640.080444 543.231934,644.064392 548.973328,648.048340 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M560.806030,658.097961 
	C565.680542,661.359497 570.303284,664.827332 575.001709,668.632324 
	C574.514587,669.178711 573.706299,669.739807 573.425781,669.544128 
	C569.359924,666.708740 565.325256,663.823364 561.396423,660.802612 
	C560.817566,660.357605 560.820557,659.155823 560.806030,658.097961 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M589.075378,680.108398 
	C589.953369,680.118164 590.801270,680.373779 591.820679,680.923035 
	C591.113403,681.264526 590.234680,681.312256 589.195801,681.104736 
	C589.035645,680.849426 589.045349,680.354248 589.075378,680.108398 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M550.790222,441.080414 
	C552.336853,439.736115 553.883423,438.391846 555.430054,437.047577 
	C555.123718,436.717102 554.817322,436.386627 554.510986,436.056183 
	C552.410278,436.870544 550.309631,437.684937 547.946289,438.248169 
	C552.174316,434.241058 556.367981,430.303955 556.198059,424.381073 
	C558.511536,422.671722 560.431763,421.252930 562.916382,419.817932 
	C563.867493,419.884033 564.254272,419.966370 564.704590,420.413391 
	C566.074280,423.250214 567.380432,425.722382 568.731812,428.515808 
	C569.404358,429.651764 570.031555,430.466492 570.705200,431.665955 
	C571.113281,433.018951 571.474976,433.987152 571.709961,435.121674 
	C571.604065,435.639709 571.790527,435.804810 572.175598,436.091858 
	C572.244446,436.604248 572.280334,436.808044 571.991943,437.129028 
	C570.505432,438.125977 569.343201,439.005737 568.048645,439.985657 
	C568.977417,441.344055 569.755493,442.482086 570.617188,443.742493 
	C568.455750,444.160522 566.670166,444.505859 564.884583,444.851196 
	C564.837524,445.348175 564.790405,445.845184 564.743286,446.342194 
	C567.127380,447.242706 569.511475,448.143250 571.969727,449.418457 
	C571.693359,453.677521 568.640686,453.683044 565.606445,453.889832 
	C563.882324,452.295654 562.422058,450.905365 560.806396,449.254608 
	C558.875732,447.935638 557.100586,446.877167 555.099915,445.563538 
	C553.513062,443.899048 552.151611,442.489746 550.790222,441.080414 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M564.640991,420.048676 
	C564.254272,419.966370 563.867493,419.884033 563.235474,419.618408 
	C563.163330,417.772858 563.336548,416.110626 563.266479,414.226074 
	C563.510925,409.688293 567.072571,408.559021 570.613647,407.358093 
	C571.364136,409.161865 571.750916,410.639008 571.984863,412.428741 
	C569.435120,415.177094 567.038025,417.612885 564.640991,420.048676 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M572.137634,412.116211 
	C571.750916,410.639008 571.364136,409.161865 570.966675,407.314087 
	C571.840271,404.931702 572.724548,402.919922 573.811401,400.632629 
	C575.786255,398.854095 577.558655,397.351044 579.656128,396.070282 
	C579.010925,399.140015 578.040588,401.987488 577.001282,405.194733 
	C575.899109,407.561371 574.865906,409.568207 573.553101,411.675537 
	C572.894836,411.889435 572.516235,412.002838 572.137634,412.116211 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M493.140137,470.257324 
	C494.091034,468.979370 495.199890,468.030334 496.626465,467.035767 
	C499.364075,467.330414 501.783997,467.670624 504.582397,468.004028 
	C505.001740,468.399170 505.042572,468.801117 504.777985,469.572205 
	C503.909943,470.298981 503.347321,470.656677 502.784729,471.014343 
	C504.438293,472.662140 506.091919,474.309998 507.833221,476.317535 
	C509.032928,478.183563 510.144897,479.689850 511.109436,481.346802 
	C510.961975,481.497406 510.665802,481.797424 510.310303,481.805756 
	C508.266937,482.053894 506.511322,482.085358 504.914093,482.604218 
	C503.782654,482.971741 502.865234,483.998169 501.611633,484.963745 
	C500.243134,485.771759 499.115234,486.346527 497.987366,486.921265 
	C497.645050,482.888947 497.606476,478.795410 496.812042,474.854218 
	C496.490723,473.260193 494.518890,471.998901 493.140137,470.257324 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M497.923431,487.272400 
	C499.115234,486.346527 500.243134,485.771759 502.040710,485.111145 
	C503.254242,486.015228 503.798096,487.005219 504.318176,488.083740 
	C504.294403,488.172241 504.054932,488.484589 503.722565,488.639252 
	C500.915558,490.839661 498.440918,492.885376 495.636353,495.001953 
	C493.830872,496.679382 492.355255,498.285919 490.610779,500.044739 
	C489.564117,501.133209 488.786346,502.069458 487.852966,503.309814 
	C486.151703,504.401886 484.605988,505.189819 482.952454,505.765625 
	C482.662689,505.275909 482.412201,505.091736 482.093079,505.000916 
	C482.240936,503.132111 482.388794,501.263336 482.788879,499.074921 
	C483.865265,498.722839 484.976318,499.025940 485.468445,498.605164 
	C489.662506,495.019165 493.744171,491.301666 497.923431,487.272400 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M484.991089,486.459045 
	C483.633911,487.449768 482.367340,488.117432 480.725189,489.104187 
	C480.176971,489.356079 480.004303,489.288940 479.868347,488.845459 
	C479.283691,486.644318 478.662354,484.819489 478.041016,482.994690 
	C480.465363,480.731506 482.889709,478.468323 485.623566,476.085022 
	C489.863190,479.839508 489.716736,481.589111 484.991089,486.459045 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M718.751709,390.101227 
	C721.283569,388.519623 723.576843,387.090820 725.925415,385.320160 
	C727.309021,385.797913 728.571167,386.763550 729.981323,387.402740 
	C732.068176,388.348602 734.260071,389.062714 736.824097,389.844421 
	C737.674683,390.467560 738.108459,391.121796 738.797791,392.161133 
	C739.358032,392.605652 739.662720,392.665100 739.967407,392.724548 
	C740.293518,393.282440 740.619629,393.840332 740.948853,395.091888 
	C741.269287,398.373718 739.995850,399.339233 737.562988,399.066376 
	C734.697693,398.745026 731.836121,398.391083 728.972900,398.051239 
	C728.249939,397.987732 727.526978,397.924255 726.317505,397.724121 
	C725.536499,397.704376 725.242004,397.821259 724.947571,397.938171 
	C724.535889,397.936035 724.124146,397.933899 723.310486,397.590363 
	C721.652161,397.053894 720.395813,396.858826 719.139465,396.663727 
	C719.097412,397.456482 719.055359,398.249207 719.013306,399.041962 
	C718.422302,400.814301 717.831299,402.586639 716.793152,404.756226 
	C710.551758,409.440094 704.757507,413.726715 698.638672,418.116089 
	C695.562866,417.684723 692.811646,417.150574 689.863403,416.422058 
	C689.130249,416.090332 688.594238,415.952972 687.838501,415.588989 
	C686.798706,415.191071 685.978699,415.019775 684.893311,414.591675 
	C683.414490,414.264038 682.200867,414.193207 680.823730,413.813995 
	C672.911438,407.937744 664.307373,406.180389 655.024841,407.447418 
	C655.359070,406.075409 655.627930,404.992462 656.122498,403.655334 
	C657.333923,402.556610 658.527649,401.862640 659.268127,400.840363 
	C661.916443,397.184204 664.115295,397.752960 666.942261,400.853394 
	C668.133240,402.159576 670.605469,402.297516 672.638184,403.248291 
	C671.742615,406.323944 672.452881,407.842560 675.671082,408.167999 
	C677.249573,408.327576 678.869812,409.076080 680.261108,409.908905 
	C685.743469,413.190674 691.595581,413.143646 696.685791,412.806213 
	C697.655457,409.997925 697.741882,407.322571 699.086426,406.297699 
	C703.530029,402.910614 703.793823,402.443329 700.222412,398.547119 
	C702.665894,397.861572 704.815063,397.414459 706.964294,396.967346 
	C707.741394,396.903656 708.518494,396.839966 709.963806,396.699707 
	C712.614258,394.869354 714.596436,393.115509 716.578674,391.361694 
	C717.223511,390.992493 717.868347,390.623260 718.751709,390.101227 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M729.141113,398.365936 
	C731.836121,398.391083 734.697693,398.745026 737.562988,399.066376 
	C739.995850,399.339233 741.269287,398.373718 740.989868,395.361572 
	C741.027832,394.937561 741.498474,394.930786 741.728882,394.977753 
	C742.046692,396.466705 742.134033,397.908691 742.059387,399.631042 
	C737.433899,402.236298 733.229919,401.907227 729.141113,398.365936 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M739.749207,392.513733 
	C739.662720,392.665100 739.358032,392.605652 738.991577,392.383514 
	C739.130310,392.248199 739.330688,392.275543 739.749207,392.513733 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M586.524780,390.736938 
	C590.858459,388.316437 595.105774,386.074738 599.929443,383.948914 
	C601.159546,384.412781 601.813354,384.760773 602.606384,385.464233 
	C603.378845,386.086121 604.012024,386.352600 604.546204,386.512817 
	C604.447144,386.406555 604.316345,386.665985 604.298584,386.989838 
	C607.345398,390.455048 610.410034,393.596436 613.098450,396.796326 
	C611.157104,396.245697 609.592041,395.636505 607.900879,394.978302 
	C607.585327,396.518005 607.328125,397.772980 606.755859,398.862061 
	C605.118042,398.376831 603.770630,397.711182 602.477051,397.803131 
	C598.612061,398.077789 594.768188,398.649231 590.554077,399.061707 
	C588.795410,399.325470 587.398743,399.632294 585.658936,399.861755 
	C585.166809,398.203369 585.017883,396.622375 585.076782,394.761963 
	C585.603271,393.408356 585.921814,392.334198 586.240356,391.260010 
	C586.240356,391.260010 586.438416,390.915741 586.524780,390.736938 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M584.868896,395.041351 
	C585.017883,396.622375 585.166809,398.203369 585.660400,400.261322 
	C584.991394,403.129272 583.977661,405.520325 582.707153,407.913574 
	C582.282776,407.880219 582.115173,407.844635 581.736206,407.572693 
	C580.039978,406.502533 578.555115,405.668732 577.070251,404.834930 
	C578.040588,401.987488 579.010925,399.140015 579.945190,395.865326 
	C579.951538,395.260956 580.048340,395.121552 580.772339,395.004883 
	C582.519714,395.007019 583.694336,395.024200 584.868896,395.041351 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M585.076843,394.761963 
	C583.694336,395.024200 582.519714,395.007019 580.899292,394.965454 
	C582.130188,393.635986 583.806885,392.330994 585.861938,391.143005 
	C585.921814,392.334198 585.603271,393.408356 585.076843,394.761963 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M726.200317,680.031494 
	C722.052124,679.359863 718.027954,678.555054 713.519653,677.387634 
	C712.590942,676.696167 712.146423,676.367249 711.739258,675.628662 
	C712.374939,673.434448 712.868835,671.606262 713.593262,669.874573 
	C715.111450,666.245544 716.750977,662.667358 718.596924,659.006592 
	C718.935303,658.296753 719.017517,657.648621 719.077637,656.630432 
	C719.081604,655.521606 719.107666,654.782776 719.430908,653.891724 
	C721.473633,653.822449 723.219116,653.905396 724.831299,654.323364 
	C723.716858,656.862976 722.645142,659.035217 721.838196,661.301758 
	C721.693237,661.708923 722.639771,662.504761 723.016174,663.431885 
	C719.401306,666.497803 724.476318,667.779663 723.977417,670.420776 
	C726.032654,675.103638 729.954407,672.198914 732.904663,673.115540 
	C730.901306,675.118774 728.897888,677.122009 726.777954,679.442627 
	C726.661438,679.759949 726.324463,679.898193 726.200317,680.031494 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M732.209351,679.952515 
	C732.163391,679.769470 732.377563,679.503540 732.820068,679.119324 
	C732.855347,679.290527 732.662415,679.580139 732.209351,679.952515 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M666.084351,704.646118 
	C664.357300,706.082520 662.803711,707.273071 661.125183,708.771362 
	C659.932983,709.072632 658.865723,709.066284 657.398560,709.080322 
	C655.557129,707.728760 654.115540,706.356750 652.047363,704.831665 
	C649.303589,703.075623 647.186462,701.472656 644.968872,699.528931 
	C639.749023,694.588196 634.222961,696.868896 628.750916,698.235901 
	C628.400757,698.081360 628.050659,697.926819 627.330261,697.338440 
	C624.108032,694.629761 620.898315,692.668701 618.530579,689.969116 
	C616.967407,688.186768 616.614441,685.343079 615.763062,682.539307 
	C615.636353,680.091858 615.466187,678.079651 615.644897,676.014893 
	C616.219849,675.897217 616.445862,675.832153 616.779053,676.137085 
	C620.085571,679.870117 623.278870,683.239075 626.498718,686.582336 
	C626.701233,686.792664 627.104370,686.838379 627.425781,686.901306 
	C630.925964,687.586182 634.428406,688.259399 638.210083,689.162170 
	C639.392517,690.832397 640.295044,692.276245 641.524414,693.828003 
	C646.665649,697.141418 651.479980,700.346863 656.964233,703.998352 
	C656.964233,701.282776 656.964233,699.641785 657.348755,698.000427 
	C658.500244,698.003296 659.267273,698.006592 660.036987,698.376221 
	C659.899231,700.050659 659.758667,701.358826 659.609924,702.743469 
	C662.371033,702.032349 664.649475,701.445557 667.083618,701.292725 
	C667.405640,701.985168 667.571899,702.243713 667.738159,702.502197 
	C667.244690,703.134949 666.751221,703.767639 666.084351,704.646118 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M654.179443,715.788940 
	C653.888794,715.387268 653.895691,715.037048 653.934937,714.426636 
	C654.481506,714.353699 654.995667,714.540894 655.509888,714.728149 
	C655.165527,715.098877 654.821228,715.469543 654.179443,715.788940 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M825.185303,605.039795 
	C822.411011,605.789673 819.940918,606.351135 816.783203,607.101868 
	C815.155823,607.380249 814.216125,607.469360 812.892151,607.389160 
	C811.007263,607.516174 809.506653,607.812500 807.643066,608.122803 
	C805.855774,608.396484 804.431335,608.656189 802.838867,608.708130 
	C802.108582,608.721191 801.546387,608.941895 800.639038,609.202759 
	C799.213013,609.602112 798.132202,609.961304 796.769653,610.141968 
	C796.060242,609.842896 795.632507,609.722229 795.073547,609.252319 
	C793.884155,608.682434 792.798462,608.185730 791.772583,608.287964 
	C786.844910,608.778992 781.933472,609.431824 777.175781,609.769531 
	C777.927368,608.743103 778.519409,607.974243 779.419312,607.145874 
	C780.144409,607.058044 780.561584,607.029724 781.399414,607.017212 
	C786.876709,605.749695 791.933350,604.466370 797.361023,603.137329 
	C799.598877,602.249939 801.465576,601.408203 803.676758,600.451294 
	C806.037354,599.687256 808.053406,599.038452 810.108765,598.772217 
	C811.439453,599.759521 812.718994,600.854675 814.024353,600.886353 
	C820.711548,601.048889 827.405334,600.937500 834.096863,600.921753 
	C832.334961,601.772827 830.583130,602.645691 828.807312,603.466797 
	C827.720398,603.969360 826.596741,604.392517 825.185303,605.039795 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M850.047180,587.704712 
	C848.444275,589.132751 846.754211,590.219849 844.910400,591.618164 
	C844.756714,591.929321 844.417358,591.964050 843.963074,591.997559 
	C843.314087,592.343689 843.119446,592.656311 842.924744,592.968933 
	C842.924744,592.968933 842.998474,592.971619 842.627197,592.950928 
	C840.807068,593.949158 839.358276,594.968201 837.909424,595.987183 
	C837.909424,595.987183 837.519958,596.017944 836.925903,596.010742 
	C832.869507,598.139771 829.279053,598.675781 825.432190,597.205688 
	C827.807678,594.448547 829.986145,591.920105 832.379272,589.258789 
	C832.789978,588.871887 832.986145,588.617859 833.601074,588.400818 
	C835.075989,588.137024 836.132202,587.836243 837.552246,587.622803 
	C839.913086,587.405945 841.910034,587.101746 843.906921,586.797607 
	C845.924622,586.986328 847.942322,587.175049 850.047180,587.704712 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M832.164673,589.391724 
	C829.986145,591.920105 827.807678,594.448547 825.432190,597.205688 
	C829.279053,598.675781 832.869507,598.139771 836.731689,596.031799 
	C836.284607,597.552429 835.437561,599.044800 834.343689,600.729431 
	C827.405334,600.937500 820.711548,601.048889 814.024353,600.886353 
	C812.718994,600.854675 811.439453,599.759521 810.368652,598.566284 
	C810.947815,597.723206 811.306274,597.468811 812.000854,597.420837 
	C814.718994,596.643860 817.101074,595.660461 819.807312,594.570007 
	C822.083191,593.299377 824.034912,592.135742 826.349304,590.986450 
	C828.529541,590.464478 830.347107,589.928101 832.164673,589.391724 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M838.123047,596.260742 
	C839.358276,594.968201 840.807068,593.949158 842.603027,592.923035 
	C842.513672,595.526245 841.555115,597.470886 838.123047,596.260742 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M825.034607,608.600769 
	C824.587708,608.857910 824.131836,608.740356 823.675903,608.622864 
	C823.951965,608.110107 824.228088,607.597351 824.739990,606.902039 
	C824.992371,607.221680 825.008972,607.723877 825.034607,608.600769 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M843.169434,592.894043 
	C843.119446,592.656311 843.314087,592.343689 843.800598,592.049438 
	C843.866333,592.318359 843.640198,592.568787 843.169434,592.894043 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M618.058716,315.096924 
	C618.534729,314.479034 619.010742,313.861145 620.088379,313.131042 
	C623.376099,313.059082 626.062256,313.099335 628.896973,313.454529 
	C632.195190,317.468231 635.691101,320.345917 641.309509,319.107025 
	C647.821228,320.386627 654.064148,321.387817 660.188721,322.781311 
	C659.407471,323.800568 658.850586,324.671143 658.063782,325.013519 
	C654.076355,326.748779 650.029358,328.346954 645.700500,330.028198 
	C644.864197,330.334747 644.330627,330.606293 643.428345,330.870789 
	C640.851074,330.975372 637.336853,327.886627 637.148926,333.577026 
	C635.376892,334.624817 633.718201,335.324493 632.014587,335.637512 
	C631.657715,333.503632 631.345764,331.756470 631.116699,329.694946 
	C630.694946,328.793823 630.190308,328.207092 629.632690,327.536804 
	C629.579712,327.453247 629.442688,327.310486 629.458862,327.069885 
	C629.366699,326.520416 629.241394,326.218597 629.084900,325.519318 
	C629.281250,323.231689 629.491760,321.348602 629.787170,318.706543 
	C626.204651,319.132904 623.170410,319.494019 619.787354,319.814026 
	C618.970764,319.782196 618.502930,319.791443 617.877075,319.531067 
	C617.832336,317.873260 617.945557,316.485107 618.058716,315.096924 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M632.470947,306.045746 
	C632.911072,307.119965 633.018005,308.089783 632.887329,309.745178 
	C631.400513,310.629974 630.151306,310.829224 628.529297,311.037537 
	C625.796692,310.882507 623.436951,310.718414 621.077148,310.554321 
	C621.077148,310.554291 621.121155,310.339539 621.155762,309.904419 
	C620.466492,308.304108 619.742615,307.138916 619.018799,305.973694 
	C619.018799,305.973694 618.836853,305.859528 618.728516,305.870117 
	C618.837280,305.582855 619.054321,305.284973 619.553040,304.927155 
	C622.451416,304.513123 625.068176,304.158966 628.470764,303.698486 
	C629.130798,304.102203 630.634277,305.021790 632.470947,306.045746 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M605.003174,330.216003 
	C604.995667,329.095612 604.988098,327.975250 605.002319,326.435669 
	C607.787842,323.376312 610.551575,320.736176 613.621887,318.386780 
	C614.246033,319.776276 614.422058,320.944672 614.919250,321.955139 
	C615.461304,323.057159 616.300903,324.012787 616.687988,325.019897 
	C615.934753,325.006683 615.504333,325.005829 614.687500,324.996216 
	C612.487183,326.295349 610.673401,327.603210 608.527588,328.982819 
	C607.131409,329.441711 606.067322,329.828888 605.003174,330.216003 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M617.010803,325.032227 
	C616.300903,324.012787 615.461304,323.057159 614.919250,321.955139 
	C614.422058,320.944672 614.246033,319.776276 613.960632,318.316772 
	C614.218628,317.440735 614.444580,316.925385 614.942993,316.659790 
	C616.279968,317.751648 614.636475,321.234863 618.035034,319.800690 
	C618.502930,319.791443 618.970764,319.782196 619.754272,320.185669 
	C619.112671,322.427490 618.155457,324.256531 617.139404,325.824371 
	C617.057251,325.386200 617.034058,325.209198 617.010803,325.032227 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M620.859375,310.807983 
	C623.436951,310.718414 625.796692,310.882507 628.538208,311.392517 
	C628.862793,312.205475 628.805542,312.672516 628.748291,313.139587 
	C626.062256,313.099335 623.376099,313.059082 620.303345,312.931274 
	C620.158325,312.249725 620.399963,311.655670 620.859375,310.807983 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M617.877075,319.531067 
	C614.636475,321.234863 616.279968,317.751648 615.225952,316.589264 
	C615.965637,315.868011 616.694702,315.467102 617.741211,315.081543 
	C617.945557,316.485107 617.832336,317.873260 617.877075,319.531067 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M618.953735,306.323639 
	C619.742615,307.138916 620.466492,308.304108 621.132019,309.797424 
	C618.368530,310.226135 617.672668,309.054901 618.953735,306.323639 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M474.880859,500.309570 
	C474.534180,507.980957 469.443665,511.069305 462.912781,506.973846 
	C461.243713,505.927216 456.334564,507.064392 458.005859,501.960022 
	C458.859863,500.860840 459.652893,500.060516 461.079590,499.107178 
	C462.102966,498.268646 462.492645,497.583130 462.882355,496.897644 
	C464.041107,495.819672 465.199829,494.741669 466.782043,493.845032 
	C469.764008,496.120758 472.322418,498.215179 474.880859,500.309570 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M475.255981,500.207886 
	C472.322418,498.215179 469.764008,496.120758 467.080139,493.674744 
	C469.271729,490.887756 471.588806,488.452362 474.373749,486.329132 
	C476.504974,487.501434 478.168304,488.361603 479.831665,489.221802 
	C480.004303,489.288940 480.176971,489.356079 480.623047,489.469940 
	C480.973511,493.957489 481.691559,498.717072 475.255981,500.207886 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M479.868347,488.845459 
	C478.168304,488.361603 476.504974,487.501434 474.694092,486.297577 
	C475.484833,484.975586 476.423126,483.997284 477.701202,483.006836 
	C478.662354,484.819489 479.283691,486.644318 479.868347,488.845459 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M462.570618,496.937317 
	C462.492645,497.583130 462.102966,498.268646 461.387329,498.975555 
	C461.460571,498.323639 461.859741,497.650299 462.570618,496.937317 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M801.973022,622.509033 
	C800.230957,623.763611 798.651550,624.861206 796.919739,625.603333 
	C793.148499,627.219604 789.299622,628.654785 784.792969,630.293152 
	C783.738647,630.369324 783.373901,630.316345 782.833008,629.943359 
	C781.972961,629.571045 781.289124,629.518616 780.329468,629.411438 
	C779.698303,629.237854 779.342896,629.119019 778.850220,628.729492 
	C778.143799,628.283142 777.574646,628.107544 776.835510,627.628784 
	C772.815857,624.005981 768.966248,620.686340 765.116577,617.366760 
	C765.442200,616.732971 765.767822,616.099243 766.093445,615.465454 
	C769.225952,616.267700 772.514587,618.101196 775.458069,617.655457 
	C781.399780,616.755615 787.153870,614.617126 793.280762,612.978271 
	C794.049072,612.667236 794.521912,612.367432 795.327759,612.055176 
	C796.104675,612.036499 796.548645,612.030396 797.247803,612.296326 
	C799.337646,612.080566 801.172363,611.592712 803.419434,611.084778 
	C805.207764,611.074890 806.583740,611.085083 808.056335,611.458801 
	C810.653015,613.513550 813.046570,614.198181 815.367981,611.073120 
	C816.134949,611.036804 816.573608,611.021545 817.345825,611.007935 
	C818.124329,611.001465 818.569214,610.993408 819.014099,610.985352 
	C819.336670,611.085754 819.599487,611.270996 819.876831,612.087524 
	C819.314697,613.773193 818.678223,614.912537 818.041626,616.051758 
	C815.410950,618.173279 812.873535,620.433594 810.079773,622.312622 
	C809.431274,622.748840 807.520874,622.251587 806.859985,621.551941 
	C804.779480,619.349426 803.458618,620.497192 801.973022,622.509033 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M818.409424,616.032715 
	C818.678223,614.912537 819.314697,613.773193 819.989380,612.304321 
	C820.637390,612.546875 821.247131,613.119019 821.856934,613.691101 
	C820.830383,614.465271 819.803772,615.239441 818.409424,616.032715 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M819.009399,610.631958 
	C818.569214,610.993408 818.124329,611.001465 817.346252,610.672607 
	C817.008423,609.670959 817.003845,609.006287 816.999207,608.341553 
	C817.429077,608.264526 817.859009,608.187500 818.615845,608.139771 
	C818.963501,608.872192 818.984131,609.575439 819.009399,610.631958 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M698.104675,312.029053 
	C695.075256,316.800659 690.469116,319.332520 683.832397,319.559265 
	C677.735107,320.562225 672.353088,321.360199 666.802979,321.922089 
	C666.248657,321.654297 665.862488,321.622620 665.476257,321.590942 
	C667.372559,319.085449 669.089355,316.403015 671.276672,314.184387 
	C671.916687,313.535187 673.896240,314.279419 675.259705,314.256622 
	C678.825134,314.196960 682.389282,314.066376 686.080811,313.581055 
	C688.264832,311.780518 690.322021,310.363007 692.668945,308.713440 
	C694.538391,309.661469 696.118042,310.841553 698.104675,312.029053 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M854.884399,494.787567 
	C855.428528,495.647003 855.829773,496.306580 856.527893,497.070007 
	C858.299011,498.769745 859.773315,500.365662 861.575928,502.028015 
	C862.409851,503.252014 862.633972,505.128326 863.465332,505.453796 
	C870.379822,508.160828 872.271606,514.141113 873.257568,519.776428 
	C876.267273,521.005005 878.605957,521.959717 880.835693,523.609497 
	C876.575989,524.980835 874.900024,527.837158 875.107788,531.601196 
	C875.243896,534.066162 876.360657,536.477051 876.681152,538.977722 
	C874.638123,540.296448 872.952271,541.549622 871.210205,542.579468 
	C871.154053,542.356140 871.080261,541.901611 871.229797,541.614380 
	C870.961609,540.297607 870.828369,538.778870 870.068787,538.337219 
	C868.314270,537.316833 866.256104,536.818542 864.349609,536.119019 
	C864.978455,534.189331 865.939636,532.156738 865.657654,531.966431 
	C861.914062,529.440186 864.767273,528.161377 866.440735,526.511414 
	C866.982849,525.976929 867.405212,525.009888 867.367188,524.267151 
	C867.300964,522.973083 866.834961,521.699463 866.536438,520.417297 
	C865.668884,521.179626 864.581116,521.798828 863.975342,522.731262 
	C862.195251,525.470825 860.606567,528.334778 858.689514,531.121216 
	C858.266113,531.096313 858.096069,531.100342 857.843262,530.750122 
	C857.117371,528.325806 856.474426,526.255798 855.904419,523.850952 
	C855.321411,522.683533 854.665405,521.850952 854.010620,520.597900 
	C853.623108,518.514771 853.770630,515.940674 852.755066,515.343018 
	C847.615479,512.318665 842.173462,509.807953 836.532227,507.101990 
	C835.821228,507.046448 835.415649,507.006073 834.965271,506.638367 
	C834.234619,505.905975 833.548706,505.500885 832.537354,505.025452 
	C831.739258,504.040192 831.266663,503.125275 831.089966,502.157684 
	C831.584351,502.077820 831.782837,502.050598 832.041321,502.171448 
	C832.200745,502.366394 832.300171,502.413300 832.399658,502.460205 
	C832.274902,502.300018 832.150146,502.139801 832.030029,501.537598 
	C832.487305,498.287170 832.940002,495.478790 833.717529,492.830688 
	C835.532837,496.830231 836.214294,501.471619 842.018799,501.406982 
	C842.845825,504.573303 843.629822,507.405518 847.033386,507.807343 
	C848.101868,504.982941 849.060059,502.450104 850.018250,499.917297 
	C850.508240,500.086700 850.998230,500.256104 851.488281,500.425537 
	C851.884216,502.247955 852.280151,504.070374 852.848328,506.685486 
	C854.995483,505.224487 856.514404,504.190979 859.306091,502.291443 
	C855.100098,499.680756 851.877136,497.680298 848.700073,495.308716 
	C848.782776,494.182800 848.819763,493.428009 848.856689,492.673218 
	C850.302307,493.135254 851.747986,493.597260 853.572876,494.037079 
	C854.261353,494.142334 854.524536,494.333313 854.884399,494.787567 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M877.038208,538.912170 
	C876.360657,536.477051 875.243896,534.066162 875.107788,531.601196 
	C874.900024,527.837158 876.575989,524.980835 880.937256,523.960205 
	C884.056580,526.057312 886.965332,528.498718 890.152466,531.081543 
	C890.466003,532.210144 890.501099,533.197388 890.141602,534.449219 
	C887.093628,533.441772 886.164429,534.105408 883.727051,539.200745 
	C883.387756,539.903625 883.133301,540.358643 882.574890,540.884583 
	C881.863525,541.041199 881.456177,541.127014 880.873230,540.909790 
	C879.826416,540.389832 878.955200,540.172852 877.857178,539.840454 
	C877.432983,539.454041 877.235596,539.183105 877.038208,538.912170 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M435.816803,562.972229 
	C438.693756,559.723328 441.692474,556.747375 445.164368,553.534302 
	C446.024872,553.319397 446.412231,553.341553 446.883606,553.682495 
	C448.704315,555.627869 450.440979,557.254333 452.081238,559.249451 
	C450.247833,560.851562 448.510895,562.085083 446.486084,563.257507 
	C445.812164,563.126526 445.426056,563.056641 444.836731,562.703186 
	C442.220795,561.243042 439.532898,559.537354 440.153687,564.840820 
	C439.775116,565.190491 439.623627,565.330322 439.472168,565.470093 
	C438.294312,564.728455 437.116425,563.986816 435.816803,562.972229 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M448.039276,574.841736 
	C447.912567,574.582886 447.735077,574.365601 447.358429,573.781006 
	C447.200195,572.969360 447.190308,572.566406 447.470001,572.090454 
	C448.136780,571.379700 448.514008,570.742004 449.241669,570.104919 
	C452.441895,571.921265 455.291718,573.737000 458.051178,575.936523 
	C457.646484,577.306335 457.332184,578.292297 456.627319,579.253662 
	C453.504272,577.766602 450.771759,576.304138 448.039276,574.841736 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M447.968506,575.207275 
	C450.771759,576.304138 453.504272,577.766602 456.675415,579.569641 
	C458.409698,581.185974 459.705383,582.461609 460.994141,584.099243 
	C460.958527,584.700867 460.929779,584.940491 460.901062,585.180115 
	C456.451874,583.118652 452.002655,581.057190 447.172302,579.075439 
	C446.691315,579.011169 446.591522,578.867371 446.561157,578.469238 
	C447.052979,577.334290 447.475342,576.453552 447.968506,575.207275 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M447.180420,572.163452 
	C447.190308,572.566406 447.200195,572.969360 447.175842,573.673584 
	C444.759552,571.897583 442.377472,569.820435 440.345825,567.367920 
	C441.452362,567.000122 442.208527,567.007690 443.261841,567.100464 
	C443.964966,567.234009 444.370911,567.282410 444.901367,567.542969 
	C445.259308,567.996338 445.536316,568.166931 445.869324,568.612427 
	C446.314697,570.026489 446.747559,571.094971 447.180420,572.163452 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M442.964661,567.015259 
	C442.208527,567.007690 441.452362,567.000122 440.317596,567.021118 
	C439.825897,566.699707 439.712830,566.349792 439.535950,565.734985 
	C439.623627,565.330322 439.775116,565.190491 440.429321,564.965210 
	C441.609589,565.591614 442.287109,566.303467 442.964661,567.015259 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M602.467163,385.108765 
	C601.813354,384.760773 601.159546,384.412781 600.309448,383.845734 
	C597.975403,383.207245 595.837585,382.787811 592.835876,382.198853 
	C594.333008,379.668976 595.807678,377.177002 597.188110,374.844330 
	C595.853882,374.114380 594.408325,373.323547 592.884644,372.147766 
	C595.170471,370.719025 597.534485,369.675323 600.251953,368.576355 
	C602.501160,369.592255 604.396973,370.663361 606.824097,372.170593 
	C611.783020,374.080444 613.778320,377.119385 612.877197,381.892609 
	C610.706787,385.204559 607.736816,386.330902 604.316345,386.665985 
	C604.316345,386.665985 604.447144,386.406555 604.516907,386.112701 
	C603.880188,385.582153 603.173645,385.345459 602.467163,385.108765 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M586.964478,375.178223 
	C587.046021,374.555817 587.275574,374.333221 587.802124,374.100586 
	C588.438965,374.878296 588.846802,375.736084 589.254578,376.593872 
	C588.517883,376.231934 587.781189,375.869965 586.964478,375.178223 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M780.467041,423.945312 
	C780.902039,424.707123 781.000732,425.460205 781.035828,426.592102 
	C780.180176,426.732117 779.388123,426.493256 778.311279,426.121399 
	C778.727966,425.304474 779.429321,424.620514 780.467041,423.945312 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M855.831421,524.185730 
	C856.474426,526.255798 857.117371,528.325806 857.803101,531.148804 
	C858.976013,538.738037 861.757202,544.452393 866.761230,549.628418 
	C871.110046,554.126648 873.912720,560.119690 877.398682,565.452148 
	C876.943726,566.031921 876.488831,566.611633 875.374878,567.168091 
	C874.147095,567.126221 873.578430,567.107666 872.817383,566.776917 
	C866.752441,564.981812 860.879822,563.498840 854.643311,561.945068 
	C850.524658,560.861877 846.769958,559.849548 842.792664,558.580078 
	C841.134399,556.642212 840.013672,554.153320 838.198547,553.445740 
	C834.186340,551.881653 829.824768,551.213867 825.423584,549.844604 
	C825.127197,547.704590 825.012878,545.900330 825.067505,544.033264 
	C825.131348,544.019897 825.026306,544.069397 824.685669,544.066467 
	C824.450134,544.013977 823.968323,544.042236 824.066345,543.642090 
	C823.973389,542.172363 823.782471,541.102844 823.711914,539.643311 
	C823.324219,536.706665 822.815979,534.160095 822.295959,531.554321 
	C820.544067,531.895264 819.178528,532.161011 817.559631,532.476074 
	C817.076904,530.415161 816.670776,528.681396 816.485596,526.721497 
	C816.798218,526.305603 816.889893,526.115784 817.236450,525.965393 
	C817.656372,525.956543 817.821411,525.908325 818.312683,525.972534 
	C819.086121,525.042664 819.533325,524.000366 820.044312,522.535889 
	C819.856567,519.652527 819.605103,517.191345 819.401062,514.578613 
	C819.448547,514.427002 819.273499,514.161865 819.321411,513.871887 
	C819.319092,513.392517 819.268860,513.203064 819.392273,512.734802 
	C818.908203,509.595795 818.250488,506.735657 817.668335,503.535400 
	C817.674011,502.739441 817.604065,502.283600 817.928650,501.821808 
	C819.889221,502.657196 821.750427,503.196960 822.970764,504.391418 
	C828.638733,509.938873 834.103699,515.693054 839.707031,521.307495 
	C840.670288,522.272583 841.952148,522.919556 843.288330,523.900513 
	C843.834534,524.201599 844.179199,524.315735 844.711426,524.720337 
	C846.736694,524.807007 848.574463,524.603149 850.744141,524.309937 
	C851.655945,523.862488 852.235901,523.504211 853.175781,523.322266 
	C854.300964,523.727600 855.066162,523.956665 855.831421,524.185730 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M877.722656,565.369324 
	C873.912720,560.119690 871.110046,554.126648 866.761230,549.628418 
	C861.757202,544.452393 858.976013,538.738037 857.885986,531.503052 
	C858.096069,531.100342 858.266113,531.096313 858.821045,531.436646 
	C860.155457,534.173462 861.104797,536.565918 862.053467,539.321533 
	C865.274597,542.866150 868.496338,546.047607 871.745972,549.600403 
	C872.433594,551.542175 873.093384,553.112549 873.753174,554.682861 
	C874.427795,554.103882 875.102417,553.524902 876.042847,552.974365 
	C876.485474,553.024902 876.662354,553.046875 876.882935,553.453430 
	C877.691467,555.551086 878.456299,557.264221 879.221191,558.977295 
	C880.496155,557.891418 881.750610,556.779907 883.056641,555.732666 
	C883.553955,555.333923 884.169678,555.082703 884.731445,554.764160 
	C884.100464,556.502686 883.469482,558.241089 882.862976,560.333496 
	C881.273865,562.220459 879.660278,563.753479 877.722656,565.369324 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M855.007202,562.015930 
	C860.879822,563.498840 866.752441,564.981812 872.845215,567.159485 
	C873.091125,568.622070 873.116882,569.389893 873.142700,570.157776 
	C872.815002,570.551331 872.487305,570.944885 871.582520,571.349304 
	C870.671143,571.858765 870.336792,572.357422 870.002441,572.856079 
	C868.884338,573.993652 867.766174,575.131226 866.388916,576.511841 
	C864.717041,577.332275 863.304321,577.909607 861.452454,578.211670 
	C861.673462,576.333679 862.333618,574.730957 863.313477,572.999817 
	C865.631226,571.299988 867.629211,569.728638 870.202515,567.704834 
	C864.665344,565.990356 859.807190,564.486145 854.963501,562.740417 
	C854.977966,562.498901 855.007202,562.015930 855.007202,562.015930 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M873.458252,570.086670 
	C873.116882,569.389893 873.091125,568.622070 873.037598,567.471619 
	C873.578430,567.107666 874.147095,567.126221 875.118774,567.286133 
	C874.939209,568.290222 874.356567,569.152893 873.458252,570.086670 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M870.297058,572.817871 
	C870.336792,572.357422 870.671143,571.858765 871.324463,571.412109 
	C871.292908,571.902527 870.942322,572.341064 870.297058,572.817871 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M713.510132,338.942535 
	C715.417847,340.275604 717.325562,341.608643 719.610168,343.393677 
	C720.736694,345.927612 721.486328,348.009583 722.235962,350.091553 
	C720.245300,350.363861 718.254700,350.636169 715.750732,350.978699 
	C715.468018,350.130463 714.975037,348.651459 714.263855,346.517670 
	C712.824585,347.168732 711.596436,347.724304 710.368286,348.279907 
	C711.458984,349.962158 712.549744,351.644409 713.787781,353.626099 
	C713.533752,354.307617 713.132507,354.689667 712.234680,355.314606 
	C709.995728,356.205566 708.253296,356.853668 706.261841,357.594391 
	C706.261841,355.397797 706.191223,353.619659 706.281982,351.849792 
	C706.370972,350.114044 707.433228,347.777222 706.666931,346.759918 
	C703.132690,342.068451 706.602600,341.303070 709.859436,340.211639 
	C711.080933,339.802307 712.293518,339.366364 713.510132,338.942535 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M722.552795,350.029846 
	C721.486328,348.009583 720.736694,345.927612 719.990479,343.414917 
	C722.396606,343.673920 724.799438,344.363647 727.595276,345.087769 
	C726.282104,346.737457 724.575867,348.352814 722.552795,350.029846 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M809.086182,458.816956 
	C811.057007,459.506409 812.627197,460.269409 814.531311,461.095398 
	C817.538269,463.542053 820.211304,465.925751 823.212280,468.397278 
	C824.176514,469.277466 824.812927,470.069855 825.128479,471.325958 
	C821.397156,477.885773 820.911194,484.454987 821.317200,491.177216 
	C815.656250,492.413788 814.056885,491.188049 812.343567,485.398438 
	C809.872864,484.598114 808.168823,484.046112 806.365356,483.160645 
	C804.882751,481.107819 803.807007,478.923920 802.053772,477.764008 
	C798.182556,475.202911 793.970215,473.157532 789.950195,470.547333 
	C791.311523,468.474670 792.619690,466.756348 794.305786,464.963867 
	C795.121033,463.277466 795.558289,461.665192 796.323303,460.202637 
	C798.443542,464.160187 800.236084,467.968018 801.997559,472.175049 
	C803.465271,477.291870 805.853638,478.638733 809.749023,476.907471 
	C808.145691,475.122681 806.554260,473.351135 805.034058,471.241089 
	C806.242798,470.302460 807.380249,469.702332 808.776245,469.235962 
	C811.061646,470.874603 813.088440,472.379456 815.115295,473.884277 
	C815.456055,473.542084 815.796814,473.199890 816.137573,472.857727 
	C813.971741,467.583008 808.713684,464.850006 805.024231,460.827637 
	C806.244690,460.181946 807.465149,459.536255 809.086182,458.816956 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M793.927856,465.038025 
	C792.619690,466.756348 791.311523,468.474670 789.656738,470.603394 
	C788.917847,470.944458 788.525452,470.875061 788.063354,470.472473 
	C787.993652,468.262238 787.993652,466.385284 787.993652,464.508301 
	C787.659180,464.284515 787.324707,464.060699 786.990234,463.836884 
	C786.261841,465.626007 785.533386,467.415131 784.490234,469.360962 
	C782.665100,470.123138 781.154663,470.728638 779.229492,471.242828 
	C777.209351,470.418640 775.604065,469.685730 774.005371,468.594299 
	C774.002747,467.517975 773.993469,466.800171 773.992065,465.802246 
	C773.999329,465.335236 773.998840,465.148407 774.034912,464.653992 
	C774.026306,463.936676 773.981079,463.526886 774.082886,462.784424 
	C775.861145,459.503235 777.492188,456.554657 779.421997,453.066071 
	C778.273743,452.033447 776.635132,450.559784 774.996521,449.086090 
	C775.540527,448.450470 776.084534,447.814850 776.928589,447.047791 
	C779.719482,447.603271 782.223145,447.956696 782.429565,451.609619 
	C782.477966,452.465485 785.438538,453.568085 787.168640,453.827423 
	C790.263733,454.291321 793.442017,454.200531 796.587646,454.907043 
	C795.349426,455.663147 794.109009,455.853882 792.517212,456.045410 
	C791.273865,456.175262 790.382019,456.304291 790.108215,456.343903 
	C790.598267,458.576813 790.821411,460.581970 791.522278,462.403320 
	C791.919983,463.437073 793.100098,464.169769 793.927856,465.038025 
M781.885376,455.741547 
	C781.808044,455.692963 781.730713,455.644379 781.653381,455.595795 
	C781.767151,455.731842 781.880859,455.867889 782.114624,456.256958 
	C782.192627,456.304993 782.270630,456.353058 782.348694,456.401093 
	C782.233704,456.265656 782.118713,456.130188 781.885376,455.741547 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M792.868652,456.044617 
	C794.109009,455.853882 795.349426,455.663147 796.825684,455.170685 
	C798.619080,456.546509 803.320190,456.199738 800.780945,460.648315 
	C800.669922,460.842957 802.338928,462.053619 803.684326,463.196472 
	C805.632507,465.435333 807.075134,467.268768 808.517761,469.102173 
	C807.380249,469.702332 806.242798,470.302460 804.669067,471.291260 
	C803.498108,471.711884 802.763428,471.743866 802.028687,471.775848 
	C800.236084,467.968018 798.443542,464.160187 796.184204,459.913757 
	C794.767822,458.331696 793.818237,457.188141 792.868652,456.044617 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M808.776245,469.235962 
	C807.075134,467.268768 805.632507,465.435333 803.959839,463.339752 
	C803.961853,462.444885 804.193909,461.812164 804.725098,461.003540 
	C808.713684,464.850006 813.971741,467.583008 816.137573,472.857727 
	C815.796814,473.199890 815.456055,473.542084 815.115295,473.884277 
	C813.088440,472.379456 811.061646,470.874603 808.776245,469.235962 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M429.632935,529.019836 
	C428.324982,530.969482 426.632080,532.906250 424.635834,534.903442 
	C425.253937,533.056458 426.175415,531.149231 427.399689,529.157471 
	C428.217651,529.051025 428.732819,529.029053 429.632935,529.019836 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M783.009216,630.263367 
	C783.373901,630.316345 783.738647,630.369324 784.379639,630.432556 
	C785.160889,631.438782 785.665894,632.434753 786.263916,633.762695 
	C784.947327,634.933777 783.537781,635.772949 781.439209,636.508301 
	C780.169617,636.350037 779.588989,636.295532 778.828979,635.924500 
	C773.430481,633.802368 768.211243,631.996765 762.953979,629.801086 
	C761.083252,628.595886 759.191162,627.889587 757.436096,626.931885 
	C755.972717,626.133423 753.364197,624.774658 753.497681,624.118835 
	C754.880005,617.325623 750.077454,618.015991 746.258057,618.063477 
	C734.494202,618.209839 722.733398,618.608276 711.053406,618.547974 
	C713.193176,617.459595 715.283081,616.809753 717.297241,615.976685 
	C718.599487,615.438049 719.790710,614.630981 721.485657,613.932373 
	C731.067017,613.413574 740.194458,612.906738 749.573914,612.547119 
	C750.194885,612.816284 750.563721,612.938232 750.950806,613.470093 
	C751.716980,614.896912 752.672974,616.886658 753.180359,616.778137 
	C757.568176,615.840088 760.194641,618.507507 763.136963,621.319519 
	C767.888550,624.044983 772.453003,626.490234 777.180298,629.124390 
	C777.891296,629.208862 778.439453,629.104492 778.987549,629.000183 
	C779.342896,629.119019 779.698303,629.237854 780.445374,629.646118 
	C781.561157,630.044800 782.285156,630.154114 783.009216,630.263367 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M522.737244,452.578552 
	C524.976929,451.254333 527.615662,450.115326 530.688477,449.358459 
	C532.785095,450.676575 534.371521,451.801422 536.126526,452.507416 
	C539.340881,453.800507 542.828186,454.510773 545.879395,456.087677 
	C549.058594,457.730804 548.161438,459.278992 545.028564,459.976135 
	C545.023376,459.986389 545.032532,459.971191 545.017822,459.587952 
	C543.665955,458.462494 542.328735,457.720306 540.563965,456.976135 
	C537.108887,457.332367 534.081299,457.690552 530.918945,457.788513 
	C530.475403,457.288513 530.166626,457.048737 529.857910,456.808960 
	C527.617371,455.460571 525.376831,454.112152 522.737244,452.578552 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M516.021118,466.989655 
	C514.819824,466.832092 513.618530,466.674500 511.972198,466.458527 
	C512.829163,464.999176 513.450684,463.940704 514.114868,462.542877 
	C516.782288,461.268372 519.406982,460.333191 522.376587,459.633789 
	C522.990662,460.259644 523.259888,460.649719 523.538574,461.394775 
	C522.541260,463.362854 521.534424,464.975952 520.213806,466.616608 
	C519.273926,466.739288 518.647766,466.834473 517.687988,466.880188 
	C516.909851,466.883728 516.465515,466.936707 516.021118,466.989655 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M523.529053,461.039795 
	C523.259888,460.649719 522.990662,460.259644 522.722168,459.620667 
	C524.849121,458.550262 526.975281,457.728729 529.479736,456.858093 
	C530.166626,457.048737 530.475403,457.288513 530.591003,457.935638 
	C528.108215,459.241943 525.818665,460.140869 523.529053,461.039795 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M611.264343,362.896576 
	C610.904175,362.173492 610.921448,361.498077 610.954041,360.484253 
	C611.462585,359.822937 611.955872,359.500061 613.065491,359.180603 
	C613.882019,359.235565 614.082214,359.287140 614.218262,359.681122 
	C616.292358,363.485046 628.865784,366.874603 634.811768,365.673096 
	C638.092773,366.186462 641.060242,366.534607 644.025513,367.203674 
	C644.325745,368.314362 644.628235,369.104187 644.524658,369.946564 
	C642.494873,370.175018 640.871155,370.350922 638.846680,370.407776 
	C634.123352,370.288727 629.800903,370.288727 625.505798,370.288727 
	C626.591980,371.777344 627.823669,373.465515 629.055420,375.153656 
	C628.749878,375.393555 628.444336,375.633453 628.138794,375.873352 
	C626.526794,374.882019 624.914795,373.890686 623.212463,372.677673 
	C623.122131,372.456024 623.024231,371.987427 622.994995,371.624176 
	C621.674072,368.994049 620.197327,367.768707 617.675293,370.346741 
	C615.828552,369.569244 614.344666,368.830597 612.860779,368.091980 
	C612.619568,366.336975 616.070312,363.707764 611.264343,362.896576 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M614.282349,359.338745 
	C614.082214,359.287140 613.882019,359.235565 613.376953,359.130585 
	C613.293640,358.481567 613.515259,357.885925 614.403442,357.120819 
	C618.068726,357.306061 619.697571,356.320801 619.169861,352.857422 
	C619.258484,352.142548 619.114075,351.568451 618.954468,350.687988 
	C618.921021,349.973297 618.902832,349.565033 619.279297,349.107239 
	C621.952209,348.727997 624.230530,348.398224 626.657166,348.391235 
	C627.133118,349.884491 627.460693,351.054932 627.606934,351.577423 
	C625.751465,352.027496 624.259338,352.389465 622.413208,352.896179 
	C621.740051,355.299805 621.421021,357.558746 620.708679,359.878784 
	C618.304382,359.739502 616.293335,359.539124 614.282349,359.338745 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M618.884583,349.156738 
	C618.902832,349.565033 618.921021,349.973297 618.733032,350.833801 
	C618.663513,351.856781 618.800171,352.427490 618.936829,352.998230 
	C619.697571,356.320801 618.068726,357.306061 614.677185,356.944824 
	C612.662781,355.127197 611.041260,353.316132 609.216980,351.243011 
	C609.014160,350.980988 608.985596,351.012177 609.390259,351.075500 
	C611.975403,350.470306 614.155945,349.801788 616.619812,349.104736 
	C617.472351,349.092407 618.041565,349.108704 618.680115,349.125244 
	C618.749451,349.125519 618.884583,349.156738 618.884583,349.156738 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M609.026550,350.963806 
	C604.008484,352.037567 601.384216,348.336243 598.313721,345.035553 
	C599.082947,344.264343 599.836914,344.011536 600.703186,344.114441 
	C601.559570,344.991119 602.464172,346.080200 603.016785,345.923981 
	C604.524048,345.497986 605.882568,344.546143 607.469482,344.096985 
	C606.955994,346.058716 605.736816,347.794952 608.640808,348.738647 
	C608.967529,348.844788 608.880676,350.223572 608.985596,351.012177 
	C608.985596,351.012177 609.014160,350.980988 609.026550,350.963806 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M600.590881,343.758728 
	C599.836914,344.011536 599.082947,344.264343 598.131104,344.741425 
	C597.603577,344.259918 597.273865,343.554138 596.957886,342.225464 
	C596.984070,341.398956 596.996399,341.195282 597.008728,340.991638 
	C597.008728,340.991638 597.000000,341.000000 597.351624,340.983582 
	C598.411255,341.031555 599.119263,341.095947 599.970215,341.402008 
	C600.272400,342.348724 600.431641,343.053741 600.590881,343.758728 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M596.657776,340.997406 
	C596.996399,341.195282 596.984070,341.398956 596.978638,341.910370 
	C596.537964,342.251556 596.090393,342.284943 595.311462,342.383911 
	C595.422363,341.967377 595.864563,341.485260 596.657776,340.997406 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M844.074219,586.513916 
	C841.910034,587.101746 839.913086,587.405945 837.542725,587.271240 
	C835.142944,584.526123 833.116638,582.219849 830.906494,579.842407 
	C830.722534,579.771118 830.331787,579.724060 830.324585,579.388428 
	C830.383301,578.387878 830.449280,577.723022 830.864624,577.086243 
	C833.452881,576.049072 835.691711,574.983765 838.245239,573.801941 
	C840.056030,571.494995 841.552124,569.304504 843.394287,567.030762 
	C844.740845,566.796326 845.741272,566.645203 846.741760,566.494080 
	C846.359070,566.843872 845.976379,567.193726 845.593689,567.543518 
	C846.683350,570.038574 847.773010,572.533630 849.058594,575.264282 
	C849.792725,575.786865 850.330994,576.073975 850.964050,576.665894 
	C854.635010,578.982788 850.560120,581.191223 851.220825,583.278137 
	C848.894348,584.262146 846.567932,585.246155 844.074219,586.513916 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M851.594116,583.309814 
	C850.560120,581.191223 854.635010,578.982788 851.310425,576.573975 
	C853.149353,575.867188 854.762939,575.652161 856.318054,575.224548 
	C858.565491,574.606567 860.770630,573.834534 862.993774,573.128235 
	C862.333618,574.730957 861.673462,576.333679 861.112488,578.299438 
	C858.130310,580.222168 855.048828,581.781860 851.594116,583.309814 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M534.059326,448.167633 
	C537.962708,446.403931 541.866150,444.640228 546.386963,443.140259 
	C548.995117,445.588715 550.796204,447.995514 553.026001,449.900360 
	C555.629272,452.124298 558.493103,454.103668 561.447815,455.842499 
	C566.451843,458.787292 571.616089,461.460022 576.928772,464.505676 
	C578.758850,464.826813 580.373047,464.889465 582.238159,464.979553 
	C582.657166,465.010651 582.825378,465.014252 583.015259,465.266235 
	C583.036926,465.514648 582.996155,466.011993 582.785156,466.241547 
	C581.678650,466.942841 580.783142,467.414581 579.512085,467.939850 
	C577.409546,468.327026 575.682495,468.660675 573.592651,468.973969 
	C571.432251,467.701996 569.696228,466.346710 567.817749,465.231262 
	C566.285706,464.321472 564.583435,463.698364 562.918701,462.591614 
	C558.227478,455.586151 548.458557,456.059631 545.232056,450.412292 
	C540.681885,449.498138 537.370605,448.832886 534.059326,448.167633 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M533.733765,448.142334 
	C537.370605,448.832886 540.681885,449.498138 545.232056,450.412292 
	C548.458557,456.059631 558.227478,455.586151 562.584473,462.657166 
	C561.204407,464.047119 560.120728,465.015045 558.695679,466.040924 
	C557.334656,467.412231 556.315002,468.725586 555.128235,469.750305 
	C553.948914,468.659210 552.936646,467.856720 551.677734,466.804443 
	C549.345459,465.813751 547.259766,465.072845 545.118225,463.898621 
	C545.052856,462.298828 545.043335,461.132355 545.033813,459.965881 
	C548.161438,459.278992 549.058594,457.730804 545.879395,456.087677 
	C542.828186,454.510773 539.340881,453.800507 536.126526,452.507416 
	C534.371521,451.801422 532.785095,450.676575 531.063232,449.392517 
	C531.805298,448.735291 532.606750,448.426178 533.733765,448.142334 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M582.993530,465.017853 
	C582.825378,465.014252 582.657166,465.010651 581.970093,464.703857 
	C579.871826,464.349457 578.292419,464.298309 576.712952,464.247162 
	C571.616089,461.460022 566.451843,458.787292 561.447815,455.842499 
	C558.493103,454.103668 555.629272,452.124298 553.026001,449.900360 
	C550.796204,447.995514 548.995117,445.588715 546.742310,443.124756 
	C546.467102,442.458832 546.453918,442.072174 546.443115,441.393646 
	C547.666016,441.094391 548.886536,441.086975 550.448608,441.079956 
	C552.151611,442.489746 553.513062,443.899048 555.009338,445.922363 
	C557.428406,451.066681 557.889099,451.302582 560.961853,449.515106 
	C562.422058,450.905365 563.882324,452.295654 565.752991,454.235229 
	C569.470276,455.807251 572.777161,456.829926 576.329834,458.082825 
	C579.515686,460.156494 582.455750,461.999939 585.077515,463.948975 
	C584.170654,464.375641 583.582092,464.696747 582.993530,465.017853 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M507.745514,475.957825 
	C506.091919,474.309998 504.438293,472.662140 502.784729,471.014343 
	C503.347321,470.656677 503.909943,470.298981 504.799042,469.874481 
	C507.466736,469.817261 509.807953,469.826874 512.584717,470.246826 
	C513.013794,471.397797 513.007324,472.138458 512.728821,473.090027 
	C510.886414,474.186584 509.315979,475.072205 507.745514,475.957825 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M513.000854,472.879120 
	C513.007324,472.138458 513.013794,471.397797 513.026245,470.284607 
	C513.942688,469.138367 514.853088,468.364594 515.892334,467.290222 
	C516.465515,466.936707 516.909851,466.883728 517.697510,467.294373 
	C518.041199,468.863007 518.041687,469.968079 517.698730,471.063995 
	C515.903748,471.662933 514.452332,472.271027 513.000854,472.879120 
z"
          />
          <path
            fill="#101010"
            opacity="1.000000"
            stroke="none"
            d="
M461.104401,585.397949 
	C460.929779,584.940491 460.958527,584.700867 461.375671,584.184448 
	C464.176849,584.580261 466.589539,585.252869 469.237946,586.261475 
	C474.542969,587.556763 479.612305,588.516174 484.803955,589.796997 
	C487.166779,590.956665 489.407349,591.794861 491.976990,592.703979 
	C493.521271,593.358948 494.736511,593.942932 495.858704,594.882812 
	C495.153412,596.141418 494.712158,597.585266 493.896027,597.843140 
	C491.633026,598.558533 489.213867,598.780029 486.476379,599.133423 
	C485.342712,599.186401 484.589050,599.302673 483.835419,599.418884 
	C483.085327,599.049316 482.335205,598.679749 481.324341,597.750488 
	C480.296265,596.854431 479.528992,596.518127 478.761688,596.181885 
	C477.683533,595.572205 476.605408,594.962524 475.290344,593.768066 
	C473.409027,592.409973 471.764648,591.636597 470.120270,590.863159 
	C469.781921,590.775269 469.443542,590.687378 468.715698,590.296997 
	C467.552490,589.678040 466.778748,589.361450 466.005035,589.044922 
	C465.554962,588.657532 465.104919,588.270203 464.351624,587.852051 
	C463.134827,587.086182 462.221283,586.350952 461.104401,585.397949 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M881.048828,541.212830 
	C881.456177,541.127014 881.863525,541.041199 882.983887,540.958984 
	C884.789185,542.072876 886.413757,543.010925 886.842712,544.336060 
	C887.412781,546.097412 887.050842,548.160339 887.089355,550.093750 
	C886.913208,550.397583 886.737061,550.701416 886.267578,550.808594 
	C885.301270,549.155823 884.753601,547.612488 883.851135,546.316223 
	C883.667542,546.052612 882.147644,546.719360 880.813232,547.010620 
	C879.255676,547.181396 878.128662,547.298767 876.706604,547.301636 
	C875.993530,547.210449 875.575500,547.233643 875.104004,546.831970 
	C874.619507,543.405273 875.347351,541.441772 879.255493,542.060974 
	C880.056641,541.782593 880.552734,541.497742 881.048828,541.212830 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M884.823853,554.509888 
	C884.169678,555.082703 883.553955,555.333923 883.056641,555.732666 
	C881.750610,556.779907 880.496155,557.891418 879.221191,558.977295 
	C878.456299,557.264221 877.691467,555.551086 876.953308,553.062378 
	C879.005127,551.262146 881.030273,550.237488 883.335083,549.387329 
	C884.123901,550.772766 884.633057,551.983704 885.142212,553.194641 
	C885.066895,553.548340 884.991577,553.901978 884.823853,554.509888 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M887.382202,550.053467 
	C887.050842,548.160339 887.412781,546.097412 886.842712,544.336060 
	C886.413757,543.010925 884.789185,542.072876 883.287842,540.888123 
	C883.133301,540.358643 883.387756,539.903625 884.123047,539.209106 
	C886.484985,539.608337 888.366028,540.247070 890.247070,540.885742 
	C890.376343,543.310791 890.505554,545.735840 890.529663,548.611450 
	C889.508057,549.379089 888.591553,549.696106 887.382202,550.053467 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M885.386719,553.051758 
	C884.633057,551.983704 884.123901,550.772766 883.294556,549.060120 
	C882.397461,548.024719 881.820618,547.490967 881.243835,546.957214 
	C882.147644,546.719360 883.667542,546.052612 883.851135,546.316223 
	C884.753601,547.612488 885.301270,549.155823 886.074402,550.989746 
	C885.993469,551.881348 885.812317,552.395081 885.386719,553.051758 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M599.827271,341.160339 
	C599.119263,341.095947 598.411255,341.031555 597.347717,340.988037 
	C597.973022,338.357422 598.953857,335.705963 600.026123,332.734497 
	C601.478638,332.211426 602.839844,332.008301 604.608765,332.162292 
	C605.041138,332.991882 605.065674,333.464355 604.727600,333.988403 
	C602.852478,336.413483 601.339844,338.786896 599.827271,341.160339 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M593.617859,337.278564 
	C593.456238,337.148865 593.433044,336.921906 593.409851,336.694977 
	C593.525330,336.857117 593.640808,337.019226 593.617859,337.278564 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M623.302795,372.899353 
	C624.914795,373.890686 626.526794,374.882019 628.138794,375.873352 
	C628.444336,375.633453 628.749878,375.393555 629.055420,375.153656 
	C627.823669,373.465515 626.591980,371.777344 625.505798,370.288727 
	C629.800903,370.288727 634.123352,370.288727 638.721069,370.693604 
	C637.432068,376.030792 643.222961,376.338409 644.333679,379.597626 
	C644.539368,378.191772 644.745117,376.785919 644.895630,375.757355 
	C647.641968,376.588196 649.799377,377.240875 652.075439,378.145416 
	C652.483337,378.620605 652.772644,378.843933 653.049316,379.473694 
	C652.369202,384.223877 649.296509,388.786499 653.831421,392.737061 
	C652.964111,392.940338 652.462097,393.029938 651.752319,393.052917 
	C651.544556,392.986267 651.113098,392.921173 651.054749,392.575928 
	C650.070435,390.702271 649.438049,388.788727 648.115479,387.780518 
	C647.230591,387.105896 645.365051,387.717560 643.624756,387.727173 
	C642.894775,387.647949 642.478394,387.595154 641.833008,387.269287 
	C635.846191,387.295532 630.088196,387.589508 624.333130,387.932678 
	C624.218994,387.939453 624.139099,388.521820 623.692871,388.868134 
	C619.980591,386.526337 616.618713,384.152069 613.256897,381.777802 
	C613.778320,377.119385 611.783020,374.080444 607.126648,372.355896 
	C606.925232,371.613708 606.952576,371.122253 607.367920,370.329346 
	C612.164307,371.548218 616.549072,373.146820 621.010986,374.489258 
	C621.565308,374.656006 622.528870,373.462524 623.302795,372.899353 
M645.475830,381.629944 
	C645.475830,381.629944 645.584290,381.427094 645.584290,381.427094 
	C645.584290,381.427094 645.365906,381.460846 645.475830,381.629944 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M623.212463,372.677673 
	C622.528870,373.462524 621.565308,374.656006 621.010986,374.489258 
	C616.549072,373.146820 612.164307,371.548218 607.373169,369.960693 
	C605.856140,367.843964 606.226379,366.685791 608.791870,367.067963 
	C609.923767,367.236572 611.014099,367.684357 612.492065,368.048706 
	C614.344666,368.830597 615.828552,369.569244 617.916382,370.559296 
	C620.021606,371.202942 621.522888,371.595184 623.024231,371.987427 
	C623.024231,371.987427 623.122131,372.456024 623.212463,372.677673 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M821.663574,491.224518 
	C820.911194,484.454987 821.397156,477.885773 825.235352,471.616119 
	C826.343140,471.810333 827.023315,472.178131 827.708862,472.909668 
	C828.173096,473.471405 828.631836,473.669373 829.090637,473.867340 
	C829.703613,474.320007 830.316650,474.772705 831.047119,475.860474 
	C831.935364,476.898865 832.706238,477.302246 833.477051,477.705597 
	C835.289856,478.929962 837.102661,480.154327 839.238647,481.454651 
	C839.886230,481.949707 840.210571,482.368744 840.709351,482.993164 
	C841.329651,484.225189 841.775513,485.251923 841.967163,486.610413 
	C838.608154,487.216614 835.060364,486.625427 833.839966,491.080414 
	C833.586121,491.737305 833.489380,492.203857 833.392700,492.670410 
	C832.940002,495.478790 832.487305,498.287170 832.017822,501.547455 
	C832.001099,501.999329 831.981384,502.023407 831.981384,502.023407 
	C831.782837,502.050598 831.584351,502.077820 830.863647,502.004822 
	C829.886230,501.615295 829.430847,501.325958 828.844971,500.752625 
	C827.803955,499.640625 826.893494,498.812561 825.987122,497.626709 
	C824.573914,495.261047 823.156555,493.253174 821.722046,491.234863 
	C821.704956,491.224426 821.663574,491.224518 821.663574,491.224518 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M833.370850,477.400146 
	C832.706238,477.302246 831.935364,476.898865 831.172119,476.120911 
	C831.874634,476.195740 832.569580,476.645203 833.370850,477.400146 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M829.037598,473.618164 
	C828.631836,473.669373 828.173096,473.471405 827.895874,473.148132 
	C828.379883,473.138245 828.682190,473.253601 829.037598,473.618164 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M605.090210,333.936798 
	C605.065674,333.464355 605.041138,332.991882 605.023315,332.161743 
	C605.023132,331.449921 605.016357,331.095734 605.006348,330.478821 
	C606.067322,329.828888 607.131409,329.441711 608.833496,329.130493 
	C610.118103,329.922546 610.749573,330.653290 611.415161,331.351501 
	C612.423828,332.409637 613.456299,333.445099 615.073975,335.098816 
	C615.073975,330.574127 615.073975,327.789551 615.073975,325.004974 
	C615.504333,325.005829 615.934753,325.006683 616.687988,325.019897 
	C617.034058,325.209198 617.057251,325.386200 617.138489,326.008301 
	C617.196472,326.453400 617.222412,326.820557 617.157837,327.160461 
	C617.334595,328.330200 617.576050,329.159973 617.854370,330.372192 
	C619.251099,332.468811 620.610962,334.182983 621.986511,336.234070 
	C622.025879,336.794586 622.049561,337.018219 622.061035,337.621155 
	C622.577820,339.718445 623.106873,341.436401 623.674316,343.279205 
	C625.988220,342.259369 627.468079,341.607086 628.964722,341.300568 
	C629.701050,342.977203 630.420654,344.308075 630.747803,345.759460 
	C629.073181,346.609467 627.791016,347.338959 626.508789,348.068451 
	C624.230530,348.398224 621.952209,348.727997 619.279297,349.107239 
	C618.884583,349.156738 618.749451,349.125519 618.772827,348.745117 
	C617.937744,345.937286 623.895569,343.655579 618.614746,341.048401 
	C616.795837,339.613434 615.312927,338.270905 613.193726,336.352325 
	C612.521484,339.169342 612.072876,341.049164 611.327148,342.870941 
	C610.140808,341.855194 609.251587,340.897491 608.212158,339.614960 
	C607.071472,337.505707 606.080811,335.721252 605.090210,333.936798 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M833.717529,492.830688 
	C833.489380,492.203857 833.586121,491.737305 834.225220,491.091675 
	C838.284241,490.288635 841.800842,489.664673 845.317505,489.040710 
	C846.413330,490.095917 847.509155,491.151093 848.730835,492.439758 
	C848.819763,493.428009 848.782776,494.182800 848.400146,495.450195 
	C846.042114,497.653351 844.029907,499.343903 842.017700,501.034454 
	C836.214294,501.471619 835.532837,496.830231 833.717529,492.830688 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M845.144409,488.777435 
	C841.800842,489.664673 838.284241,490.288635 834.382324,490.901306 
	C835.060364,486.625427 838.608154,487.216614 842.179688,486.887207 
	C843.421326,487.392883 844.196350,487.953522 845.144409,488.777435 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M713.449463,338.536682 
	C712.293518,339.366364 711.080933,339.802307 709.859436,340.211639 
	C706.602600,341.303070 703.132690,342.068451 706.666931,346.759918 
	C707.433228,347.777222 706.370972,350.114044 706.281982,351.849792 
	C706.191223,353.619659 706.261841,355.397797 706.261841,357.594391 
	C708.253296,356.853668 709.995728,356.205566 712.088135,355.562286 
	C713.608521,356.579590 714.778931,357.592102 716.246582,359.006256 
	C716.543945,359.407898 716.788208,359.560608 716.788208,359.560608 
	C715.455322,359.749817 714.122498,359.939026 712.298157,360.259644 
	C708.217346,360.739410 704.628052,361.087738 700.406616,361.265198 
	C699.511536,360.745880 699.248535,360.397461 699.140869,359.721893 
	C703.572266,357.221741 703.886414,353.864197 702.425659,350.199951 
	C699.870117,349.774384 697.656982,349.405823 695.088440,349.063232 
	C691.550659,347.676697 692.901367,345.866882 694.329407,344.103973 
	C695.208862,343.018311 696.179565,342.006531 697.437622,341.000732 
	C698.705627,341.202667 699.645325,341.365417 700.932800,341.588409 
	C699.078979,337.279114 697.488220,333.581482 695.897461,329.883850 
	C697.300659,325.187988 701.335876,326.503723 703.588379,327.698395 
	C707.040039,329.528992 709.045227,328.776001 711.505066,326.159119 
	C712.358032,330.117218 712.873413,334.123993 713.449463,338.536682 
z"
          />
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M716.802307,359.528320 
	C716.788208,359.560608 716.543945,359.407898 716.412720,359.351318 
	C716.281433,359.294739 716.605774,359.409058 716.757751,359.488434 
	C716.909729,359.567810 716.921753,359.533020 716.902527,359.525604 
	C716.883301,359.518188 716.816406,359.496063 716.802307,359.528320 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M816.946533,608.089783 
	C817.003845,609.006287 817.008423,609.670959 817.012634,610.671021 
	C816.573608,611.021545 816.134949,611.036804 814.925354,611.047424 
	C812.089478,611.060303 810.024536,611.077820 807.959656,611.095337 
	C806.583740,611.085083 805.207764,611.074890 803.408447,610.815308 
	C802.987183,610.386780 802.989319,610.207703 802.996094,609.750488 
	C803.002930,609.286804 803.004944,609.101379 803.006897,608.915894 
	C804.431335,608.656189 805.855774,608.396484 807.877258,608.374084 
	C810.075012,608.260437 811.675659,607.909424 813.276367,607.558411 
	C814.216125,607.469360 815.155823,607.380249 816.448792,607.311462 
	C816.832581,607.500549 816.863220,607.669250 816.946533,608.089783 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M890.407715,540.480103 
	C888.366028,540.247070 886.484985,539.608337 884.207886,538.961304 
	C886.164429,534.105408 887.093628,533.441772 890.001953,534.793884 
	C890.360657,536.607422 890.464539,538.340942 890.407715,540.480103 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M462.863831,592.434814 
	C463.508972,592.545471 464.200195,592.969727 464.936340,593.733276 
	C464.290802,593.631165 463.600372,593.189758 462.863831,592.434814 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M713.677246,429.081604 
	C714.031067,429.875763 714.384888,430.669891 714.676331,432.089661 
	C714.428589,435.509766 714.243225,438.304291 714.059204,441.416748 
	C714.041199,442.157806 714.021912,442.580841 713.841919,443.317505 
	C712.309448,446.231995 713.572021,447.024841 716.009766,447.335938 
	C715.997559,448.084137 715.979370,448.511597 715.729248,449.086365 
	C715.345703,449.746277 715.194214,450.258942 714.913452,451.111847 
	C713.515930,453.150543 712.247559,454.848999 711.032837,457.020081 
	C711.537048,461.145599 711.987549,464.798492 712.064087,468.603210 
	C710.129272,469.504333 708.568298,470.253662 707.002563,471.293762 
	C706.997253,471.778381 706.996643,471.972229 706.569702,472.148224 
	C705.028259,471.934418 703.913147,471.738464 702.892273,471.233887 
	C704.927979,467.549164 707.450500,464.359253 708.611877,460.733337 
	C709.421448,458.205688 710.653198,454.306061 706.096924,452.495483 
	C705.892273,451.133209 705.799377,450.156952 705.554993,449.220154 
	C704.140564,443.798676 702.690308,438.386536 701.620483,432.962219 
	C703.404419,430.799713 704.820007,428.646210 706.448730,426.168396 
	C708.628784,427.046997 711.153015,428.064301 713.677246,429.081604 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M713.782959,428.676514 
	C711.153015,428.064301 708.628784,427.046997 706.448730,426.168396 
	C704.820007,428.646210 703.404419,430.799713 701.504517,432.590668 
	C700.037903,428.813171 699.055603,425.398224 698.330322,421.743866 
	C698.712708,420.340729 698.837952,419.177032 698.963257,418.013306 
	C704.757507,413.726715 710.551758,409.440094 716.677917,405.088623 
	C716.244995,408.840485 715.425720,412.647766 714.762451,416.482056 
	C714.634155,417.223846 715.116089,418.071198 715.653687,418.934631 
	C715.290100,422.090027 714.589355,425.180725 713.782959,428.676514 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M714.002625,443.003906 
	C714.021912,442.580841 714.041199,442.157806 714.126343,441.084778 
	C715.104553,439.933502 716.016785,439.432220 717.318665,438.970795 
	C718.521423,437.974670 719.334534,436.938660 720.147705,435.902649 
	C722.811096,437.256470 725.474487,438.610291 728.538269,440.167633 
	C729.497742,434.297333 721.714050,433.240295 722.885498,427.372253 
	C723.699707,423.293427 722.358032,418.784271 721.979492,414.467377 
	C722.433899,414.327393 722.888306,414.187408 723.342712,414.047424 
	C725.362183,416.902161 727.381653,419.756927 729.692749,422.811096 
	C729.984375,423.010498 729.968933,423.069183 729.968933,423.069183 
	C729.993774,423.484741 730.018616,423.900330 729.798645,424.857361 
	C728.938538,426.585846 728.323242,427.772858 727.707947,428.959869 
	C729.808289,429.978882 731.908691,430.997864 734.009033,432.016876 
	C733.999756,433.197601 733.990540,434.378326 734.003662,436.282135 
	C733.902466,437.567596 733.778809,438.129974 733.318298,438.783997 
	C732.380798,440.167694 731.780212,441.459686 730.793335,442.858948 
	C726.648804,443.325897 722.896118,443.838531 719.129700,443.976135 
	C717.434753,444.038025 715.712463,443.351074 714.002625,443.003906 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M733.655151,438.692352 
	C733.778809,438.129974 733.902466,437.567596 734.053589,436.722351 
	C734.546692,436.376801 735.012268,436.314087 735.776245,436.718872 
	C736.520874,438.001312 736.819031,439.377441 737.437256,439.540588 
	C741.609558,440.641510 741.985229,443.859894 742.077698,447.670532 
	C743.441650,449.822510 744.850159,451.598663 746.512146,453.694519 
	C746.578979,453.597137 745.992615,454.451202 745.078735,455.782288 
	C747.979797,455.803284 750.866638,457.402985 751.182922,453.136902 
	C752.983032,454.217621 754.460571,455.203766 755.684570,456.436371 
	C751.547119,458.433044 747.663269,460.183258 743.416260,461.934113 
	C739.690186,463.313568 736.327393,464.692352 733.008545,465.681152 
	C733.729614,462.503021 734.406677,459.714844 735.071899,456.550232 
	C734.687134,452.659851 739.815308,450.109100 736.866760,445.733521 
	C735.828979,443.118439 734.742065,440.905396 733.655151,438.692352 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M742.122192,447.294739 
	C741.985229,443.859894 741.609558,440.641510 737.437256,439.540588 
	C736.819031,439.377441 736.520874,438.001312 736.109619,436.846130 
	C736.535095,436.349304 736.925537,436.192749 737.629517,436.005524 
	C741.053528,437.188965 744.164001,438.403046 747.987854,439.895630 
	C746.904541,442.149994 745.962036,444.111450 744.604370,446.491516 
	C743.500305,447.038300 742.811218,447.166534 742.122192,447.294739 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M734.096252,431.634369 
	C731.908691,430.997864 729.808289,429.978882 727.707947,428.959869 
	C728.323242,427.772858 728.938538,426.585846 729.789185,425.169434 
	C731.410828,427.044037 732.797119,429.147949 734.096252,431.634369 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M716.929077,438.930908 
	C716.016785,439.432220 715.104553,439.933502 714.125061,440.766785 
	C714.243225,438.304291 714.428589,435.509766 714.794189,432.395782 
	C716.177734,432.900940 717.381104,433.725555 718.589905,435.117065 
	C718.039917,436.766235 717.484497,437.848572 716.929077,438.930908 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M729.955322,423.020081 
	C730.415833,421.170624 730.905334,419.311523 731.554077,416.847717 
	C733.555420,419.370544 735.211060,421.457642 737.614807,424.487732 
	C734.760376,423.890594 732.745239,423.469025 730.349487,423.058350 
	C729.968933,423.069183 729.984375,423.010498 729.955322,423.020081 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M717.318665,438.970795 
	C717.484497,437.848572 718.039917,436.766235 718.734497,435.407898 
	C719.184143,435.259399 719.494690,435.386932 719.976440,435.708557 
	C719.334534,436.938660 718.521423,437.974670 717.318665,438.970795 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M719.338867,398.899536 
	C719.055359,398.249207 719.097412,397.456482 719.139465,396.663727 
	C720.395813,396.858826 721.652161,397.053894 723.002075,397.575134 
	C721.951904,398.186554 720.808105,398.471832 719.338867,398.899536 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M725.232422,397.957397 
	C725.242004,397.821259 725.536499,397.704376 725.954102,397.726257 
	C725.890625,397.902222 725.703979,397.939453 725.232422,397.957397 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M774.637146,449.080994 
	C776.635132,450.559784 778.273743,452.033447 779.421997,453.066071 
	C777.492188,456.554657 775.861145,459.503235 773.760254,462.766510 
	C772.864563,463.023315 772.438538,462.965393 772.013306,462.530670 
	C770.748535,457.580688 767.309631,456.247528 763.634277,459.017822 
	C762.518188,458.657013 761.740112,458.314819 761.128967,457.645416 
	C761.843262,456.009949 762.390686,454.701660 762.933105,453.126099 
	C763.140076,452.573273 763.351929,452.287689 763.563782,452.002075 
	C764.715515,451.623657 765.867310,451.245239 767.315063,450.827332 
	C769.833252,450.217194 772.055542,449.646515 774.637146,449.080994 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M763.222900,451.855377 
	C763.351929,452.287689 763.140076,452.573273 762.555176,453.208130 
	C760.100830,454.434875 758.019470,455.312378 755.938110,456.189880 
	C754.460571,455.203766 752.983032,454.217621 751.123291,452.941925 
	C750.741028,452.652344 750.692322,452.246002 750.768433,451.875366 
	C750.789917,451.258392 750.735352,451.012115 750.683044,450.475128 
	C750.708923,449.798126 750.732666,449.411835 750.756348,449.025574 
	C751.502441,448.542053 752.248535,448.058533 753.057556,447.206055 
	C753.410339,446.143097 753.700134,445.449066 753.999878,444.473450 
	C754.756470,444.143738 755.503113,444.095612 756.768677,444.263947 
	C757.786072,444.662292 758.284485,444.844208 758.782959,445.026123 
	C760.149353,447.253632 761.515747,449.481171 763.222900,451.855377 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M758.884216,444.699402 
	C758.284485,444.844208 757.786072,444.662292 757.142517,444.248474 
	C757.435791,443.711426 757.874146,443.406281 758.588562,443.058289 
	C758.904907,443.467804 758.945190,443.920258 758.884216,444.699402 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M767.792053,448.937988 
	C768.056641,449.264282 768.041870,449.486206 767.798096,449.956055 
	C767.208435,449.948883 766.847717,449.693817 766.487000,449.438782 
	C766.828857,449.237061 767.170776,449.035339 767.792053,448.937988 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M750.517944,448.809692 
	C750.732666,449.411835 750.708923,449.798126 750.531189,450.635132 
	C750.482178,451.472565 750.587219,451.859283 750.692322,452.246002 
	C750.692322,452.246002 750.741028,452.652344 750.800659,452.847351 
	C750.866638,457.402985 747.979797,455.803284 745.078735,455.782288 
	C745.992615,454.451202 746.578979,453.597137 746.512146,453.694519 
	C744.850159,451.598663 743.441650,449.822510 742.077698,447.670532 
	C742.811218,447.166534 743.500305,447.038300 744.530151,446.826324 
	C746.807190,446.970093 749.437195,445.170746 750.517944,448.809692 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M592.054016,670.965576 
	C588.986633,668.886841 585.769836,666.995483 582.881653,664.692139 
	C575.903687,659.127136 569.079163,653.369690 562.584106,647.370911 
	C566.348755,647.344727 569.722107,647.637451 573.474182,647.943237 
	C574.867981,648.037964 575.882935,648.119568 576.906860,648.531372 
	C577.575317,649.626953 578.234863,650.392334 578.907959,651.534180 
	C578.923828,653.188171 578.926086,654.465759 578.988159,656.039978 
	C579.359924,656.565247 579.671936,656.793884 579.978638,657.017212 
	C579.973389,657.012024 579.953613,657.037415 580.079285,657.353760 
	C588.103699,662.847595 596.002502,668.024963 604.080444,673.464966 
	C605.294312,675.026123 606.329041,676.324524 607.273010,677.951111 
	C607.135681,678.501343 607.089050,678.723511 607.042419,678.945557 
	C606.276855,678.964233 605.511230,678.982910 604.324341,678.680969 
	C603.293518,677.920715 602.684021,677.481079 601.763672,676.889648 
	C598.319885,674.813721 595.186951,672.889648 592.054016,670.965576 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M607.225037,679.224731 
	C607.089050,678.723511 607.135681,678.501343 607.587891,678.050537 
	C608.314941,676.640747 609.190979,675.268127 608.860779,674.312134 
	C607.517456,670.423218 605.804321,666.661926 604.556396,662.942993 
	C606.977173,667.094666 609.015991,671.185608 611.180176,675.209106 
	C612.599548,677.848022 614.200134,680.389526 615.719604,682.974609 
	C616.614441,685.343079 616.967407,688.186768 618.530579,689.969116 
	C620.898315,692.668701 624.108032,694.629761 627.077393,697.201477 
	C623.973328,698.566772 621.490173,697.564575 619.456970,694.921631 
	C615.480042,689.751953 611.428345,684.639771 607.225037,679.224731 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M645.069275,699.869690 
	C647.186462,701.472656 649.303589,703.075623 651.708008,704.845947 
	C651.588867,705.637085 651.065735,706.850159 650.793884,706.796265 
	C646.646423,705.973694 642.490356,705.119385 638.460327,703.871948 
	C637.938171,703.710388 638.106323,701.318665 638.415283,699.551025 
	C640.940308,699.384583 643.004822,699.627136 645.069275,699.869690 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M592.043945,671.340271 
	C595.186951,672.889648 598.319885,674.813721 601.745667,677.215210 
	C602.046021,678.128479 602.053528,678.564331 602.061035,679.000122 
	C601.746338,679.210388 601.431641,679.420593 600.642517,679.825562 
	C599.498840,680.266479 598.829590,680.512512 598.160278,680.758545 
	C597.961975,680.806152 597.763733,680.853760 597.178101,680.745911 
	C596.624084,680.343384 596.398193,680.176208 596.113159,680.088928 
	C594.753357,677.297607 593.393616,674.506226 592.043945,671.340271 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M644.968872,699.528931 
	C643.004822,699.627136 640.940308,699.384583 638.494141,699.193542 
	C635.042114,700.035217 631.943604,701.550171 628.879150,698.563354 
	C634.222961,696.868896 639.749023,694.588196 644.968872,699.528931 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M595.860718,680.193665 
	C596.398193,680.176208 596.624084,680.343384 596.875183,680.788208 
	C596.421875,681.180969 595.884277,681.375977 595.184204,681.381653 
	C595.217346,680.894409 595.412842,680.596375 595.860718,680.193665 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M602.380432,679.024292 
	C602.053528,678.564331 602.046021,678.128479 602.056458,677.367065 
	C602.684021,677.481079 603.293518,677.920715 603.941528,678.671082 
	C603.553284,679.004028 603.126587,679.026245 602.380432,679.024292 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M598.449219,680.815247 
	C598.829590,680.512512 599.498840,680.266479 600.448364,680.035034 
	C600.065125,680.323792 599.401611,680.597839 598.449219,680.815247 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M660.307007,322.389038 
	C654.064148,321.387817 647.821228,320.386627 641.292297,318.753784 
	C641.006592,317.414581 641.006958,316.706940 641.007324,315.999329 
	C642.941772,315.668213 644.927673,315.510620 646.795776,314.956024 
	C648.583252,314.425446 650.248230,313.482147 652.421631,312.518738 
	C653.164429,313.017853 654.716187,314.060547 656.631470,315.118347 
	C658.278259,317.072693 659.561462,319.011932 660.759644,321.414307 
	C660.501160,322.011444 660.378662,322.181976 660.307007,322.389038 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M640.713135,315.844025 
	C641.006958,316.706940 641.006592,317.414581 641.023438,318.475403 
	C635.691101,320.345917 632.195190,317.468231 628.896973,313.454529 
	C628.805542,312.672516 628.862793,312.205475 628.911072,311.383423 
	C630.151306,310.829224 631.400513,310.629974 632.872498,310.112854 
	C635.536560,311.759583 637.977722,313.724152 640.713135,315.844025 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M641.048218,305.594299 
	C642.789978,305.575928 644.603455,305.886108 646.783936,306.316345 
	C644.872314,309.415863 642.995117,307.681702 641.048218,305.594299 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M638.083984,305.138000 
	C638.105164,304.880890 638.576050,304.928345 638.802979,304.996460 
	C638.822693,305.469299 638.615479,305.874084 638.408203,306.278870 
	C638.293091,305.984253 638.177979,305.689667 638.083984,305.138000 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M660.188721,322.781311 
	C660.378662,322.181976 660.501160,322.011444 660.773010,321.747070 
	C662.214417,321.684479 663.557434,321.752258 665.188354,321.705475 
	C665.862488,321.622620 666.248657,321.654297 666.884155,322.264099 
	C671.406799,324.916962 675.680237,326.991760 679.953674,329.066528 
	C680.546814,329.530945 681.139954,329.995331 681.875000,330.720886 
	C682.016968,330.982056 681.953857,331.045685 681.570923,331.015106 
	C679.884094,331.497162 677.982361,331.632294 677.379211,332.587494 
	C674.682617,336.857605 673.275818,342.319458 666.406616,342.666443 
	C663.334412,342.500153 660.666931,342.234161 657.809570,341.736511 
	C656.507690,341.861023 655.395630,342.217194 654.687073,342.444122 
	C651.690186,338.147186 648.846680,334.070160 646.003174,329.993164 
	C650.029358,328.346954 654.076355,326.748779 658.063782,325.013519 
	C658.850586,324.671143 659.407471,323.800568 660.188721,322.781311 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M463.250000,525.038574 
	C463.319214,522.254639 463.629578,519.514221 463.898865,516.349121 
	C466.548279,514.132446 469.153259,512.189453 471.958649,510.600311 
	C473.908203,509.495880 476.133270,508.877777 478.612823,508.413269 
	C478.247894,512.018433 477.505341,515.252625 476.721802,518.665344 
	C478.197327,518.129089 479.659760,517.597534 481.061157,517.400330 
	C480.274200,519.157593 479.548340,520.580505 478.439941,522.002075 
	C477.257751,522.088684 475.922791,521.919983 475.739166,522.303101 
	C473.198334,527.604553 469.073700,526.232788 464.949036,525.046143 
	C464.653900,524.961182 464.304993,525.063049 463.981079,525.078003 
	C463.981079,525.078003 463.491119,525.082031 463.250000,525.038574 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M481.122192,517.065979 
	C479.659760,517.597534 478.197327,518.129089 476.721802,518.665344 
	C477.505341,515.252625 478.247894,512.018433 478.977600,508.369049 
	C479.419067,507.638916 479.873383,507.323944 480.928253,506.971558 
	C482.039307,506.615387 482.549805,506.296600 483.060303,505.977783 
	C484.605988,505.189819 486.151703,504.401886 488.218262,503.309937 
	C490.747375,504.991516 495.042877,501.023682 495.584839,505.836487 
	C490.335205,506.151550 486.266052,509.616638 484.404388,514.854675 
	C483.100342,515.630005 482.111267,516.348022 481.122192,517.065979 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M482.952454,505.765625 
	C482.549805,506.296600 482.039307,506.615387 481.211670,506.924377 
	C481.116608,506.371429 481.338654,505.828247 481.826904,505.143005 
	C482.412201,505.091736 482.662689,505.275909 482.952454,505.765625 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M445.856842,568.266846 
	C445.536316,568.166931 445.259308,567.996338 444.980255,567.218262 
	C444.969788,565.449951 445.004883,564.218384 445.039978,562.986816 
	C445.426056,563.056641 445.812164,563.126526 446.638092,563.585571 
	C449.008270,563.921997 450.938599,563.869385 452.868927,563.816711 
	C452.638489,562.171448 452.408081,560.526123 452.177643,558.880859 
	C450.440979,557.254333 448.704315,555.627869 446.946716,553.254150 
	C446.904541,551.644775 446.883301,550.782532 446.862000,549.920288 
	C447.074524,549.665100 447.287079,549.409912 448.091614,549.069214 
	C454.091553,552.410645 459.499512,555.837585 464.907440,559.264587 
	C465.297058,558.817932 465.686676,558.371277 466.076294,557.924622 
	C462.094147,555.097656 463.558990,548.267029 456.941803,547.088257 
	C456.146667,546.946655 455.009918,542.264709 455.606659,541.825012 
	C459.226044,539.158264 463.302673,537.112000 467.429993,535.021118 
	C467.855713,535.648193 468.078033,536.112549 468.216736,536.972656 
	C469.149597,538.552795 470.166077,539.737244 471.274231,541.044800 
	C471.365936,541.167908 471.406433,541.472168 471.310669,541.850952 
	C472.195892,543.126892 473.176849,544.024109 474.357513,545.098999 
	C474.762421,545.764343 474.967621,546.251892 475.120911,546.996948 
	C475.398499,547.457031 475.727936,547.659729 476.302826,547.976440 
	C476.886658,548.195557 477.225037,548.300659 477.686127,548.727173 
	C478.309143,550.333618 478.809448,551.618713 478.993927,553.086304 
	C476.879364,555.284241 475.080658,557.299744 473.182068,559.323486 
	C473.082245,559.331726 473.280853,559.358276 472.938477,559.269043 
	C471.841095,559.818542 471.086060,560.457336 470.331055,561.096069 
	C470.978943,561.456116 471.626831,561.816162 472.540222,562.378174 
	C473.866913,563.379456 474.928101,564.178772 475.977905,565.316772 
	C475.937714,566.328003 475.908875,567.000427 475.435944,567.743164 
	C471.679260,567.980774 468.366669,568.148193 464.846802,567.978760 
	C462.266266,567.235779 459.893036,566.829651 457.519836,566.423462 
	C457.377533,566.993591 457.235229,567.563660 457.092926,568.133728 
	C459.018555,568.757263 460.944183,569.380859 463.182068,570.181519 
	C469.290253,572.270325 475.086212,574.182068 480.908020,576.478638 
	C481.012390,577.633057 481.090881,578.402588 481.016388,579.466431 
	C479.677185,580.305786 478.490997,580.850830 476.899994,581.301880 
	C473.298187,581.141418 470.101227,581.075073 466.694000,580.929321 
	C466.224579,580.673279 466.052368,580.433960 465.940338,579.764832 
	C465.146393,579.003723 464.379242,578.609863 463.295532,578.092590 
	C461.366455,577.163696 459.753998,576.358276 458.141541,575.552795 
	C455.291718,573.737000 452.441895,571.921265 448.967285,569.949768 
	C447.513947,569.284973 446.685394,568.775940 445.856842,568.266846 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M467.226562,534.858459 
	C463.302673,537.112000 459.226044,539.158264 455.606659,541.825012 
	C455.009918,542.264709 456.146667,546.946655 456.941803,547.088257 
	C463.558990,548.267029 462.094147,555.097656 466.076294,557.924622 
	C465.686676,558.371277 465.297058,558.817932 464.907440,559.264587 
	C459.499512,555.837585 454.091553,552.410645 448.310669,548.946594 
	C448.160034,547.959656 448.109436,546.801514 448.649292,546.093872 
	C451.940552,541.779663 451.985321,541.813843 451.293915,536.588074 
	C451.854248,536.416138 452.672668,535.884888 453.013641,536.102295 
	C458.270355,539.454590 460.702484,536.986206 462.083038,531.925110 
	C462.273895,531.225342 463.129700,530.706970 464.304932,530.066650 
	C465.696991,531.638672 466.461761,533.248535 467.226562,534.858459 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M467.429993,535.021118 
	C466.461761,533.248535 465.696991,531.638672 464.636688,529.888550 
	C464.214325,528.453064 464.087433,527.157776 463.970825,525.470276 
	C464.304993,525.063049 464.653900,524.961182 464.949036,525.046143 
	C469.073700,526.232788 473.198334,527.604553 475.739166,522.303101 
	C475.922791,521.919983 477.257751,522.088684 478.444763,522.345947 
	C477.799988,524.151123 476.767883,525.611206 475.486694,527.090576 
	C475.079193,527.683655 474.920776,528.257385 474.789917,529.208618 
	C474.237091,530.753967 473.656708,531.921875 472.887207,532.885254 
	C472.448944,533.788757 472.199799,534.896790 471.946960,536.374939 
	C473.128967,538.823364 474.191589,540.994446 475.621857,542.887878 
	C475.859009,543.201782 477.701813,542.302795 479.215210,541.998901 
	C480.170685,542.116699 480.713593,542.200012 481.210632,542.654053 
	C479.964355,544.818481 478.763916,546.612122 477.563477,548.405762 
	C477.225037,548.300659 476.886658,548.195557 476.240845,547.732422 
	C475.679932,547.162781 475.426361,546.951111 475.172791,546.739502 
	C474.967621,546.251892 474.762421,545.764343 474.320251,544.766113 
	C473.190979,543.327698 472.298706,542.399902 471.406433,541.472168 
	C471.406433,541.472168 471.365936,541.167908 471.231934,540.703247 
	C470.165375,539.018005 469.232849,537.797485 468.300323,536.576965 
	C468.078033,536.112549 467.855713,535.648193 467.429993,535.021118 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M446.515015,550.004700 
	C446.883301,550.782532 446.904541,551.644775 446.862671,552.935303 
	C446.412231,553.341553 446.024872,553.319397 445.347504,553.266479 
	C445.427643,552.186890 445.797821,551.138000 446.515015,550.004700 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M733.224731,672.967773 
	C729.954407,672.198914 726.032654,675.103638 724.298584,670.292358 
	C734.112732,664.481689 743.562927,659.213684 753.013184,653.945679 
	C753.749512,653.905334 754.485779,653.864990 755.583740,653.746582 
	C752.392090,657.493713 748.938965,661.419678 745.240784,665.099243 
	C743.532410,666.798889 741.345276,668.017395 739.188843,669.729370 
	C737.183289,670.943115 735.364014,671.881592 733.224731,672.967773 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M753.018616,653.571045 
	C743.562927,659.213684 734.112732,664.481689 724.341309,669.878052 
	C724.476318,667.779663 719.401306,666.497803 723.360229,663.361450 
	C727.498230,660.949707 731.226013,658.919128 735.285217,656.891663 
	C736.739136,655.939392 737.861572,654.984009 739.206177,653.909180 
	C739.629456,653.524536 739.830627,653.259399 740.368652,652.967773 
	C742.135986,651.978333 743.566406,651.015320 745.254150,649.928833 
	C745.992126,649.498047 746.472839,649.190857 747.267456,648.780518 
	C748.702698,647.119324 749.823914,645.561157 750.990234,643.986694 
	C751.035339,643.970520 751.121826,643.929077 751.432129,643.929810 
	C752.155884,643.936768 752.569397,643.942993 752.982849,643.949158 
	C756.080078,647.017883 753.716492,650.110901 753.018616,653.571045 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M753.411499,643.943604 
	C752.569397,643.942993 752.155884,643.936768 751.466248,643.565918 
	C756.824158,642.206421 762.458313,641.211487 768.084595,640.519531 
	C768.098816,641.417358 768.120850,642.012146 768.142883,642.606995 
	C763.375305,643.050659 758.607666,643.494324 753.411499,643.943604 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M768.504395,642.701904 
	C768.120850,642.012146 768.098816,641.417358 768.446289,640.472290 
	C769.546997,640.128235 770.278259,640.134460 771.240601,640.373962 
	C773.753784,640.159546 776.035828,639.711853 778.317871,639.264160 
	C776.884766,640.567017 775.451660,641.869934 773.724182,643.307739 
	C772.797729,643.062927 772.165649,642.683228 771.323975,642.501099 
	C770.364868,642.731323 769.615356,642.764038 768.504395,642.701904 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M778.498901,639.227356 
	C776.035828,639.711853 773.753784,640.159546 771.183960,639.978638 
	C765.576477,636.794312 760.213379,635.215576 754.460571,638.742126 
	C753.483582,637.446228 752.550171,636.100769 751.507019,634.846680 
	C749.337341,632.238220 745.143127,632.380920 744.919189,635.318359 
	C744.469727,641.213562 739.157776,639.834534 736.330078,643.069702 
	C736.083191,641.452942 735.961853,640.658264 735.939880,639.566284 
	C736.656677,638.187500 737.273987,637.105957 738.242676,635.983398 
	C740.079224,634.344604 741.564392,632.746826 743.419312,630.950134 
	C750.190063,630.564453 756.591003,630.377747 762.992004,630.191040 
	C768.211243,631.996765 773.430481,633.802368 778.840942,636.267700 
	C779.024963,637.626953 779.017822,638.326477 779.010681,639.026062 
	C779.010681,639.026062 778.679932,639.190552 778.498901,639.227356 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M779.316528,639.011353 
	C779.017822,638.326477 779.024963,637.626953 779.020264,636.584229 
	C779.588989,636.295532 780.169617,636.350037 781.122253,636.653564 
	C780.870300,637.600647 780.246399,638.298645 779.316528,639.011353 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M573.095398,647.930176 
	C569.722107,647.637451 566.348755,647.344727 562.573486,647.033386 
	C559.763794,646.158142 557.388916,645.188416 554.939331,644.475220 
	C552.228394,643.685913 549.694885,643.652344 548.966797,647.658447 
	C543.231934,644.064392 537.490540,640.080444 531.421631,636.013794 
	C530.832336,632.297852 532.884583,632.917603 535.226990,633.525757 
	C546.154968,636.362915 557.108337,639.101868 568.760376,641.932617 
	C569.973755,642.309326 570.479370,642.629456 571.016418,643.298218 
	C571.730408,645.074585 572.412903,646.502380 573.095398,647.930176 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M594.804443,626.062744 
	C595.205383,627.452759 595.606262,628.842773 596.043213,630.880005 
	C596.102722,631.995361 596.126099,632.463440 595.702026,632.920776 
	C592.331543,633.569031 588.330322,631.064880 586.372437,635.856201 
	C583.670105,635.988220 581.389832,636.057617 578.785156,635.982971 
	C577.991394,635.867126 577.522217,635.895386 577.148682,635.884399 
	C577.244385,635.845215 577.208191,635.641602 577.121338,635.354370 
	C576.372070,634.682495 575.709717,634.297913 574.900940,633.645020 
	C573.853271,632.561462 572.952026,631.746277 572.050781,630.931091 
	C572.039307,629.842834 572.027893,628.754639 572.019470,627.257080 
	C579.042542,626.308777 586.062622,625.769836 593.585205,625.439880 
	C594.283508,625.861389 594.522461,625.999390 594.804443,626.062744 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M571.715698,630.943970 
	C572.952026,631.746277 573.853271,632.561462 574.910522,633.993347 
	C575.780396,634.953918 576.494263,635.297791 577.208191,635.641602 
	C577.208191,635.641602 577.244385,635.845215 577.140259,636.236267 
	C577.598999,637.459290 578.161865,638.291199 578.854126,639.411743 
	C579.392883,640.438904 579.802368,641.177429 580.278198,642.242065 
	C579.945190,643.243469 579.459106,644.539062 579.160156,644.497192 
	C576.416626,644.113464 573.705505,643.497742 570.984985,642.949585 
	C570.479370,642.629456 569.973755,642.309326 569.153687,641.939331 
	C568.891785,638.658813 568.848389,635.420532 569.102173,632.205750 
	C569.139526,631.732483 570.586121,631.370361 571.715698,630.943970 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M529.062805,607.670044 
	C524.957581,606.828430 520.852417,605.986755 516.360291,604.861023 
	C515.973389,604.576965 515.947754,604.093994 516.194946,604.016602 
	C516.612549,603.117249 516.782898,602.295410 517.275757,601.294922 
	C518.155151,600.404785 518.712097,599.693298 519.598633,599.056152 
	C522.118530,601.134033 523.712158,604.833191 527.823303,601.966187 
	C525.155334,598.207031 522.597107,594.602600 520.362183,590.947754 
	C522.386353,587.453247 520.462708,585.911255 517.406677,584.553467 
	C515.148438,581.152649 513.019348,578.094116 510.963135,574.689148 
	C513.372131,572.567566 515.708191,570.792480 518.004272,569.366516 
	C518.320496,571.807739 518.676575,573.899780 519.090576,576.334229 
	C519.230103,577.364197 519.311768,578.051697 519.289307,579.034546 
	C519.617188,579.939819 520.049011,580.549866 520.627258,581.520020 
	C521.220093,584.137512 521.974304,585.976013 524.934143,585.279053 
	C523.049561,591.737061 530.981079,603.412781 537.824951,603.988770 
	C545.869995,604.665771 553.959290,604.817139 562.019226,605.538452 
	C562.003174,606.567749 561.997253,607.254883 561.524109,607.969971 
	C551.890442,607.354126 542.728271,606.626465 533.553101,606.152100 
	C532.086426,606.076233 530.561035,607.135559 529.062805,607.670044 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M529.404053,607.854858 
	C530.561035,607.135559 532.086426,606.076233 533.553101,606.152100 
	C542.728271,606.626465 551.890442,607.354126 561.907837,608.038696 
	C567.565979,609.285217 572.373169,610.490967 577.484680,611.728516 
	C578.197021,611.784607 578.605042,611.808960 579.163818,612.158203 
	C580.228821,612.629395 581.143188,612.775574 582.429321,612.932312 
	C584.226685,613.271423 585.652222,613.599976 586.799500,614.254333 
	C576.119080,614.662170 565.679688,617.028870 555.339600,613.397339 
	C552.142639,612.274536 548.482727,612.469727 545.034790,612.061584 
	C544.574097,612.000305 544.113464,611.938965 543.314636,611.450806 
	C542.341797,610.967651 541.707092,610.911316 541.072388,610.854980 
	C540.606567,610.799500 540.140686,610.744080 539.239319,610.223999 
	C538.038635,609.676147 537.273438,609.592773 536.508301,609.509460 
	C534.253967,609.019531 531.999573,608.529663 529.404053,607.854858 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M545.247559,612.353149 
	C548.482727,612.469727 552.142639,612.274536 555.339600,613.397339 
	C565.679688,617.028870 576.119080,614.662170 587.250977,614.222900 
	C591.636902,613.934937 595.293274,614.004150 599.037231,614.435913 
	C600.756653,615.191162 602.388489,615.583862 604.016052,616.341309 
	C604.026367,617.191650 604.040894,617.677307 603.819580,618.377808 
	C602.755615,618.801392 601.927551,619.010254 600.844238,619.149536 
	C600.413940,619.059326 600.238770,619.038818 600.063660,619.018311 
	C597.130981,618.669067 594.203430,618.081116 591.264648,618.018372 
	C584.268616,617.869141 577.261780,617.820862 570.272583,618.084900 
	C568.781738,618.141174 567.347534,619.697205 565.786621,621.013428 
	C565.001099,620.750122 564.316284,620.035706 563.336365,618.785156 
	C562.313721,617.932434 561.586182,617.615723 560.858643,617.299072 
	C560.122864,617.137573 559.387024,616.976135 558.186890,616.703369 
	C557.722473,616.591980 557.464844,616.555054 557.464844,616.555054 
	C553.463318,615.251648 549.461792,613.948242 545.247559,612.353149 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M516.953247,601.473511 
	C516.782898,602.295410 516.612549,603.117249 515.809326,603.997253 
	C514.424438,604.128967 513.672363,604.202637 512.920288,604.276245 
	C509.254456,603.804993 505.588593,603.333740 501.423157,602.403076 
	C497.224030,601.727722 493.524536,601.511658 489.825043,601.295532 
	C489.104187,601.267151 488.383362,601.238770 487.302551,600.897888 
	C486.913849,600.122498 486.885101,599.659546 486.856384,599.196655 
	C489.213867,598.780029 491.633026,598.558533 493.896027,597.843140 
	C494.712158,597.585266 495.153412,596.141418 496.110626,594.801880 
	C496.803833,594.423401 497.152100,594.481750 497.715637,594.808777 
	C500.963806,595.987122 503.996704,596.896790 507.226135,597.996704 
	C507.763306,598.292053 508.103912,598.397095 508.644287,598.811646 
	C511.547150,599.905273 514.250183,600.689392 516.953247,601.473511 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M489.900299,601.602173 
	C493.524536,601.511658 497.224030,601.727722 501.007172,602.267334 
	C500.678558,602.827820 500.266296,603.064697 499.204590,603.313171 
	C497.248199,603.863892 495.941223,604.403076 494.634247,604.942261 
	C493.081360,603.931091 491.528473,602.919983 489.900299,601.602173 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M494.704041,605.167603 
	C495.941223,604.403076 497.248199,603.863892 498.881042,603.470093 
	C497.985626,604.384521 496.764404,605.153503 495.205048,605.888916 
	C494.866943,605.855286 494.773865,605.392883 494.704041,605.167603 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M512.970154,604.576599 
	C513.672363,604.202637 514.424438,604.128967 515.562134,604.074707 
	C515.947754,604.093994 515.973389,604.576965 515.970947,604.818726 
	C514.985657,604.999329 514.002869,604.938110 512.970154,604.576599 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M536.663086,609.773376 
	C537.273438,609.592773 538.038635,609.676147 538.934509,610.041748 
	C538.316101,610.228394 537.566956,610.132812 536.663086,609.773376 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M541.221924,611.114746 
	C541.707092,610.911316 542.341797,610.967651 543.012085,611.274231 
	C542.488892,611.474487 541.930176,611.424500 541.221924,611.114746 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M560.857666,617.578369 
	C561.586182,617.615723 562.313721,617.932434 563.110352,618.579224 
	C562.709900,618.928894 562.240295,618.948547 561.419678,618.961914 
	C560.997986,618.589539 560.927368,618.223633 560.857666,617.578369 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M557.348877,616.779907 
	C557.464844,616.555054 557.722473,616.591980 557.809387,616.685730 
	C557.675171,616.854553 557.453979,616.929688 557.348877,616.779907 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M596.149414,632.931519 
	C596.126099,632.463440 596.102722,631.995361 596.213684,631.201172 
	C597.218201,629.871887 598.519531,628.995056 598.859375,627.836243 
	C599.394287,626.012329 599.282166,623.998657 599.897583,621.658325 
	C600.351196,621.251404 600.806824,621.123108 601.289307,621.161987 
	C605.812866,621.255188 609.853943,621.309509 613.989136,621.628418 
	C614.710693,622.239746 615.338257,622.586426 615.971313,623.308960 
	C615.503540,624.677917 615.107056,625.717407 614.540039,626.653625 
	C613.536072,628.311462 612.428528,629.906555 611.364258,631.527893 
	C613.364807,632.351746 615.304565,633.644409 617.377075,633.913147 
	C624.056519,634.779358 630.775452,635.611389 637.497192,635.793884 
	C642.849915,635.939148 648.506042,637.344421 654.849243,633.857910 
	C649.801392,630.844055 645.717407,628.448669 641.699890,625.946472 
	C641.252991,625.668091 641.185242,624.781067 641.360229,624.172607 
	C647.813538,624.800293 653.848145,625.431641 660.344604,626.050842 
	C663.737305,625.803040 666.666748,625.548828 669.599182,625.335083 
	C680.400635,624.547607 691.203064,623.773560 701.994751,623.383911 
	C702.569641,626.027710 703.154968,628.281982 703.931824,631.273926 
	C704.963684,629.229370 705.523010,628.121033 706.344238,627.255737 
	C706.410645,630.684387 706.215210,633.869873 705.770752,637.340332 
	C692.018677,640.278809 678.515686,642.932373 665.012695,645.585938 
	C665.046570,646.196899 665.080444,646.807800 665.114380,647.418762 
	C667.063232,647.613342 669.012085,647.807861 670.960449,648.361145 
	C670.342834,649.519836 669.498230,650.244324 669.197754,651.149414 
	C669.029602,651.656006 669.763489,652.462097 670.066040,653.471375 
	C670.359436,654.895142 670.677734,655.980774 670.834106,657.352417 
	C670.477844,659.215637 670.283508,660.792847 670.092163,662.766113 
	C669.865845,666.575378 672.353882,665.999573 674.420105,666.174133 
	C677.206360,666.409546 679.982300,666.768494 682.762634,667.074463 
	C682.732483,667.581665 682.702393,668.088806 682.672241,668.596008 
	C680.808655,669.084290 678.945068,669.572571 676.781921,670.002930 
	C673.641235,667.783813 673.377441,671.325439 671.837769,672.044006 
	C670.855896,670.687805 669.874023,669.331604 668.919800,668.013611 
	C665.831421,671.159668 664.450928,677.304749 665.595947,682.552734 
	C664.458496,683.056519 663.640808,683.065247 662.825073,682.730042 
	C662.550049,681.525146 662.273071,680.664185 661.953247,679.400879 
	C662.728943,674.904785 663.547424,670.811096 664.456909,666.262390 
	C659.734070,665.992859 656.036743,665.781921 650.674744,665.475952 
	C653.031799,670.937073 654.988220,675.469849 656.633057,680.148315 
	C655.573730,681.203796 654.825989,682.113647 653.692261,683.167419 
	C651.065002,683.486328 648.779785,683.453613 646.599243,683.915405 
	C645.326965,684.184875 644.222229,685.245300 642.991333,686.251648 
	C642.967407,686.956970 642.994263,687.360413 642.757935,688.010864 
	C642.062439,690.078552 641.630066,691.899292 641.197632,693.720032 
	C640.295044,692.276245 639.392517,690.832397 638.220703,688.778076 
	C639.183655,679.368164 646.985657,683.396545 652.125854,681.186035 
	C651.148193,677.437317 650.391602,673.748047 649.172852,670.218445 
	C648.620117,668.617493 647.150757,667.332947 646.067261,665.520752 
	C645.959473,663.849731 645.881836,662.562988 646.171509,661.160400 
	C646.648438,659.896851 647.003967,657.788696 646.830811,657.744324 
	C643.929810,657.001282 640.962891,656.516052 637.651367,655.940002 
	C636.589966,655.750366 635.887756,655.594177 635.042969,655.169373 
	C633.915344,653.958313 632.930298,653.015869 632.371460,652.042114 
	C638.091064,652.010742 643.384399,652.010742 649.315796,652.010742 
	C646.337463,649.460388 643.678406,647.183411 641.430664,644.924377 
	C644.576111,645.643311 647.310364,646.344299 650.345215,646.999023 
	C650.433289,645.974121 650.220642,644.995544 649.657959,643.852661 
	C642.821777,642.782104 636.334961,641.880432 629.849731,640.968384 
	C619.117004,639.459167 608.385132,637.943176 597.786987,636.155579 
	C599.467773,633.614258 598.352905,632.945984 596.149414,632.931519 
M666.315308,655.425110 
	C662.351013,656.063416 658.350220,658.730286 654.506165,654.815186 
	C653.639954,653.932983 651.150085,653.140564 650.803955,653.534241 
	C649.766357,654.714661 649.419678,656.502380 648.427673,658.998108 
	C654.507568,658.998108 659.570374,659.122314 664.613953,658.866638 
	C665.385559,658.827454 666.068237,657.033569 666.315308,655.425110 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M600.806824,621.123108 
	C600.806824,621.123108 600.351196,621.251404 600.146973,621.370850 
	C599.925720,620.939026 599.908813,620.387634 599.977783,619.427246 
	C600.238770,619.038818 600.413940,619.059326 600.812439,619.467529 
	C600.959473,620.277771 600.883118,620.700439 600.806824,621.123108 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M594.831543,625.786133 
	C594.522461,625.999390 594.283508,625.861389 593.995972,625.460815 
	C594.222351,625.351685 594.540527,625.430603 594.831543,625.786133 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M470.108521,591.228699 
	C471.764648,591.636597 473.409027,592.409973 475.094330,593.532593 
	C473.455750,593.119324 471.776245,592.356750 470.108521,591.228699 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M486.476379,599.133423 
	C486.885101,599.659546 486.913849,600.122498 486.942993,600.938110 
	C485.912903,600.880188 484.882385,600.469543 483.843628,599.738892 
	C484.589050,599.302673 485.342712,599.186401 486.476379,599.133423 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M466.061737,589.406860 
	C466.778748,589.361450 467.552490,589.678040 468.453156,590.297607 
	C467.759552,590.323303 466.938995,590.046021 466.061737,589.406860 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M478.832794,596.512085 
	C479.528992,596.518127 480.296265,596.854431 481.093811,597.539917 
	C480.384033,597.540161 479.643982,597.191223 478.832794,596.512085 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M706.733887,396.690918 
	C704.815063,397.414459 702.665894,397.861572 699.825073,398.485504 
	C698.094299,398.869629 697.055054,399.076965 695.706299,399.154968 
	C694.573669,399.264709 693.750671,399.503784 692.503296,399.695374 
	C688.409424,400.243530 684.739929,400.839142 680.754028,401.312225 
	C679.645447,401.453674 678.853271,401.717712 677.630249,401.990143 
	C675.499146,402.202423 673.798950,402.406250 672.148682,402.271912 
	C672.417175,401.296844 672.635681,400.659943 673.122559,399.808716 
	C679.644470,394.924774 684.325684,386.819183 694.203735,390.070801 
	C696.511902,390.043396 698.820007,390.015991 701.457153,389.886841 
	C701.939941,393.004425 702.254944,396.108917 706.733887,396.690918 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M696.784668,367.970367 
	C696.498535,369.942596 696.212463,371.914795 695.291382,373.694153 
	C689.347351,371.513092 685.399292,374.660980 680.887390,376.919800 
	C678.077209,377.248199 675.630615,377.710052 673.129272,378.119751 
	C673.074524,378.067627 672.966003,377.962372 672.998962,377.578064 
	C675.150085,376.137970 677.251343,375.046295 679.391113,374.036285 
	C681.563721,373.010803 683.774292,372.065918 686.219971,370.929199 
	C686.570740,369.586945 686.669739,368.401276 687.185303,367.174225 
	C690.662781,367.412048 693.723755,367.691193 696.784668,367.970367 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M694.101807,389.719727 
	C684.325684,386.819183 679.644470,394.924774 672.772583,399.780457 
	C669.641724,399.555450 669.471924,398.652527 671.238525,396.819977 
	C672.402832,395.612213 673.119263,393.972656 674.163452,392.238220 
	C674.307983,391.740662 674.325378,391.531281 674.362183,391.259399 
	C674.381592,391.196960 674.366272,391.332550 674.654663,391.313171 
	C675.324402,391.209625 675.705750,391.125458 676.443481,391.002502 
	C680.632019,389.562469 684.464233,388.161224 688.296387,386.759949 
	C690.197571,387.629547 692.098694,388.499115 694.101807,389.719727 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M681.250977,377.053253 
	C685.399292,374.660980 689.347351,371.513092 694.959717,373.791351 
	C695.177917,374.479065 695.092834,374.876709 694.621094,375.663635 
	C692.389587,376.725494 690.501953,377.301392 688.709290,378.092316 
	C685.714905,379.413483 683.011963,381.018524 683.993164,385.328857 
	C682.173096,385.470886 680.612305,385.366913 679.065613,384.868225 
	C679.803467,382.000092 680.527222,379.526672 681.250977,377.053253 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M696.813843,367.735016 
	C693.723755,367.691193 690.662781,367.412048 686.775513,367.140381 
	C683.033386,367.142944 680.117676,367.138000 676.825073,367.097900 
	C676.197266,367.036316 675.946289,367.009888 675.804749,366.665009 
	C676.908936,365.509613 677.903687,364.672699 679.254333,363.878113 
	C681.057800,363.361603 682.505371,362.802826 684.375488,362.248444 
	C688.081848,362.925690 691.365723,363.598511 694.649597,364.271362 
	C695.287598,365.039581 695.925659,365.807800 696.764404,366.808685 
	C696.965149,367.041382 696.843018,367.499664 696.813843,367.735016 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M684.252380,385.082825 
	C683.011963,381.018524 685.714905,379.413483 688.709290,378.092316 
	C690.501953,377.301392 692.389587,376.725494 694.593262,375.967377 
	C692.686157,378.564606 690.420349,381.247406 687.708984,384.343994 
	C686.259766,384.866150 685.256104,384.974487 684.252380,385.082825 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M683.993164,385.328857 
	C685.256104,384.974487 686.259766,384.866150 687.635620,384.657623 
	C688.050049,385.052765 688.092468,385.548096 688.215576,386.401672 
	C684.464233,388.161224 680.632019,389.562469 676.425598,390.634247 
	C676.534607,388.908356 677.017822,387.511902 677.735840,386.097778 
	C678.330933,385.807678 678.691162,385.535278 679.051453,385.262878 
	C680.612305,385.366913 682.173096,385.470886 683.993164,385.328857 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M716.175842,391.264282 
	C714.596436,393.115509 712.614258,394.869354 710.313965,396.523071 
	C710.188110,395.205658 710.380249,393.988403 710.653687,392.255829 
	C712.165222,391.934296 713.969116,391.550629 716.175842,391.264282 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M694.928711,364.007751 
	C691.365723,363.598511 688.081848,362.925690 684.292114,361.903107 
	C683.561951,360.673920 683.337585,359.794434 683.500122,358.942932 
	C684.795410,358.731628 686.414001,358.641571 686.500549,358.229675 
	C687.248352,354.670044 688.505432,350.955139 688.074341,347.476959 
	C687.544678,343.203857 685.536682,339.114014 684.477417,335.032410 
	C685.718201,336.156128 686.649414,337.192383 687.580566,338.228638 
	C687.607361,337.839600 687.634155,337.450562 687.660950,337.061523 
	C689.606873,337.061523 691.552795,337.061523 693.629700,337.382385 
	C694.876892,338.789368 695.993103,339.875488 697.109314,340.961609 
	C696.179565,342.006531 695.208862,343.018311 694.329407,344.103973 
	C692.901367,345.866882 691.550659,347.676697 695.164917,349.439697 
	C696.338135,351.801392 697.079468,353.812561 697.920593,356.094452 
	C695.277100,355.040222 693.603271,354.372711 691.716064,353.620087 
	C691.098999,359.686066 691.287720,359.829102 698.985596,360.049011 
	C699.248535,360.397461 699.511536,360.745880 699.987549,361.353760 
	C698.536316,362.323517 696.872070,363.033813 694.928711,364.007751 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M677.081543,670.060913 
	C678.945068,669.572571 680.808655,669.084290 682.672241,668.596008 
	C682.702393,668.088806 682.732483,667.581665 682.762634,667.074463 
	C679.982300,666.768494 677.206360,666.409546 674.420105,666.174133 
	C672.353882,665.999573 669.865845,666.575378 670.547607,662.677246 
	C676.963074,662.222351 682.926025,662.252441 688.815918,662.616211 
	C688.157715,664.159119 687.572754,665.368286 686.750122,667.068542 
	C689.333923,666.732056 691.262939,666.480835 693.208191,666.258362 
	C693.224487,666.287109 693.290222,666.281494 693.469116,666.611450 
	C694.089478,668.634460 694.530945,670.327393 694.724243,672.125732 
	C694.321716,672.544556 694.167419,672.857910 694.013062,673.171265 
	C692.296509,673.114807 690.579895,673.058350 688.129028,672.743896 
	C685.181824,672.550659 682.968994,672.615479 680.756165,672.680298 
	C679.429932,672.504578 678.103699,672.328857 676.506470,671.865967 
	C676.517517,671.072815 676.799500,670.566895 677.081543,670.060913 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M694.972473,672.020386 
	C694.530945,670.327393 694.089478,668.634460 693.886719,666.532898 
	C695.123108,666.626892 696.210388,667.006470 697.101379,667.655396 
	C699.612854,669.484558 701.911682,671.640381 704.548767,673.250854 
	C706.579285,674.490967 708.995056,675.100159 710.986450,676.113281 
	C710.466248,676.703064 710.199036,677.169434 709.931824,677.635864 
	C708.172119,679.337341 706.412415,681.038757 704.375122,682.912903 
	C702.960449,683.576660 701.823425,684.067688 701.181091,684.345032 
	C699.889954,683.259888 698.769531,682.318237 697.354370,681.250977 
	C696.390686,679.019043 695.721802,676.912842 695.215942,674.278625 
	C695.243530,673.173889 695.108032,672.597168 694.972473,672.020386 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M662.823059,683.073975 
	C663.640808,683.065247 664.458496,683.056519 665.670898,682.931580 
	C666.480408,682.804016 666.895264,682.792725 667.937866,682.834106 
	C671.033630,683.260315 673.501648,683.633728 675.969727,684.007141 
	C675.916016,684.725098 675.862305,685.443115 675.781067,686.517822 
	C675.753479,686.874573 675.280273,686.834167 674.639648,686.869324 
	C669.651245,688.845947 663.420471,685.838928 660.465393,691.711914 
	C661.115173,688.656067 661.969116,685.864990 662.823059,683.073975 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M676.218994,683.815918 
	C673.501648,683.633728 671.033630,683.260315 668.238831,682.745850 
	C672.446777,679.351196 674.852356,679.637939 676.218994,683.815918 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M676.781982,670.002930 
	C676.799500,670.566895 676.517517,671.072815 676.104370,671.838135 
	C674.629333,672.270020 673.285339,672.442505 671.889587,672.329468 
	C673.377441,671.325439 673.641235,667.783813 676.781982,670.002930 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M680.971558,672.943848 
	C682.968994,672.615479 685.181824,672.550659 687.699585,672.743042 
	C685.731995,673.069336 683.459534,673.138367 680.971558,672.943848 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M694.724243,672.125732 
	C695.108032,672.597168 695.243530,673.173889 695.157104,673.918640 
	C694.636353,673.992249 694.380127,673.830444 694.089844,673.386169 
	C694.167419,672.857910 694.321716,672.544556 694.724243,672.125732 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M710.342590,677.732300 
	C710.199036,677.169434 710.466248,676.703064 711.101501,676.115967 
	C711.469543,675.995300 711.701904,676.038391 711.701904,676.038391 
	C712.146423,676.367249 712.590942,676.696167 713.143677,677.286743 
	C712.419067,677.641785 711.586243,677.735229 710.342590,677.732300 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M660.669617,691.976807 
	C663.420471,685.838928 669.651245,688.845947 674.404175,686.899658 
	C671.525330,690.462097 668.674988,694.180725 670.708130,700.008789 
	C671.010681,700.690369 671.012024,700.862976 671.013428,701.035522 
	C670.104065,701.463440 669.194763,701.891418 668.011780,702.410767 
	C667.571899,702.243713 667.405640,701.985168 667.127869,700.893799 
	C667.430481,696.846863 667.844482,693.632751 668.258545,690.418701 
	C667.608276,690.124512 666.958069,689.830261 666.307861,689.536072 
	C664.579590,691.392883 662.851257,693.249695 661.026611,694.751099 
	C660.839111,694.168579 660.747925,693.941528 660.673706,693.425171 
	C660.683655,692.749573 660.676636,692.363220 660.669617,691.976807 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M671.358765,701.018799 
	C671.012024,700.862976 671.010681,700.690369 671.011841,700.258911 
	C671.895691,699.791199 672.777161,699.582397 673.828979,699.597290 
	C673.234253,700.214600 672.469177,700.608337 671.358765,701.018799 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M680.337402,329.033936 
	C675.680237,326.991760 671.406799,324.916962 667.052246,322.500183 
	C672.353088,321.360199 677.735107,320.562225 683.546326,319.821045 
	C686.660706,323.154205 685.203003,327.240875 680.337402,329.033936 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M693.498718,337.061523 
	C691.552795,337.061523 689.606873,337.061523 687.660950,337.061523 
	C687.634155,337.450562 687.607361,337.839600 687.580566,338.228638 
	C686.649414,337.192383 685.718201,336.156128 684.439209,334.706207 
	C684.043457,333.857941 683.995667,333.423370 683.980469,332.668030 
	C684.021362,331.918427 684.029663,331.489532 684.037964,331.060608 
	C687.135010,329.062988 690.232117,327.065369 693.631226,324.878906 
	C694.015381,326.731476 694.097473,328.772858 694.510742,331.271851 
	C694.394165,333.506805 693.946411,335.284180 693.498718,337.061523 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M683.711426,331.032288 
	C684.029663,331.489532 684.021362,331.918427 683.647339,332.672058 
	C682.836914,332.975739 682.392090,332.954651 681.963501,332.618774 
	C681.971008,331.884583 681.962402,331.465149 681.953857,331.045685 
	C681.953857,331.045685 682.016968,330.982056 682.049072,330.948792 
	C682.515747,330.944977 682.950317,330.974457 683.711426,331.032288 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M693.629700,337.382385 
	C693.946411,335.284180 694.394165,333.506805 694.836914,331.337708 
	C695.104248,330.743225 695.376465,330.540497 695.773071,330.110809 
	C697.488220,333.581482 699.078979,337.279114 700.932800,341.588409 
	C699.645325,341.365417 698.705627,341.202667 697.437622,341.000732 
	C695.993103,339.875488 694.876892,338.789368 693.629700,337.382385 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M582.963928,407.911346 
	C583.977661,405.520325 584.991394,403.129272 586.003662,400.338684 
	C587.398743,399.632294 588.795410,399.325470 590.533875,399.416931 
	C589.687256,402.324646 588.338806,404.773132 587.353455,407.359985 
	C586.285095,410.165039 586.754089,412.198303 590.490845,412.075165 
	C593.982117,411.960144 597.480530,412.060791 600.820923,412.292725 
	C600.992249,413.311554 601.318420,414.104156 601.805542,415.287750 
	C600.722534,416.201599 599.039185,417.622009 597.310181,418.660278 
	C597.439758,417.252258 597.615051,416.226440 598.109558,413.332672 
	C593.357422,415.429932 589.785767,417.006195 586.232117,418.561035 
	C586.250061,418.539612 586.306152,418.607483 586.546631,418.923401 
	C586.827393,419.500946 586.867676,419.762573 586.537109,420.123322 
	C584.447571,422.923553 585.612549,427.540436 580.515747,428.229492 
	C579.504700,428.202759 578.830566,428.167816 577.839966,427.998260 
	C575.235291,429.002838 572.947021,430.141998 570.658813,431.281189 
	C570.031555,430.466492 569.404358,429.651764 569.107849,428.447021 
	C573.972778,425.533386 578.618408,423.105988 578.997437,416.995850 
	C578.999756,417.000000 578.992126,416.994812 579.389038,417.016418 
	C581.622314,416.758575 583.458679,416.479065 585.872131,416.111725 
	C584.742615,412.926758 583.853271,410.419067 582.963928,407.911346 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M571.895569,449.043793 
	C569.511475,448.143250 567.127380,447.242706 564.743286,446.342194 
	C564.790405,445.845184 564.837524,445.348175 564.884583,444.851196 
	C566.670166,444.505859 568.455750,444.160522 570.617188,443.742493 
	C569.755493,442.482086 568.977417,441.344055 568.048645,439.985657 
	C569.343201,439.005737 570.505432,438.125977 572.321289,437.173645 
	C574.077942,437.810822 575.180969,438.520599 576.324768,439.644226 
	C576.401672,442.460175 574.277649,445.764862 579.006775,446.544495 
	C579.576172,447.141815 580.058167,447.496399 580.597595,447.695770 
	C581.637390,448.080139 582.709595,448.376801 583.768433,448.709839 
	C583.028137,449.488770 582.272949,450.254150 581.551331,451.050079 
	C580.655273,452.038422 579.787109,453.052063 578.601013,454.106506 
	C577.883362,454.147095 577.471680,454.135956 577.040771,453.725525 
	C577.583069,449.181366 573.815369,450.218567 571.895569,449.043793 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M578.995056,416.991669 
	C578.618408,423.105988 573.972778,425.533386 569.062500,428.125793 
	C567.380432,425.722382 566.074280,423.250214 564.704590,420.413391 
	C567.038025,417.612885 569.435120,415.177094 571.984863,412.428772 
	C572.516235,412.002838 572.894836,411.889435 573.725952,412.040466 
	C575.122375,414.547119 575.409058,417.464661 578.995056,416.991669 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M576.283936,439.230377 
	C575.180969,438.520599 574.077942,437.810822 572.645630,437.056458 
	C572.280334,436.808044 572.244446,436.604248 572.183594,435.936768 
	C572.115295,435.260681 572.007935,435.088135 571.836670,434.955353 
	C571.474976,433.987152 571.113281,433.018951 570.705200,431.665955 
	C572.947021,430.141998 575.235291,429.002838 577.803467,428.397949 
	C577.483521,432.364929 576.883728,435.797668 576.283936,439.230377 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M571.969727,449.418457 
	C573.815369,450.218567 577.583069,449.181366 576.861206,454.004211 
	C576.495178,455.739014 576.289612,456.795837 576.083984,457.852631 
	C572.777161,456.829926 569.470276,455.807251 566.016846,454.439148 
	C568.640686,453.683044 571.693359,453.677521 571.969727,449.418457 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M560.806396,449.254608 
	C557.889099,451.302582 557.428406,451.066681 555.234802,446.177551 
	C557.100586,446.877167 558.875732,447.935638 560.806396,449.254608 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M571.709961,435.121674 
	C572.007935,435.088135 572.115295,435.260681 572.150757,435.628143 
	C571.790527,435.804810 571.604065,435.639709 571.709961,435.121674 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M578.997437,416.995850 
	C575.409058,417.464661 575.122375,414.547119 574.005615,411.939941 
	C574.865906,409.568207 575.899109,407.561371 577.001282,405.194733 
	C578.555115,405.668732 580.039978,406.502533 581.413879,407.781311 
	C580.532654,411.149139 579.762390,414.071960 578.992126,416.994812 
	C578.992126,416.994812 578.999756,417.000000 578.997437,416.995850 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M512.728882,473.090027 
	C514.452332,472.271027 515.903748,471.662933 517.826660,471.345764 
	C519.830261,471.502258 521.362427,471.367828 523.320312,471.199951 
	C525.913330,470.747681 528.066406,470.149628 530.253052,469.977570 
	C531.555603,469.875061 532.910767,470.441742 533.889221,470.864410 
	C530.046204,473.083557 526.556335,475.145905 522.688599,477.214478 
	C518.626160,478.545868 514.941528,479.871033 511.256866,481.196167 
	C510.144897,479.689850 509.032928,478.183563 507.833221,476.317535 
	C509.315979,475.072205 510.886414,474.186584 512.728882,473.090027 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M511.109436,481.346802 
	C514.941528,479.871033 518.626160,478.545868 522.906982,477.443909 
	C524.672363,478.101074 525.841614,478.535034 526.781372,479.245483 
	C524.783630,480.257050 522.047791,480.518219 521.415344,481.809601 
	C518.733704,487.284729 514.680969,487.605804 509.727020,485.581665 
	C510.047668,484.079681 510.356720,482.938538 510.665802,481.797424 
	C510.665802,481.797424 510.961975,481.497406 511.109436,481.346802 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M510.310303,481.805756 
	C510.356720,482.938538 510.047668,484.079681 509.595917,485.731598 
	C509.269867,486.440277 509.064178,486.611298 508.448120,486.820496 
	C506.820709,487.255432 505.581329,487.625336 504.341949,487.995239 
	C503.798096,487.005219 503.254242,486.015228 502.281342,484.877869 
	C502.865234,483.998169 503.782654,482.971741 504.914093,482.604218 
	C506.511322,482.085358 508.266937,482.053894 510.310303,481.805756 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M495.983154,505.789001 
	C495.042877,501.023682 490.747375,504.991516 488.373840,503.005798 
	C488.786346,502.069458 489.564117,501.133209 490.830048,500.308167 
	C493.209686,499.939575 495.101196,499.459839 497.369263,498.980652 
	C498.498993,498.988770 499.252106,498.996338 500.130005,499.289307 
	C500.889587,500.097687 501.524414,500.620697 501.992859,501.440155 
	C500.851135,503.985535 497.720978,505.710510 499.784729,508.867554 
	C498.966217,509.617554 498.476898,510.278595 497.795532,510.958221 
	C497.603485,510.976837 497.218353,511.001526 497.158630,510.625671 
	C496.727020,508.762848 496.355072,507.275940 495.983154,505.789001 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M504.318176,488.083740 
	C505.581329,487.625336 506.820709,487.255432 508.493286,487.216888 
	C507.957397,489.774628 506.988251,492.000977 505.922516,494.449280 
	C508.592773,495.029205 510.786804,495.505707 512.989136,496.221405 
	C512.997437,496.460602 512.983032,496.938843 512.650146,496.909180 
	C511.879425,496.947540 511.441559,497.015625 510.725769,497.069305 
	C510.175018,497.290894 509.902191,497.526825 509.440063,497.808594 
	C509.250732,497.854431 508.982605,498.136963 508.579956,498.043030 
	C505.878601,497.668243 503.579895,497.387329 501.422302,496.743774 
	C502.412842,494.991394 503.501465,493.686127 504.031891,492.183777 
	C504.419830,491.084961 504.073822,489.727020 504.054932,488.484589 
	C504.054932,488.484589 504.294403,488.172241 504.318176,488.083740 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M503.722565,488.639252 
	C504.073822,489.727020 504.419830,491.084961 504.031891,492.183777 
	C503.501465,493.686127 502.412842,494.991394 501.179077,496.786957 
	C500.531494,497.796478 500.268341,498.400177 500.005249,499.003906 
	C499.252106,498.996338 498.498993,498.988770 497.225708,498.659302 
	C496.459137,497.201965 496.212708,496.066528 495.966278,494.931122 
	C498.440918,492.885376 500.915558,490.839661 503.722565,488.639252 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M495.636353,495.001953 
	C496.212708,496.066528 496.459137,497.201965 496.849121,498.658752 
	C495.101196,499.459839 493.209686,499.939575 491.098938,500.155884 
	C492.355255,498.285919 493.830872,496.679382 495.636353,495.001953 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M654.959473,407.736511 
	C664.307373,406.180389 672.911438,407.937744 680.771790,414.158173 
	C679.492798,417.916595 678.102173,421.022430 676.711609,424.128296 
	C677.370972,424.491547 678.030273,424.854828 678.689636,425.218109 
	C680.845947,421.761566 683.002319,418.305054 685.158630,414.848511 
	C685.978699,415.019775 686.798706,415.191071 687.703247,415.932709 
	C684.134888,423.131134 680.482178,429.759247 676.924194,436.215424 
	C675.547485,436.330750 674.279419,436.436951 672.632690,436.673340 
	C665.871948,440.089233 659.485535,440.792664 653.013733,436.534790 
	C651.543274,437.083832 650.150513,437.933350 648.875000,438.711395 
	C644.379761,431.637878 639.596558,424.111328 634.928833,416.258087 
	C636.580933,415.583374 638.117554,415.235321 639.883972,415.173462 
	C642.338135,418.595612 644.562439,421.731537 646.786804,424.867462 
	C647.091675,424.686249 647.396545,424.505066 647.701416,424.323883 
	C647.015991,420.121674 646.330566,415.919495 645.999329,411.517944 
	C649.222168,410.124542 652.090820,408.930511 654.959473,407.736511 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M655.024841,407.447449 
	C652.090820,408.930511 649.222168,410.124542 645.732788,411.600586 
	C643.292786,412.884125 641.473511,413.885681 639.654175,414.887268 
	C638.117554,415.235321 636.580933,415.583374 634.549927,416.328003 
	C633.036011,416.829529 632.016479,416.934479 630.747498,417.088776 
	C630.324036,417.415619 630.149902,417.693115 629.983521,417.985321 
	C629.991211,418.000000 629.965332,417.972534 629.564758,417.990753 
	C627.210022,417.344849 625.293030,416.366058 623.288391,416.128723 
	C621.982727,415.974213 619.589722,416.606995 619.351318,417.392883 
	C618.604553,419.854919 618.648438,422.556824 618.203491,425.462280 
	C615.991333,426.125641 614.938782,431.326416 612.005981,426.634247 
	C611.514221,425.170990 610.973694,424.057953 610.446045,422.581116 
	C610.497498,421.734070 610.536072,421.250854 610.765991,420.463562 
	C611.548096,415.196564 613.098938,409.945251 607.002747,406.568909 
	C606.957764,405.015228 606.912476,403.883240 606.880066,402.377930 
	C606.952271,401.012390 607.011597,400.020142 607.070923,399.027924 
	C607.328125,397.772980 607.585327,396.518005 607.900879,394.978302 
	C609.592041,395.636505 611.157104,396.245697 613.365723,396.797607 
	C614.319275,396.913757 614.629395,397.087128 614.900513,397.630341 
	C616.601257,401.381226 618.341064,404.762238 620.080872,408.143219 
	C620.704712,407.893951 621.328613,407.644714 621.952454,407.395447 
	C621.952454,404.266327 621.952454,401.137238 622.384277,397.987793 
	C624.537842,398.042023 626.259460,398.116638 628.264038,398.438782 
	C636.710510,400.059418 644.874207,401.432556 653.197510,403.095215 
	C654.203674,403.559631 655.050232,403.734558 655.896790,403.909485 
	C655.627930,404.992462 655.359070,406.075409 655.024841,407.447449 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M698.638672,418.116089 
	C698.837952,419.177032 698.712708,420.340729 698.017639,421.591309 
	C693.289368,420.744019 693.581116,423.855682 693.283264,426.334137 
	C693.098816,427.869293 693.114929,429.428589 692.752136,430.864777 
	C690.997070,431.792297 689.098022,432.534637 688.142151,433.925232 
	C684.677917,438.964996 681.691040,444.333740 678.208984,449.360107 
	C675.694763,452.989380 673.844116,457.462463 667.991150,459.331024 
	C668.868286,453.651489 669.621155,448.887970 673.035400,445.062592 
	C675.001404,442.777252 676.989380,440.395691 678.497742,437.741577 
	C682.463379,430.763184 686.220337,423.666199 690.060425,416.616455 
	C692.811646,417.150574 695.562866,417.684723 698.638672,418.116089 
z"
          />
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M696.015869,399.284302 
	C697.055054,399.076965 698.094299,398.869629 699.530762,398.723938 
	C703.793823,402.443329 703.530029,402.910614 699.086426,406.297699 
	C697.741882,407.322571 697.655457,409.997925 696.685791,412.806213 
	C691.595581,413.143646 685.743469,413.190674 680.261108,409.908905 
	C678.869812,409.076080 677.249573,408.327576 675.671082,408.167999 
	C672.452881,407.842560 671.742615,406.323944 672.588867,403.103760 
	C672.396606,402.677429 672.098694,402.610107 672.098694,402.610107 
	C673.798950,402.406250 675.499146,402.202423 677.771362,402.249176 
	C679.252319,402.144775 680.161316,401.789795 681.070374,401.434814 
	C684.739929,400.839142 688.409424,400.243530 692.656677,399.984924 
	C694.161499,399.976044 695.088684,399.630157 696.015869,399.284302 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M672.148682,402.271912 
	C672.098694,402.610107 672.396606,402.677429 672.445923,402.821960 
	C670.605469,402.297516 668.133240,402.159576 666.942261,400.853394 
	C664.115295,397.752960 661.916443,397.184204 659.268127,400.840363 
	C658.527649,401.862640 657.333923,402.556610 656.122498,403.655334 
	C655.050232,403.734558 654.203674,403.559631 653.309326,402.845581 
	C653.595215,401.874695 653.928833,401.442963 654.565308,401.026947 
	C657.701172,401.889771 657.251038,400.380219 656.275635,398.902771 
	C654.952576,396.898773 653.408997,395.040405 651.960022,393.119568 
	C652.462097,393.029938 652.964111,392.940338 654.163330,392.812988 
	C655.542480,392.772766 656.224487,392.770325 657.260864,392.902039 
	C661.324951,396.033081 665.009583,396.317383 668.669556,392.944458 
	C668.782104,392.840698 668.975464,392.824646 669.464294,392.804321 
	C670.879761,392.620483 671.962036,392.400055 673.288391,392.277588 
	C673.700378,392.425873 673.868347,392.476166 674.036255,392.526459 
	C673.119263,393.972656 672.402832,395.612213 671.238525,396.819977 
	C669.471924,398.652527 669.641724,399.555450 672.504150,399.994812 
	C672.635681,400.659943 672.417175,401.296844 672.148682,402.271912 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M689.863403,416.422058 
	C686.220337,423.666199 682.463379,430.763184 678.497742,437.741577 
	C676.989380,440.395691 675.001404,442.777252 673.065430,444.623779 
	C672.936340,441.490723 672.973877,439.016937 673.011414,436.543152 
	C674.279419,436.436951 675.547485,436.330750 676.924194,436.215424 
	C680.482178,429.759247 684.134888,423.131134 687.922913,416.159302 
	C688.594238,415.952972 689.130249,416.090332 689.863403,416.422058 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M684.893311,414.591675 
	C683.002319,418.305054 680.845947,421.761566 678.689636,425.218109 
	C678.030273,424.854828 677.370972,424.491547 676.711609,424.128296 
	C678.102173,421.022430 679.492798,417.916595 680.935303,414.466553 
	C682.200867,414.193207 683.414490,414.264038 684.893311,414.591675 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M606.755920,398.862061 
	C607.011597,400.020142 606.952271,401.012390 606.569092,402.478058 
	C605.215332,402.900452 604.086792,402.567474 603.184998,402.882782 
	C602.385925,403.162109 601.838989,404.162323 601.169189,405.180756 
	C601.807983,405.962189 602.458313,406.407196 603.118774,406.864319 
	C603.128845,406.876465 603.164673,406.878754 603.154053,407.248535 
	C604.002747,408.439240 605.062317,409.135345 605.671448,410.112213 
	C606.461182,411.378754 606.892090,412.869049 607.210815,414.041687 
	C604.954651,413.235718 602.965210,412.651123 600.975769,412.066528 
	C597.480530,412.060791 593.982117,411.960144 590.490845,412.075165 
	C586.754089,412.198303 586.285095,410.165039 587.353455,407.359985 
	C588.338806,404.773132 589.687256,402.324646 590.895874,399.460022 
	C594.768188,398.649231 598.612061,398.077789 602.477051,397.803131 
	C603.770630,397.711182 605.118042,398.376831 606.755920,398.862061 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M627.981140,398.191223 
	C626.259460,398.116638 624.537842,398.042023 622.142334,397.738464 
	C619.292175,397.426453 617.115845,397.343475 614.939514,397.260468 
	C614.629395,397.087128 614.319275,396.913757 613.741943,396.739075 
	C610.410034,393.596436 607.345398,390.455048 604.298584,386.989838 
	C607.736816,386.330902 610.706787,385.204559 612.877197,381.892609 
	C616.618713,384.152069 619.980591,386.526337 623.909119,389.083771 
	C624.700439,389.825073 624.925049,390.383209 624.817749,391.103424 
	C622.665405,393.086823 622.139282,394.356842 625.458374,395.119690 
	C626.480591,395.354553 627.150146,397.124268 627.981140,398.191223 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M602.606384,385.464233 
	C603.173645,385.345459 603.880188,385.582153 604.615967,386.218964 
	C604.012024,386.352600 603.378845,386.086121 602.606384,385.464233 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M579.389038,417.016418 
	C579.762390,414.071960 580.532654,411.149139 581.625244,408.017670 
	C582.115173,407.844635 582.282776,407.880219 582.707153,407.913574 
	C583.853271,410.419067 584.742615,412.926758 585.872131,416.111725 
	C583.458679,416.479065 581.622314,416.758575 579.389038,417.016418 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M670.090942,653.133240 
	C669.763489,652.462097 669.029602,651.656006 669.197754,651.149414 
	C669.498230,650.244324 670.342834,649.519836 671.347900,648.195557 
	C683.317627,645.809937 695.121948,648.046692 706.778137,644.455444 
	C716.056458,641.596863 726.123535,641.298523 735.840454,639.863525 
	C735.961853,640.658264 736.083191,641.452942 736.330078,643.069702 
	C739.157776,639.834534 744.469727,641.213562 744.919189,635.318359 
	C745.143127,632.380920 749.337341,632.238220 751.507019,634.846680 
	C752.550171,636.100769 753.483582,637.446228 754.460571,638.742126 
	C760.213379,635.215576 765.576477,636.794312 770.952820,639.745361 
	C770.278259,640.134460 769.546997,640.128235 768.454102,640.169312 
	C762.458313,641.211487 756.824158,642.206421 751.155945,643.565186 
	C751.121826,643.929077 751.035339,643.970520 750.652283,644.021973 
	C749.179504,644.382263 748.089722,644.691162 746.607178,645.048828 
	C741.685181,646.051270 737.175903,647.117798 732.620544,647.923035 
	C728.896790,648.581299 726.077087,650.146545 724.964600,653.988281 
	C723.219116,653.905396 721.473633,653.822449 718.950256,653.842651 
	C711.191528,654.025879 704.210754,654.106018 696.945435,653.884155 
	C691.859741,651.622986 687.327271,652.254639 682.919922,654.604614 
	C678.580994,653.852173 674.335999,653.492737 670.090942,653.133240 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M697.229919,654.186157 
	C704.210754,654.106018 711.191528,654.025879 718.653015,653.994873 
	C719.107666,654.782776 719.081604,655.521606 718.813599,656.793335 
	C718.494629,657.906921 718.417664,658.487610 718.340698,659.068237 
	C716.750977,662.667358 715.111450,666.245544 713.593262,669.874573 
	C712.868835,671.606262 712.374939,673.434448 711.739258,675.628662 
	C711.701904,676.038391 711.469543,675.995300 711.354492,675.992554 
	C708.995056,675.100159 706.579285,674.490967 704.548767,673.250854 
	C701.911682,671.640381 699.612854,669.484558 697.101379,667.655396 
	C696.210388,667.006470 695.123108,666.626892 693.707825,666.202881 
	C693.290222,666.281494 693.224487,666.287109 693.182373,665.813110 
	C692.981445,663.882629 692.822571,662.426270 692.770264,660.629517 
	C694.327881,658.254883 695.778870,656.220520 697.229919,654.186157 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M724.831299,654.323364 
	C726.077087,650.146545 728.896790,648.581299 732.620544,647.923035 
	C737.175903,647.117798 741.685181,646.051270 746.610107,645.438965 
	C746.988464,646.814758 746.970947,647.849182 746.953491,648.883606 
	C746.472839,649.190857 745.992126,649.498047 744.874023,649.911133 
	C742.835022,651.009399 741.433472,652.001831 740.031860,652.994263 
	C739.830627,653.259399 739.629456,653.524536 738.848206,653.901489 
	C737.163330,654.971680 736.058533,655.930115 734.953735,656.888550 
	C731.226013,658.919128 727.498230,660.949707 723.426392,663.050720 
	C722.639771,662.504761 721.693237,661.708923 721.838196,661.301758 
	C722.645142,659.035217 723.716858,656.862976 724.831299,654.323364 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M718.596924,659.006592 
	C718.417664,658.487610 718.494629,657.906921 718.835693,657.163330 
	C719.017517,657.648621 718.935303,658.296753 718.596924,659.006592 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M641.019409,644.906494 
	C643.678406,647.183411 646.337463,649.460388 649.315796,652.010742 
	C643.384399,652.010742 638.091064,652.010742 631.999451,652.009277 
	C630.453552,651.970215 629.705933,651.932678 628.705322,651.633545 
	C623.489075,650.229492 625.421753,653.964294 625.140381,656.404907 
	C625.788391,657.128052 626.399292,657.553162 627.005127,657.989136 
	C627.000000,658.000000 627.021790,657.990295 627.075562,658.317810 
	C628.651062,659.339478 630.172852,660.033569 631.919373,661.054932 
	C632.786499,662.846985 633.607910,664.262024 634.031738,665.787537 
	C634.775146,668.463379 635.133972,671.250854 635.960022,673.895996 
	C636.218689,674.724243 637.394653,675.630615 638.274963,675.782837 
	C638.957397,675.900818 640.082031,675.075134 640.579163,674.366516 
	C642.511780,671.611389 644.272522,668.735718 646.097290,665.904907 
	C647.150757,667.332947 648.620117,668.617493 649.172852,670.218445 
	C650.391602,673.748047 651.148193,677.437317 652.125854,681.186035 
	C646.985657,683.396545 639.183655,679.368164 637.940796,688.551758 
	C634.428406,688.259399 630.925964,687.586182 627.425781,686.901306 
	C627.104370,686.838379 626.701233,686.792664 626.498718,686.582336 
	C623.278870,683.239075 620.085571,679.870117 616.844604,675.763062 
	C616.872925,674.255310 616.942932,673.491577 617.332581,672.680542 
	C618.293457,672.555237 618.934631,672.477295 619.950806,672.568359 
	C621.719299,671.993896 623.112854,671.250366 624.512268,670.251038 
	C624.433350,669.495789 624.348450,668.996277 624.458862,668.098999 
	C624.205994,666.810425 623.757812,665.919678 623.359497,664.673218 
	C623.427673,663.359619 623.446106,662.401733 623.602295,661.140625 
	C622.492249,659.891357 621.244263,658.945435 619.906250,657.733643 
	C619.474365,657.057373 619.132507,656.647034 618.837830,655.929688 
	C618.263489,655.362366 617.641968,655.102112 616.781006,654.893188 
	C616.541565,654.944458 616.062744,655.047546 615.694580,655.060425 
	C615.295898,656.718262 615.265381,658.363342 615.255493,660.384766 
	C615.280457,661.515442 615.284790,662.269653 614.919312,663.008179 
	C611.735229,661.004211 608.920898,659.016052 605.959717,656.751953 
	C605.201904,655.644043 604.590942,654.812134 604.168091,653.668213 
	C606.081543,652.616943 607.806824,651.877563 609.944763,650.961304 
	C609.107910,650.251526 608.495239,649.288269 607.748596,649.169739 
	C602.554443,648.345398 597.345520,647.272949 592.115784,647.112122 
	C587.059143,646.956604 581.971802,647.797791 576.897949,648.201172 
	C575.882935,648.119568 574.867981,648.037964 573.474182,647.943237 
	C572.412903,646.502380 571.730408,645.074585 571.016418,643.298218 
	C573.705505,643.497742 576.416626,644.113464 579.160156,644.497192 
	C579.459106,644.539062 579.945190,643.243469 580.666809,642.273315 
	C594.771423,642.956177 608.550476,643.983948 622.339111,644.862061 
	C624.594421,645.005676 626.891724,644.489502 629.140015,644.659241 
	C632.154541,648.272583 637.457458,647.829651 639.148560,644.186768 
	C639.975403,644.399719 640.497375,644.653137 641.019409,644.906494 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M641.524475,693.828003 
	C641.630066,691.899292 642.062439,690.078552 643.192078,688.073792 
	C647.814392,689.023926 651.739502,690.158081 655.687988,691.577271 
	C655.762390,692.240295 655.813354,692.618225 655.645508,693.187622 
	C655.929749,694.568909 656.432800,695.758606 656.947083,697.211304 
	C656.960266,697.649841 656.962219,697.825378 656.964233,698.000854 
	C656.964233,699.641785 656.964233,701.282776 656.964233,703.998352 
	C651.479980,700.346863 646.665649,697.141418 641.524475,693.828003 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M603.979919,653.980164 
	C604.590942,654.812134 605.201904,655.644043 605.950195,657.095215 
	C609.156982,663.832153 612.226562,669.949829 615.296082,676.067505 
	C615.466187,678.079651 615.636353,680.091858 615.763000,682.539307 
	C614.200134,680.389526 612.599548,677.848022 611.180176,675.209106 
	C609.015991,671.185608 606.977173,667.094666 604.454102,662.761963 
	C604.023010,662.491516 603.857361,662.110352 603.878662,661.761475 
	C602.989380,660.232788 602.078918,659.052917 600.970093,657.611572 
	C600.198059,656.237488 599.624329,655.124878 599.356323,653.915405 
	C601.101379,653.872375 602.540649,653.926270 603.979919,653.980164 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M661.122986,695.106506 
	C662.851257,693.249695 664.579590,691.392883 666.307861,689.536072 
	C666.958069,689.830261 667.608276,690.124512 668.258545,690.418701 
	C667.844482,693.632751 667.430481,696.846863 666.972168,700.459839 
	C664.649475,701.445557 662.371033,702.032349 659.609924,702.743469 
	C659.758667,701.358826 659.899231,700.050659 660.039307,698.109741 
	C660.044983,697.299500 660.051086,697.122131 660.284119,696.788452 
	C660.715027,696.123657 660.919006,695.615112 661.122986,695.106506 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M615.644897,676.014893 
	C612.226562,669.949829 609.156982,663.832153 606.097046,657.371155 
	C608.920898,659.016052 611.735229,661.004211 615.158569,663.308350 
	C616.182739,666.658813 616.597839,669.693359 617.012939,672.727844 
	C616.942932,673.491577 616.872925,674.255310 616.737427,675.393066 
	C616.445862,675.832153 616.219849,675.897217 615.644897,676.014893 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M660.057190,696.944702 
	C660.051086,697.122131 660.044983,697.299500 660.036560,697.743408 
	C659.267273,698.006592 658.500244,698.003296 657.348755,698.000427 
	C656.962219,697.825378 656.960266,697.649841 657.337891,697.208740 
	C658.497437,696.943665 659.277283,696.944153 660.057190,696.944702 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M796.989929,603.183044 
	C791.933350,604.466370 786.876709,605.749695 781.395508,606.704224 
	C780.976318,605.958557 780.981812,605.541626 781.174255,604.838440 
	C783.392639,602.419250 785.511719,600.362366 787.439026,598.139221 
	C793.100281,591.609314 793.240662,589.816589 789.184814,582.269531 
	C789.510681,581.051880 789.610046,580.121216 790.014282,579.198425 
	C790.850708,579.499390 791.382202,579.792542 791.970642,580.438477 
	C792.561584,582.085449 793.095642,583.379578 793.712280,584.873718 
	C797.780334,580.425171 799.245667,575.457703 799.524170,569.985474 
	C800.871887,570.373047 801.893616,570.720337 803.129700,571.323120 
	C807.402283,573.333923 807.087097,575.829529 804.538086,578.688843 
	C803.671936,579.660400 802.865173,580.710693 801.878296,581.543823 
	C799.427856,583.612366 800.221558,584.990112 802.742249,586.437866 
	C802.305847,587.596436 801.824524,588.437195 801.127136,589.058228 
	C796.504089,593.119385 792.097168,597.400452 787.690247,601.681519 
	C788.165894,602.290466 788.641541,602.899475 789.117188,603.508423 
	C790.868591,602.427612 792.707825,601.463074 794.342834,600.228088 
	C795.591248,599.285156 796.580872,597.999756 797.787781,597.234009 
	C797.948669,598.106750 798.007324,598.612122 797.974731,599.441284 
	C797.761841,600.196594 797.640198,600.628174 797.322144,601.264038 
	C797.080505,602.039978 797.035217,602.611511 796.989929,603.183044 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M777.016235,610.027222 
	C781.933472,609.431824 786.844910,608.778992 791.772583,608.287964 
	C792.798462,608.185730 793.884155,608.682434 794.758667,609.390869 
	C793.992859,610.192444 793.410706,610.506287 792.459595,610.930542 
	C784.359375,611.893799 776.628052,612.746704 769.024170,613.276184 
	C771.773132,611.977661 774.394714,611.002441 777.016235,610.027222 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M797.518494,601.059692 
	C797.640198,600.628174 797.761841,600.196594 798.317017,599.385010 
	C802.479614,595.965271 806.208801,592.925537 810.365845,589.927002 
	C811.947083,590.007996 813.100281,590.047791 814.149902,590.434021 
	C813.252441,592.925110 812.458618,595.069763 811.664795,597.214417 
	C811.306274,597.468811 810.947815,597.723206 810.329407,598.183594 
	C808.053406,599.038452 806.037354,599.687256 803.282471,600.295532 
	C800.868652,600.523193 799.193542,600.791443 797.518494,601.059692 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M777.175781,609.769592 
	C774.394714,611.002441 771.773132,611.977661 768.623840,613.350830 
	C766.496765,613.961548 764.897400,614.174133 762.904236,614.269287 
	C760.760437,613.219543 757.902466,617.151123 757.108398,612.818237 
	C757.302979,612.759583 757.704041,612.694092 758.060730,612.797852 
	C759.354370,612.399170 760.291321,611.896667 761.594177,611.305786 
	C763.197815,611.016235 764.435669,610.815002 766.031738,610.728394 
	C767.303223,610.289734 768.216492,609.736450 769.459717,609.107666 
	C770.858643,608.696228 771.927673,608.360229 773.388489,608.056030 
	C775.557373,607.793762 777.334412,607.499634 779.111450,607.205444 
	C778.519409,607.974243 777.927368,608.743103 777.175781,609.769592 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M802.991394,610.028687 
	C802.989319,610.207703 802.987183,610.386780 802.995972,610.835327 
	C801.172363,611.592712 799.337646,612.080566 797.260742,612.011597 
	C797.029419,611.076721 797.040405,610.698547 797.051392,610.320435 
	C798.132202,609.961304 799.213013,609.602112 800.815063,609.427856 
	C801.888000,609.751404 802.439697,609.890015 802.991394,610.028687 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M779.419373,607.145935 
	C777.334412,607.499634 775.557373,607.793762 773.353943,607.733154 
	C772.923157,606.947021 772.918701,606.515686 773.305542,605.930054 
	C776.126892,605.558777 778.557068,605.341736 780.987305,605.124756 
	C780.981812,605.541626 780.976318,605.958557 780.974792,606.688416 
	C780.561584,607.029724 780.144409,607.058044 779.419373,607.145935 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M797.322144,601.264038 
	C799.193542,600.791443 800.868652,600.523193 802.937988,600.410767 
	C801.465576,601.408203 799.598877,602.249939 797.361023,603.137329 
	C797.035217,602.611511 797.080505,602.039978 797.322144,601.264038 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M812.892151,607.389160 
	C811.675659,607.909424 810.075012,608.260437 808.240112,608.360107 
	C809.506653,607.812500 811.007263,607.516174 812.892151,607.389160 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M796.769653,610.141968 
	C797.040405,610.698547 797.029419,611.076721 797.005554,611.739502 
	C796.548645,612.030396 796.104675,612.036499 795.177734,611.803711 
	C794.156006,611.403320 793.617249,611.242004 793.015747,611.015747 
	C792.953064,610.950806 792.828491,610.820129 792.828491,610.820129 
	C793.410706,610.506287 793.992859,610.192444 794.889954,609.740051 
	C795.632507,609.722229 796.060242,609.842896 796.769653,610.141968 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M802.996094,609.750488 
	C802.439697,609.890015 801.888000,609.751404 801.160217,609.387695 
	C801.546387,608.941895 802.108582,608.721191 802.838867,608.708130 
	C803.004944,609.101379 803.002930,609.286804 802.996094,609.750488 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M831.090332,579.913635 
	C833.116638,582.219849 835.142944,584.526123 837.178772,587.183899 
	C836.132202,587.836243 835.075989,588.137024 833.498535,588.095215 
	C832.370544,587.160583 831.763855,586.568542 831.143433,585.629211 
	C830.988464,584.601440 830.847290,583.920898 830.793091,582.829834 
	C830.950195,581.584106 831.020264,580.748840 831.090332,579.913635 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M831.157166,585.976440 
	C831.763855,586.568542 832.370544,587.160583 833.079773,588.058228 
	C832.986145,588.617859 832.789978,588.871887 832.379272,589.258789 
	C830.347107,589.928101 828.529541,590.464478 826.358643,590.566895 
	C826.027405,589.018066 826.049438,587.903198 826.331665,586.526978 
	C828.113647,586.169250 829.635437,586.072876 831.157166,585.976440 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M826.071472,586.788208 
	C826.049438,587.903198 826.027405,589.018066 825.995972,590.552551 
	C824.034912,592.135742 822.083191,593.299377 819.449097,594.378052 
	C814.940125,594.024353 816.983093,591.606140 817.141479,589.775635 
	C817.134033,589.675903 817.030090,589.501648 817.400391,589.384827 
	C819.854431,588.548767 821.938110,587.829468 824.363831,587.061462 
	C825.161072,586.937927 825.616272,586.863037 826.071472,586.788208 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M817.148926,589.875305 
	C816.983093,591.606140 814.940125,594.024353 819.125000,594.485046 
	C817.101074,595.660461 814.718994,596.643860 812.000854,597.420837 
	C812.458618,595.069763 813.252441,592.925110 814.506226,590.411926 
	C815.693848,589.987366 816.421387,589.931335 817.148926,589.875305 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M631.140259,345.638947 
	C630.420654,344.308075 629.701050,342.977203 629.149719,341.026001 
	C630.231873,338.945190 631.145691,337.484650 632.059448,336.024170 
	C633.718201,335.324493 635.376892,334.624817 637.522217,333.526520 
	C641.217896,332.684601 641.176575,334.464508 640.572693,336.766510 
	C639.526733,340.753967 638.446472,344.739532 637.633301,348.776764 
	C637.381897,350.024994 637.950806,351.438446 638.144104,352.776245 
	C638.544617,352.751984 638.945068,352.727753 639.345520,352.703522 
	C640.154175,350.157959 640.962769,347.612366 641.826416,344.893738 
	C643.848999,345.716492 645.475403,346.378082 647.373169,347.255890 
	C647.917542,349.143219 648.620972,350.880463 648.376465,352.472076 
	C647.825195,356.059845 646.804871,359.575562 645.565918,363.082275 
	C641.164307,362.298248 636.665588,362.587646 634.715881,357.504883 
	C633.593445,353.297882 632.366882,349.468414 631.140259,345.638947 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M647.101868,347.039703 
	C645.475403,346.378082 643.848999,345.716492 641.826416,344.893738 
	C640.962769,347.612366 640.154175,350.157959 639.345520,352.703522 
	C638.945068,352.727753 638.544617,352.751984 638.144104,352.776245 
	C637.950806,351.438446 637.381897,350.024994 637.633301,348.776764 
	C638.446472,344.739532 639.526733,340.753967 640.572693,336.766510 
	C641.176575,334.464508 641.217896,332.684601 637.635559,333.178375 
	C637.336853,327.886627 640.851074,330.975372 643.517822,331.250793 
	C644.692383,334.096588 645.408691,336.555267 646.143494,339.409729 
	C646.475281,342.216919 646.788574,344.628296 647.101868,347.039703 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M646.125000,339.013947 
	C645.408691,336.555267 644.692383,334.096588 643.886597,331.257874 
	C644.330627,330.606293 644.864197,330.334747 645.700500,330.028198 
	C648.846680,334.070160 651.690186,338.147186 654.687073,342.444122 
	C655.395630,342.217194 656.507690,341.861023 657.698242,342.049622 
	C658.213989,345.890717 658.651123,349.187073 659.088318,352.483429 
	C658.567871,352.606171 658.047424,352.728912 657.527039,352.851654 
	C653.726379,348.239075 649.925720,343.626495 646.125000,339.013947 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M632.014526,335.637482 
	C631.145691,337.484650 630.231873,338.945190 629.133057,340.680237 
	C627.468079,341.607086 625.988220,342.259369 623.674316,343.279205 
	C623.106873,341.436401 622.577820,339.718445 622.387451,337.491150 
	C624.036987,335.366486 625.441406,333.816498 626.631042,332.116272 
	C627.691589,330.600677 628.514771,328.918915 629.442688,327.310486 
	C629.442688,327.310486 629.579712,327.453247 629.434631,327.853821 
	C629.870972,328.839355 630.452393,329.424316 631.033813,330.009277 
	C631.345764,331.756470 631.657715,333.503632 632.014526,335.637482 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M617.222412,326.820557 
	C617.222412,326.820557 617.196472,326.453400 617.197388,326.269470 
	C618.155457,324.256531 619.112671,322.427490 620.103027,320.226776 
	C623.170410,319.494019 626.204651,319.132904 629.787170,318.706543 
	C629.491760,321.348602 629.281250,323.231689 628.660034,325.562927 
	C624.573792,326.280914 620.898071,326.550720 617.222412,326.820557 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M617.157837,327.160461 
	C620.898071,326.550720 624.573792,326.280914 628.674316,325.967468 
	C629.241394,326.218597 629.366699,326.520416 629.458862,327.069885 
	C628.514771,328.918915 627.691589,330.600677 626.631042,332.116272 
	C625.441406,333.816498 624.036987,335.366486 622.399658,337.111816 
	C622.049561,337.018219 622.025879,336.794586 622.134766,335.901123 
	C622.887817,333.357697 623.508301,331.484161 624.420654,328.729248 
	C621.510559,329.284760 619.664062,329.637268 617.817505,329.989746 
	C617.576050,329.159973 617.334595,328.330200 617.157837,327.160461 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M631.116699,329.694946 
	C630.452393,329.424316 629.870972,328.839355 629.487610,327.937378 
	C630.190308,328.207092 630.694946,328.793823 631.116699,329.694946 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M614.687500,324.996216 
	C615.073975,327.789551 615.073975,330.574127 615.073975,335.098816 
	C613.456299,333.445099 612.423828,332.409637 611.415161,331.351501 
	C610.749573,330.653290 610.118103,329.922546 609.165466,329.058716 
	C610.673401,327.603210 612.487183,326.295349 614.687500,324.996216 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M763.298096,614.386719 
	C764.897400,614.174133 766.496765,613.961548 768.496399,613.674194 
	C776.628052,612.746704 784.359375,611.893799 792.459595,610.930542 
	C792.828491,610.820129 792.953064,610.950806 792.989380,611.332275 
	C793.012268,612.139038 792.998840,612.564270 792.985352,612.989563 
	C787.153870,614.617126 781.399780,616.755615 775.458069,617.655457 
	C772.514587,618.101196 769.225952,616.267700 766.093445,615.465454 
	C765.767822,616.099243 765.442200,616.732971 765.116577,617.366760 
	C768.966248,620.686340 772.815857,624.005981 776.837646,627.880005 
	C777.012329,628.601440 777.014893,628.768433 777.017395,628.935486 
	C772.453003,626.490234 767.888550,624.044983 763.140503,620.954895 
	C763.070557,618.335632 763.184326,616.361206 763.298096,614.386719 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M808.056335,611.458801 
	C810.024536,611.077820 812.089478,611.060303 814.596985,611.068481 
	C813.046570,614.198181 810.653015,613.513550 808.056335,611.458801 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M782.833008,629.943359 
	C782.285156,630.154114 781.561157,630.044800 780.721191,629.700806 
	C781.289124,629.518616 781.972961,629.571045 782.833008,629.943359 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M777.180298,629.124390 
	C777.014893,628.768433 777.012329,628.601440 777.007690,628.183105 
	C777.574646,628.107544 778.143799,628.283142 778.850220,628.729492 
	C778.439453,629.104492 777.891296,629.208862 777.180298,629.124390 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M793.280762,612.978271 
	C792.998840,612.564270 793.012268,612.139038 793.052063,611.397217 
	C793.617249,611.242004 794.156006,611.403320 794.844849,611.816162 
	C794.521912,612.367432 794.049072,612.667236 793.280762,612.978271 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M828.975525,501.036591 
	C829.430847,501.325958 829.886230,501.615295 830.567810,502.057495 
	C831.266663,503.125275 831.739258,504.040192 832.612427,505.338043 
	C833.678650,506.135864 834.344299,506.550781 835.010010,506.965668 
	C835.415649,507.006073 835.821228,507.046448 836.614990,507.456635 
	C839.031067,513.122131 841.058960,518.417786 843.086853,523.713501 
	C841.952148,522.919556 840.670288,522.272583 839.707031,521.307495 
	C834.103699,515.693054 828.638733,509.938873 822.970764,504.391418 
	C821.750427,503.196960 819.889221,502.657196 817.730591,501.599548 
	C816.258301,499.938385 815.378723,498.493561 814.427490,496.852448 
	C814.355896,496.656189 814.174683,496.279724 814.182617,495.961212 
	C814.165161,495.431854 814.139709,495.220978 814.486084,494.992157 
	C818.279480,496.997070 821.641663,499.133118 825.158569,500.973602 
	C826.208496,501.523071 827.697510,505.016266 828.975525,501.036591 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M843.288330,523.900513 
	C841.058960,518.417786 839.031067,513.122131 836.920349,507.471802 
	C842.173462,509.807953 847.615479,512.318665 852.755066,515.343018 
	C853.770630,515.940674 853.623108,518.514771 853.917358,520.827942 
	C853.487244,522.034363 853.151611,522.590149 852.815918,523.145996 
	C852.235901,523.504211 851.655945,523.862488 850.263062,524.183228 
	C847.808167,524.240479 846.166016,524.335144 844.523926,524.429810 
	C844.179199,524.315735 843.834534,524.201599 843.288330,523.900513 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M842.018799,501.406982 
	C844.029907,499.343903 846.042114,497.653351 848.354309,495.821320 
	C851.877136,497.680298 855.100098,499.680756 859.306091,502.291443 
	C856.514404,504.190979 854.995483,505.224487 852.848328,506.685486 
	C852.280151,504.070374 851.884216,502.247955 851.488281,500.425537 
	C850.998230,500.256104 850.508240,500.086700 850.018250,499.917297 
	C849.060059,502.450104 848.101868,504.982941 847.033386,507.807343 
	C843.629822,507.405518 842.845825,504.573303 842.018799,501.406982 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M862.054138,538.958374 
	C861.104797,536.565918 860.155457,534.173462 859.074463,531.465515 
	C860.606567,528.334778 862.195251,525.470825 863.975342,522.731262 
	C864.581116,521.798828 865.668884,521.179626 866.536438,520.417297 
	C866.834961,521.699463 867.300964,522.973083 867.367188,524.267151 
	C867.405212,525.009888 866.982849,525.976929 866.440735,526.511414 
	C864.767273,528.161377 861.914062,529.440186 865.657654,531.966431 
	C865.939636,532.156738 864.978455,534.189331 864.349609,536.119019 
	C866.256104,536.818542 868.314270,537.316833 870.068787,538.337219 
	C870.828369,538.778870 870.961609,540.297607 870.877991,541.672607 
	C869.131653,541.784424 867.819397,541.724609 866.658081,541.274963 
	C865.063538,540.657532 863.583313,539.744629 862.054138,538.958374 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M878.950439,542.054443 
	C875.347351,541.441772 874.619507,543.405273 875.103149,547.082031 
	C875.390686,548.183716 875.625549,548.610352 875.826538,549.427246 
	C875.787354,550.860229 875.782166,551.903076 875.776978,552.945862 
	C875.102417,553.524902 874.427795,554.103882 873.753174,554.682861 
	C873.093384,553.112549 872.433594,551.542175 871.840759,549.190369 
	C871.693970,546.540222 871.480164,544.671509 871.266357,542.802856 
	C872.952271,541.549622 874.638123,540.296448 876.681152,538.977722 
	C877.235596,539.183105 877.432983,539.454041 877.935974,540.114746 
	C878.477905,541.021179 878.714172,541.537781 878.950439,542.054443 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M871.210205,542.579468 
	C871.480164,544.671509 871.693970,546.540222 871.812927,548.818970 
	C868.496338,546.047607 865.274597,542.866150 862.053467,539.321533 
	C863.583313,539.744629 865.063538,540.657532 866.658081,541.274963 
	C867.819397,541.724609 869.131653,541.784424 870.728455,541.959839 
	C871.080261,541.901611 871.154053,542.356140 871.210205,542.579468 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M853.175781,523.322266 
	C853.151611,522.590149 853.487244,522.034363 853.916138,521.248413 
	C854.665405,521.850952 855.321411,522.683533 855.904419,523.850952 
	C855.066162,523.956665 854.300964,523.727600 853.175781,523.322266 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M834.965271,506.638367 
	C834.344299,506.550781 833.678650,506.135864 832.937866,505.408386 
	C833.548706,505.500885 834.234619,505.905975 834.965271,506.638367 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M832.013245,501.989441 
	C832.150146,502.139801 832.274902,502.300018 832.399658,502.460205 
	C832.300171,502.413300 832.200745,502.366394 832.041321,502.171448 
	C831.981384,502.023407 832.001099,501.999329 832.013245,501.989441 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M879.255493,542.060974 
	C878.714172,541.537781 878.477905,541.021179 878.162781,540.230225 
	C878.955200,540.172852 879.826416,540.389832 880.873230,540.909790 
	C880.552734,541.497742 880.056641,541.782593 879.255493,542.060974 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M452.081238,559.249451 
	C452.408081,560.526123 452.638489,562.171448 452.868927,563.816711 
	C450.938599,563.869385 449.008270,563.921997 446.925934,563.646606 
	C448.510895,562.085083 450.247833,560.851562 452.081238,559.249451 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M444.836731,562.703186 
	C445.004883,564.218384 444.969788,565.449951 444.855774,567.006165 
	C444.370911,567.282410 443.964966,567.234009 443.261841,567.100464 
	C442.287109,566.303467 441.609589,565.591614 440.656433,564.755432 
	C439.532898,559.537354 442.220795,561.243042 444.836731,562.703186 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M465.967133,580.132080 
	C466.052368,580.433960 466.224579,580.673279 466.779266,581.249268 
	C467.828705,582.725647 468.582611,583.802795 469.275085,585.148438 
	C469.143127,585.586426 469.072693,585.755981 469.002258,585.925537 
	C466.589539,585.252869 464.176849,584.580261 461.382568,583.822449 
	C459.705383,582.461609 458.409698,581.185974 457.065948,579.594299 
	C457.332184,578.292297 457.646484,577.306335 458.051178,575.936523 
	C459.753998,576.358276 461.366455,577.163696 463.363434,578.432922 
	C464.487671,579.308472 465.227417,579.720276 465.967133,580.132080 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M445.869324,568.612427 
	C446.685394,568.775940 447.513947,569.284973 448.616882,569.949219 
	C448.514008,570.742004 448.136780,571.379700 447.469971,572.090454 
	C446.747559,571.094971 446.314697,570.026489 445.869324,568.612427 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M816.981567,525.926025 
	C816.889893,526.115784 816.798218,526.305603 816.208496,526.865723 
	C814.502930,529.323975 812.992737,531.300964 812.175232,533.531738 
	C811.399292,535.648804 810.398376,539.579407 811.140137,540.034729 
	C814.708984,542.225342 813.971436,544.225586 812.020447,546.821289 
	C810.564026,548.759094 809.269470,550.818604 807.574829,552.984985 
	C803.744934,554.399475 800.243103,555.652832 796.362305,556.909424 
	C794.995300,555.858337 794.007263,554.803894 793.361816,553.817078 
	C794.849792,552.169983 795.995178,550.455261 797.408997,548.491089 
	C797.521545,546.404236 797.927429,544.229126 797.074524,542.810730 
	C795.875122,540.815918 793.741821,539.382568 791.881897,537.452148 
	C791.441528,536.514526 791.127197,535.833984 790.989258,534.867249 
	C791.516846,534.307373 791.867981,534.033630 792.541199,533.858276 
	C794.819824,534.900269 796.776489,535.843933 799.151428,536.842163 
	C803.049194,537.585815 805.259399,536.115356 806.540527,532.907043 
	C807.994995,529.264526 809.528503,525.653625 811.388916,522.017273 
	C813.493958,523.312378 815.237793,524.619202 816.981567,525.926025 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M843.048218,567.114014 
	C841.552124,569.304504 840.056030,571.494995 838.259705,573.444092 
	C837.737793,572.624695 837.442688,571.523987 837.304749,571.543335 
	C830.401917,572.511597 829.757324,567.781982 828.851074,562.799805 
	C828.229126,559.380859 826.729553,556.121582 825.588867,552.464722 
	C825.572266,551.485779 825.588989,550.833130 825.605713,550.180481 
	C829.824768,551.213867 834.186340,551.881653 838.198547,553.445740 
	C840.013672,554.153320 841.134399,556.642212 842.668274,558.911011 
	C840.835083,562.011841 838.903809,564.524658 836.046753,568.242065 
	C839.392578,567.703003 841.220398,567.408508 843.048218,567.114014 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M825.423584,549.844604 
	C825.588989,550.833130 825.572266,551.485779 825.242554,552.479248 
	C820.946228,549.141968 816.511169,550.151672 812.031982,551.569519 
	C810.660645,552.003662 809.279419,552.406616 807.902771,552.823853 
	C809.269470,550.818604 810.564026,548.759094 812.020447,546.821289 
	C813.971436,544.225586 814.708984,542.225342 811.140137,540.034729 
	C810.398376,539.579407 811.399292,535.648804 812.175232,533.531738 
	C812.992737,531.300964 814.502930,529.323975 815.987549,527.091919 
	C816.670776,528.681396 817.076904,530.415161 817.559631,532.476074 
	C819.178528,532.161011 820.544067,531.895264 822.295959,531.554321 
	C822.815979,534.160095 823.324219,536.706665 823.398010,539.590881 
	C817.442749,539.463318 817.643494,544.609802 814.999329,546.966003 
	C815.425476,547.561890 815.851685,548.157776 816.277893,548.753662 
	C818.841370,547.183228 821.404846,545.612732 823.968323,544.042236 
	C823.968323,544.042236 824.450134,544.013977 824.726440,544.014648 
	C825.002747,544.015320 824.898621,544.096130 824.898621,544.096130 
	C825.012878,545.900330 825.127197,547.704590 825.423584,549.844604 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M843.394287,567.030762 
	C841.220398,567.408508 839.392578,567.703003 836.046753,568.242065 
	C838.903809,564.524658 840.835083,562.011841 842.890808,559.168091 
	C846.769958,559.849548 850.524658,560.861877 854.643311,561.945068 
	C855.007202,562.015930 854.977966,562.498901 854.537720,562.761230 
	C852.544739,563.023560 850.991943,563.023560 848.862671,563.023560 
	C848.862671,567.479126 848.862671,571.253906 848.862671,575.028687 
	C847.773010,572.533630 846.683350,570.038574 845.593689,567.543518 
	C845.976379,567.193726 846.359070,566.843872 846.741760,566.494080 
	C845.741272,566.645203 844.740845,566.796326 843.394287,567.030762 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M817.236450,525.965393 
	C815.237793,524.619202 813.493958,523.312378 811.328064,521.957642 
	C810.905945,521.909729 810.783752,521.790955 810.810547,521.430542 
	C811.576599,519.063721 812.315979,517.057190 813.311157,514.846069 
	C814.089233,513.437256 814.611572,512.232971 815.448792,510.967224 
	C816.179016,510.238159 816.594360,509.570557 817.282043,509.131226 
	C818.109131,510.577545 818.663879,511.795624 819.218628,513.013672 
	C819.268860,513.203064 819.319092,513.392517 819.062683,513.865051 
	C818.955200,514.342224 819.154419,514.536194 819.353638,514.730225 
	C819.605103,517.191345 819.856567,519.652527 819.706665,522.585938 
	C818.865662,523.992188 818.426086,524.926086 817.986511,525.860046 
	C817.821411,525.908325 817.656372,525.956543 817.236450,525.965393 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M817.009705,508.902985 
	C816.594360,509.570557 816.179016,510.238159 815.397949,510.575714 
	C813.671692,506.153076 812.311157,502.060516 811.308228,497.885925 
	C812.610291,497.552185 813.554749,497.300446 814.499146,497.048706 
	C815.378723,498.493561 816.258301,499.938385 817.336060,501.605469 
	C817.604065,502.283600 817.674011,502.739441 817.355408,503.636047 
	C816.981140,505.685547 816.995422,507.294281 817.009705,508.902985 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M824.066345,543.642090 
	C821.404846,545.612732 818.841370,547.183228 816.277893,548.753662 
	C815.851685,548.157776 815.425476,547.561890 814.999329,546.966003 
	C817.643494,544.609802 817.442749,539.463318 823.277588,539.980896 
	C823.782471,541.102844 823.973389,542.172363 824.066345,543.642090 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M817.282043,509.131226 
	C816.995422,507.294281 816.981140,505.685547 817.279785,503.976196 
	C818.250488,506.735657 818.908203,509.595795 819.392212,512.734802 
	C818.663879,511.795624 818.109131,510.577545 817.282043,509.131226 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M844.711426,524.720337 
	C846.166016,524.335144 847.808167,524.240479 849.931274,524.272522 
	C848.574463,524.603149 846.736694,524.807007 844.711426,524.720337 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M818.312683,525.972534 
	C818.426086,524.926086 818.865662,523.992188 819.642944,523.008179 
	C819.533325,524.000366 819.086121,525.042664 818.312683,525.972534 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M824.961975,544.067139 
	C825.026306,544.069397 825.131348,544.019897 825.067505,544.033264 
	C824.898621,544.096130 825.002747,544.015320 824.961975,544.067139 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M819.401123,514.578613 
	C819.154419,514.536194 818.955200,514.342224 819.014771,514.155029 
	C819.273499,514.161865 819.448547,514.427002 819.401123,514.578613 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M876.042786,552.974365 
	C875.782166,551.903076 875.787354,550.860229 876.072510,549.310608 
	C876.568909,548.341248 876.785278,547.878723 877.001709,547.416138 
	C878.128662,547.298767 879.255676,547.181396 880.813232,547.010620 
	C881.820618,547.490967 882.397461,548.024719 883.014893,548.885620 
	C881.030273,550.237488 879.005127,551.262146 876.909546,552.677856 
	C876.662354,553.046875 876.485474,553.024902 876.042786,552.974365 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M849.058594,575.264282 
	C848.862671,571.253906 848.862671,567.479126 848.862671,563.023560 
	C850.991943,563.023560 852.544739,563.023560 854.523254,563.002747 
	C859.807190,564.486145 864.665344,565.990356 870.202515,567.704834 
	C867.629211,569.728638 865.631226,571.299988 863.313477,572.999817 
	C860.770630,573.834534 858.565491,574.606567 856.318054,575.224548 
	C854.762939,575.652161 853.149353,575.867188 851.215576,576.269104 
	C850.330994,576.073975 849.792725,575.786865 849.058594,575.264282 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M779.644287,471.334106 
	C781.154663,470.728638 782.665100,470.123138 784.730225,469.686371 
	C786.234375,470.171967 787.183777,470.488831 788.133118,470.805695 
	C788.525452,470.875061 788.917847,470.944458 789.603638,470.957764 
	C793.970215,473.157532 798.182556,475.202911 802.053772,477.764008 
	C803.807007,478.923920 804.882751,481.107819 806.245239,483.286255 
	C806.224670,483.745392 805.997681,484.001465 805.709961,483.826660 
	C804.403198,482.459686 803.384277,481.267517 802.009766,479.659424 
	C799.125305,485.525879 795.664246,483.316803 792.099976,480.564026 
	C791.345276,479.821930 790.679626,479.390137 789.612793,478.929382 
	C786.808594,478.604309 784.405518,478.308167 781.641113,477.951294 
	C779.210938,477.263367 777.142212,476.636200 775.124817,475.714722 
	C775.576233,474.973389 775.976196,474.526398 776.696655,474.044495 
	C777.892761,473.117767 778.768494,472.225952 779.644287,471.334106 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M828.844971,500.752625 
	C827.697510,505.016266 826.208496,501.523071 825.158569,500.973602 
	C821.641663,499.133118 818.279480,496.997070 814.212158,494.886963 
	C813.079834,494.476166 812.593262,494.152588 811.951660,493.534973 
	C809.863586,490.161102 807.930603,487.081299 805.997681,484.001465 
	C805.997681,484.001465 806.224670,483.745392 806.344727,483.619751 
	C808.168823,484.046112 809.872864,484.598114 812.343567,485.398438 
	C814.056885,491.188049 815.656250,492.413788 821.317200,491.177216 
	C821.663574,491.224518 821.704956,491.224426 821.750244,491.610718 
	C821.775208,494.983185 822.819824,497.224670 825.982971,497.984528 
	C826.893494,498.812561 827.803955,499.640625 828.844971,500.752625 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M792.517212,456.045410 
	C793.818237,457.188141 794.767822,458.331696 795.856445,459.764069 
	C795.558289,461.665192 795.121033,463.277466 794.305786,464.963898 
	C793.100098,464.169769 791.919983,463.437073 791.522278,462.403320 
	C790.821411,460.581970 790.598267,458.576813 790.108215,456.343903 
	C790.382019,456.304291 791.273865,456.175262 792.517212,456.045410 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M801.997559,472.175049 
	C802.763428,471.743866 803.498108,471.711884 804.597778,471.629761 
	C806.554260,473.351135 808.145691,475.122681 809.749023,476.907471 
	C805.853638,478.638733 803.465271,477.291870 801.997559,472.175049 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M779.229492,471.242798 
	C778.768494,472.225952 777.892761,473.117767 776.337891,473.937256 
	C772.197021,474.040192 768.718872,474.488220 765.280273,474.280609 
	C763.441650,474.169617 761.397339,473.246094 760.002502,472.043518 
	C759.430359,471.550232 760.211304,469.322693 760.587036,467.941254 
	C760.961426,466.564545 761.599121,465.259399 762.358643,463.687622 
	C763.054749,461.980408 763.513550,460.508423 763.972351,459.036407 
	C767.309631,456.247528 770.748535,457.580688 771.658936,462.581299 
	C768.626282,465.093353 765.948669,467.177979 763.355164,469.197113 
	C767.175903,474.450745 770.532349,470.486908 773.998779,468.952850 
	C775.604065,469.685730 777.209351,470.418640 779.229492,471.242798 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M774.005371,468.594299 
	C770.532349,470.486908 767.175903,474.450745 763.355164,469.197113 
	C765.948669,467.177979 768.626282,465.093353 771.658203,462.958099 
	C772.438538,462.965393 772.864563,463.023315 773.613159,463.099182 
	C773.981079,463.526886 774.026306,463.936676 773.815491,464.739075 
	C773.468323,465.258972 773.282043,465.480255 773.300659,465.499146 
	C773.510315,465.711517 773.751526,465.892761 773.984192,466.082367 
	C773.993469,466.800171 774.002747,467.517975 774.005371,468.594299 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M788.063354,470.472473 
	C787.183777,470.488831 786.234375,470.171967 785.045044,469.529663 
	C785.533386,467.415131 786.261841,465.626007 786.990234,463.836884 
	C787.324707,464.060699 787.659180,464.284515 787.993652,464.508301 
	C787.993652,466.385284 787.993652,468.262238 788.063354,470.472473 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M773.992065,465.802246 
	C773.751526,465.892761 773.510315,465.711517 773.300659,465.499146 
	C773.282043,465.480255 773.468323,465.258972 773.778870,465.046631 
	C773.998840,465.148407 773.999329,465.335236 773.992065,465.802246 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M782.003723,455.994720 
	C782.118713,456.130188 782.233704,456.265656 782.348694,456.401123 
	C782.270630,456.353058 782.192627,456.304993 782.057312,456.128479 
	C782.000000,456.000000 782.003723,455.994720 782.003723,455.994720 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M781.997314,456.001953 
	C781.880859,455.867889 781.767151,455.731842 781.653381,455.595795 
	C781.730713,455.644379 781.808044,455.692963 781.944580,455.868134 
	C782.003723,455.994720 782.000000,456.000000 781.997314,456.001953 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M749.321838,612.399902 
	C740.194458,612.906738 731.067017,613.413574 721.719910,613.671631 
	C723.095398,611.861389 724.690613,610.299927 726.615479,608.801025 
	C728.256409,609.162720 729.567688,609.461914 731.272583,609.839355 
	C737.481934,609.686768 740.423218,606.000916 742.467896,601.096191 
	C743.621643,598.328491 745.031372,595.667542 746.270935,593.073303 
	C748.242859,593.815063 749.351501,594.232056 750.460205,594.649109 
	C750.915955,594.293945 751.371765,593.938843 751.827515,593.583679 
	C748.928406,588.674683 746.029236,583.765747 743.116699,578.452393 
	C742.703552,576.109436 742.509460,574.099487 741.866577,572.245117 
	C739.650574,565.852966 737.277771,559.515076 735.163452,552.842041 
	C736.609802,549.386658 737.869812,546.250610 739.102295,543.103699 
	C741.041992,538.150940 744.199768,536.809937 748.203430,539.965332 
	C751.794312,542.795471 755.122803,543.114746 758.966614,541.435120 
	C759.032288,543.544617 759.075256,545.231995 758.739746,546.958618 
	C756.026428,549.346008 753.691589,551.694153 750.584167,554.819214 
	C756.947571,556.296814 756.502014,559.591309 755.419617,563.114258 
	C755.032349,564.374451 754.114685,565.705078 754.317749,566.847412 
	C754.519226,567.980530 755.826660,568.916992 756.810425,570.324707 
	C760.745789,571.494995 764.519897,572.280334 768.293945,573.065735 
	C766.347168,574.024658 764.324890,574.058533 762.618469,574.809753 
	C761.518127,575.294250 760.881104,576.831177 759.751953,578.113525 
	C758.780945,579.514526 758.353455,581.396729 757.364014,581.763611 
	C752.630737,583.519104 752.576599,583.373108 755.596130,587.764343 
	C754.412109,589.376892 752.724304,591.077881 752.991577,592.376648 
	C753.962769,597.096619 755.588623,601.681946 756.688110,606.540283 
	C753.401123,607.558533 750.393616,608.354492 746.947266,609.266602 
	C748.035950,610.703186 748.678894,611.551514 749.321838,612.399902 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M726.285889,608.738525 
	C724.690613,610.299927 723.095398,611.861389 721.265930,613.683533 
	C719.790710,614.630981 718.599487,615.438049 717.297241,615.976685 
	C715.283081,616.809753 713.193176,617.459595 710.709106,618.738525 
	C708.478821,619.483521 706.674866,619.677124 705.011475,619.631348 
	C705.377686,619.152100 705.622681,618.926392 705.814636,618.662170 
	C705.859558,618.600281 705.719604,618.290222 705.673645,618.292358 
	C700.793274,618.512695 695.913696,618.751892 690.899658,618.613831 
	C689.922119,617.867981 689.095337,617.222595 688.233337,617.171692 
	C680.651794,616.723999 673.064148,616.378967 665.477966,616.010803 
	C662.538086,615.868164 659.591309,615.553711 656.658020,615.646118 
	C646.096436,615.979065 635.539185,616.452209 625.085510,616.485474 
	C628.525696,615.731873 631.857971,615.336731 635.196716,615.007996 
	C637.636719,614.767822 640.516968,615.372070 642.444519,614.273132 
	C647.139771,611.596680 651.811218,610.626831 657.342163,611.945557 
	C660.118591,609.923767 662.552917,607.950134 665.299011,606.144409 
	C667.506531,608.598450 668.996887,612.126465 671.384460,612.905701 
	C676.002258,614.412903 681.125732,614.371033 686.405640,614.973816 
	C687.504822,614.978577 688.237976,614.980713 689.313538,614.991821 
	C690.112488,614.995544 690.569153,614.990356 691.489014,614.984985 
	C699.153137,615.777710 705.924866,614.153992 712.653564,611.710938 
	C715.702087,610.604065 717.035828,609.278259 717.163452,605.793762 
	C717.435364,598.364868 718.394653,590.961182 719.041504,583.884216 
	C721.875854,584.343018 723.891296,584.669250 725.993103,585.446289 
	C726.292847,589.946716 726.506287,593.996338 726.610291,598.399231 
	C726.478821,600.479309 726.456726,602.205994 726.299072,604.318115 
	C726.204346,606.048523 726.245117,607.393494 726.285889,608.738525 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M704.870911,619.870728 
	C706.674866,619.677124 708.478821,619.483521 710.627075,619.099365 
	C722.733398,618.608276 734.494202,618.209839 746.258057,618.063477 
	C750.077454,618.015991 754.880005,617.325623 753.497681,624.118835 
	C753.364197,624.774658 755.972717,626.133423 757.436096,626.931885 
	C759.191162,627.889587 761.083252,628.595886 762.953979,629.801025 
	C756.591003,630.377747 750.190063,630.564453 743.130371,630.875977 
	C742.077942,630.977661 741.684204,630.954651 741.165161,630.554993 
	C739.416809,629.570496 737.839478,628.739197 736.162354,628.396057 
	C730.793579,627.297607 725.387817,626.379944 719.834839,625.253418 
	C719.388916,624.986206 719.113342,625.003418 718.419067,625.138367 
	C714.675232,624.776978 711.359131,624.443115 707.692505,624.060120 
	C706.922119,625.011536 706.502258,626.012146 706.082397,627.012695 
	C705.523010,628.121033 704.963684,629.229370 703.931824,631.273926 
	C703.154968,628.281982 702.569641,626.027710 702.253784,623.208069 
	C703.305786,621.718689 704.088318,620.794739 704.870911,619.870728 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M749.573914,612.547119 
	C748.678894,611.551514 748.035950,610.703186 746.947266,609.266602 
	C750.393616,608.354492 753.401123,607.558533 757.158447,606.525879 
	C762.910217,606.220947 767.912292,606.152649 772.914307,606.084351 
	C772.918701,606.515686 772.923157,606.947021 772.962158,607.701294 
	C771.927673,608.360229 770.858643,608.696228 769.110962,609.044189 
	C767.512695,609.575378 766.593079,610.094604 765.673462,610.613831 
	C764.435669,610.815002 763.197815,611.016235 761.245422,611.168335 
	C759.588562,611.644226 758.646301,612.169189 757.704041,612.694092 
	C757.704041,612.694092 757.302979,612.759583 756.685303,612.794312 
	C754.355896,612.906128 752.644287,612.983154 750.932617,613.060181 
	C750.563721,612.938232 750.194885,612.816284 749.573914,612.547119 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M750.950806,613.470093 
	C752.644287,612.983154 754.355896,612.906128 756.490723,612.852966 
	C757.902466,617.151123 760.760437,613.219543 762.904236,614.269287 
	C763.184326,616.361206 763.070557,618.335632 762.953369,620.674683 
	C760.194641,618.507507 757.568176,615.840088 753.180359,616.778137 
	C752.672974,616.886658 751.716980,614.896912 750.950806,613.470093 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M534.242310,470.707581 
	C532.910767,470.441742 531.555603,469.875061 530.253052,469.977570 
	C528.066406,470.149628 525.913330,470.747681 523.153564,470.863281 
	C521.883179,469.236389 521.205383,467.912720 520.527588,466.589081 
	C521.534424,464.975952 522.541260,463.362854 523.538574,461.394775 
	C525.818665,460.140869 528.108215,459.241943 530.725769,458.195862 
	C534.081299,457.690552 537.108887,457.332367 540.614563,457.316650 
	C542.405945,458.429810 543.719238,459.200500 545.032532,459.971191 
	C545.032532,459.971191 545.023376,459.986389 545.028564,459.976135 
	C545.043335,461.132355 545.052856,462.298828 545.088135,464.257141 
	C544.984741,466.008087 544.855469,466.967194 544.500854,468.044769 
	C544.073364,468.435150 543.871399,468.707031 543.317139,468.999817 
	C540.865723,469.724365 538.766541,470.428070 536.356812,471.145203 
	C535.444946,471.008301 534.843628,470.857941 534.242310,470.707581 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M545.017822,459.587952 
	C543.719238,459.200500 542.405945,458.429810 541.042114,457.318604 
	C542.328735,457.720306 543.665955,458.462494 545.017822,459.587952 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M520.213806,466.616608 
	C521.205383,467.912720 521.883179,469.236389 522.727783,470.896729 
	C521.362427,471.367828 519.830261,471.502258 518.170166,471.354919 
	C518.041687,469.968079 518.041199,468.863007 518.031250,467.343781 
	C518.647766,466.834473 519.273926,466.739288 520.213806,466.616608 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M675.695374,366.983459 
	C675.946289,367.009888 676.197266,367.036316 676.831848,367.388733 
	C677.480469,368.436005 677.745422,369.157318 677.810669,370.195618 
	C673.869263,371.594604 670.127686,372.676636 666.150085,373.826935 
	C668.737244,375.396606 670.851624,376.679474 672.966003,377.962341 
	C672.966003,377.962372 673.074524,378.067627 673.087891,378.496063 
	C673.418579,380.643951 673.735901,382.363403 673.796875,384.345551 
	C671.832520,384.374817 669.405823,384.778748 668.538208,383.800232 
	C664.245361,378.958954 658.587463,379.389191 653.062012,379.067230 
	C652.772644,378.843933 652.483337,378.620605 652.139160,377.771301 
	C654.655334,376.001068 657.226257,374.856842 660.599121,373.355743 
	C659.073730,372.720795 658.199402,372.171234 657.255493,371.996063 
	C653.158325,371.235809 649.040894,370.584778 644.930786,369.894012 
	C644.628235,369.104187 644.325745,368.314362 644.153442,366.969421 
	C644.773193,366.211792 645.262756,366.009277 646.005127,366.084656 
	C649.933594,366.206757 653.609314,366.051025 657.700195,365.886444 
	C660.424561,365.951813 662.733704,366.026001 665.324890,366.391174 
	C668.969727,366.782593 672.332581,366.883026 675.695374,366.983459 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M644.524658,369.946564 
	C649.040894,370.584778 653.158325,371.235809 657.255493,371.996063 
	C658.199402,372.171234 659.073730,372.720795 660.599121,373.355743 
	C657.226257,374.856842 654.655334,376.001068 652.020569,377.519409 
	C649.799377,377.240875 647.641968,376.588196 644.895630,375.757355 
	C644.745117,376.785919 644.539368,378.191772 644.333679,379.597626 
	C643.222961,376.338409 637.432068,376.030792 639.121887,370.812683 
	C640.871155,370.350922 642.494873,370.175018 644.524658,369.946564 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M614.218262,359.681122 
	C616.293335,359.539124 618.304382,359.739502 621.098511,359.954590 
	C624.157715,360.459320 626.433838,360.949310 628.945801,361.687744 
	C630.287964,362.124207 631.394348,362.312134 632.597290,362.714783 
	C633.295349,363.788940 633.896790,364.648407 634.498230,365.507874 
	C628.865784,366.874603 616.292358,363.485046 614.218262,359.681122 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M634.811768,365.673096 
	C633.896790,364.648407 633.295349,363.788940 632.869629,362.492004 
	C633.567505,360.663788 634.089600,359.273102 634.611694,357.882385 
	C636.665588,362.587646 641.164307,362.298248 645.520142,363.415039 
	C645.840027,364.459625 645.796204,365.133240 645.752441,365.806824 
	C645.262756,366.009277 644.773193,366.211792 644.155640,366.648529 
	C641.060242,366.534607 638.092773,366.186462 634.811768,365.673096 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M622.994995,371.624176 
	C621.522888,371.595184 620.021606,371.202942 618.279175,370.598145 
	C620.197327,367.768707 621.674072,368.994049 622.994995,371.624176 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M634.715881,357.504883 
	C634.089600,359.273102 633.567505,360.663788 632.773071,362.277283 
	C631.394348,362.312134 630.287964,362.124207 629.010986,361.319946 
	C626.815918,358.052917 624.791565,355.402161 622.767273,352.751404 
	C624.259338,352.389465 625.751465,352.027496 627.606934,351.577423 
	C627.460693,351.054932 627.133118,349.884491 626.657166,348.391235 
	C627.791016,347.338959 629.073181,346.609467 630.747864,345.759460 
	C632.366882,349.468414 633.593445,353.297882 634.715881,357.504883 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M622.413208,352.896149 
	C624.791565,355.402161 626.815918,358.052917 628.775085,361.071472 
	C626.433838,360.949310 624.157715,360.459320 621.491821,359.893494 
	C621.421021,357.558746 621.740051,355.299805 622.413208,352.896149 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M619.169861,352.857422 
	C618.800171,352.427490 618.663513,351.856781 618.748291,351.140198 
	C619.114075,351.568451 619.258484,352.142548 619.169861,352.857422 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M608.362305,339.939758 
	C609.251587,340.897491 610.140808,341.855194 611.403625,343.272949 
	C612.828918,344.555115 613.880737,345.377258 615.089966,346.484009 
	C615.610413,347.556885 615.973450,348.345093 616.336487,349.133301 
	C614.155945,349.801788 611.975403,350.470306 609.390259,351.075500 
	C608.880676,350.223572 608.967529,348.844788 608.640808,348.738647 
	C605.736816,347.794952 606.955994,346.058716 607.649780,343.729187 
	C607.894470,342.024597 608.128357,340.982178 608.362305,339.939758 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M616.619812,349.104736 
	C615.973450,348.345093 615.610413,347.556885 615.221985,346.154846 
	C616.447937,344.074280 617.699341,342.607544 618.950684,341.140808 
	C623.895569,343.655579 617.937744,345.937286 618.703491,348.744812 
	C618.041565,349.108704 617.472351,349.092407 616.619812,349.104736 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M608.212158,339.614960 
	C608.128357,340.982178 607.894470,342.024597 607.480225,343.434845 
	C605.882568,344.546143 604.524048,345.497986 603.016785,345.923981 
	C602.464172,346.080200 601.559570,344.991119 600.703186,344.114441 
	C600.431641,343.053741 600.272400,342.348724 599.970215,341.402039 
	C601.339844,338.786896 602.852478,336.413483 604.727661,333.988403 
	C606.080811,335.721252 607.071472,337.505707 608.212158,339.614960 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M807.574829,552.984985 
	C809.279419,552.406616 810.660645,552.003662 812.031982,551.569519 
	C816.511169,550.151672 820.946228,549.141968 825.275879,552.805481 
	C826.729553,556.121582 828.229126,559.380859 828.851074,562.799805 
	C829.757324,567.781982 830.401917,572.511597 837.304749,571.543335 
	C837.442688,571.523987 837.737793,572.624695 837.945068,573.560669 
	C835.691711,574.983765 833.452881,576.049072 830.696472,576.770874 
	C830.126099,575.954529 830.073364,575.481689 830.197876,574.918457 
	C830.207336,574.583374 830.039490,574.338684 829.749023,573.799194 
	C829.146240,572.560486 828.665955,571.616577 828.162842,570.306580 
	C827.897827,568.708374 827.655701,567.476257 827.413635,566.244202 
	C826.905762,566.252380 826.397888,566.260498 825.890015,566.268616 
	C825.615906,567.850342 825.341797,569.432007 824.782349,571.271973 
	C822.962036,571.539917 821.427185,571.549500 819.921143,571.226807 
	C820.769470,569.937378 821.487122,568.863159 822.428040,568.045959 
	C824.875427,565.920227 824.059937,563.381348 822.215027,562.110474 
	C819.111145,559.972229 815.571960,558.159668 811.953308,557.171570 
	C809.035461,556.374878 805.715881,556.839111 802.609131,557.123047 
	C801.991760,557.179504 801.531677,558.956909 800.642212,559.956848 
	C799.154968,559.645508 798.024475,559.317993 796.898315,558.640991 
	C796.848816,557.829712 796.795044,557.367981 796.741211,556.906189 
	C800.243103,555.652832 803.744934,554.399475 807.574829,552.984985 
M832.391174,574.480957 
	C832.391174,574.480957 832.518616,574.401428 832.391174,574.480957 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M828.185730,570.672729 
	C828.665955,571.616577 829.146240,572.560486 829.751587,574.033203 
	C829.876648,574.562073 830.020569,575.008850 830.020569,575.008850 
	C830.073364,575.481689 830.126099,575.954529 830.347046,576.742798 
	C830.449280,577.723022 830.383301,578.387878 830.126953,579.658081 
	C830.193054,581.255737 830.449585,582.248047 830.706116,583.240417 
	C830.847290,583.920898 830.988464,584.601440 831.143433,585.629150 
	C829.635437,586.072876 828.113647,586.169250 826.331665,586.526917 
	C825.616272,586.863037 825.161072,586.937927 824.217285,586.752808 
	C823.793335,584.984070 823.858032,583.475281 824.192017,581.758057 
	C825.626709,578.745300 824.026489,578.967957 822.138550,579.380676 
	C820.436707,579.752686 818.711670,580.018677 816.879028,580.034180 
	C816.219971,579.455933 815.678589,579.175110 814.875366,578.635071 
	C813.429871,578.061340 812.246277,577.746704 811.302185,577.378052 
	C811.615234,576.862061 811.688721,576.400024 811.918701,575.650757 
	C813.400330,574.303589 814.725525,573.243713 816.441956,572.159912 
	C817.852905,571.943665 818.872620,571.751404 819.892334,571.559143 
	C821.427185,571.549500 822.962036,571.539917 825.173462,571.282227 
	C826.628540,570.913757 827.407166,570.793213 828.185730,570.672729 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M830.793091,582.829834 
	C830.449585,582.248047 830.193054,581.255737 830.134155,579.993713 
	C830.331787,579.724060 830.722534,579.771118 830.906433,579.842407 
	C831.020264,580.748840 830.950195,581.584106 830.793091,582.829834 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M573.955505,468.994324 
	C575.682495,468.660675 577.409546,468.327026 579.522339,468.343079 
	C579.841248,472.828308 583.420349,474.034637 586.055359,474.703979 
	C593.401428,476.570068 600.927185,477.818665 608.444885,478.854156 
	C610.230164,479.100067 612.261841,477.556885 614.413086,476.965576 
	C615.016663,477.474884 615.387390,477.855194 615.825928,478.583832 
	C616.966187,479.715240 618.038513,480.498291 619.096680,481.624817 
	C619.433655,483.264801 619.784790,484.561371 619.764709,485.962708 
	C617.502686,490.561523 614.184814,490.299438 610.372375,488.916901 
	C606.528687,487.523102 602.608459,486.340118 598.721802,485.064789 
	C598.536377,485.473175 598.350952,485.881531 598.165527,486.289917 
	C601.377747,489.245453 604.589905,492.201019 607.996338,495.429932 
	C606.665100,502.551392 614.038147,501.097046 616.569214,504.127075 
	C615.694031,504.891113 615.099304,505.410309 614.564819,505.876892 
	C617.615295,508.991791 620.327698,511.761444 622.953491,514.811218 
	C621.924988,515.035156 620.983093,514.978943 619.853027,514.647949 
	C612.768982,508.430969 605.873108,502.488831 599.160522,496.257751 
	C599.256348,495.228516 599.453796,494.186981 599.038086,493.793762 
	C593.854614,488.891144 589.834106,482.473053 582.057129,480.419678 
	C581.214966,479.510864 580.558655,478.832977 579.953369,477.780609 
	C577.988098,474.602203 575.971802,471.798248 573.955505,468.994324 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M614.180176,476.836609 
	C612.261841,477.556885 610.230164,479.100067 608.444885,478.854156 
	C600.927185,477.818665 593.401428,476.570068 586.055359,474.703979 
	C583.420349,474.034637 579.841248,472.828308 579.897827,468.289551 
	C580.783142,467.414581 581.678650,466.942841 583.154724,466.332031 
	C585.496277,466.429749 587.257263,466.666504 589.267273,467.175262 
	C592.184509,468.133179 594.852722,468.819092 597.828186,469.687988 
	C603.338196,471.789124 608.540955,473.707245 613.741028,475.645569 
	C613.738281,475.665771 613.727844,475.704559 613.839966,475.987946 
	C614.028076,476.459747 614.104126,476.648163 614.180176,476.836609 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M573.592651,468.973938 
	C575.971802,471.798248 577.988098,474.602203 579.620178,477.786011 
	C578.580078,478.037140 577.924194,477.908386 577.166138,477.477295 
	C576.367920,476.804352 575.671814,476.433777 574.796021,475.985107 
	C574.616272,475.907074 574.234558,475.818481 574.145630,475.511078 
	C573.374634,474.802002 572.692566,474.400330 571.833862,473.922546 
	C571.657227,473.846405 571.281006,473.766113 571.174377,473.468140 
	C570.380920,472.781067 569.694153,472.391937 568.688354,471.878235 
	C566.256531,470.793427 564.143677,469.833160 561.906860,468.590454 
	C560.867737,467.532990 559.952454,466.757965 559.037109,465.982971 
	C560.120728,465.015045 561.204407,464.047119 562.622314,463.013672 
	C564.583435,463.698364 566.285706,464.321472 567.817749,465.231262 
	C569.696228,466.346710 571.432251,467.701996 573.592651,468.973938 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M589.018311,466.903259 
	C587.257263,466.666504 585.496277,466.429749 583.365723,466.102478 
	C582.996155,466.011993 583.036926,465.514648 583.015259,465.266235 
	C583.582092,464.696747 584.170654,464.375641 585.394653,463.945190 
	C587.017761,464.232056 588.005432,464.628296 588.980103,465.338074 
	C588.984192,466.068848 589.001221,466.486084 589.018311,466.903259 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M576.928772,464.505676 
	C578.292419,464.298309 579.871826,464.349457 581.719299,464.676361 
	C580.373047,464.889465 578.758850,464.826813 576.928772,464.505676 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M558.695679,466.040894 
	C559.952454,466.757965 560.867737,467.532990 561.905151,468.973145 
	C563.698486,472.086609 565.369751,474.534943 567.041016,476.983276 
	C566.744812,477.359619 566.448669,477.735962 566.152527,478.112274 
	C559.770874,477.090515 553.389160,476.068726 547.010986,474.821075 
	C547.014526,474.595215 547.024536,474.143555 547.412476,474.089050 
	C550.298767,472.702698 552.797058,471.370819 555.295349,470.038940 
	C556.315002,468.725586 557.334656,467.412231 558.695679,466.040894 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M555.128235,469.750305 
	C552.797058,471.370819 550.298767,472.702698 547.095337,473.918335 
	C545.483276,472.194397 544.576355,470.586670 543.669434,468.978943 
	C543.871399,468.707031 544.073364,468.435150 544.861267,468.193634 
	C547.756348,468.665253 550.826233,473.318726 551.924438,467.054230 
	C552.936646,467.856720 553.948914,468.659210 555.128235,469.750305 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M551.677734,466.804443 
	C550.826233,473.318726 547.756348,468.665253 545.086670,468.075134 
	C544.855469,466.967194 544.984741,466.008087 545.144043,464.690491 
	C547.259766,465.072845 549.345459,465.813751 551.677734,466.804443 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M588.993103,465.024536 
	C588.005432,464.628296 587.017761,464.232056 585.712952,463.839600 
	C582.455750,461.999939 579.515686,460.156494 576.329834,458.082825 
	C576.289612,456.795837 576.495178,455.739014 576.880371,454.403503 
	C577.471680,454.135956 577.883362,454.147095 578.840759,454.323151 
	C583.875854,452.250244 585.452942,457.245361 588.693970,458.576233 
	C588.107056,460.994476 592.678894,462.836731 588.993103,465.024536 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M469.237946,586.261475 
	C469.072693,585.755981 469.143127,585.586426 469.632629,585.146423 
	C472.469391,583.715942 474.887115,582.555908 477.304810,581.395874 
	C478.490997,580.850830 479.677185,580.305786 481.343018,579.390503 
	C482.104767,578.056396 482.386841,577.092529 483.014038,576.186890 
	C484.252472,576.492004 485.145813,576.738953 485.977539,577.376587 
	C484.694672,581.567993 483.747894,585.284119 488.824219,587.079773 
	C488.844147,587.006714 488.829102,587.155273 488.839142,587.499329 
	C488.916168,588.529175 488.983124,589.214905 488.676270,589.925659 
	C487.095490,589.792236 485.888580,589.633911 484.681641,589.475525 
	C479.612305,588.516174 474.542969,587.556763 469.237946,586.261475 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M497.500366,594.540161 
	C497.152100,594.481750 496.803833,594.423401 496.203613,594.445984 
	C494.736511,593.942932 493.521271,593.358948 491.976990,592.399597 
	C491.333374,591.511047 491.018799,590.997925 490.793671,590.126709 
	C490.198425,588.897522 489.513763,588.026428 488.829102,587.155273 
	C488.829102,587.155273 488.844147,587.006714 488.899109,586.664062 
	C488.980194,585.482605 489.006317,584.643799 489.354065,583.910645 
	C490.777954,584.186218 491.880188,584.356079 493.072144,584.874207 
	C494.959656,587.492371 496.850281,589.699585 498.466278,592.092346 
	C498.743835,592.503296 497.851990,593.704163 497.500366,594.540161 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M490.704254,590.484741 
	C491.018799,590.997925 491.333374,591.511047 491.647888,592.328613 
	C489.407349,591.794861 487.166779,590.956665 484.803955,589.796997 
	C485.888580,589.633911 487.095490,589.792236 488.937439,590.066528 
	C489.949738,590.283142 490.326996,590.383972 490.704254,590.484741 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M876.706665,547.301697 
	C876.785278,547.878723 876.568909,548.341248 876.106445,548.920410 
	C875.625549,548.610352 875.390686,548.183716 875.156616,547.506958 
	C875.575500,547.233643 875.993530,547.210449 876.706665,547.301697 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M653.049316,379.473694 
	C658.587463,379.389191 664.245361,378.958954 668.538208,383.800232 
	C669.405823,384.778748 671.832520,384.374817 674.125427,384.346222 
	C675.148132,384.737549 675.585938,385.390900 675.742676,386.253845 
	C675.096497,388.086456 674.731384,389.709503 674.366272,391.332550 
	C674.366272,391.332550 674.381592,391.196960 674.063721,391.243408 
	C673.511963,391.586456 673.278198,391.883026 673.044373,392.179626 
	C671.962036,392.400055 670.879761,392.620483 669.432861,392.425049 
	C668.111145,390.334351 667.439575,387.560272 666.135437,387.221558 
	C663.683411,386.584625 660.702209,386.898010 658.283325,387.767914 
	C657.346191,388.104889 657.337952,391.024689 656.906494,392.767883 
	C656.224487,392.770325 655.542480,392.772766 654.528564,392.699341 
	C649.296509,388.786499 652.369202,384.223877 653.049316,379.473694 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M651.752319,393.052917 
	C653.408997,395.040405 654.952576,396.898773 656.275635,398.902771 
	C657.251038,400.380219 657.701172,401.889771 654.283203,400.814392 
	C649.673035,396.909454 645.647766,393.232788 641.753418,389.057281 
	C641.943665,388.219757 642.002869,387.881073 642.062134,387.542389 
	C642.478394,387.595154 642.894775,387.647949 643.789795,388.039551 
	C646.549988,389.892639 648.831543,391.406921 651.113098,392.921173 
	C651.113098,392.921173 651.544556,392.986267 651.752319,393.052917 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M641.833008,387.269287 
	C642.002869,387.881073 641.943665,388.219757 641.365234,388.974274 
	C635.613892,389.907196 630.381775,390.424286 625.149597,390.941376 
	C624.925049,390.383209 624.700439,389.825073 624.259521,389.051300 
	C624.139099,388.521820 624.218994,387.939453 624.333130,387.932678 
	C630.088196,387.589508 635.846191,387.295532 641.833008,387.269287 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M651.054749,392.575928 
	C648.831543,391.406921 646.549988,389.892639 644.103394,388.066040 
	C645.365051,387.717560 647.230591,387.105896 648.115479,387.780518 
	C649.438049,388.788727 650.070435,390.702271 651.054749,392.575928 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M645.420898,381.545410 
	C645.365906,381.460846 645.584290,381.427094 645.584290,381.427094 
	C645.584290,381.427094 645.475830,381.629944 645.420898,381.545410 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M825.987122,497.626709 
	C822.819824,497.224670 821.775208,494.983185 821.767334,491.621155 
	C823.156555,493.253174 824.573914,495.261047 825.987122,497.626709 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M618.614746,341.048401 
	C617.699341,342.607544 616.447937,344.074280 615.064575,345.870209 
	C613.880737,345.377258 612.828918,344.555115 611.700684,343.330994 
	C612.072876,341.049164 612.521484,339.169342 613.193726,336.352325 
	C615.312927,338.270905 616.795837,339.613434 618.614746,341.048401 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M617.854370,330.372192 
	C619.664062,329.637268 621.510559,329.284760 624.420654,328.729248 
	C623.508301,331.484161 622.887817,333.357697 622.119019,335.564209 
	C620.610962,334.182983 619.251099,332.468811 617.854370,330.372192 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M699.140869,359.721863 
	C691.287720,359.829102 691.098999,359.686066 691.716064,353.620087 
	C693.603271,354.372711 695.277100,355.040222 697.920593,356.094452 
	C697.079468,353.812561 696.338135,351.801392 695.520386,349.413757 
	C697.656982,349.405823 699.870117,349.774384 702.425659,350.199951 
	C703.886414,353.864197 703.572266,357.221741 699.140869,359.721863 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M693.041016,430.977051 
	C693.114929,429.428589 693.098816,427.869293 693.283264,426.334137 
	C693.581116,423.855682 693.289368,420.744019 697.760620,421.830688 
	C699.055603,425.398224 700.037903,428.813171 701.136169,432.599670 
	C702.690308,438.386536 704.140564,443.798676 705.554993,449.220154 
	C705.799377,450.156952 705.892273,451.133209 705.901611,452.823914 
	C704.293396,457.399780 703.034668,461.335327 701.288757,465.041290 
	C700.578003,466.549957 698.808960,467.560059 697.425659,468.384644 
	C697.214661,465.984100 697.099487,463.995911 697.182251,461.862244 
	C697.464783,461.243439 697.549255,460.770050 697.750305,459.913940 
	C697.671448,458.020905 697.476013,456.510559 697.237549,454.736572 
	C697.153137,454.299622 697.111755,454.126404 697.119324,453.533234 
	C697.168274,450.980377 697.168274,448.847443 697.168274,446.703369 
	C695.010132,447.562378 693.644836,448.105804 692.285889,448.242249 
	C692.541870,442.215851 692.791443,436.596466 693.041016,430.977051 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M712.437988,468.451385 
	C711.987549,464.798492 711.537048,461.145599 711.386230,457.136536 
	C713.977844,458.934509 716.269775,461.088623 719.175720,463.819824 
	C720.329041,461.587860 721.244446,459.816254 722.488159,457.979858 
	C724.904236,456.250458 725.990112,452.036804 729.996704,455.417816 
	C730.605774,456.266418 731.159973,456.811737 731.709839,457.559021 
	C731.705505,457.760956 731.785828,458.156860 731.611694,458.429993 
	C728.624084,461.806213 725.810669,464.909271 722.599487,467.995422 
	C721.429138,468.118713 720.656433,468.258911 719.504395,468.328613 
	C716.895935,468.322540 714.666992,468.386963 712.437988,468.451385 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M696.984253,462.007690 
	C697.099487,463.995911 697.214661,465.984100 697.278198,468.719879 
	C697.224915,469.951172 697.223389,470.434875 696.925781,471.036530 
	C696.087219,471.770447 695.544861,472.386444 694.704102,473.000885 
	C693.877014,473.083405 693.348389,473.167450 692.819702,473.251526 
	C692.888550,473.463959 692.957397,473.676392 693.026245,473.888824 
	C693.683594,473.591736 694.341003,473.294617 695.391846,473.001221 
	C696.574158,472.957855 697.362976,472.910797 698.453308,472.848969 
	C699.163330,472.457855 699.571716,472.081543 700.335083,471.743896 
	C701.392761,471.702515 702.095337,471.622498 702.797974,471.542480 
	C703.913147,471.738464 705.028259,471.934418 706.741943,472.342834 
	C707.675415,472.546234 708.010376,472.537170 708.566528,472.580353 
	C708.787720,472.632599 709.208252,472.804932 709.159790,473.160126 
	C709.160889,474.209839 709.210388,474.904388 708.826538,475.709717 
	C701.591675,476.191772 694.790222,476.563049 688.000061,476.572998 
	C688.998047,474.797729 689.984680,473.383789 691.210938,471.956177 
	C691.450500,471.942505 691.929016,471.914276 692.228882,471.915894 
	C692.694092,471.273163 692.859436,470.628815 693.026489,469.522461 
	C693.045593,465.058807 693.063049,461.057220 693.293823,457.031799 
	C693.507141,457.007965 693.933167,456.954620 694.252014,456.977173 
	C694.994080,457.022614 695.417236,457.045441 695.889343,457.426514 
	C695.979736,458.506622 696.021240,459.228485 695.825317,460.120605 
	C696.053345,460.863098 696.518799,461.435394 696.984253,462.007690 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M713.841919,443.317505 
	C715.712463,443.351074 717.434753,444.038025 719.129700,443.976135 
	C722.896118,443.838531 726.648804,443.325897 731.003723,442.993286 
	C731.881531,443.210083 732.105957,443.452362 732.229797,444.097778 
	C732.838013,444.895782 733.490173,445.343201 734.155151,445.812439 
	C734.168030,445.834320 734.217896,445.843872 734.165771,446.266174 
	C733.740234,448.830200 733.366943,450.971863 732.664673,453.082550 
	C731.895996,453.026276 731.456299,453.000977 730.836060,452.708099 
	C728.082153,451.447876 725.518127,450.429626 722.932373,449.470215 
	C720.635437,448.617950 718.314331,447.830994 716.003845,447.015198 
	C713.572021,447.024841 712.309448,446.231995 713.841919,443.317505 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M697.221924,470.918610 
	C697.223389,470.434875 697.224915,469.951172 697.373901,469.132202 
	C698.808960,467.560059 700.578003,466.549957 701.288757,465.041290 
	C703.034668,461.335327 704.293396,457.399780 705.943420,453.228149 
	C710.653198,454.306061 709.421448,458.205688 708.611877,460.733337 
	C707.450500,464.359253 704.927979,467.549164 702.892273,471.233856 
	C702.095337,471.622498 701.392761,471.702515 700.244385,471.538696 
	C699.191589,471.144440 698.584656,470.993988 697.788452,470.859528 
	C697.599182,470.875549 697.221863,470.918610 697.221924,470.918610 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M722.159851,458.044617 
	C721.244446,459.816254 720.329041,461.587860 719.175720,463.819824 
	C716.269775,461.088623 713.977844,458.934509 711.332520,456.663940 
	C712.247559,454.848999 713.515930,453.150543 715.233154,451.249817 
	C717.796326,452.968689 719.910522,454.889862 722.017517,457.123901 
	C722.060181,457.639435 722.109985,457.842041 722.159851,458.044617 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M722.488159,457.979858 
	C722.109985,457.842041 722.060181,457.639435 722.027649,456.774048 
	C723.152954,452.222351 721.279480,450.013702 715.961243,448.939087 
	C715.979370,448.511597 715.997559,448.084137 716.009766,447.335938 
	C718.314331,447.830994 720.635437,448.617950 722.932373,449.470215 
	C725.518127,450.429626 728.082153,451.447876 730.716553,452.982727 
	C730.498962,454.054810 730.220398,454.584686 729.941772,455.114532 
	C725.990112,452.036804 724.904236,456.250458 722.488159,457.979858 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M709.208252,472.804932 
	C709.208252,472.804932 708.787720,472.632599 708.453125,472.346924 
	C707.748169,471.708527 707.377747,471.355774 707.007324,471.002991 
	C708.568298,470.253662 710.129272,469.504333 712.064087,468.603210 
	C714.666992,468.386963 716.895935,468.322540 719.505493,468.587097 
	C719.900513,469.261047 719.914856,469.606049 719.565430,470.014801 
	C715.870544,470.987366 712.539368,471.896149 709.208252,472.804932 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M715.729248,449.086365 
	C721.279480,450.013702 723.152954,452.222351 722.034851,456.461182 
	C719.910522,454.889862 717.796326,452.968689 715.362366,450.909576 
	C715.194214,450.258942 715.345703,449.746277 715.729248,449.086365 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M707.002563,471.293762 
	C707.377747,471.355774 707.748169,471.708527 708.231995,472.294678 
	C708.010376,472.537170 707.675415,472.546234 707.168213,472.360687 
	C706.996643,471.972229 706.997253,471.778381 707.002563,471.293762 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M732.273682,443.747162 
	C732.105957,443.452362 731.881531,443.210083 731.389954,442.886047 
	C731.780212,441.459686 732.380798,440.167694 733.318298,438.783997 
	C734.742065,440.905396 735.828979,443.118439 736.542480,445.699341 
	C735.518616,445.992798 734.868286,445.918335 734.217896,445.843872 
	C734.217896,445.843872 734.168030,445.834320 734.105591,445.518311 
	C733.453369,444.717224 732.863525,444.232208 732.273682,443.747162 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M732.964539,466.071136 
	C736.327393,464.692352 739.690186,463.313568 743.543945,462.327118 
	C747.651367,462.742920 751.267883,462.766388 754.888794,463.153778 
	C753.102905,465.387939 751.946411,467.441895 748.296509,466.915039 
	C745.377930,466.493744 742.100891,468.556061 738.558594,469.650360 
	C736.084900,469.870239 734.035400,469.955811 732.003906,469.693481 
	C732.336060,468.254059 732.650330,467.162598 732.964539,466.071136 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M754.884338,462.789795 
	C751.267883,462.766388 747.651367,462.742920 743.907166,462.326447 
	C747.663269,460.183258 751.547119,458.433044 755.684570,456.436371 
	C758.019470,455.312378 760.100830,454.434875 762.560181,453.475403 
	C762.390686,454.701660 761.843262,456.009949 760.800720,457.729187 
	C758.498474,459.690033 756.691406,461.239929 754.884338,462.789795 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M733.008545,465.681152 
	C732.650330,467.162598 732.336060,468.254059 731.875427,469.812256 
	C731.729004,470.279022 731.388062,470.359467 730.958984,470.248352 
	C727.384338,470.106384 724.238770,470.075531 721.402344,469.979340 
	C722.140015,469.280121 722.568604,468.646240 722.997192,468.012329 
	C725.810669,464.909271 728.624084,461.806213 731.963318,458.361084 
	C733.353943,457.654877 734.218811,457.290741 735.083740,456.926636 
	C734.406677,459.714844 733.729614,462.503021 733.008545,465.681152 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M735.071899,456.550232 
	C734.218811,457.290741 733.353943,457.654877 732.137451,458.087952 
	C731.785828,458.156860 731.705505,457.760956 731.823792,457.191132 
	C732.292603,455.452057 732.643127,454.282776 732.993652,453.113525 
	C733.366943,450.971863 733.740234,448.830200 734.165771,446.266174 
	C734.868286,445.918335 735.518616,445.992798 736.493286,446.101410 
	C739.815308,450.109100 734.687134,452.659851 735.071899,456.550232 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M754.888794,463.153748 
	C756.691406,461.239929 758.498474,459.690033 760.633850,458.056366 
	C761.740112,458.314819 762.518188,458.657013 763.634277,459.017822 
	C763.513550,460.508423 763.054749,461.980408 762.027771,463.733002 
	C760.662170,464.371857 759.864746,464.730103 758.734619,465.092896 
	C757.934143,465.736877 757.466309,466.376312 756.791687,467.274261 
	C753.840149,469.403046 751.095459,471.273346 748.246704,472.792786 
	C746.194031,468.008667 742.016541,470.552734 738.982910,469.516052 
	C742.100891,468.556061 745.377930,466.493744 748.296509,466.915039 
	C751.946411,467.441895 753.102905,465.387939 754.888794,463.153748 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M750.768433,451.875366 
	C750.587219,451.859283 750.482178,451.472565 750.528992,450.925842 
	C750.735352,451.012115 750.789917,451.258392 750.768433,451.875366 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M604.168091,653.668213 
	C602.540649,653.926270 601.101379,653.872375 599.005066,653.777832 
	C595.207703,652.466919 592.632263,652.968079 590.513306,656.085083 
	C588.687256,655.973511 587.247437,655.889160 585.781250,655.803284 
	C586.669678,651.264648 584.305725,649.747314 578.894409,651.157654 
	C578.234863,650.392334 577.575317,649.626953 576.906860,648.531372 
	C581.971802,647.797791 587.059143,646.956604 592.115784,647.112122 
	C597.345520,647.272949 602.554443,648.345398 607.748596,649.169739 
	C608.495239,649.288269 609.107910,650.251526 609.944763,650.961304 
	C607.806824,651.877563 606.081543,652.616943 604.168091,653.668213 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M590.899597,656.112244 
	C592.632263,652.968079 595.207703,652.466919 598.699341,653.874756 
	C599.624329,655.124878 600.198059,656.237488 600.922485,657.963257 
	C602.001221,659.754395 602.929260,660.932373 603.857361,662.110352 
	C603.857361,662.110352 604.023010,662.491516 604.125244,662.672607 
	C605.804321,666.661926 607.517456,670.423218 608.860779,674.312134 
	C609.190979,675.268127 608.314941,676.640747 607.678589,677.722412 
	C606.329041,676.324524 605.294312,675.026123 604.085205,673.090576 
	C599.598389,667.646973 595.286011,662.840332 590.963135,657.712646 
	C590.934875,656.965088 590.917236,656.538635 590.899597,656.112244 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M590.973633,658.033752 
	C595.286011,662.840332 599.598389,667.646973 603.906006,672.827942 
	C596.002502,668.024963 588.103699,662.847595 580.502686,657.322144 
	C582.710876,657.310730 584.611145,657.737793 586.535095,657.954529 
	C587.999023,658.119446 589.493042,658.017151 590.973633,658.033752 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M590.963135,657.712646 
	C589.493042,658.017151 587.999023,658.119446 586.535095,657.954529 
	C584.611145,657.737793 582.710876,657.310730 580.377014,657.005737 
	C579.953613,657.037415 579.973389,657.012024 579.872925,656.755554 
	C579.491150,656.247131 579.209717,655.995239 578.928345,655.743286 
	C578.926086,654.465759 578.923828,653.188171 578.907959,651.534180 
	C584.305725,649.747314 586.669678,651.264648 585.781250,655.803284 
	C587.247437,655.889160 588.687256,655.973511 590.513306,656.085083 
	C590.917236,656.538635 590.934875,656.965088 590.963135,657.712646 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M578.988159,656.039978 
	C579.209717,655.995239 579.491150,656.247131 579.878174,656.760742 
	C579.671936,656.793884 579.359924,656.565247 578.988159,656.039978 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M646.005127,366.084656 
	C645.796204,365.133240 645.840027,364.459625 645.929626,363.453308 
	C646.804871,359.575562 647.825195,356.059845 648.376465,352.472076 
	C648.620972,350.880463 647.917542,349.143219 647.373169,347.255890 
	C646.788574,344.628296 646.475281,342.216919 646.143555,339.409729 
	C649.925720,343.626495 653.726379,348.239075 657.527039,352.851654 
	C658.047424,352.728912 658.567871,352.606171 659.088318,352.483429 
	C658.651123,349.187073 658.213989,345.890717 657.888123,342.281250 
	C660.666931,342.234161 663.334412,342.500153 666.705688,342.978943 
	C667.409546,346.388641 667.409546,349.585510 667.409546,352.782410 
	C667.869934,352.930511 668.330322,353.078644 668.790710,353.226776 
	C670.539062,350.801758 672.102966,348.211304 674.087891,345.998535 
	C676.050415,343.810822 678.438110,342.004486 680.705811,340.472290 
	C680.795959,343.278809 680.819580,345.643860 680.553650,348.173218 
	C677.146545,352.980560 674.028931,357.623657 670.574219,362.404999 
	C666.379578,362.618256 662.517944,362.609528 658.666260,362.800842 
	C656.489380,362.908966 655.608521,363.910400 657.285034,365.895264 
	C653.609314,366.051025 649.933594,366.206757 646.005127,366.084656 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M680.639282,340.030823 
	C678.438110,342.004486 676.050415,343.810822 674.087891,345.998535 
	C672.102966,348.211304 670.539062,350.801758 668.790710,353.226776 
	C668.330322,353.078644 667.869934,352.930511 667.409546,352.782410 
	C667.409546,349.585510 667.409546,346.388641 667.110474,342.879211 
	C673.275818,342.319458 674.682617,336.857605 677.379211,332.587494 
	C677.982361,331.632294 679.884094,331.497162 681.570923,331.015106 
	C681.962402,331.465149 681.971008,331.884583 681.668701,332.895813 
	C681.118225,335.668671 680.878723,337.849731 680.639282,340.030823 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M478.802612,541.964355 
	C477.701813,542.302795 475.859009,543.201782 475.621857,542.887878 
	C474.191589,540.994446 473.128967,538.823364 472.209900,536.174500 
	C472.676422,534.765869 472.876343,533.927795 473.076294,533.089783 
	C473.656708,531.921875 474.237091,530.753967 475.051514,529.099487 
	C475.435638,528.098999 475.585724,527.585205 475.735779,527.071350 
	C476.767883,525.611206 477.799988,524.151123 478.827271,522.347229 
	C479.548340,520.580505 480.274200,519.157593 481.061157,517.400330 
	C482.111267,516.348022 483.100342,515.630005 484.535461,515.226990 
	C485.567078,516.905457 486.159576,518.265930 486.737000,519.632812 
	C488.924408,524.811157 491.554077,525.484436 496.288940,522.181030 
	C497.081421,522.963623 497.618500,523.573059 497.992798,524.466370 
	C496.218811,526.525452 494.607605,528.300659 492.651611,530.104736 
	C490.536346,532.395142 488.765900,534.656616 486.643738,536.988647 
	C483.795532,538.694214 481.299072,540.329285 478.802612,541.964355 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M496.033600,522.007751 
	C491.554077,525.484436 488.924408,524.811157 486.737000,519.632812 
	C486.159576,518.265930 485.567078,516.905457 484.850464,515.169678 
	C486.266052,509.616638 490.335205,506.151550 495.584839,505.836487 
	C496.355072,507.275940 496.727020,508.762848 497.107178,511.044312 
	C496.821808,514.565857 496.528168,517.292969 496.168945,520.347534 
	C496.080078,521.119263 496.056824,521.563477 496.033600,522.007751 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M486.039154,576.985962 
	C485.145813,576.738953 484.252472,576.492004 482.719635,576.142517 
	C481.680817,576.057861 481.281494,576.075806 480.882172,576.093750 
	C475.086212,574.182068 469.290253,572.270325 463.348785,569.950439 
	C463.820190,569.133423 464.437164,568.724487 465.054108,568.315552 
	C468.366669,568.148193 471.679260,567.980774 475.791901,567.877441 
	C477.326660,567.606384 478.061310,567.271301 478.795990,566.936218 
	C477.860413,566.283508 476.924866,565.630737 475.989288,564.978027 
	C474.928101,564.178772 473.866913,563.379456 472.726257,562.042664 
	C472.858154,560.789490 473.069489,560.073914 473.280823,559.358276 
	C473.280853,559.358276 473.082245,559.331726 473.550659,559.303223 
	C476.847992,558.299255 480.324432,558.105957 480.810425,553.982178 
	C482.314606,555.560486 483.392151,557.176453 484.717834,558.553528 
	C485.920013,559.802307 487.392487,560.790894 488.813782,562.288696 
	C488.914581,565.498474 488.947327,568.313599 488.837280,571.425659 
	C489.766998,573.133606 490.839508,574.544678 491.811371,576.243896 
	C490.975922,577.364441 490.241089,578.196777 489.351013,578.686096 
	C488.143585,577.890686 487.091370,577.438354 486.039154,576.985962 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M488.745758,561.894165 
	C487.392487,560.790894 485.920013,559.802307 484.717834,558.553528 
	C483.392151,557.176453 482.314606,555.560486 480.650787,553.764038 
	C479.885040,553.287537 479.597412,553.095642 479.309784,552.903809 
	C478.809448,551.618713 478.309143,550.333618 477.686157,548.727173 
	C478.763916,546.612122 479.964355,544.818481 481.549194,542.591919 
	C483.635620,541.093079 485.337555,540.027161 487.351776,538.825928 
	C489.117554,538.127625 490.571045,537.564514 492.028595,537.403687 
	C491.971680,540.196045 491.910706,542.586121 491.852966,545.367065 
	C490.819397,551.136658 489.782593,556.515381 488.745758,561.894165 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M476.899963,581.301819 
	C474.887115,582.555908 472.469391,583.715942 469.694092,584.877930 
	C468.582611,583.802795 467.828705,582.725647 466.989532,581.328613 
	C470.101227,581.075073 473.298187,581.141418 476.899963,581.301819 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M464.846802,567.978760 
	C464.437164,568.724487 463.820190,569.133423 463.036530,569.773315 
	C460.944183,569.380859 459.018555,568.757263 457.092926,568.133728 
	C457.235229,567.563660 457.377533,566.993591 457.519836,566.423462 
	C459.893036,566.829651 462.266266,567.235779 464.846802,567.978760 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M478.993927,553.086304 
	C479.597412,553.095642 479.885040,553.287537 480.332275,553.697632 
	C480.324432,558.105957 476.847992,558.299255 473.650513,559.294983 
	C475.080658,557.299744 476.879364,555.284241 478.993927,553.086304 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M468.216736,536.972656 
	C469.232849,537.797485 470.165375,539.018005 471.140259,540.580139 
	C470.166077,539.737244 469.149597,538.552795 468.216736,536.972656 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M475.977905,565.316772 
	C476.924866,565.630737 477.860413,566.283508 478.795990,566.936218 
	C478.061310,567.271301 477.326660,567.606384 476.236023,567.807129 
	C475.908875,567.000427 475.937714,566.328003 475.977905,565.316772 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M472.938477,559.269043 
	C473.069489,560.073914 472.858154,560.789490 472.460754,561.840637 
	C471.626831,561.816162 470.978943,561.456116 470.331055,561.096069 
	C471.086060,560.457336 471.841095,559.818542 472.938477,559.269043 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M471.310669,541.850952 
	C472.298706,542.399902 473.190979,543.327698 474.120514,544.588379 
	C473.176849,544.024109 472.195892,543.126892 471.310669,541.850952 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M480.908020,576.478638 
	C481.281494,576.075806 481.680817,576.057861 482.374512,576.084351 
	C482.386841,577.092529 482.104767,578.056396 481.496033,579.096191 
	C481.090881,578.402588 481.012390,577.633057 480.908020,576.478638 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M465.940338,579.764832 
	C465.227417,579.720276 464.487671,579.308472 463.680054,578.556335 
	C464.379242,578.609863 465.146393,579.003723 465.940338,579.764832 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M475.120911,546.996948 
	C475.426361,546.951111 475.679932,547.162781 475.995422,547.618408 
	C475.727936,547.659729 475.398499,547.457031 475.120911,546.996948 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M487.039490,538.961182 
	C485.337555,540.027161 483.635620,541.093079 481.595093,542.221191 
	C480.713593,542.200012 480.170685,542.116699 479.215210,541.998901 
	C481.299072,540.329285 483.795532,538.694214 486.647369,537.330505 
	C487.014984,538.054932 487.027252,538.508118 487.039490,538.961182 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M472.887177,532.885254 
	C472.876343,533.927795 472.676422,534.765869 472.213562,535.804321 
	C472.199799,534.896790 472.448944,533.788757 472.887177,532.885254 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M475.486694,527.090576 
	C475.585724,527.585205 475.435638,528.098999 475.023987,528.722046 
	C474.920776,528.257385 475.079193,527.683655 475.486694,527.090576 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M747.267456,648.780518 
	C746.970947,647.849182 746.988464,646.814758 747.002930,645.390137 
	C748.089722,644.691162 749.179504,644.382263 750.607178,644.038208 
	C749.823914,645.561157 748.702698,647.119324 747.267456,648.780518 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M740.368713,652.967773 
	C741.433472,652.001831 742.835022,651.009399 744.616699,650.034668 
	C743.566406,651.015320 742.135986,651.978333 740.368713,652.967773 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M735.285217,656.891663 
	C736.058533,655.930115 737.163330,654.971680 738.626099,654.020935 
	C737.861572,654.984009 736.739136,655.939392 735.285217,656.891663 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M735.939880,639.566284 
	C726.123535,641.298523 716.056458,641.596863 706.778137,644.455444 
	C695.121948,648.046692 683.317627,645.809937 671.348389,647.836792 
	C669.012085,647.807861 667.063232,647.613342 665.114380,647.418762 
	C665.080444,646.807800 665.046570,646.196899 665.012695,645.585938 
	C678.515686,642.932373 692.018677,640.278809 706.138794,637.311646 
	C707.495239,636.985474 708.234558,636.972900 709.187134,637.229370 
	C710.610657,637.270508 711.820923,637.042419 713.415161,636.764771 
	C715.882874,636.441833 717.966736,636.168457 720.510498,635.949951 
	C726.215881,635.903809 731.461365,635.802734 736.995422,635.809937 
	C737.486511,635.953613 737.688904,635.989014 737.891357,636.024414 
	C737.273987,637.105957 736.656677,638.187500 735.939880,639.566284 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M738.242676,635.983398 
	C737.688904,635.989014 737.486511,635.953613 737.042297,635.477417 
	C738.297180,633.668213 739.793823,632.299927 741.290527,630.931641 
	C741.684204,630.954651 742.077942,630.977661 742.760620,631.074951 
	C741.564392,632.746826 740.079224,634.344604 738.242676,635.983398 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M641.430664,644.924377 
	C640.497375,644.653137 639.975403,644.399719 638.714600,644.145630 
	C635.040466,644.189331 632.105042,644.233826 629.169617,644.278320 
	C626.891724,644.489502 624.594421,645.005676 622.339111,644.862061 
	C608.550476,643.983948 594.771423,642.956177 580.600464,641.947144 
	C579.802368,641.177429 579.392883,640.438904 578.922363,639.038025 
	C578.944092,637.626099 579.026855,636.876587 579.109619,636.127075 
	C581.389832,636.057617 583.670105,635.988220 586.788696,636.033936 
	C590.969055,636.242737 594.310974,636.336365 597.652954,636.429993 
	C608.385132,637.943176 619.117004,639.459167 629.849731,640.968384 
	C636.334961,641.880432 642.821777,642.782104 649.657227,644.230469 
	C650.019226,645.530090 650.031921,646.287659 650.044556,647.045288 
	C647.310364,646.344299 644.576111,645.643311 641.430664,644.924377 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M597.786987,636.155579 
	C594.310974,636.336365 590.969055,636.242737 587.210815,635.971436 
	C588.330322,631.064880 592.331543,633.569031 595.701965,632.920776 
	C598.352905,632.945984 599.467773,633.614258 597.786987,636.155579 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M578.785156,635.982971 
	C579.026855,636.876587 578.944092,637.626099 578.793091,638.749390 
	C578.161865,638.291199 577.598999,637.459290 577.044556,636.275513 
	C577.522217,635.895386 577.991394,635.867126 578.785156,635.982971 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M577.121338,635.354370 
	C576.494263,635.297791 575.780396,634.953918 575.056885,634.261719 
	C575.709717,634.297913 576.372070,634.682495 577.121338,635.354370 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M562.029419,605.196411 
	C553.959290,604.817139 545.869995,604.665771 537.824951,603.988770 
	C530.981079,603.412781 523.049561,591.737061 524.997192,584.954163 
	C525.313843,583.428467 525.626831,582.612549 526.254883,581.796997 
	C527.108459,580.943542 527.646912,580.089783 528.551575,579.148376 
	C532.621216,582.041321 536.324585,585.021851 540.036621,588.349304 
	C541.367493,590.039307 542.689636,591.382385 544.011841,592.725464 
	C544.338135,592.461060 544.664429,592.196716 544.990723,591.932312 
	C543.895935,586.206543 542.801208,580.480835 541.615173,574.277527 
	C540.112183,574.277527 537.855591,574.277527 535.372559,574.043457 
	C533.093262,573.515930 531.040344,573.222534 528.945862,572.579956 
	C526.776978,570.612976 524.649597,568.995178 522.737427,567.128418 
	C523.540894,566.597046 524.129089,566.314514 525.074097,566.297363 
	C526.287354,566.737732 527.143860,566.912659 528.292236,567.295166 
	C530.173096,568.279419 531.762024,569.721497 533.350952,569.721497 
	C541.857849,569.721375 550.364746,569.361633 558.917969,569.512695 
	C560.459045,576.907471 561.953796,583.915649 563.392517,591.279968 
	C564.204773,593.028015 566.032227,594.654236 565.760315,595.767639 
	C564.965698,599.020264 563.333374,602.068237 562.029419,605.196411 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M510.890259,575.035583 
	C513.019348,578.094116 515.148438,581.152649 516.985718,584.599792 
	C515.911255,585.751282 515.128662,586.514160 514.313232,587.614746 
	C515.219177,590.312378 516.157898,592.672363 517.043945,595.364685 
	C516.949158,596.143921 516.907043,596.590881 516.533142,597.038208 
	C515.539001,597.000732 514.876709,596.962769 514.011841,596.666443 
	C513.129089,595.348206 512.448730,594.288391 511.848480,592.872192 
	C513.595825,589.014404 511.701477,587.367249 508.692535,586.300049 
	C507.140961,585.749695 505.502197,585.365356 504.058838,584.608521 
	C501.893890,583.473145 499.866180,582.076111 497.861938,580.483765 
	C498.679993,579.164185 499.416229,578.151245 500.545776,577.148132 
	C501.937225,576.620178 502.935394,576.082397 503.933533,575.544617 
	C503.074310,574.736450 502.307983,573.776123 501.322723,573.174438 
	C500.683502,572.784058 499.724762,572.916809 498.864258,572.477478 
	C498.632660,569.956299 494.641052,567.100159 499.993835,566.233765 
	C503.671600,569.400818 507.280945,572.218201 510.890259,575.035583 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M510.963135,574.689148 
	C507.280945,572.218201 503.671600,569.400818 500.187317,566.016479 
	C500.861481,565.279907 501.410645,565.110352 502.209290,565.230957 
	C506.826630,566.863037 509.754974,565.504883 511.354492,560.900940 
	C514.442139,559.243042 517.166260,557.656555 520.219177,556.084534 
	C521.660645,556.752319 522.773315,557.405701 523.936279,558.412964 
	C525.038635,560.873657 526.090820,562.980469 526.860474,565.072388 
	C525.957764,565.382263 525.337524,565.707092 524.717346,566.031982 
	C524.129089,566.314514 523.540894,566.597046 522.412109,567.199707 
	C520.595764,568.018982 519.320007,568.518127 518.044250,569.017334 
	C515.708191,570.792480 513.372131,572.567566 510.963135,574.689148 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M562.019287,605.538452 
	C563.333374,602.068237 564.965698,599.020264 565.760315,595.767639 
	C566.032227,594.654236 564.204773,593.028015 563.712891,591.291260 
	C565.750549,590.294128 567.411804,589.641724 569.059204,589.417664 
	C567.330811,596.336426 568.600525,601.721924 574.210938,606.033203 
	C575.745361,607.212280 576.219604,609.771057 577.180359,611.696655 
	C572.373169,610.490967 567.565979,609.285217 562.375122,608.010742 
	C561.997253,607.254883 562.003174,606.567749 562.019287,605.538452 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M518.004272,569.366516 
	C519.320007,568.518127 520.595764,568.018982 522.196899,567.448547 
	C524.649597,568.995178 526.776978,570.612976 528.919678,572.924133 
	C527.622192,574.783081 526.309448,575.948669 524.647522,576.956665 
	C522.543152,576.529968 520.787903,576.260864 519.032715,575.991821 
	C518.676575,573.899780 518.320496,571.807739 518.004272,569.366516 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M517.096680,595.032410 
	C516.157898,592.672363 515.219177,590.312378 514.632935,587.562744 
	C516.669983,588.448181 518.354431,589.723145 520.038879,590.998108 
	C522.597107,594.602600 525.155334,598.207031 527.823303,601.966187 
	C523.712158,604.833191 522.118530,601.134033 519.433044,598.845703 
	C518.846313,598.221252 518.754700,597.881653 518.741577,597.183289 
	C518.245544,596.227112 517.671143,595.629761 517.096680,595.032410 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M519.090576,576.334229 
	C520.787903,576.260864 522.543152,576.529968 524.639404,577.280762 
	C525.323608,578.507996 525.666748,579.253601 525.951172,580.297974 
	C525.908325,580.996765 525.924072,581.396729 525.939819,581.796631 
	C525.626831,582.612549 525.313843,583.428467 524.937744,584.569275 
	C521.974304,585.976013 521.220093,584.137512 520.737061,581.232666 
	C520.264832,579.969788 519.829102,579.354553 519.393372,578.739258 
	C519.311768,578.051697 519.230103,577.364197 519.090576,576.334229 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M518.663086,597.542114 
	C518.754700,597.881653 518.846313,598.221252 519.103516,598.771240 
	C518.712097,599.693298 518.155151,600.404785 517.275757,601.294922 
	C514.250183,600.689392 511.547150,599.905273 509.035309,598.695068 
	C510.889160,597.820923 512.551758,597.372864 514.214355,596.924805 
	C514.876709,596.962769 515.539001,597.000732 516.840942,597.097900 
	C517.874756,597.285400 518.268921,597.413757 518.663086,597.542114 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M520.362183,590.947754 
	C518.354431,589.723145 516.669983,588.448181 514.665771,587.225159 
	C515.128662,586.514160 515.911255,585.751282 517.114868,584.942078 
	C520.462708,585.911255 522.386353,587.453247 520.362183,590.947754 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M519.289307,579.034546 
	C519.829102,579.354553 520.264832,579.969788 520.590698,580.872437 
	C520.049011,580.549866 519.617188,579.939819 519.289307,579.034546 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M577.484680,611.728516 
	C576.219604,609.771057 575.745361,607.212280 574.210938,606.033203 
	C568.600525,601.721924 567.330811,596.336426 569.327515,589.176392 
	C570.689880,585.960693 571.770020,583.414612 572.900635,581.277283 
	C573.103088,586.948975 576.171326,590.483521 579.970825,594.184692 
	C580.803833,592.450623 581.393921,591.222351 582.261475,590.241699 
	C582.355042,593.779663 582.171082,597.070007 581.987122,600.360352 
	C585.807495,596.042969 587.426392,599.307617 589.742676,601.392761 
	C593.771423,605.019470 594.352905,611.949463 600.785645,613.284058 
	C600.061523,613.708984 599.505554,613.891174 598.949585,614.073364 
	C595.293274,614.004150 591.636902,613.934937 587.529175,613.897095 
	C585.652222,613.599976 584.226685,613.271423 582.356567,612.622314 
	C580.945679,612.145508 579.979370,611.989380 579.013062,611.833252 
	C578.605042,611.808960 578.197021,611.784607 577.484680,611.728516 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M579.163818,612.158203 
	C579.979370,611.989380 580.945679,612.145508 581.984741,612.611694 
	C581.143188,612.775574 580.228821,612.629395 579.163818,612.158203 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M599.037231,614.435913 
	C599.505554,613.891174 600.061523,613.708984 600.800781,612.864258 
	C599.468872,607.449890 598.032654,602.671021 596.420227,597.952393 
	C593.950745,590.726013 597.204956,586.903137 604.816833,588.064026 
	C606.505066,588.321472 608.275024,588.043945 610.007751,588.401123 
	C610.672119,591.194702 611.336060,593.599792 611.648560,595.968384 
	C606.688843,594.366089 605.076782,597.292480 605.134338,600.856140 
	C605.193726,604.535889 606.056946,608.297852 607.163208,611.838013 
	C608.212708,615.196350 606.963013,616.036255 604.020264,615.976624 
	C602.388489,615.583862 600.756653,615.191162 599.037231,614.435913 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M604.016052,616.341309 
	C606.963013,616.036255 608.212708,615.196350 607.163208,611.838013 
	C606.056946,608.297852 605.193726,604.535889 605.134338,600.856140 
	C605.076782,597.292480 606.688843,594.366089 611.689331,596.345703 
	C613.785889,603.244019 615.490295,609.728699 617.184570,616.192444 
	C617.174377,616.171570 617.137024,616.179321 616.755920,616.124268 
	C615.551758,616.790100 614.728699,617.510925 613.508911,618.246277 
	C612.061340,618.151428 611.010437,618.041992 609.880127,617.784912 
	C609.800659,617.637329 609.373291,617.535034 609.373291,617.535034 
	C609.373291,617.535034 609.055908,617.895813 608.642700,617.960938 
	C606.838074,618.071655 605.446777,618.117371 604.055420,618.163025 
	C604.040894,617.677307 604.026367,617.191650 604.016052,616.341309 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M609.959534,617.932556 
	C611.010437,618.041992 612.061340,618.151428 613.799316,618.432861 
	C615.383850,618.538818 616.281311,618.472717 617.608704,618.518188 
	C623.007385,619.254578 627.976196,619.879456 632.922729,620.788513 
	C632.906982,621.637634 632.913574,622.202637 632.522583,622.763184 
	C629.754395,622.489197 627.383789,622.219543 624.869019,621.736572 
	C624.144104,621.629272 623.563293,621.735291 622.636841,621.839539 
	C621.830261,621.832336 621.369324,621.826782 620.557617,621.667236 
	C618.102905,621.463440 615.998962,621.413635 613.895081,621.363770 
	C609.853943,621.309509 605.812866,621.255188 601.289307,621.161987 
	C600.883118,620.700439 600.959473,620.277771 601.067627,619.537109 
	C601.927551,619.010254 602.755615,618.801392 603.819580,618.377808 
	C605.446777,618.117371 606.838074,618.071655 608.866638,618.012329 
	C609.503845,617.998718 609.959534,617.932556 609.959534,617.932556 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M497.780090,580.790405 
	C499.866180,582.076111 501.893890,583.473145 504.058838,584.608521 
	C505.502197,585.365356 507.140961,585.749695 508.692535,586.300049 
	C511.701477,587.367249 513.595825,589.014404 511.529022,592.774414 
	C507.425751,590.679810 503.722046,588.326782 499.893524,585.761597 
	C499.398499,585.572327 499.028320,585.595093 498.658142,585.617859 
	C499.095337,585.755554 499.532501,585.893250 499.963135,586.397034 
	C502.314240,590.444275 504.671936,594.125305 507.029602,597.806396 
	C503.996704,596.896790 500.963806,595.987122 497.715637,594.808838 
	C497.851990,593.704163 498.743835,592.503296 498.466278,592.092346 
	C496.850281,589.699585 494.959656,587.492371 493.203247,584.603027 
	C494.756470,582.919189 496.268280,581.854797 497.780090,580.790405 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M500.018372,585.973694 
	C503.722046,588.326782 507.425751,590.679810 511.448914,593.130676 
	C512.448730,594.288391 513.129089,595.348206 514.011841,596.666443 
	C512.551758,597.372864 510.889160,597.820923 508.835510,598.385498 
	C508.103912,598.397095 507.763306,598.292053 507.226135,597.996704 
	C504.671936,594.125305 502.314240,590.444275 499.976257,586.383423 
	C499.995972,586.003723 500.018372,585.973694 500.018372,585.973694 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M625.013245,621.949951 
	C627.383789,622.219543 629.754395,622.489197 632.777893,623.093689 
	C635.934448,623.677795 638.437988,623.927063 640.941589,624.176270 
	C641.185242,624.781067 641.252991,625.668091 641.699890,625.946472 
	C645.717407,628.448669 649.801392,630.844055 654.849243,633.857910 
	C648.506042,637.344421 642.849915,635.939148 637.497192,635.793884 
	C630.775452,635.611389 624.056519,634.779358 617.377075,633.913147 
	C615.304565,633.644409 613.364807,632.351746 611.364258,631.527893 
	C612.428528,629.906555 613.536072,628.311462 614.540039,626.653625 
	C615.107056,625.717407 615.503540,624.677917 616.363525,623.306641 
	C618.136230,622.559387 619.522339,622.190308 620.908447,621.821228 
	C621.369324,621.826782 621.830261,621.832336 622.770264,622.083130 
	C623.837402,622.202332 624.425293,622.076111 625.013245,621.949951 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M705.011475,619.631348 
	C704.088318,620.794739 703.305786,621.718689 702.264160,622.818604 
	C691.203064,623.773560 680.400635,624.547607 669.599182,625.335083 
	C666.666748,625.548828 663.737305,625.803040 660.372437,625.698547 
	C659.934448,624.889526 659.930420,624.420593 660.292786,623.815308 
	C670.784180,622.115906 680.909119,620.552979 691.034119,618.989990 
	C695.913696,618.751892 700.793274,618.512695 705.673645,618.292358 
	C705.719604,618.290222 705.859558,618.600281 705.814636,618.662170 
	C705.622681,618.926392 705.377686,619.152100 705.011475,619.631348 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M696.945435,653.884155 
	C695.778870,656.220520 694.327881,658.254883 692.423706,660.641846 
	C690.943420,661.423767 689.916260,661.853149 688.889038,662.282532 
	C682.926025,662.252441 676.963074,662.222351 670.544617,662.281128 
	C670.283508,660.792847 670.477844,659.215637 671.267212,657.360962 
	C675.579468,656.388184 679.296631,655.692871 683.013794,654.997559 
	C687.327271,652.254639 691.859741,651.622986 696.945435,653.884155 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M646.067261,665.520752 
	C644.272522,668.735718 642.511780,671.611389 640.579163,674.366516 
	C640.082031,675.075134 638.957397,675.900818 638.274963,675.782837 
	C637.394653,675.630615 636.218689,674.724243 635.960022,673.895996 
	C635.133972,671.250854 634.775146,668.463379 634.031738,665.787537 
	C633.607910,664.262024 632.786499,662.846985 632.223633,660.857666 
	C633.985413,659.737183 635.667664,659.141357 637.621765,658.502808 
	C638.061096,658.384888 638.228455,658.309692 638.674561,658.427734 
	C641.236816,659.506042 643.520508,660.391113 645.804138,661.276184 
	C645.881836,662.562988 645.959473,663.849731 646.067261,665.520752 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M656.944580,680.002686 
	C654.988220,675.469849 653.031799,670.937073 650.674744,665.475952 
	C656.036743,665.781921 659.734070,665.992859 664.456909,666.262390 
	C663.547424,670.811096 662.728943,674.904785 661.550049,679.534180 
	C659.774597,680.047485 658.359619,680.025085 656.944580,680.002686 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M720.050537,635.895020 
	C717.966736,636.168457 715.882874,636.441833 713.177979,636.539917 
	C711.362488,636.563171 710.168213,636.761719 708.973877,636.960266 
	C708.234558,636.972900 707.495239,636.985474 706.387817,637.026733 
	C706.215210,633.869873 706.410645,630.684387 706.344238,627.255737 
	C706.502258,626.012146 706.922119,625.011536 707.692993,624.440125 
	C709.661560,626.177002 711.289368,628.602539 712.894714,628.587708 
	C718.221436,628.538574 719.786255,631.391846 720.050537,635.895020 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M656.633057,680.148315 
	C658.359619,680.025085 659.774597,680.047485 661.592896,679.936584 
	C662.273071,680.664185 662.550049,681.525146 662.825073,682.730042 
	C661.969116,685.864990 661.115173,688.656067 660.465393,691.711914 
	C660.676636,692.363220 660.683655,692.749573 660.382690,693.543335 
	C658.671326,693.632507 657.267822,693.314270 655.864319,692.996094 
	C655.813354,692.618225 655.762390,692.240295 655.795532,691.215759 
	C655.585327,689.380188 655.290894,688.191345 655.007507,686.639404 
	C654.705078,685.192078 654.391663,684.107788 654.078247,683.023438 
	C654.825989,682.113647 655.573730,681.203796 656.633057,680.148315 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M659.926392,623.951782 
	C659.930420,624.420593 659.934448,624.889526 659.910645,625.710693 
	C653.848145,625.431641 647.813538,624.800293 641.360229,624.172607 
	C638.437988,623.927063 635.934448,623.677795 633.175537,623.098022 
	C632.913574,622.202637 632.906982,621.637634 633.319336,620.662842 
	C642.467651,621.485901 651.196960,622.718811 659.926392,623.951782 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M666.553650,655.738159 
	C666.068237,657.033569 665.385559,658.827454 664.613953,658.866638 
	C659.570374,659.122314 654.507568,658.998108 648.427673,658.998108 
	C649.419678,656.502380 649.766357,654.714661 650.803955,653.534241 
	C651.150085,653.140564 653.639954,653.932983 654.506165,654.815186 
	C658.350220,658.730286 662.351013,656.063416 666.553650,655.738159 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M682.919922,654.604614 
	C679.296631,655.692871 675.579468,656.388184 671.429199,657.074951 
	C670.677734,655.980774 670.359436,654.895142 670.066040,653.471375 
	C674.335999,653.492737 678.580994,653.852173 682.919922,654.604614 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M646.171509,661.160400 
	C643.520508,660.391113 641.236816,659.506042 638.575989,658.056519 
	C638.136047,656.985901 638.073303,656.479675 638.010559,655.973450 
	C640.962891,656.516052 643.929810,657.001282 646.830811,657.744324 
	C647.003967,657.788696 646.648438,659.896851 646.171509,661.160400 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M637.651367,655.940002 
	C638.073303,656.479675 638.136047,656.985901 638.297363,657.863342 
	C638.228455,658.309692 638.061096,658.384888 637.222778,658.338074 
	C633.375183,658.140808 630.198486,658.065552 627.021790,657.990295 
	C627.021790,657.990295 627.000000,658.000000 627.010010,657.718140 
	C626.783447,656.967896 626.546875,656.499512 626.462524,655.829651 
	C627.053589,655.388428 627.492432,655.148560 628.342346,654.956604 
	C630.897522,655.148987 633.041504,655.293457 635.185547,655.437988 
	C635.887756,655.594177 636.589966,655.750366 637.651367,655.940002 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M653.692261,683.167419 
	C654.391663,684.107788 654.705078,685.192078 654.579407,686.633728 
	C650.440857,686.643982 646.741516,686.296875 643.042114,685.949829 
	C644.222229,685.245300 645.326965,684.184875 646.599243,683.915405 
	C648.779785,683.453613 651.065002,683.486328 653.692261,683.167419 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M642.991333,686.251648 
	C646.741516,686.296875 650.440857,686.643982 654.568359,686.996704 
	C655.290894,688.191345 655.585327,689.380188 655.772156,690.930664 
	C651.739502,690.158081 647.814392,689.023926 643.455200,687.826904 
	C642.994263,687.360413 642.967407,686.956970 642.991333,686.251648 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M635.042969,655.169373 
	C633.041504,655.293457 630.897522,655.148987 628.371704,654.638184 
	C628.312805,653.479675 628.635559,652.687439 628.958313,651.895142 
	C629.705933,651.932678 630.453552,651.970215 631.573242,652.040588 
	C632.930298,653.015869 633.915344,653.958313 635.042969,655.169373 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M620.557617,621.667236 
	C619.522339,622.190308 618.136230,622.559387 616.357971,622.930786 
	C615.338257,622.586426 614.710693,622.239746 613.989136,621.628418 
	C615.998962,621.413635 618.102905,621.463440 620.557617,621.667236 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M650.345215,646.999023 
	C650.031921,646.287659 650.019226,645.530090 650.007263,644.394653 
	C650.220642,644.995544 650.433289,645.974121 650.345215,646.999023 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M695.706299,399.154968 
	C695.088684,399.630157 694.161499,399.976044 693.081055,400.032410 
	C693.750671,399.503784 694.573669,399.264709 695.706299,399.154968 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M680.754028,401.312256 
	C680.161316,401.789795 679.252319,402.144775 678.202148,402.240753 
	C678.853271,401.717712 679.645447,401.453674 680.754028,401.312256 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M672.998962,377.578064 
	C670.851624,376.679474 668.737244,375.396606 666.150085,373.826935 
	C670.127686,372.676636 673.869263,371.594604 678.182617,370.352692 
	C681.158997,370.490448 683.563599,370.788116 685.968262,371.085754 
	C683.774292,372.065918 681.563721,373.010803 679.391113,374.036285 
	C677.251343,375.046295 675.150085,376.137970 672.998962,377.578064 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M676.023682,386.044250 
	C675.585938,385.390900 675.148132,384.737549 674.381836,384.083527 
	C673.735901,382.363403 673.418579,380.643951 673.142639,378.548218 
	C675.630615,377.710052 678.077209,377.248199 680.887390,376.919800 
	C680.527222,379.526672 679.803467,382.000092 679.065613,384.868195 
	C678.691162,385.535278 678.330933,385.807678 677.366272,386.061493 
	C676.515808,386.043365 676.269714,386.043823 676.023682,386.044250 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M686.219971,370.929199 
	C683.563599,370.788116 681.158997,370.490448 678.382385,370.035706 
	C677.745422,369.157318 677.480469,368.436005 677.208740,367.423859 
	C680.117676,367.138000 683.033386,367.142944 686.358948,367.181763 
	C686.669739,368.401276 686.570740,369.586945 686.219971,370.929199 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M675.742676,386.253845 
	C676.269714,386.043823 676.515808,386.043365 677.131470,386.079163 
	C677.017822,387.511902 676.534607,388.908356 676.069214,390.673035 
	C675.705750,391.125458 675.324402,391.209625 674.654663,391.313171 
	C674.731384,389.709503 675.096497,388.086456 675.742676,386.253845 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M673.288391,392.277588 
	C673.278198,391.883026 673.511963,391.586456 674.044312,391.305847 
	C674.325378,391.531281 674.307983,391.740662 674.163452,392.238220 
	C673.868347,392.476166 673.700378,392.425873 673.288391,392.277588 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M670.911316,362.266754 
	C674.028931,357.623657 677.146545,352.980560 680.917664,348.320251 
	C682.011536,350.545502 682.451843,352.787933 682.528320,355.095886 
	C681.075806,358.052856 679.987183,360.944336 678.898499,363.835815 
	C677.903687,364.672699 676.908936,365.509613 675.804749,366.665009 
	C672.332581,366.883026 668.969727,366.782593 665.656860,366.240326 
	C668.017883,365.119720 670.329102,364.440979 672.978699,363.802582 
	C673.711243,363.520294 674.105347,363.197723 674.499451,362.875122 
	C673.886230,362.653717 673.272949,362.432343 672.369202,362.231018 
	C671.689575,362.256317 671.300415,362.261536 670.911316,362.266754 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M679.254395,363.878113 
	C679.987183,360.944336 681.075806,358.052856 682.574097,355.483398 
	C683.026917,356.841949 683.070068,357.878448 683.113220,358.914978 
	C683.337585,359.794434 683.561951,360.673920 683.869629,361.898712 
	C682.505371,362.802826 681.057800,363.361603 679.254395,363.878113 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M683.500122,358.942932 
	C683.070068,357.878448 683.026917,356.841949 682.937988,355.417908 
	C682.451843,352.787933 682.011536,350.545502 681.207153,348.156006 
	C680.819580,345.643860 680.795959,343.278809 680.705811,340.472290 
	C680.878723,337.849731 681.118225,335.668671 681.652527,333.210571 
	C682.392090,332.954651 682.836914,332.975739 683.614746,332.992798 
	C683.995667,333.423370 684.043457,333.857941 684.129517,334.618774 
	C685.536682,339.114014 687.544678,343.203857 688.074341,347.476959 
	C688.505432,350.955139 687.248352,354.670044 686.500549,358.229675 
	C686.414001,358.641571 684.795410,358.731628 683.500122,358.942932 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M688.815918,662.616211 
	C689.916260,661.853149 690.943420,661.423767 692.317139,660.982117 
	C692.822571,662.426270 692.981445,663.882629 693.166138,665.784302 
	C691.262939,666.480835 689.333923,666.732056 686.750122,667.068542 
	C687.572754,665.368286 688.157715,664.159119 688.815918,662.616211 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M655.645508,693.187622 
	C657.267822,693.314270 658.671326,693.632507 660.365784,693.832520 
	C660.747925,693.941528 660.839111,694.168579 661.026611,694.751099 
	C660.919006,695.615112 660.715027,696.123657 660.284119,696.788452 
	C659.277283,696.944153 658.497437,696.943665 657.326721,696.945679 
	C656.432800,695.758606 655.929749,694.568909 655.645508,693.187622 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M576.324768,439.644226 
	C576.883728,435.797668 577.483521,432.364929 578.119873,428.532532 
	C578.830566,428.167816 579.504700,428.202759 580.667419,428.528412 
	C584.322693,431.373260 587.489502,433.927460 590.707764,436.772400 
	C590.751892,437.650116 590.744446,438.237000 590.546082,438.853485 
	C590.355103,438.883057 590.010254,439.057495 589.639832,439.052063 
	C585.828064,441.441589 582.386658,443.836609 578.945312,446.231628 
	C574.277649,445.764862 576.401672,442.460175 576.324768,439.644226 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M590.656250,436.481628 
	C587.489502,433.927460 584.322693,431.373260 581.004272,428.520142 
	C585.612549,427.540436 584.447571,422.923553 586.746948,420.389313 
	C587.980652,422.224457 588.633667,423.892670 589.199341,425.941284 
	C589.836182,427.867493 590.560364,429.413361 591.472778,431.360962 
	C593.699646,429.603119 595.349548,428.300690 596.990234,427.415955 
	C596.654663,431.553558 596.328308,435.273529 595.746887,438.997925 
	C595.321899,439.010468 595.151978,439.018494 594.883545,438.857727 
	C594.333130,438.765717 593.881226,438.842468 593.315063,438.550964 
	C592.352600,437.615692 591.504456,437.048645 590.656250,436.481628 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M600.820923,412.292725 
	C602.965210,412.651123 604.954651,413.235718 607.289429,414.393921 
	C608.072937,416.271210 608.511169,417.574860 608.580811,418.902313 
	C606.626221,419.646332 604.907898,420.170502 603.484985,421.131989 
	C601.258850,422.636261 599.232605,424.436340 597.091919,425.668976 
	C597.031494,423.465607 597.000793,421.704346 597.009888,419.693665 
	C597.151733,419.310272 597.253784,419.176331 597.355835,419.042419 
	C599.039185,417.622009 600.722534,416.201599 601.805542,415.287750 
	C601.318420,414.104156 600.992249,413.311554 600.820923,412.292725 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M597.310181,418.660278 
	C597.253784,419.176331 597.151733,419.310272 596.656006,419.778870 
	C594.203552,422.270416 594.825867,427.860870 589.286621,425.560913 
	C588.633667,423.892670 587.980652,422.224457 587.117798,420.290222 
	C586.867676,419.762573 586.827393,419.500946 586.538818,418.871948 
	C586.290527,418.504517 586.214172,418.582489 586.214172,418.582458 
	C589.785767,417.006195 593.357422,415.429932 598.109558,413.332672 
	C597.615051,416.226440 597.439758,417.252258 597.310181,418.660278 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M586.232117,418.561035 
	C586.214172,418.582489 586.290527,418.504517 586.298340,418.556000 
	C586.306152,418.607483 586.250061,418.539612 586.232117,418.561035 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M579.006775,446.544495 
	C582.386658,443.836609 585.828064,441.441589 589.595093,439.391907 
	C588.030701,441.157715 586.140747,442.578217 583.640076,444.457642 
	C585.409302,445.484863 586.729492,446.251434 588.018311,447.389648 
	C589.014832,449.500732 590.042725,451.240143 591.375916,453.032593 
	C592.129333,453.696808 592.577332,454.307983 592.679993,454.978241 
	C591.106323,456.097534 589.878052,457.157745 588.649719,458.217957 
	C585.452942,457.245361 583.875854,452.250244 579.146667,454.271423 
	C579.787109,453.052063 580.655273,452.038422 581.551331,451.050079 
	C582.272949,450.254150 583.028137,449.488770 583.768433,448.709839 
	C582.709595,448.376801 581.637390,448.080139 580.597595,447.695770 
	C580.058167,447.496399 579.576172,447.141815 579.006775,446.544495 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M533.889221,470.864380 
	C534.843628,470.857941 535.444946,471.008301 536.456543,471.469238 
	C538.259216,473.181824 539.651733,474.583771 540.659546,476.011658 
	C537.517944,476.993073 534.761108,477.948517 531.588013,478.916229 
	C529.784851,478.941986 528.397827,478.955475 527.010864,478.968994 
	C525.841614,478.535034 524.672363,478.101074 523.284790,477.437683 
	C526.556335,475.145905 530.046204,473.083557 533.889221,470.864380 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M526.781372,479.245483 
	C528.397827,478.955475 529.784851,478.941986 531.585815,479.314026 
	C531.466370,481.164001 531.260193,482.889374 530.339111,484.044922 
	C527.368286,487.772003 524.134094,491.289215 520.617920,494.924744 
	C519.463562,494.955475 518.692810,494.948853 517.645569,494.945587 
	C516.933838,495.229675 516.498596,495.510406 515.682373,495.865417 
	C514.527832,495.953888 513.754333,495.968048 512.980835,495.982208 
	C510.786804,495.505707 508.592773,495.029205 505.922516,494.449280 
	C506.988251,492.000977 507.957397,489.774628 508.881348,487.151855 
	C509.064178,486.611298 509.269867,486.440277 509.584351,486.092468 
	C514.680969,487.605804 518.733704,487.284729 521.415344,481.809601 
	C522.047791,480.518219 524.783630,480.257050 526.781372,479.245483 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M496.288940,522.181030 
	C496.056824,521.563477 496.080078,521.119263 496.508545,520.251831 
	C497.271698,516.865723 497.629639,513.902649 497.987579,510.939606 
	C498.476898,510.278595 498.966217,509.617554 499.784058,508.904541 
	C500.112610,508.852600 500.087860,508.922302 500.087708,509.291016 
	C501.373169,513.443359 502.658783,517.226990 503.574890,521.013916 
	C502.466156,522.027283 501.726929,523.037415 500.631409,524.039551 
	C499.568634,524.081787 498.862122,524.132141 498.155609,524.182434 
	C497.618500,523.573059 497.081421,522.963623 496.288940,522.181030 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M500.113281,508.815613 
	C497.720978,505.710510 500.851135,503.985535 502.404175,501.423157 
	C504.640289,501.038177 506.298676,500.966644 507.818878,501.196075 
	C507.303223,502.512695 506.925751,503.528320 506.259033,504.726379 
	C504.293213,506.663025 503.850281,510.225128 500.087860,508.922302 
	C500.087860,508.922302 500.112610,508.852600 500.113281,508.815613 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M507.957062,500.895081 
	C506.298676,500.966644 504.640289,501.038177 502.570557,501.126740 
	C501.524414,500.620697 500.889587,500.097687 500.130005,499.289246 
	C500.268341,498.400177 500.531494,497.796478 501.037903,497.149597 
	C503.579895,497.387329 505.878601,497.668243 508.492645,498.325134 
	C508.524323,499.432434 508.240692,500.163757 507.957062,500.895081 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M497.795532,510.958221 
	C497.629639,513.902649 497.271698,516.865723 496.574158,519.924377 
	C496.528168,517.292969 496.821808,514.565857 497.166901,511.420166 
	C497.218353,511.001526 497.603485,510.976837 497.795532,510.958221 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M507.818848,501.196075 
	C508.240692,500.163757 508.524323,499.432434 508.895264,498.419037 
	C508.982605,498.136963 509.250732,497.854431 509.721924,497.827118 
	C510.463348,497.561096 510.733521,497.322388 511.003723,497.083679 
	C511.441559,497.015625 511.879425,496.947540 512.816895,497.159729 
	C514.256592,497.265076 515.196716,497.090210 516.452026,496.904053 
	C517.184265,496.840759 517.601196,496.788757 518.013184,497.104126 
	C516.359558,499.694305 514.710876,501.917114 512.742310,504.236420 
	C510.903687,506.537170 509.384979,508.741455 507.590424,511.083008 
	C506.565155,511.506775 505.815735,511.793243 505.033997,512.043701 
	C505.001678,512.007629 504.929962,511.942657 504.945740,511.562225 
	C505.490448,508.969177 506.019348,506.756531 506.548279,504.543915 
	C506.925751,503.528320 507.303223,502.512695 507.818848,501.196075 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M516.136780,496.915314 
	C515.196716,497.090210 514.256592,497.265076 513.149780,497.189392 
	C512.983032,496.938843 512.997437,496.460602 512.989136,496.221405 
	C513.754333,495.968048 514.527832,495.953888 515.652710,496.150116 
	C516.048340,496.545441 516.092590,496.730377 516.136780,496.915314 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M510.725769,497.069305 
	C510.733521,497.322388 510.463348,497.561096 509.911255,497.781311 
	C509.902191,497.526825 510.175018,497.290894 510.725769,497.069305 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M690.971313,471.969849 
	C689.984680,473.383789 688.998047,474.797729 687.679321,476.624207 
	C686.915588,477.046814 686.483887,477.056854 685.802124,476.791321 
	C684.329102,476.298096 683.106079,476.080475 681.563660,475.623779 
	C676.833740,474.986908 672.410767,474.155670 668.015381,474.282135 
	C659.050659,474.539978 650.100708,475.313690 640.702393,475.928986 
	C638.485107,475.959503 636.710083,475.935913 634.840332,475.662231 
	C634.497864,474.939880 634.250183,474.467682 634.141479,473.659058 
	C634.025513,470.576874 634.010437,467.781525 633.455933,465.097595 
	C632.726135,461.564758 632.392700,458.515167 636.575500,456.822998 
	C637.303650,456.528412 638.023560,454.923157 637.898682,454.043793 
	C637.466309,450.999847 636.659180,448.009094 635.970642,444.614227 
	C635.333679,442.152802 634.720764,440.075287 634.050476,437.560425 
	C633.819946,435.052979 633.646790,432.982880 633.851562,430.954285 
	C636.702820,434.407288 639.182251,437.814392 641.648132,441.231293 
	C646.195923,447.532959 650.735718,453.840424 655.278809,460.145447 
	C655.829346,459.881500 656.379883,459.617584 656.930481,459.353638 
	C656.511230,457.059448 656.065613,454.769714 655.678223,452.470154 
	C654.800720,447.261108 653.951904,442.047211 653.091431,436.835297 
	C659.485535,440.792664 665.871948,440.089233 672.632690,436.673340 
	C672.973877,439.016937 672.936340,441.490723 672.868835,444.403320 
	C669.621155,448.887970 668.868286,453.651489 667.991150,459.331024 
	C673.844116,457.462463 675.694763,452.989380 678.208984,449.360107 
	C681.691040,444.333740 684.677917,438.964996 688.142151,433.925232 
	C689.098022,432.534637 690.997070,431.792297 692.752136,430.864777 
	C692.791443,436.596466 692.541870,442.215851 692.179443,448.653351 
	C692.032104,450.306244 691.997620,451.141022 691.604614,451.988525 
	C684.181274,457.064209 680.301086,464.294342 675.993164,473.436920 
	C682.024170,472.846191 686.497742,472.408020 690.971313,471.969849 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M653.013733,436.534790 
	C653.951904,442.047211 654.800720,447.261108 655.678223,452.470154 
	C656.065613,454.769714 656.511230,457.059448 656.930481,459.353638 
	C656.379883,459.617584 655.829346,459.881500 655.278809,460.145447 
	C650.735718,453.840424 646.195923,447.532959 641.648132,441.231293 
	C639.182251,437.814392 636.702820,434.407288 633.659912,430.666138 
	C632.042175,427.247528 630.994019,424.158508 629.959961,420.682617 
	C629.971130,419.521362 629.968201,418.746948 629.965332,417.972534 
	C629.965332,417.972534 629.991211,418.000000 630.205505,417.894592 
	C630.692261,417.600983 630.884644,417.351074 630.996887,417.039459 
	C632.016479,416.934479 633.036011,416.829529 634.434448,416.654663 
	C639.596558,424.111328 644.379761,431.637878 648.875000,438.711395 
	C650.150513,437.933350 651.543274,437.083832 653.013733,436.534790 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M639.883972,415.173462 
	C641.473511,413.885681 643.292786,412.884125 645.378662,411.799927 
	C646.330566,415.919495 647.015991,420.121674 647.701416,424.323883 
	C647.396545,424.505066 647.091675,424.686249 646.786804,424.867462 
	C644.562439,421.731537 642.338135,418.595612 639.883972,415.173462 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M611.957275,426.984467 
	C614.938782,431.326416 615.991333,426.125641 618.528198,425.388916 
	C621.835693,423.693817 624.635986,422.368500 627.336792,421.449341 
	C626.985474,423.503967 626.733704,425.152374 626.151367,426.930786 
	C623.503479,436.026947 621.186157,444.993164 618.497620,454.057373 
	C617.685913,455.856079 617.245483,457.556732 616.470337,459.196808 
	C615.453735,459.058411 614.771851,458.980591 614.026733,458.514984 
	C613.654541,455.746155 613.345459,453.365143 613.242920,450.818298 
	C613.076538,450.008392 612.703613,449.364319 612.297668,448.328094 
	C612.011658,446.136200 611.758728,444.336456 611.850586,442.237244 
	C613.425964,438.073669 614.044678,434.358765 609.837036,431.504639 
	C610.275269,429.809906 610.611206,428.456024 611.196655,427.046692 
	C611.616516,426.988983 611.786926,426.986725 611.957275,426.984467 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M624.817749,391.103424 
	C630.381775,390.424286 635.613892,389.907196 641.234253,389.473114 
	C645.647766,393.232788 649.673035,396.909454 653.980347,400.798706 
	C653.928833,401.442963 653.595215,401.874695 653.149719,402.556061 
	C644.874207,401.432556 636.710510,400.059418 628.264038,398.438782 
	C627.150146,397.124268 626.480591,395.354553 625.458374,395.119690 
	C622.139282,394.356842 622.665405,393.086823 624.817749,391.103424 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M610.947144,427.102142 
	C610.611206,428.456024 610.275269,429.809906 609.552368,431.655945 
	C607.131775,433.787872 605.098145,435.427612 602.695557,437.083313 
	C600.218323,437.730713 598.110107,438.362091 596.001953,438.993469 
	C596.328308,435.273529 596.654663,431.553558 597.017822,427.193726 
	C597.054688,426.553864 597.121643,426.111115 597.121643,426.111115 
	C599.232605,424.436340 601.258850,422.636261 603.484985,421.131989 
	C604.907898,420.170502 606.626221,419.646332 608.652954,419.267151 
	C609.587463,419.994629 610.080994,420.381134 610.574585,420.767639 
	C610.536072,421.250854 610.497498,421.734070 610.273682,422.913910 
	C610.374634,424.774414 610.660889,425.938263 610.947144,427.102142 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M627.436340,421.043182 
	C624.635986,422.368500 621.835693,423.693817 618.710693,425.092468 
	C618.648438,422.556824 618.604553,419.854919 619.351318,417.392883 
	C619.589722,416.606995 621.982727,415.974213 623.288391,416.128723 
	C625.293030,416.366058 627.210022,417.344849 629.564758,417.990753 
	C629.968201,418.746948 629.971130,419.521362 629.643677,420.669739 
	C628.687622,421.043518 628.062012,421.043365 627.436340,421.043182 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M614.900513,397.630341 
	C617.115845,397.343475 619.292175,397.426453 621.710449,397.758789 
	C621.952454,401.137238 621.952454,404.266327 621.952454,407.395447 
	C621.328613,407.644714 620.704712,407.893951 620.080872,408.143219 
	C618.341064,404.762238 616.601257,401.381226 614.900513,397.630341 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M610.765991,420.463562 
	C610.080994,420.381134 609.587463,419.994629 609.021667,419.243347 
	C608.511169,417.574860 608.072937,416.271210 607.556091,414.615295 
	C606.892090,412.869049 606.461182,411.378754 605.671448,410.112213 
	C605.062317,409.135345 604.002747,408.439240 603.534302,407.260437 
	C604.950867,406.931946 605.976685,406.961304 607.002441,406.990662 
	C613.098938,409.945251 611.548096,415.196564 610.765991,420.463562 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M607.002747,406.568939 
	C605.976685,406.961304 604.950867,406.931946 603.544922,406.890686 
	C603.164673,406.878754 603.128845,406.876465 603.070312,406.560486 
	C602.401489,405.777802 601.791077,405.311066 601.180725,404.844330 
	C601.838989,404.162323 602.385925,403.162109 603.184998,402.882782 
	C604.086792,402.567474 605.215332,402.900452 606.556213,402.851379 
	C606.912476,403.883240 606.957764,405.015228 607.002747,406.568939 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M611.196655,427.046692 
	C610.660889,425.938263 610.374634,424.774414 610.260742,423.277710 
	C610.973694,424.057953 611.514221,425.170990 612.005981,426.634247 
	C611.786926,426.986725 611.616516,426.988983 611.196655,427.046692 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M630.747498,417.088776 
	C630.884644,417.351074 630.692261,417.600983 630.197815,417.879913 
	C630.149902,417.693115 630.324036,417.415619 630.747498,417.088776 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M657.260864,392.902039 
	C657.337952,391.024689 657.346191,388.104889 658.283325,387.767914 
	C660.702209,386.898010 663.683411,386.584625 666.135437,387.221558 
	C667.439575,387.560272 668.111145,390.334351 669.099731,392.388428 
	C668.975464,392.824646 668.782104,392.840698 668.669556,392.944458 
	C665.009583,396.317383 661.324951,396.033081 657.260864,392.902039 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M601.169189,405.180756 
	C601.791077,405.311066 602.401489,405.777802 603.060242,406.548340 
	C602.458313,406.407196 601.807983,405.962189 601.169189,405.180756 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M617.332581,672.680542 
	C616.597839,669.693359 616.182739,666.658813 615.528381,663.324097 
	C615.284790,662.269653 615.280457,661.515442 615.479187,660.102722 
	C615.809082,657.978699 615.935913,656.513123 616.062744,655.047546 
	C616.062744,655.047546 616.541565,654.944458 616.879761,655.184326 
	C617.742188,655.694946 618.266418,655.965820 618.790649,656.236694 
	C619.132507,656.647034 619.474365,657.057373 619.684631,657.940552 
	C620.856873,659.423523 622.160645,660.433716 623.464478,661.443909 
	C623.446106,662.401733 623.427673,663.359619 623.170471,664.894653 
	C623.375610,666.480164 623.819580,667.488525 624.263550,668.496826 
	C624.348450,668.996277 624.433350,669.495789 624.173340,670.307251 
	C622.410889,671.212585 620.993347,671.805969 619.575867,672.399353 
	C618.934631,672.477295 618.293457,672.555237 617.332581,672.680542 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M629.140015,644.659302 
	C632.105042,644.233826 635.040466,644.189331 638.409851,644.186035 
	C637.457458,647.829651 632.154541,648.272583 629.140015,644.659302 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M627.075562,658.317810 
	C630.198486,658.065552 633.375183,658.140808 636.950867,658.380798 
	C635.667664,659.141357 633.985413,659.737183 631.998901,660.530396 
	C630.172852,660.033569 628.651062,659.339478 627.075562,658.317810 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M628.705322,651.633545 
	C628.635559,652.687439 628.312805,653.479675 627.960571,654.590332 
	C627.492432,655.148560 627.053589,655.388428 626.158936,655.828735 
	C625.503113,656.055115 625.303284,656.080994 625.103394,656.106934 
	C625.421753,653.964294 623.489075,650.229492 628.705322,651.633545 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M623.602295,661.140625 
	C622.160645,660.433716 620.856873,659.423523 619.774658,658.206421 
	C621.244263,658.945435 622.492249,659.891357 623.602295,661.140625 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M615.694580,655.060425 
	C615.935913,656.513123 615.809082,657.978699 615.458496,659.726318 
	C615.265381,658.363342 615.295898,656.718262 615.694580,655.060425 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M619.950806,672.568359 
	C620.993347,671.805969 622.410889,671.212585 624.167358,670.562988 
	C623.112854,671.250366 621.719299,671.993896 619.950806,672.568359 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M624.458862,668.098999 
	C623.819580,667.488525 623.375610,666.480164 623.120667,665.250366 
	C623.757812,665.919678 624.205994,666.810425 624.458862,668.098999 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M625.140381,656.404907 
	C625.303284,656.080994 625.503113,656.055115 626.006592,656.030151 
	C626.546875,656.499512 626.783447,656.967896 627.015137,657.707275 
	C626.399292,657.553162 625.788391,657.128052 625.140381,656.404907 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M618.837830,655.929688 
	C618.266418,655.965820 617.742188,655.694946 617.119202,655.132996 
	C617.641968,655.102112 618.263489,655.362366 618.837830,655.929688 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M603.878662,661.761475 
	C602.929260,660.932373 602.001221,659.754395 601.120728,658.224731 
	C602.078918,659.052917 602.989380,660.232788 603.878662,661.761475 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M773.305542,605.930054 
	C767.912292,606.152649 762.910217,606.220947 757.437866,606.303650 
	C755.588623,601.681946 753.962769,597.096619 752.991577,592.376648 
	C752.724304,591.077881 754.412109,589.376892 755.872681,587.949463 
	C757.384216,589.009277 758.225891,589.980225 759.067627,591.300903 
	C758.655945,596.670593 762.109192,599.251282 766.021057,602.394653 
	C767.071594,600.952637 768.051208,599.608032 769.299805,598.048584 
	C772.008606,594.560120 774.448425,591.286377 777.213379,587.901489 
	C778.696167,585.876282 779.853760,583.962219 780.986938,582.032532 
	C780.962463,582.016846 780.940796,582.070984 781.246460,581.852539 
	C784.020874,581.941650 786.489624,582.249084 788.958374,582.556580 
	C793.240662,589.816589 793.100281,591.609314 787.439026,598.139221 
	C785.511719,600.362366 783.392639,602.419250 781.174194,604.838501 
	C778.557068,605.341736 776.126892,605.558777 773.305542,605.930054 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M811.762268,575.938049 
	C811.688721,576.400024 811.615234,576.862061 811.215820,577.744507 
	C808.159058,580.816650 805.428223,583.468323 802.697327,586.120056 
	C800.221558,584.990112 799.427856,583.612366 801.878296,581.543823 
	C802.865173,580.710693 803.671936,579.660400 804.538086,578.688843 
	C807.087097,575.829529 807.402283,573.333923 803.166870,570.944702 
	C804.012817,567.864197 805.035950,565.417664 806.426147,562.982544 
	C810.186096,564.082031 811.663330,565.771484 809.869568,569.582886 
	C808.842346,571.765625 806.758606,575.158386 811.762268,575.938049 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M806.059082,562.971130 
	C805.035950,565.417664 804.012817,567.864197 802.952515,570.689148 
	C801.893616,570.720337 800.871887,570.373047 799.481934,569.950317 
	C799.113647,569.874817 799.031433,569.801941 798.985474,569.473816 
	C797.080627,567.679810 795.011963,566.406067 793.434204,564.682800 
	C792.317932,563.463684 791.866028,561.636292 791.483337,560.011108 
	C793.530762,559.624695 795.212402,559.307556 796.894043,558.990479 
	C798.024475,559.317993 799.154968,559.645508 800.653320,559.974731 
	C801.021179,559.976379 801.060791,559.983398 801.117310,560.335999 
	C802.802307,561.449402 804.430725,562.210266 806.059082,562.971130 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M802.742249,586.437866 
	C805.428223,583.468323 808.159058,580.816650 810.976318,577.798462 
	C812.246277,577.746704 813.429871,578.061340 814.769897,578.971802 
	C813.264038,582.368225 811.601685,585.168762 809.939697,587.942871 
	C809.940125,587.916443 809.893616,587.890686 809.544434,587.901367 
	C807.600098,589.251221 806.132080,590.805725 804.363037,591.850281 
	C803.267395,592.497253 801.714417,592.369507 800.472412,592.260864 
	C800.833191,591.047546 801.088196,590.162781 801.343201,589.278015 
	C801.824524,588.437195 802.305847,587.596436 802.742249,586.437866 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M799.155884,569.910034 
	C799.245667,575.457703 797.780334,580.425171 793.712280,584.873718 
	C793.095642,583.379578 792.561584,582.085449 791.997009,580.014771 
	C791.670166,576.794800 791.373779,574.351501 791.442749,571.891113 
	C794.215942,571.183350 796.623657,570.492676 799.031433,569.801941 
	C799.031433,569.801941 799.113647,569.874817 799.155884,569.910034 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M801.127136,589.058228 
	C801.088196,590.162781 800.833191,591.047546 800.194702,592.405640 
	C799.102661,594.208191 798.394104,595.537415 797.685547,596.866638 
	C796.580872,597.999756 795.591248,599.285156 794.342834,600.228088 
	C792.707825,601.463074 790.868591,602.427612 789.117188,603.508423 
	C788.641541,602.899475 788.165894,602.290466 787.690247,601.681519 
	C792.097168,597.400452 796.504089,593.119385 801.127136,589.058228 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M789.184814,582.269531 
	C786.489624,582.249084 784.020874,581.941650 781.220032,581.465942 
	C782.286499,578.630127 783.684998,575.962463 785.432007,573.317627 
	C786.476929,573.377258 787.173279,573.414062 787.963562,573.775757 
	C788.608032,575.797241 789.158691,577.493896 789.709351,579.190552 
	C789.610046,580.121216 789.510681,581.051880 789.184814,582.269531 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M797.787781,597.234009 
	C798.394104,595.537415 799.102661,594.208191 800.088928,592.734253 
	C801.714417,592.369507 803.267395,592.497253 804.363037,591.850281 
	C806.132080,590.805725 807.600098,589.251221 809.574829,588.234863 
	C809.948914,589.000427 809.943420,589.443115 809.937988,589.885864 
	C806.208801,592.925537 802.479614,595.965271 798.408203,599.061218 
	C798.007324,598.612122 797.948669,598.106750 797.787781,597.234009 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M790.014282,579.198425 
	C789.158691,577.493896 788.608032,575.797241 788.134155,573.439819 
	C789.151245,572.502258 790.091614,572.225464 791.043457,571.941528 
	C791.054932,571.934326 791.077454,571.908142 791.077393,571.908142 
	C791.373779,574.351501 791.670166,576.794800 791.940125,579.661865 
	C791.382202,579.792542 790.850708,579.499390 790.014282,579.198425 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M810.365906,589.927002 
	C809.943420,589.443115 809.948914,589.000427 809.924011,588.224182 
	C809.893616,587.890686 809.940125,587.916443 810.251587,587.818604 
	C812.707642,585.257690 814.852234,582.794556 816.996826,580.331421 
	C818.711670,580.018677 820.436707,579.752686 822.138550,579.380676 
	C824.026489,578.967957 825.626709,578.745300 823.814880,581.759644 
	C819.059265,582.798889 818.141174,586.228943 817.030090,589.501648 
	C817.030090,589.501648 817.134033,589.675903 817.141479,589.775635 
	C816.421387,589.931335 815.693848,589.987366 814.609924,590.065491 
	C813.100281,590.047791 811.947083,590.007996 810.365906,589.927002 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M766.031738,610.728394 
	C766.593079,610.094604 767.512695,609.575378 768.781006,609.119690 
	C768.216492,609.736450 767.303223,610.289734 766.031738,610.728394 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M758.060730,612.797852 
	C758.646301,612.169189 759.588562,611.644226 760.879578,611.256714 
	C760.291321,611.896667 759.354370,612.399170 758.060730,612.797852 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M817.400391,589.384827 
	C818.141174,586.228943 819.059265,582.798889 823.545654,581.968018 
	C823.858032,583.475281 823.793335,584.984070 823.875244,586.801514 
	C821.938110,587.829468 819.854431,588.548767 817.400391,589.384827 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M814.427490,496.852448 
	C813.554749,497.300446 812.610291,497.552185 810.993652,497.906891 
	C809.218994,498.975830 807.903809,500.932587 807.048340,500.748840 
	C800.936523,499.436157 794.918396,497.687286 788.666687,495.943176 
	C788.168762,495.602814 788.032043,495.320190 788.350464,494.890625 
	C789.420410,494.573578 790.192688,494.327240 791.372925,494.140350 
	C795.441284,494.523468 799.101624,494.847107 802.899170,495.512939 
	C805.035156,495.936127 807.033936,496.017181 809.405640,496.091125 
	C810.525024,496.071167 811.271423,496.058380 812.379028,496.067627 
	C813.218384,496.153046 813.696533,496.216370 814.174683,496.279724 
	C814.174683,496.279724 814.355896,496.656189 814.427490,496.852448 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M814.182617,495.961212 
	C813.696533,496.216370 813.218384,496.153046 812.375854,495.697906 
	C812.043152,494.813721 812.074951,494.321381 812.106750,493.829010 
	C812.593262,494.152588 813.079834,494.476166 813.840271,494.904907 
	C814.139709,495.220978 814.165161,495.431854 814.182617,495.961212 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M810.966797,521.969360 
	C809.528503,525.653625 807.994995,529.264526 806.540527,532.907043 
	C805.259399,536.115356 803.049194,537.585815 799.225830,536.506592 
	C797.451782,532.439819 796.021606,528.763062 794.591370,525.086304 
	C793.956116,525.191162 793.320801,525.296021 792.685547,525.400818 
	C792.530090,528.187195 792.374634,530.973572 792.219177,533.759949 
	C791.867981,534.033630 791.516846,534.307373 790.678223,534.764648 
	C786.853699,531.957581 783.516602,528.967041 780.080688,525.623413 
	C778.664917,523.780945 777.347900,522.291687 776.065918,520.482788 
	C776.397644,519.147705 776.694214,518.132324 777.377441,517.092041 
	C779.569641,517.685364 781.331665,518.586853 783.188416,518.871887 
	C790.450012,519.986877 797.741577,520.907043 805.434692,521.946167 
	C807.492798,521.925476 809.138306,521.858215 810.783752,521.790955 
	C810.783752,521.790955 810.905945,521.909729 810.966797,521.969360 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M780.179504,525.976562 
	C783.516602,528.967041 786.853699,531.957581 790.501831,535.050781 
	C791.127197,535.833984 791.441528,536.514526 791.496338,537.489258 
	C789.148071,538.849121 787.059387,539.914734 784.995605,540.989258 
	C785.020508,540.998047 785.009399,541.050720 785.049316,540.752319 
	C784.575378,540.657043 784.061584,540.860168 783.227539,540.988342 
	C780.953003,539.594727 778.998779,538.276062 777.030212,536.556763 
	C777.002808,534.555054 776.989685,532.954041 777.372437,531.264893 
	C778.572083,529.443420 779.375793,527.710022 780.179504,525.976562 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M784.970642,540.980408 
	C787.059387,539.914734 789.148071,538.849121 791.622375,537.746277 
	C793.741821,539.382568 795.875122,540.815918 797.074524,542.810730 
	C797.927429,544.229126 797.521545,546.404236 797.039307,548.320923 
	C793.197205,547.441589 789.993286,546.483032 786.540405,545.249390 
	C785.851196,543.643005 785.410950,542.311707 784.970642,540.980408 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M796.898315,558.640991 
	C795.212402,559.307556 793.530762,559.624695 791.189697,559.979919 
	C790.334351,560.015259 790.138306,560.012451 789.932495,559.647644 
	C787.451294,556.247559 784.979858,553.209473 782.866943,550.012512 
	C784.400391,549.523499 785.575256,549.193359 786.978149,549.135498 
	C789.143860,550.854919 791.081543,552.302185 793.019226,553.749451 
	C794.007263,554.803894 794.995300,555.858337 796.362305,556.909424 
	C796.795044,557.367981 796.848816,557.829712 796.898315,558.640991 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M793.361816,553.817078 
	C791.081543,552.302185 789.143860,550.854919 787.019592,548.802307 
	C786.818481,547.306091 786.803955,546.415283 786.789429,545.524414 
	C789.993286,546.483032 793.197205,547.441589 796.770874,548.570312 
	C795.995178,550.455261 794.849792,552.169983 793.361816,553.817078 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M792.541199,533.858276 
	C792.374634,530.973572 792.530090,528.187195 792.685547,525.400818 
	C793.320801,525.296021 793.956116,525.191162 794.591370,525.086304 
	C796.021606,528.763062 797.451782,532.439819 798.807495,536.452087 
	C796.776489,535.843933 794.819824,534.900269 792.541199,533.858276 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M805.021973,521.899658 
	C797.741577,520.907043 790.450012,519.986877 783.188416,518.871887 
	C781.331665,518.586853 779.569641,517.685364 776.985474,517.036255 
	C775.418640,516.944153 774.630493,516.882935 773.869446,516.455933 
	C773.924622,515.111633 773.952698,514.133179 774.315430,513.098389 
	C775.102234,513.027893 775.554260,513.013733 776.101257,513.393921 
	C783.845764,516.286743 791.334656,515.123413 799.187073,512.496948 
	C794.361938,509.898193 793.800049,501.605591 785.862854,503.547852 
	C785.704712,503.586548 785.278259,502.528778 785.125244,501.692749 
	C786.472534,499.627655 787.671509,497.852386 788.870483,496.077087 
	C794.918396,497.687286 800.936523,499.436157 807.048340,500.748840 
	C807.903809,500.932587 809.218994,498.975830 810.635986,497.988922 
	C812.311157,502.060516 813.671692,506.153076 815.083008,510.637207 
	C814.611572,512.232971 814.089233,513.437256 813.066162,514.578613 
	C808.807129,514.966125 805.048950,515.416565 800.296082,515.986267 
	C802.471130,518.707825 803.746521,520.303772 805.021973,521.899658 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M805.434692,521.946167 
	C803.746521,520.303772 802.471130,518.707825 800.296082,515.986267 
	C805.048950,515.416565 808.807129,514.966125 812.810303,514.783142 
	C812.315979,517.057190 811.576599,519.063721 810.810547,521.430542 
	C809.138306,521.858215 807.492798,521.925476 805.434692,521.946167 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M759.067261,465.088379 
	C759.864746,464.730103 760.662170,464.371857 761.790466,463.968231 
	C761.599121,465.259399 760.961426,466.564545 760.587036,467.941254 
	C760.211304,469.322693 759.430359,471.550232 760.002502,472.043518 
	C761.397339,473.246094 763.441650,474.169617 765.280273,474.280609 
	C768.718872,474.488220 772.197021,474.040192 776.017456,473.972168 
	C775.976196,474.526398 775.576233,474.973389 774.844849,475.814941 
	C773.973572,476.772125 773.433594,477.334747 772.579956,477.910309 
	C771.729736,478.515900 771.193176,479.108521 770.385925,479.780701 
	C769.719543,480.250336 769.323975,480.640442 768.620728,481.042999 
	C767.793823,481.623169 767.274658,482.190857 766.529968,482.828156 
	C766.304443,482.897766 765.995056,483.254242 765.650146,483.252441 
	C763.034424,486.431152 756.626587,484.809662 756.378601,490.782898 
	C755.737427,491.118042 755.394287,491.354523 754.651978,491.489868 
	C749.827026,492.292542 745.401306,493.196350 740.568970,494.057220 
	C739.074097,494.071686 737.985962,494.129089 736.662720,493.957520 
	C733.968628,493.950195 731.509644,494.171875 729.158447,494.047882 
	C730.817932,491.431396 732.369568,489.160522 734.101562,486.748169 
	C734.515137,486.408203 734.734558,486.195618 735.287354,485.997314 
	C736.798096,484.757996 737.961609,483.490204 739.289795,481.973663 
	C739.781494,481.236908 740.108398,480.748901 740.624634,480.239258 
	C740.813965,480.217651 741.178894,480.107819 741.503174,480.094360 
	C742.581421,479.129059 743.335449,478.177185 744.316406,476.990173 
	C745.812439,475.551239 747.081604,474.347443 748.350708,473.143616 
	C751.095459,471.273346 753.840149,469.403046 757.110718,467.241333 
	C758.113464,466.329407 758.590393,465.708893 759.067261,465.088379 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M768.928406,481.030548 
	C769.323975,480.640442 769.719543,480.250336 770.737061,479.848206 
	C771.870544,479.189880 772.382080,478.543610 772.893555,477.897339 
	C773.433594,477.334747 773.973572,476.772125 774.793457,476.109314 
	C777.142212,476.636200 779.210938,477.263367 781.599365,478.336731 
	C780.188660,484.647034 780.696350,485.525635 786.297302,487.062927 
	C789.811218,488.027435 791.363770,490.441315 790.964966,494.080933 
	C790.192688,494.327240 789.420410,494.573578 787.979492,494.882507 
	C786.581177,494.825226 785.851440,494.705292 785.014099,494.281128 
	C783.572815,494.261139 782.239136,494.545349 780.587646,494.968323 
	C779.841858,495.280518 779.413940,495.453949 779.054077,495.418457 
	C779.122131,495.209595 779.122314,494.770294 779.010681,494.428711 
	C776.950806,493.416901 775.047058,492.444519 773.043579,492.149048 
	C770.214905,491.731903 767.558716,492.107849 768.158569,496.557251 
	C768.134277,498.295258 768.159302,499.633514 768.019287,501.267914 
	C767.560486,502.378662 767.266846,503.193298 766.988342,504.007050 
	C767.003479,504.006195 767.004883,503.974884 766.665894,503.984741 
	C765.878174,503.960938 765.429443,503.927307 764.986328,503.505188 
	C765.002014,502.078857 765.011963,501.040955 765.302856,499.734283 
	C766.566162,492.226868 765.821045,491.276428 759.020569,490.582153 
	C761.464172,487.949860 763.729614,485.602020 765.995056,483.254211 
	C765.995056,483.254242 766.304443,482.897766 766.865112,482.891388 
	C767.926575,482.266876 768.427490,481.648712 768.928406,481.030548 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M791.372925,494.140381 
	C791.363770,490.441315 789.811218,488.027435 786.297302,487.062927 
	C780.696350,485.525635 780.188660,484.647034 781.960754,478.397461 
	C784.405518,478.308167 786.808594,478.604309 789.651062,479.268188 
	C790.789978,480.048706 791.489502,480.461517 792.189026,480.874329 
	C795.664246,483.316803 799.125305,485.525879 802.009766,479.659424 
	C803.384277,481.267517 804.403198,482.459686 805.709961,483.826660 
	C807.930603,487.081299 809.863586,490.161102 811.951660,493.534973 
	C812.074951,494.321381 812.043152,494.813721 812.014648,495.675842 
	C811.271423,496.058380 810.525024,496.071167 809.146545,495.782166 
	C806.596985,495.377167 804.679504,495.273987 802.761963,495.170776 
	C799.101624,494.847107 795.441284,494.523468 791.372925,494.140381 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M792.099976,480.564026 
	C791.489502,480.461517 790.789978,480.048706 790.052246,479.297119 
	C790.679626,479.390137 791.345276,479.821930 792.099976,480.564026 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M764.980652,503.893646 
	C765.429443,503.927307 765.878174,503.960938 766.705078,504.340210 
	C766.752014,506.047699 766.420837,507.409576 765.816528,509.063171 
	C764.222046,514.206360 762.771362,519.028259 761.654358,523.926392 
	C761.295593,525.499512 761.817993,527.273560 761.960815,529.346191 
	C761.648987,531.482605 761.313660,533.228638 760.921265,535.326111 
	C760.223999,537.456055 759.583984,539.234558 758.943909,541.013000 
	C755.122803,543.114746 751.794312,542.795471 748.203430,539.965332 
	C744.199768,536.809937 741.041992,538.150940 739.102295,543.103699 
	C737.869812,546.250610 736.609802,549.386658 734.808838,552.786255 
	C734.016296,553.035095 733.777100,553.025208 733.669678,552.657104 
	C733.812378,551.288635 733.823181,550.278320 733.866577,548.881409 
	C732.674438,546.329285 731.449646,544.163818 730.143127,541.638123 
	C729.078369,538.834900 728.095337,536.391846 727.338257,533.703857 
	C727.344238,531.300964 727.124329,529.143005 727.141724,526.766113 
	C728.678589,525.547668 729.978088,524.548157 731.613525,523.465454 
	C733.303711,522.615051 734.658081,521.847778 736.371399,521.043579 
	C740.940979,518.392883 745.151550,515.779053 749.534180,513.165710 
	C749.706238,513.166199 750.004883,512.995056 750.330322,512.974548 
	C751.265747,512.375244 751.875854,511.796478 752.747803,511.115479 
	C753.673035,510.670197 754.336304,510.327118 755.326660,509.966187 
	C756.283630,509.384003 756.913574,508.819733 757.802856,508.265076 
	C758.373230,508.130341 758.684326,507.985992 759.299438,507.853729 
	C760.152527,507.351135 760.701599,506.836456 761.270996,506.299438 
	C761.291260,506.277069 761.351379,506.282654 761.643799,506.222656 
	C762.677917,505.475372 763.419678,504.788055 764.369995,504.063843 
	C764.578552,504.026947 764.980652,503.893646 764.980652,503.893646 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M785.083496,573.294800 
	C783.684998,575.962463 782.286499,578.630127 780.914368,581.684387 
	C780.940796,582.070984 780.962463,582.016846 780.625732,581.932495 
	C778.510742,583.821045 776.732422,585.793823 774.614136,587.939087 
	C772.684326,587.153687 771.009338,586.311340 769.522217,585.214355 
	C766.309204,582.844299 763.192505,580.343689 760.036438,577.896484 
	C760.881104,576.831177 761.518127,575.294250 762.618469,574.809753 
	C764.324890,574.058533 766.347168,574.024658 768.293945,573.065735 
	C764.519897,572.280334 760.745789,571.494995 756.949890,569.968506 
	C757.597717,567.765198 758.267395,566.302917 759.325439,564.890503 
	C761.475098,564.628235 763.236328,564.316040 765.145813,564.345276 
	C766.620483,565.864746 767.788879,567.728149 769.306213,568.078003 
	C772.450806,568.802917 775.780701,568.723511 779.363098,568.984375 
	C780.481995,569.270752 781.272217,569.547607 781.819824,570.118774 
	C780.040039,570.715576 778.502930,571.017944 776.671997,571.378113 
	C779.714233,572.071350 782.398865,572.683044 785.083496,573.294800 
M771.213806,576.346680 
	C771.922668,576.438538 772.631470,576.530396 773.340332,576.622253 
	C773.341675,576.201660 773.342957,575.781006 773.344238,575.360413 
	C772.637146,575.448730 771.930054,575.536987 771.213806,576.346680 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M758.966614,541.435120 
	C759.583984,539.234558 760.223999,537.456055 761.291138,535.326233 
	C763.098511,535.752441 764.543884,536.434814 765.846741,537.325684 
	C769.550842,539.858337 773.196716,542.476135 777.048584,545.327942 
	C778.478638,546.822327 779.725586,548.049194 781.113037,549.464722 
	C781.671875,549.826111 782.090210,549.998779 782.508484,550.171387 
	C784.979858,553.209473 787.451294,556.247559 789.828247,559.873657 
	C789.446472,560.870667 789.159119,561.279663 788.495056,561.787842 
	C786.103516,561.518127 784.088745,561.149292 782.001465,560.459778 
	C779.922607,558.821655 777.916138,557.504150 775.626587,555.994751 
	C773.544067,554.897461 771.744812,553.992126 769.680664,552.919922 
	C768.366211,552.075378 767.316589,551.397766 766.147949,550.420227 
	C765.293945,549.813965 764.559082,549.507690 763.492065,549.062012 
	C762.466370,548.745728 761.772766,548.568787 761.044434,548.119751 
	C760.379211,547.538269 759.748657,547.228821 759.118164,546.919373 
	C759.075256,545.231995 759.032288,543.544617 758.966614,541.435120 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M733.537842,553.015320 
	C733.777100,553.025208 734.016296,553.035095 734.610107,553.100769 
	C737.277771,559.515076 739.650574,565.852966 741.866577,572.245117 
	C742.509460,574.099487 742.703552,576.109436 743.069092,578.849182 
	C743.032593,581.709534 743.705261,584.085083 742.913696,585.774353 
	C739.119324,593.871704 734.928833,601.783447 730.879028,609.761108 
	C729.567688,609.461914 728.256409,609.162720 726.615479,608.801025 
	C726.245117,607.393494 726.204346,606.048523 726.645691,604.325562 
	C728.602234,602.860474 730.700439,602.089050 731.439209,600.629456 
	C734.099792,595.373108 736.445496,589.942200 738.638855,584.468506 
	C741.049316,578.452942 741.623352,572.184448 737.858459,566.606750 
	C736.115234,564.024109 732.921509,562.429688 730.435120,560.328369 
	C730.109619,560.053284 730.080872,559.427063 729.931396,558.571777 
	C729.979309,557.135010 730.010498,556.090027 730.356506,554.986572 
	C731.097900,554.277344 731.524353,553.626648 732.218018,552.958984 
	C732.836060,552.966492 733.186951,552.990906 733.537842,553.015320 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M759.751953,578.113525 
	C763.192505,580.343689 766.309204,582.844299 769.522217,585.214355 
	C771.009338,586.311340 772.684326,587.153687 774.916443,588.087708 
	C776.001953,588.046814 776.445068,588.029724 776.888245,588.012634 
	C774.448425,591.286377 772.008606,594.560120 768.962341,597.926880 
	C765.259766,595.663757 762.163635,593.307495 759.067505,590.951233 
	C758.225891,589.980225 757.384216,589.009277 756.265991,587.853271 
	C752.576599,583.373108 752.630737,583.519104 757.364014,581.763611 
	C758.353455,581.396729 758.780945,579.514526 759.751953,578.113525 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M731.272583,609.839355 
	C734.928833,601.783447 739.119324,593.871704 742.913696,585.774353 
	C743.705261,584.085083 743.032593,581.709534 743.082397,579.253540 
	C746.029236,583.765747 748.928406,588.674683 751.827515,593.583679 
	C751.371765,593.938843 750.915955,594.293945 750.460205,594.649109 
	C749.351501,594.232056 748.242859,593.815063 746.270935,593.073303 
	C745.031372,595.667542 743.621643,598.328491 742.467896,601.096191 
	C740.423218,606.000916 737.481934,609.686768 731.272583,609.839355 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M766.267029,550.720154 
	C767.316589,551.397766 768.366211,552.075378 769.720032,553.271240 
	C772.218689,558.458618 767.980042,558.535217 765.805847,558.707642 
	C761.624695,559.039185 759.479919,560.832031 758.937073,564.840637 
	C758.267395,566.302917 757.597717,567.765198 756.788574,569.583618 
	C755.826660,568.916992 754.519226,567.980530 754.317749,566.847412 
	C754.114685,565.705078 755.032349,564.374451 755.419617,563.114258 
	C756.502014,559.591309 756.947571,556.296814 750.584167,554.819214 
	C753.691589,551.694153 756.026428,549.346008 758.739746,546.958618 
	C759.748657,547.228821 760.379211,547.538269 760.949768,548.518982 
	C759.788879,552.151794 758.687866,555.113403 757.341980,558.733765 
	C763.094910,558.146667 764.201172,554.847046 765.229919,551.062195 
	C765.806580,550.873474 766.036804,550.796814 766.267029,550.720154 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M617.194763,616.213379 
	C615.490295,609.728699 613.785889,603.244019 612.040710,596.382141 
	C611.336060,593.599792 610.672119,591.194702 610.263489,588.193359 
	C610.002258,586.063904 609.485718,584.530701 608.975403,582.572510 
	C608.049072,579.044250 607.116516,575.941101 606.154724,572.529297 
	C606.075439,571.807617 606.025330,571.394653 605.994141,570.611816 
	C605.635986,569.157654 605.258972,568.073181 604.891968,566.559448 
	C603.243530,559.765381 601.585083,553.400635 599.901245,546.701599 
	C599.786133,545.930542 599.696411,545.493774 599.725952,544.674316 
	C599.891052,543.487183 599.936890,542.682739 600.276245,542.054077 
	C601.297302,542.906372 602.114075,543.509399 602.739136,544.270020 
	C615.515625,559.816956 628.270142,575.381897 641.052124,591.293579 
	C644.187561,595.167847 647.454712,598.569519 650.364624,602.253418 
	C652.793701,605.328552 654.804199,608.734314 657.000000,611.993652 
	C651.811218,610.626831 647.139771,611.596680 642.444519,614.273132 
	C640.516968,615.372070 637.636719,614.767822 635.196716,615.007996 
	C631.857971,615.336731 628.525696,615.731873 624.691895,616.531250 
	C621.860352,616.713745 619.527588,616.463562 617.194763,616.213379 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M657.342163,611.945557 
	C654.804199,608.734314 652.793701,605.328552 650.364624,602.253418 
	C647.454712,598.569519 644.187561,595.167847 641.402344,591.340454 
	C643.200989,591.942078 644.672424,592.847290 646.284302,593.838867 
	C646.593872,593.037170 647.007629,592.520935 646.896118,592.173218 
	C644.199768,583.773010 641.448486,575.390259 638.689514,567.010193 
	C638.657532,566.913147 638.393250,566.892517 638.186951,566.442261 
	C637.405640,562.313660 636.674805,558.579041 636.330811,554.884277 
	C640.154846,556.106995 643.524353,557.579529 647.045776,558.400635 
	C651.643799,559.472717 656.414978,560.858765 661.013367,560.566101 
	C665.321899,560.291870 669.487671,557.958801 673.745239,556.624084 
	C675.940979,555.935669 678.199707,555.448120 680.884277,554.752075 
	C680.161072,558.219238 679.659912,560.621643 678.856262,563.229614 
	C676.706360,569.286804 674.858948,575.138550 672.790161,581.273071 
	C670.780640,586.896912 668.969299,592.230835 667.325745,597.615906 
	C667.280212,597.764954 669.035095,598.463501 669.918579,599.246948 
	C668.253601,601.717529 666.620422,603.846985 664.987244,605.976440 
	C662.552917,607.950134 660.118591,609.923767 657.342163,611.945557 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M665.299011,606.144409 
	C666.620422,603.846985 668.253601,601.717529 670.067322,598.945923 
	C671.198242,596.770203 672.046692,595.160706 673.120178,593.718750 
	C675.333557,590.745422 677.667175,587.861633 680.260864,584.811157 
	C684.796204,579.599548 689.039062,574.531738 693.244263,569.433044 
	C700.808655,560.261353 708.350586,551.071228 716.214722,541.893127 
	C717.031067,541.363037 717.534607,540.828674 718.187195,540.280701 
	C718.336243,540.267090 718.617676,540.164856 718.671631,540.591858 
	C718.700684,543.327698 718.675781,545.636536 718.435059,548.185913 
	C718.028870,549.278259 717.838562,550.130005 717.310791,551.092285 
	C717.047424,553.160889 717.121460,555.119019 717.198914,557.516968 
	C717.106384,560.313721 717.010376,562.670532 716.552124,565.088989 
	C715.582031,565.900757 714.379456,566.718933 714.456238,567.390991 
	C715.482117,576.371033 707.602905,580.724426 703.824768,587.015259 
	C699.586243,594.072693 693.232849,599.932556 687.409241,605.934753 
	C684.516968,608.915833 683.950684,611.568481 686.039612,614.971191 
	C681.125732,614.371033 676.002258,614.412903 671.384460,612.905701 
	C668.996887,612.126465 667.506531,608.598450 665.299011,606.144409 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M686.405640,614.973755 
	C683.950684,611.568481 684.516968,608.915833 687.409241,605.934753 
	C693.232849,599.932556 699.586243,594.072693 703.824768,587.015259 
	C707.602905,580.724426 715.482117,576.371033 714.456238,567.390991 
	C714.379456,566.718933 715.582031,565.900757 716.622803,565.541931 
	C717.055786,569.543884 717.055786,573.154480 717.055786,576.765076 
	C717.515991,576.838684 717.976135,576.912231 718.436340,576.985779 
	C720.237915,575.328064 722.039490,573.670349 723.998047,571.868164 
	C724.927917,573.883423 725.625549,575.395264 726.260132,577.312317 
	C726.100281,580.143494 726.003540,582.569458 725.906799,584.995483 
	C723.891296,584.669250 721.875854,584.343018 719.041504,583.884216 
	C718.394653,590.961182 717.435364,598.364868 717.163452,605.793762 
	C717.035828,609.278259 715.702087,610.604065 712.653564,611.710938 
	C705.924866,614.153992 699.153137,615.777710 691.444885,614.686279 
	C690.282043,614.586182 689.626648,614.784546 688.971191,614.982910 
	C688.237976,614.980713 687.504822,614.978577 686.405640,614.973755 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M725.993103,585.446289 
	C726.003540,582.569458 726.100281,580.143494 726.492432,577.013977 
	C726.531067,572.938293 726.274353,569.566101 725.934326,565.100281 
	C722.950867,567.629272 721.276123,569.048828 719.400146,570.638977 
	C718.970093,565.726624 718.588928,561.373169 718.154480,556.649536 
	C718.164612,555.537292 718.228088,554.795227 718.626465,553.941101 
	C718.857849,551.867859 718.754333,549.906616 718.650879,547.945374 
	C718.675781,545.636536 718.700684,543.327698 718.813843,540.249146 
	C718.912048,538.987488 718.921997,538.495544 719.181458,537.848755 
	C719.194275,537.094421 718.957581,536.494995 718.820801,535.593506 
	C719.570129,534.467346 720.219604,533.643250 721.233459,532.810303 
	C723.436035,533.183838 725.274170,533.566345 727.112366,533.948853 
	C728.095337,536.391846 729.078369,538.834900 730.101929,542.001099 
	C731.373047,544.905518 732.603577,547.086792 733.834045,549.268066 
	C733.823181,550.278320 733.812378,551.288635 733.669678,552.657104 
	C733.186951,552.990906 732.836060,552.966492 731.901001,553.010132 
	C730.891785,553.733826 730.466675,554.389404 730.041626,555.044983 
	C730.010498,556.090027 729.979309,557.135010 729.615784,558.631653 
	C730.064758,560.990112 730.324280,563.528381 731.724854,564.686096 
	C736.017700,568.234497 737.323853,572.944824 736.685730,577.839844 
	C736.034851,582.831909 734.391174,587.796448 732.463440,592.476990 
	C731.502991,594.808838 731.071716,598.766541 726.719666,598.045898 
	C726.506287,593.996338 726.292847,589.946716 725.993103,585.446289 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M617.184570,616.192505 
	C619.527588,616.463562 621.860352,616.713745 624.586792,616.918152 
	C635.539185,616.452209 646.096436,615.979065 656.658020,615.646118 
	C659.591309,615.553711 662.538086,615.868164 665.477966,616.010803 
	C673.064148,616.378967 680.651794,616.723999 688.233337,617.171692 
	C689.095337,617.222595 689.922119,617.867981 690.899658,618.613831 
	C680.909119,620.552979 670.784180,622.115906 660.292725,623.815308 
	C651.196960,622.718811 642.467651,621.485901 633.341675,620.378662 
	C627.976196,619.879456 623.007385,619.254578 617.609619,618.240845 
	C617.166077,617.294373 617.151550,616.736816 617.137024,616.179321 
	C617.137024,616.179321 617.174377,616.171570 617.184570,616.192505 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M726.610291,598.399231 
	C731.071716,598.766541 731.502991,594.808838 732.463440,592.476990 
	C734.391174,587.796448 736.034851,582.831909 736.685730,577.839844 
	C737.323853,572.944824 736.017700,568.234497 731.724854,564.686096 
	C730.324280,563.528381 730.064758,560.990112 729.598999,559.023315 
	C730.080872,559.427063 730.109619,560.053284 730.435120,560.328369 
	C732.921509,562.429688 736.115234,564.024109 737.858459,566.606750 
	C741.623352,572.184448 741.049316,578.452942 738.638855,584.468506 
	C736.445496,589.942200 734.099792,595.373108 731.439209,600.629456 
	C730.700439,602.089050 728.602234,602.860474 726.781189,603.940186 
	C726.456726,602.205994 726.478821,600.479309 726.610291,598.399231 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M689.313538,614.991821 
	C689.626648,614.784546 690.282043,614.586182 690.981628,614.686462 
	C690.569153,614.990356 690.112488,614.995544 689.313538,614.991821 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M720.510498,635.949951 
	C719.786255,631.391846 718.221436,628.538574 712.894714,628.587708 
	C711.289368,628.602539 709.661560,626.177002 708.043518,624.489319 
	C711.359131,624.443115 714.675232,624.776978 718.566528,625.323120 
	C719.141785,625.535400 719.600037,625.648987 719.600037,625.648987 
	C719.600037,625.648987 719.996338,625.392456 719.996338,625.392456 
	C725.387817,626.379944 730.793579,627.297607 736.162354,628.396057 
	C737.839478,628.739197 739.416809,629.570496 741.165161,630.554993 
	C739.793823,632.299927 738.297180,633.668213 736.753662,635.369080 
	C731.461365,635.802734 726.215881,635.903809 720.510498,635.949951 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M719.834839,625.253418 
	C719.996338,625.392456 719.600037,625.648987 719.600037,625.648987 
	C719.600037,625.648987 719.141785,625.535400 718.994263,625.350647 
	C719.113342,625.003418 719.388916,624.986206 719.834839,625.253418 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M541.044250,475.985718 
	C539.651733,474.583771 538.259216,473.181824 536.767090,471.455811 
	C538.766541,470.428070 540.865723,469.724365 543.317139,468.999817 
	C544.576355,470.586670 545.483276,472.194397 546.707397,473.972839 
	C547.024536,474.143555 547.014526,474.595215 546.688232,474.851471 
	C546.660645,476.268127 546.691101,477.607513 547.318848,478.548340 
	C548.339294,480.077759 549.750671,481.346375 550.664124,482.728760 
	C547.235535,480.483551 544.139893,478.234650 541.044250,475.985718 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M670.574219,362.404999 
	C671.300415,362.261536 671.689575,362.256317 672.412476,362.488190 
	C672.710999,363.070923 672.675598,363.416595 672.640259,363.762268 
	C670.329102,364.440979 668.017883,365.119720 665.374756,365.949341 
	C662.733704,366.026001 660.424561,365.951813 657.700195,365.886444 
	C655.608521,363.910400 656.489380,362.908966 658.666260,362.800842 
	C662.517944,362.609528 666.379578,362.618256 670.574219,362.404999 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M819.921143,571.226807 
	C818.872620,571.751404 817.852905,571.943665 816.428589,571.731201 
	C815.883423,564.375305 808.343506,558.659546 801.060791,559.983398 
	C801.060791,559.983398 801.021179,559.976379 801.010132,559.958496 
	C801.531677,558.956909 801.991760,557.179504 802.609131,557.123047 
	C805.715881,556.839111 809.035461,556.374878 811.953308,557.171570 
	C815.571960,558.159668 819.111145,559.972229 822.215027,562.110474 
	C824.059937,563.381348 824.875427,565.920227 822.428040,568.045959 
	C821.487122,568.863159 820.769470,569.937378 819.921143,571.226807 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M828.162842,570.306580 
	C827.407166,570.793213 826.628540,570.913757 825.458862,571.023926 
	C825.341797,569.432007 825.615906,567.850342 825.890015,566.268616 
	C826.397888,566.260498 826.905762,566.252380 827.413635,566.244202 
	C827.655701,567.476257 827.897827,568.708374 828.162842,570.306580 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M832.454895,574.441162 
	C832.518616,574.401428 832.391174,574.480957 832.454895,574.441162 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M830.197876,574.918457 
	C830.020569,575.008850 829.876648,574.562073 829.874146,574.328064 
	C830.039490,574.338684 830.207336,574.583374 830.197876,574.918457 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M801.117310,560.335999 
	C808.343506,558.659546 815.883423,564.375305 816.037354,571.755127 
	C814.725525,573.243713 813.400330,574.303589 811.918701,575.650757 
	C806.758606,575.158386 808.842346,571.765625 809.869568,569.582886 
	C811.663330,565.771484 810.186096,564.082031 806.426147,562.982544 
	C804.430725,562.210266 802.802307,561.449402 801.117310,560.335999 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M816.879028,580.034119 
	C814.852234,582.794556 812.707642,585.257690 810.251221,587.845032 
	C811.601685,585.168762 813.264038,582.368225 815.031860,579.230957 
	C815.678589,579.175110 816.219971,579.455933 816.879028,580.034119 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M598.977234,496.546692 
	C605.873108,502.488831 612.768982,508.430969 619.817749,515.021606 
	C619.955750,515.919617 619.940857,516.169128 619.696350,516.525635 
	C618.857300,516.867065 618.247864,517.101440 617.205017,517.256714 
	C610.327393,518.088989 603.809265,520.043030 597.461731,519.592651 
	C592.065735,519.209656 588.321716,520.356812 584.590820,524.077148 
	C580.822083,522.895996 577.426880,521.768921 573.785339,520.560059 
	C573.054016,524.741455 572.646729,527.668396 572.018555,530.547119 
	C570.239624,538.700378 565.783630,542.732544 558.476440,542.416931 
	C557.769592,539.345886 557.226135,536.654053 556.980957,533.797241 
	C556.157349,528.299072 556.601868,523.054382 558.417908,517.959778 
	C559.564453,514.743164 557.935974,513.680237 555.273254,512.592346 
	C552.648438,511.519958 550.310791,509.744843 547.916260,507.891846 
	C548.987366,504.757202 550.518372,502.077087 550.883789,499.246490 
	C551.707764,492.864075 552.539795,491.754944 559.636230,490.795593 
	C560.292847,491.151611 560.675659,491.505920 561.061707,492.218628 
	C562.394043,494.029419 563.723145,495.481873 565.030884,496.954163 
	C565.009583,496.973969 565.066650,496.963165 565.045044,497.331268 
	C567.023193,499.788239 569.022888,501.877136 571.297729,504.104675 
	C572.089905,505.089264 572.606873,505.935242 573.115479,507.128357 
	C573.754761,507.962921 574.402283,508.450348 575.029541,508.967590 
	C575.009216,508.997375 575.064636,508.950653 575.044312,509.325378 
	C577.255737,512.372559 579.318787,515.227051 581.852234,517.573975 
	C582.402161,518.083374 585.307068,517.209534 585.961975,516.209595 
	C587.402161,514.010498 588.098206,511.323914 589.624878,507.525452 
	C586.022766,508.368103 583.792480,508.889862 581.189880,509.498718 
	C579.821533,504.475311 578.448730,499.435364 577.103027,494.213440 
	C577.130188,494.031494 577.008789,493.684204 577.434631,493.732239 
	C579.555908,494.008911 581.251282,494.237488 582.995178,494.714294 
	C583.665466,494.886688 584.287292,494.810822 585.347961,494.774841 
	C590.183594,495.392029 594.580444,495.969360 598.977234,496.546692 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M550.997070,482.725037 
	C549.750671,481.346375 548.339294,480.077759 547.318848,478.548340 
	C546.691101,477.607513 546.660645,476.268127 546.684692,475.077332 
	C553.389160,476.068726 559.770874,477.090515 566.152527,478.112274 
	C566.448669,477.735962 566.744812,477.359619 567.041016,476.983276 
	C565.369751,474.534943 563.698486,472.086609 562.028992,469.255615 
	C564.143677,469.833160 566.256531,470.793427 568.731323,472.217407 
	C569.822449,473.042816 570.551697,473.404449 571.281006,473.766113 
	C571.281006,473.766113 571.657227,473.846405 571.869629,474.260132 
	C572.799561,475.055389 573.517090,475.436920 574.234558,475.818481 
	C574.234558,475.818481 574.616272,475.907074 574.848083,476.316498 
	C575.809387,477.077148 576.538818,477.428375 577.268311,477.779602 
	C577.924194,477.908386 578.580078,478.037140 579.569153,478.160492 
	C580.558655,478.832977 581.214966,479.510864 581.959229,480.799011 
	C580.729248,482.759705 579.243958,483.985962 578.130127,485.487671 
	C576.107788,488.214264 576.364136,490.459198 580.164246,491.478119 
	C579.005066,491.968079 578.007263,492.389832 576.635742,492.830933 
	C575.524048,492.755707 574.786133,492.661102 573.937012,492.257721 
	C572.213867,491.957764 570.601807,491.966583 568.593811,491.989624 
	C567.153564,491.895355 566.109314,491.786926 565.038635,491.290710 
	C565.916016,489.057495 566.819763,487.212067 567.337830,486.154144 
	C562.634888,485.280334 557.141296,484.259644 551.433228,483.176086 
	C551.218750,483.113190 550.997070,482.725037 550.997070,482.725037 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M623.040161,514.531067 
	C620.327698,511.761444 617.615295,508.991791 614.564819,505.876892 
	C615.099304,505.410309 615.694031,504.891113 616.569214,504.127075 
	C614.038147,501.097046 606.665100,502.551392 608.094482,495.045776 
	C610.840393,491.285065 612.698975,493.302826 614.776001,495.505554 
	C617.336609,498.221039 620.115356,500.736359 622.915588,503.207092 
	C623.094727,503.365143 624.269226,502.395081 625.231812,501.683533 
	C630.857605,500.852905 632.602112,502.408539 632.628235,508.026154 
	C630.781616,507.260651 629.283630,506.568970 626.854126,505.447205 
	C628.678772,509.114655 629.936157,511.641998 631.076965,514.491333 
	C628.978516,514.874878 626.996704,514.936523 624.682007,514.936035 
	C623.912781,514.759705 623.476440,514.645386 623.040161,514.531067 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M577.009460,492.811584 
	C578.007263,492.389832 579.005066,491.968079 580.164246,491.478119 
	C576.364136,490.459198 576.107788,488.214264 578.130127,485.487671 
	C579.243958,483.985962 580.729248,482.759705 582.145081,481.029907 
	C589.834106,482.473053 593.854614,488.891144 599.038086,493.793762 
	C599.453796,494.186981 599.256348,495.228516 599.160522,496.257751 
	C594.580444,495.969360 590.183594,495.392029 585.174072,494.549316 
	C584.023132,494.344635 583.484924,494.405334 582.946716,494.466064 
	C581.251282,494.237488 579.555908,494.008911 577.461792,493.514191 
	C577.063049,493.248077 577.009460,492.811584 577.009460,492.811584 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M624.980713,501.949799 
	C624.269226,502.395081 623.094727,503.365143 622.915588,503.207092 
	C620.115356,500.736359 617.336609,498.221039 614.776001,495.505554 
	C612.698975,493.302826 610.840393,491.285065 607.900269,494.772369 
	C604.589905,492.201019 601.377747,489.245453 598.165527,486.289917 
	C598.350952,485.881531 598.536377,485.473175 598.721802,485.064789 
	C602.608459,486.340118 606.528687,487.523102 610.372375,488.916901 
	C614.184814,490.299438 617.502686,490.561523 620.079956,486.037781 
	C621.519104,486.344910 622.271790,486.681732 622.909546,487.297791 
	C622.908264,488.684601 623.021729,489.792145 622.909668,491.178528 
	C621.545410,491.872803 620.406677,492.288300 618.358826,493.035431 
	C620.890015,494.721680 622.711243,495.934937 623.372375,496.375336 
	C624.137634,499.027802 624.559204,500.488800 624.980713,501.949799 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M619.110840,481.281372 
	C618.038513,480.498291 616.966187,479.715240 615.964905,478.168518 
	C621.074585,477.284882 626.113220,477.164856 631.119507,477.367126 
	C630.780212,478.762604 630.473206,479.835754 630.128540,480.944275 
	C630.091003,480.979645 629.991150,481.005432 629.964905,480.724182 
	C629.612244,480.327820 629.285767,480.212677 628.567993,480.207520 
	C625.154663,480.638763 622.132751,480.960083 619.110840,481.281372 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M619.096680,481.624817 
	C622.132751,480.960083 625.154663,480.638763 628.705566,480.398621 
	C629.414673,480.758728 629.666870,480.933960 629.991150,481.005432 
	C629.991150,481.005432 630.091003,480.979645 630.099243,481.302612 
	C630.228088,482.331055 630.348694,483.036499 630.207764,484.001160 
	C628.895386,485.084198 627.844543,485.908051 626.421387,486.802917 
	C625.040894,486.922150 624.032654,486.970337 623.024414,487.018555 
	C622.271790,486.681732 621.519104,486.344910 620.451233,485.932983 
	C619.784790,484.561371 619.433655,483.264801 619.096680,481.624817 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M631.151855,477.044830 
	C626.113220,477.164856 621.074585,477.284882 615.896973,477.820190 
	C615.387390,477.855194 615.016663,477.474884 614.413086,476.965576 
	C614.104126,476.648163 614.028076,476.459747 614.028198,476.051880 
	C614.342468,475.730835 614.562317,475.598724 615.023926,475.090210 
	C618.847107,474.178284 622.410278,473.612305 626.382202,473.008301 
	C629.194824,473.311981 631.598633,473.653717 634.002441,473.995453 
	C634.250183,474.467682 634.497864,474.939880 634.554993,475.722076 
	C633.900940,476.352478 633.437500,476.672913 632.669800,477.001801 
	C631.960876,477.021790 631.556396,477.033325 631.151855,477.044830 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M622.953491,514.811218 
	C623.476440,514.645386 623.912781,514.759705 624.621582,515.149292 
	C624.894043,515.424500 624.868896,515.866760 624.583923,516.045654 
	C623.750000,516.613220 623.201050,517.001892 622.360352,517.323975 
	C621.354370,516.977783 620.640198,516.698181 619.926025,516.418579 
	C619.940857,516.169128 619.955750,515.919617 620.005981,515.296448 
	C620.983093,514.978943 621.924988,515.035156 622.953491,514.811218 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M625.973389,473.046326 
	C622.410278,473.612305 618.847107,474.178284 614.755798,475.062347 
	C614.066345,475.462067 613.905090,475.543732 613.743774,475.625397 
	C608.540955,473.707245 603.338196,471.789124 598.049927,469.361084 
	C598.380005,468.193237 598.795593,467.535248 599.619995,466.901001 
	C604.607422,467.288849 609.186035,467.652985 614.105103,468.142151 
	C618.288208,469.860229 622.130798,471.453278 625.973389,473.046326 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M599.211182,466.877258 
	C598.795593,467.535248 598.380005,468.193237 597.742676,469.178101 
	C594.852722,468.819092 592.184509,468.133179 589.267273,467.175293 
	C589.001221,466.486084 588.984192,466.068848 588.980103,465.338104 
	C592.678894,462.836731 588.107056,460.994476 588.693970,458.576233 
	C589.878052,457.157745 591.106323,456.097534 592.951172,455.029510 
	C593.746338,455.065918 593.924927,455.110168 594.085205,455.533752 
	C595.781616,459.567841 597.496399,463.222565 599.211182,466.877258 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M613.741028,475.645569 
	C613.905090,475.543732 614.066345,475.462067 614.495728,475.408295 
	C614.562317,475.598724 614.342468,475.730835 613.916077,475.768494 
	C613.727844,475.704559 613.738281,475.665771 613.741028,475.645569 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M571.174377,473.468140 
	C570.551697,473.404449 569.822449,473.042816 569.050232,472.341980 
	C569.694153,472.391937 570.380920,472.781067 571.174377,473.468140 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M574.145630,475.511047 
	C573.517090,475.436920 572.799561,475.055389 572.046265,474.336243 
	C572.692566,474.400330 573.374634,474.802002 574.145630,475.511047 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M577.166138,477.477295 
	C576.538818,477.428375 575.809387,477.077148 575.027832,476.394531 
	C575.671814,476.433777 576.367920,476.804352 577.166138,477.477295 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M485.977539,577.376648 
	C487.091370,577.438354 488.143585,577.890686 489.182526,579.064209 
	C489.123627,581.125305 489.078033,582.465149 489.032410,583.805054 
	C489.006317,584.643799 488.980194,585.482605 488.879211,586.737061 
	C483.747894,585.284119 484.694672,581.567993 485.977539,577.376648 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M490.793671,590.126709 
	C490.326996,590.383972 489.949738,590.283142 489.311279,590.041504 
	C488.983124,589.214905 488.916168,588.529175 488.839142,587.499329 
	C489.513763,588.026428 490.198425,588.897522 490.793671,590.126709 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M489.354065,583.910645 
	C489.078033,582.465149 489.123627,581.125305 489.337769,579.407227 
	C490.241089,578.196777 490.975922,577.364441 492.228821,576.247253 
	C495.215424,576.354370 497.683960,576.746338 500.152466,577.138306 
	C499.416229,578.151245 498.679993,579.164185 497.861938,580.483765 
	C496.268280,581.854797 494.756470,582.919189 493.113525,584.254761 
	C491.880188,584.356079 490.777954,584.186218 489.354065,583.910645 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M691.963135,451.975830 
	C691.997620,451.141022 692.032104,450.306244 692.173096,449.060364 
	C693.644836,448.105804 695.010132,447.562378 697.168274,446.703369 
	C697.168274,448.847443 697.168274,450.980377 696.739746,453.558594 
	C695.518555,454.987457 694.725830,455.971039 693.933167,456.954620 
	C693.933167,456.954620 693.507141,457.007965 692.946533,456.961609 
	C692.244995,455.268768 692.104065,453.622314 691.963135,451.975830 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M696.062744,459.950378 
	C696.021240,459.228485 695.979736,458.506622 695.980957,457.097107 
	C696.442688,455.939728 696.861633,455.470001 697.280579,455.000244 
	C697.476013,456.510559 697.671448,458.020905 697.494080,459.839478 
	C696.768433,460.081909 696.415588,460.016144 696.062744,459.950378 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M697.237549,454.736572 
	C696.861633,455.470001 696.442688,455.939728 695.932129,456.738892 
	C695.417236,457.045441 694.994080,457.022614 694.252014,456.977173 
	C694.725830,455.971039 695.518555,454.987457 696.690796,453.978516 
	C697.111755,454.126404 697.153137,454.299622 697.237549,454.736572 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M695.825317,460.120605 
	C696.415588,460.016144 696.768433,460.081909 697.377502,460.222168 
	C697.549255,460.770050 697.464783,461.243439 697.182251,461.862244 
	C696.518799,461.435394 696.053345,460.863098 695.825317,460.120605 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M732.664673,453.082550 
	C732.643127,454.282776 732.292603,455.452057 731.828125,456.989197 
	C731.159973,456.811737 730.605774,456.266418 729.996704,455.417816 
	C730.220398,454.584686 730.498962,454.054810 730.897095,453.250305 
	C731.456299,453.000977 731.895996,453.026276 732.664673,453.082550 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M722.599487,467.995422 
	C722.568604,468.646240 722.140015,469.280121 721.110657,469.959595 
	C720.316345,469.987061 720.122742,469.969025 719.929199,469.951019 
	C719.914856,469.606049 719.900513,469.261047 719.884949,468.657593 
	C720.656433,468.258911 721.429138,468.118713 722.599487,467.995422 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M691.604614,451.988525 
	C692.104065,453.622314 692.244995,455.268768 692.733215,456.985413 
	C693.063049,461.057220 693.045593,465.058807 692.778625,469.681274 
	C692.329041,470.839508 692.129028,471.376892 691.929077,471.914307 
	C691.929016,471.914276 691.450500,471.942505 691.210938,471.956177 
	C686.497742,472.408020 682.024170,472.846191 675.993164,473.436920 
	C680.301086,464.294342 684.181274,457.064209 691.604614,451.988525 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M686.052246,477.066956 
	C686.483887,477.056854 686.915588,477.046814 687.667969,476.985535 
	C694.790222,476.563049 701.591675,476.191772 709.209290,475.797058 
	C711.396423,476.564117 712.741150,477.405487 714.147034,478.128143 
	C715.178772,478.658478 716.290466,479.033081 717.597168,479.638062 
	C718.225708,480.187897 718.623657,480.576752 718.989075,481.367432 
	C718.973633,482.311737 718.990723,482.854156 718.550842,483.308472 
	C713.711182,483.161346 709.328430,483.102325 704.669556,482.774139 
	C699.285889,481.536713 694.188599,480.505371 689.058716,479.675537 
	C688.375549,479.565033 687.526917,480.476685 686.549072,481.091248 
	C685.389221,480.724091 684.435242,480.180695 683.614502,479.356415 
	C684.515930,478.406006 685.284119,477.736481 686.052246,477.066956 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M717.366577,479.477112 
	C716.290466,479.033081 715.178772,478.658478 714.147034,478.128143 
	C712.741150,477.405487 711.396423,476.564117 709.642700,475.686279 
	C709.210388,474.904388 709.160889,474.209839 709.159790,473.160126 
	C712.539368,471.896149 715.870544,470.987366 719.565430,470.014801 
	C720.122742,469.969025 720.316345,469.987061 720.801514,470.024902 
	C724.238770,470.075531 727.384338,470.106384 730.784790,470.563660 
	C726.010193,472.269745 720.980713,473.549469 715.801819,474.867218 
	C716.425232,476.703796 716.895874,478.090454 717.366577,479.477112 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M697.977661,470.843506 
	C698.584656,470.993988 699.191589,471.144440 699.889343,471.500061 
	C699.571716,472.081543 699.163330,472.457855 698.412964,472.515076 
	C698.039856,471.745148 698.008728,471.294342 697.977661,470.843506 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M697.788391,470.859528 
	C698.008728,471.294342 698.039856,471.745148 698.111328,472.529877 
	C697.362976,472.910797 696.574158,472.957855 695.392700,473.002441 
	C695.000000,473.000000 695.002441,473.002441 695.002441,473.002441 
	C695.544861,472.386444 696.087219,471.770447 696.925781,471.036530 
	C697.221863,470.918610 697.599182,470.875549 697.788391,470.859528 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M692.228882,471.915894 
	C692.129028,471.376892 692.329041,470.839508 692.776917,470.143311 
	C692.859436,470.628815 692.694092,471.273163 692.228882,471.915894 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M694.999146,472.998779 
	C694.341003,473.294617 693.683594,473.591736 693.026245,473.888824 
	C692.957397,473.676392 692.888550,473.463959 692.819702,473.251526 
	C693.348389,473.167450 693.877014,473.083405 694.704102,473.000885 
	C695.002441,473.002441 695.000000,473.000000 694.999146,472.998779 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M732.229797,444.097778 
	C732.863525,444.232208 733.453369,444.717224 734.092773,445.496429 
	C733.490173,445.343201 732.838013,444.895782 732.229797,444.097778 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M717.597168,479.638062 
	C716.895874,478.090454 716.425232,476.703796 715.801819,474.867218 
	C720.980713,473.549469 726.010193,472.269745 731.213867,470.674744 
	C731.388062,470.359467 731.729004,470.279022 731.857483,470.160217 
	C734.035400,469.955811 736.084900,469.870239 738.558594,469.650330 
	C742.016541,470.552734 746.194031,468.008667 748.246704,472.792786 
	C747.081604,474.347443 745.812439,475.551239 743.971191,476.934052 
	C742.658936,478.111298 741.918945,479.109558 741.178894,480.107819 
	C741.178894,480.107819 740.813965,480.217651 740.230042,480.138733 
	C737.249329,480.490112 734.810547,480.765747 732.469849,481.402283 
	C730.773560,481.863617 729.207458,482.803345 727.221436,483.453735 
	C724.246887,482.573700 721.634216,481.769653 719.021606,480.965576 
	C718.623657,480.576752 718.225708,480.187897 717.597168,479.638062 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M758.734619,465.092896 
	C758.590393,465.708893 758.113464,466.329407 757.317505,466.982849 
	C757.466309,466.376312 757.934143,465.736877 758.734619,465.092896 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M497.992798,524.466370 
	C498.862122,524.132141 499.568634,524.081787 500.633362,524.350464 
	C501.038269,525.082581 501.084930,525.495605 500.912415,526.199707 
	C499.439484,528.708801 498.185730,530.926880 496.547668,533.155090 
	C495.154846,533.280334 494.146301,533.395325 493.085938,533.082275 
	C493.021545,531.794739 493.008972,530.935242 492.996399,530.075806 
	C494.607605,528.300659 496.218811,526.525452 497.992798,524.466370 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M492.651611,530.104736 
	C493.008972,530.935242 493.021545,531.794739 493.088440,533.385437 
	C493.111511,534.746033 493.080261,535.375305 492.822815,536.107300 
	C492.309387,536.403992 492.118683,536.667786 492.024506,537.001404 
	C490.571045,537.564514 489.117554,538.127625 487.351807,538.825928 
	C487.027252,538.508118 487.014984,538.054932 486.999084,537.259888 
	C488.765900,534.656616 490.536346,532.395142 492.651611,530.104736 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M501.959778,564.940735 
	C501.410645,565.110352 500.861481,565.279907 500.118896,565.666870 
	C494.641052,567.100159 498.632660,569.956299 498.474518,572.524719 
	C495.079803,572.316101 492.029938,571.722412 488.980042,571.128784 
	C488.947327,568.313599 488.914581,565.498474 488.813782,562.288696 
	C489.782593,556.515381 490.819397,551.136658 492.163574,545.179077 
	C493.478851,541.785706 494.980408,540.809326 497.572662,543.069397 
	C498.543579,543.915955 499.740662,544.503113 500.925598,545.480347 
	C501.204681,546.289368 501.392761,546.826721 501.201599,547.518188 
	C496.938812,547.052307 493.264893,547.257080 492.244781,551.939819 
	C491.278015,556.377747 490.839020,560.930542 490.122803,565.735779 
	C491.589111,565.735779 492.634216,566.004639 493.460876,565.693359 
	C500.261719,563.132629 507.048065,560.526917 513.756165,557.736145 
	C514.468323,557.439880 514.649658,555.867554 515.441528,554.883911 
	C517.167419,555.277100 518.528931,555.673645 519.890442,556.070129 
	C517.166260,557.656555 514.442139,559.243042 510.990662,560.929932 
	C507.495514,562.333862 504.727631,563.637329 501.959778,564.940735 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M488.837280,571.425659 
	C492.029938,571.722412 495.079803,572.316101 498.519440,572.862488 
	C499.724762,572.916809 500.683502,572.784058 501.322723,573.174438 
	C502.307983,573.776123 503.074310,574.736450 503.933533,575.544617 
	C502.935394,576.082397 501.937225,576.620178 500.545776,577.148132 
	C497.683960,576.746338 495.215424,576.354370 492.329468,575.959045 
	C490.839508,574.544678 489.766998,573.133606 488.837280,571.425659 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M500.834625,545.208557 
	C499.740662,544.503113 498.543579,543.915955 497.572662,543.069397 
	C494.980408,540.809326 493.478851,541.785706 492.160339,544.788208 
	C491.910706,542.586121 491.971680,540.196045 492.028595,537.403687 
	C492.118683,536.667786 492.309387,536.403992 493.121643,536.256714 
	C496.042664,539.271851 498.438629,542.240173 500.834625,545.208557 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M709.187134,637.229370 
	C710.168213,636.761719 711.362488,636.563171 712.794006,636.589478 
	C711.820923,637.042419 710.610657,637.270508 709.187134,637.229370 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M572.850220,580.868469 
	C571.770020,583.414612 570.689880,585.960693 569.341431,588.748047 
	C567.411804,589.641724 565.750549,590.294128 563.768921,590.935181 
	C561.953796,583.915649 560.459045,576.907471 558.969849,569.130188 
	C558.947693,567.341125 558.919861,566.321106 559.180176,564.990479 
	C559.147095,560.797485 559.289246,556.787048 558.269897,553.097534 
	C557.878052,551.679260 554.840637,550.991943 553.003418,549.715332 
	C552.994385,549.288330 552.990479,549.117493 553.198608,548.685303 
	C555.115173,547.270996 556.819702,546.117920 558.872070,544.949829 
	C560.323608,544.697632 561.565369,544.708862 562.509399,544.184204 
	C570.611694,539.681335 578.643311,535.051025 586.761475,530.577576 
	C587.321106,530.269104 588.406311,530.914673 589.143127,531.475586 
	C584.417786,544.891724 579.796448,557.948792 574.867554,571.145142 
	C574.452454,571.912231 574.344910,572.539978 574.218018,573.547546 
	C574.155945,574.937683 574.113159,575.948120 573.775635,577.164185 
	C573.270630,578.536133 573.060425,579.702271 572.850220,580.868469 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M558.892029,565.301086 
	C558.919861,566.321106 558.947693,567.341125 558.923584,568.743652 
	C550.364746,569.361633 541.857849,569.721375 533.350952,569.721497 
	C531.762024,569.721497 530.173096,568.279419 528.278809,567.002686 
	C527.696655,566.030823 527.419800,565.559082 527.142944,565.087341 
	C526.090820,562.980469 525.038635,560.873657 524.268372,558.408081 
	C525.357300,558.378540 526.164429,558.707764 527.258667,559.256958 
	C529.681824,559.551086 532.007935,560.200195 533.910889,559.570496 
	C537.102478,558.514343 540.024414,556.643555 542.990234,555.456970 
	C541.882751,557.087769 540.844727,558.377136 539.232727,560.379578 
	C545.848511,561.964722 551.519714,563.323486 557.472534,564.789429 
	C558.133484,565.031433 558.512756,565.166260 558.892029,565.301086 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M526.009888,579.999146 
	C525.666748,579.253601 525.323608,578.507996 524.988525,577.438354 
	C526.309448,575.948669 527.622192,574.783081 528.961182,573.273315 
	C531.040344,573.222534 533.093262,573.515930 535.404602,574.398865 
	C537.355469,578.479736 539.124756,581.937622 540.668152,585.493713 
	C540.939758,586.119446 540.265503,587.155701 540.028015,588.002380 
	C536.324585,585.021851 532.621216,582.041321 528.258118,579.139709 
	C527.068909,579.478821 526.539429,579.738953 526.009888,579.999146 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M540.036621,588.349304 
	C540.265503,587.155701 540.939758,586.119446 540.668152,585.493713 
	C539.124756,581.937622 537.355469,578.479736 535.630981,574.632935 
	C537.855591,574.277527 540.112183,574.277527 541.615173,574.277527 
	C542.801208,580.480835 543.895935,586.206543 544.990723,591.932312 
	C544.664429,592.196716 544.338135,592.461060 544.011841,592.725464 
	C542.689636,591.382385 541.367493,590.039307 540.036621,588.349304 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M526.860474,565.072388 
	C527.419800,565.559082 527.696655,566.030823 527.986938,566.795044 
	C527.143860,566.912659 526.287354,566.737732 525.074097,566.297363 
	C525.337524,565.707092 525.957764,565.382263 526.860474,565.072388 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M525.951172,580.297974 
	C526.539429,579.738953 527.068909,579.478821 527.891907,579.227295 
	C527.646912,580.089783 527.108459,580.943542 526.254883,581.796997 
	C525.924072,581.396729 525.908325,580.996765 525.951172,580.297974 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M518.741577,597.183289 
	C518.268921,597.413757 517.874756,597.285400 517.172729,597.097473 
	C516.907043,596.590881 516.949158,596.143921 517.043945,595.364685 
	C517.671143,595.629761 518.245544,596.227112 518.741577,597.183289 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M526.971497,559.036987 
	C526.164429,558.707764 525.357300,558.378540 524.218140,558.054199 
	C522.773315,557.405701 521.660645,556.752319 520.219177,556.084534 
	C518.528931,555.673645 517.167419,555.277100 515.264771,554.640259 
	C514.145020,554.260498 513.566528,554.121094 512.878296,553.684448 
	C512.182922,553.261353 511.597290,553.135437 511.005310,552.999512 
	C510.998993,552.989441 510.976166,552.996033 510.808838,552.748718 
	C509.980103,552.059875 509.318726,551.618347 509.039246,551.114014 
	C512.028564,550.768677 512.713623,549.665710 510.851318,547.574707 
	C508.910400,545.395386 506.923096,543.257385 505.285522,540.916565 
	C507.775635,536.371521 505.392212,528.491272 501.131592,525.908691 
	C501.084930,525.495605 501.038269,525.082581 500.989655,524.358521 
	C501.726929,523.037415 502.466156,522.027283 503.836365,521.011169 
	C504.641144,521.007751 504.814972,521.010254 504.982971,521.345825 
	C504.988007,522.123352 504.998901,522.567749 505.049377,523.341919 
	C506.338440,524.806946 507.587891,525.942322 509.116028,527.271851 
	C511.590576,531.307617 513.786377,535.149292 515.973389,539.363464 
	C518.796997,546.249939 518.789734,546.241638 524.073914,541.592102 
	C525.436401,540.393127 526.859985,539.263672 528.629272,538.099121 
	C529.962891,537.888062 531.110718,537.948975 531.853149,537.429993 
	C538.311279,532.915466 544.696655,528.297058 551.411987,523.848022 
	C552.233154,524.937256 552.746094,525.887817 552.892700,526.917969 
	C544.823303,532.931946 537.110046,538.853271 529.437256,544.826721 
	C528.856873,545.278625 528.269470,546.065491 528.187012,546.756531 
	C527.699524,550.840149 527.360474,554.941589 526.971497,559.036987 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M502.209290,565.230957 
	C504.727631,563.637329 507.495514,562.333862 510.627197,561.001465 
	C509.754974,565.504883 506.826630,566.863037 502.209290,565.230957 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M608.969177,582.997559 
	C609.485718,584.530701 610.002258,586.063904 610.263062,587.804871 
	C608.275024,588.043945 606.505066,588.321472 604.816833,588.064026 
	C597.204956,586.903137 593.950745,590.726013 596.420227,597.952393 
	C598.032654,602.671021 599.468872,607.449890 600.968994,612.621582 
	C594.352905,611.949463 593.771423,605.019470 589.742676,601.392761 
	C587.426392,599.307617 585.807495,596.042969 581.987122,600.360352 
	C582.171082,597.070007 582.355042,593.779663 582.268433,589.809814 
	C581.989746,587.401367 581.981628,585.672485 581.987915,583.548950 
	C582.117676,581.839172 582.232971,580.523987 582.598145,579.112244 
	C582.902405,578.341370 582.956787,577.667175 583.006348,576.614319 
	C583.039490,575.481140 583.077393,574.726624 583.454834,573.912598 
	C586.279053,571.422241 588.079163,572.333923 590.903809,574.086182 
	C596.582825,577.609192 602.909668,580.087891 608.969177,582.997559 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M581.973511,583.943542 
	C581.981628,585.672485 581.989746,587.401367 581.990845,589.562195 
	C581.393921,591.222351 580.803833,592.450623 579.970825,594.184692 
	C576.171326,590.483521 573.103088,586.948975 572.900635,581.277283 
	C573.060425,579.702271 573.270630,578.536133 574.117859,577.222717 
	C577.260864,579.259949 576.825745,584.535767 581.973511,583.943542 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M616.755920,616.124268 
	C617.151550,616.736816 617.166077,617.294373 617.179688,618.129272 
	C616.281311,618.472717 615.383850,618.538818 614.195923,618.418335 
	C614.728699,617.510925 615.551758,616.790100 616.755920,616.124268 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M609.880127,617.784912 
	C609.959534,617.932556 609.503845,617.998718 609.279907,617.947266 
	C609.055908,617.895813 609.373291,617.535034 609.373291,617.535034 
	C609.373291,617.535034 609.800659,617.637329 609.880127,617.784912 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M624.869019,621.736572 
	C624.425293,622.076111 623.837402,622.202332 623.115967,622.084839 
	C623.563293,621.735291 624.144104,621.629272 624.869019,621.736572 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M499.982849,586.017334 
	C499.532501,585.893250 499.095337,585.755554 498.658142,585.617859 
	C499.028320,585.595093 499.398499,585.572327 499.893524,585.761597 
	C500.018372,585.973694 499.995972,586.003723 499.982849,586.017334 
z"
          />
          <path
            fill="#5495A4"
            opacity="1.000000"
            stroke="none"
            d="
M672.978699,363.802551 
	C672.675598,363.416595 672.710999,363.070923 672.703003,362.468109 
	C673.272949,362.432343 673.886230,362.653717 674.499451,362.875122 
	C674.105347,363.197723 673.711243,363.520294 672.978699,363.802551 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M588.049683,447.017975 
	C586.729492,446.251434 585.409302,445.484863 583.640076,444.457642 
	C586.140747,442.578217 588.030701,441.157715 589.965454,439.397339 
	C590.010254,439.057495 590.355103,438.883057 590.991943,438.914978 
	C592.228943,438.937683 592.829163,438.928436 593.429382,438.919189 
	C593.881226,438.842468 594.333130,438.765717 594.905884,439.151489 
	C594.995239,440.907745 594.963684,442.201416 594.602905,443.574921 
	C593.834900,443.755615 593.396179,443.856506 592.604858,443.966156 
	C590.851379,444.989258 589.450500,446.003632 588.049683,447.017975 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M593.315063,438.550964 
	C592.829163,438.928436 592.228943,438.937683 591.182861,438.885406 
	C590.744446,438.237000 590.751892,437.650116 590.707764,436.772400 
	C591.504456,437.048645 592.352600,437.615692 593.315063,438.550964 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M594.932129,443.495117 
	C594.963684,442.201416 594.995239,440.907745 595.004456,439.320251 
	C595.151978,439.018494 595.321899,439.010468 595.746887,438.997925 
	C598.110107,438.362091 600.218323,437.730713 602.690430,437.452454 
	C605.070984,439.327332 607.087524,440.849091 609.071228,442.766724 
	C604.423157,446.958618 599.674072,445.074249 594.932129,443.495117 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M597.091919,425.668976 
	C597.121643,426.111115 597.054688,426.553864 597.027039,426.776062 
	C595.349548,428.300690 593.699646,429.603119 591.472778,431.360962 
	C590.560364,429.413361 589.836182,427.867493 589.199341,425.941284 
	C594.825867,427.860870 594.203552,422.270416 596.616272,420.028320 
	C597.000793,421.704346 597.031494,423.465607 597.091919,425.668976 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M594.103516,455.154388 
	C593.924927,455.110168 593.746338,455.065918 593.296570,454.970428 
	C592.577332,454.307983 592.129333,453.696808 591.315002,452.657410 
	C591.315247,451.101654 591.681702,449.974091 592.358887,448.986450 
	C593.857117,448.742157 595.030457,448.108856 596.236023,448.040131 
	C597.584290,447.963287 599.689758,447.927277 600.189209,448.699249 
	C601.650269,450.957855 602.476318,453.627289 603.551208,456.135742 
	C603.265442,456.318420 602.979675,456.501099 602.693848,456.683807 
	C600.857239,454.998260 599.020569,453.312714 597.186584,451.629669 
	C595.983521,453.005096 595.043518,454.079742 594.103516,455.154388 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M592.048157,448.846527 
	C591.681702,449.974091 591.315247,451.101654 591.009644,452.604370 
	C590.042725,451.240143 589.014832,449.500732 588.018311,447.389648 
	C589.450500,446.003632 590.851379,444.989258 592.481323,444.309662 
	C592.489746,446.045135 592.268921,447.445831 592.048157,448.846527 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M550.664124,482.728760 
	C550.997070,482.725037 551.218750,483.113190 551.547119,483.493439 
	C554.371094,486.180420 556.866760,488.487152 559.362427,490.793884 
	C552.539795,491.754944 551.707764,492.864075 550.883789,499.246490 
	C550.518372,502.077087 548.987366,504.757202 547.563660,507.816254 
	C543.011414,507.276855 546.353577,504.719452 546.262390,502.819580 
	C547.178101,500.495544 547.804871,498.364502 548.579834,495.919922 
	C548.152710,495.080200 547.577454,494.554047 546.689514,493.881348 
	C544.570862,493.827301 542.764893,493.919739 540.773315,493.745178 
	C539.006958,494.122375 537.426270,494.766571 535.451294,495.238953 
	C533.087280,495.062958 531.117554,495.058838 528.739258,494.983795 
	C525.887573,494.904358 523.444519,494.895844 521.001465,494.887329 
	C524.134094,491.289215 527.368286,487.772003 530.339111,484.044922 
	C531.260193,482.889374 531.466370,481.164001 532.002075,479.301758 
	C534.761108,477.948517 537.517944,476.993073 540.659546,476.011658 
	C544.139893,478.234650 547.235535,480.483551 550.664124,482.728760 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M520.617920,494.924713 
	C523.444519,494.895844 525.887573,494.904358 528.766846,495.326660 
	C530.248291,497.673492 531.293518,499.606567 532.210144,501.929749 
	C527.263245,504.515564 522.582031,507.137390 517.575378,508.746918 
	C514.580444,509.709717 512.612183,507.616760 513.062195,504.139923 
	C514.710876,501.917114 516.359558,499.694305 517.964600,496.808716 
	C517.921326,495.744690 517.921692,495.343475 517.921997,494.942261 
	C518.692810,494.948853 519.463562,494.955475 520.617920,494.924713 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M517.645508,494.945557 
	C517.921692,495.343475 517.921326,495.744690 517.969543,496.441345 
	C517.601196,496.788757 517.184265,496.840759 516.452026,496.904053 
	C516.092590,496.730377 516.048340,496.545441 516.033691,496.075806 
	C516.498596,495.510406 516.933838,495.229675 517.645508,494.945557 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M504.988831,521.012695 
	C504.814972,521.010254 504.641144,521.007751 504.205841,521.007935 
	C502.658783,517.226990 501.373169,513.443359 500.087708,509.291016 
	C503.850281,510.225128 504.293213,506.663025 506.259003,504.726379 
	C506.019348,506.756531 505.490448,508.969177 504.806335,511.638306 
	C504.789490,512.089783 504.927887,512.084717 505.066284,512.079712 
	C505.815735,511.793243 506.565155,511.506775 507.610901,511.473755 
	C507.700653,512.980164 507.494141,514.233215 507.218170,515.907654 
	C512.659729,516.396423 517.840088,516.861694 522.901123,517.667725 
	C516.850830,519.009888 510.919830,520.011292 504.988831,521.012695 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M523.020447,517.327026 
	C517.840088,516.861694 512.659729,516.396423 507.218170,515.907654 
	C507.494141,514.233215 507.700653,512.980164 507.886719,511.336456 
	C509.384979,508.741455 510.903687,506.537170 512.742310,504.236420 
	C512.612183,507.616760 514.580444,509.709717 517.575378,508.746918 
	C522.582031,507.137390 527.263245,504.515564 532.522461,502.013489 
	C533.832458,501.731354 534.701538,501.755524 535.709656,502.132935 
	C535.952698,502.980927 536.056580,503.475708 535.949097,504.142761 
	C536.819641,505.686401 537.703186,507.302429 539.026978,508.375336 
	C541.386780,510.287933 542.207214,512.414001 540.859131,515.461914 
	C540.041870,516.048035 539.393677,516.416199 538.312317,516.766602 
	C532.926270,516.941528 527.973328,517.134277 523.020447,517.327026 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M626.382202,473.008301 
	C622.130798,471.453278 618.288208,469.860229 614.232300,467.829468 
	C614.137695,466.731293 614.256531,466.070923 614.679810,465.246918 
	C615.591187,463.141327 616.198120,461.199371 616.805054,459.257385 
	C617.245483,457.556732 617.685913,455.856079 618.565002,454.419556 
	C619.891113,456.073395 620.778564,457.463165 621.666016,458.852905 
	C622.126831,458.779877 622.587708,458.706818 623.048584,458.633789 
	C624.253296,452.290619 625.457947,445.947449 626.618164,439.838562 
	C630.650208,440.919220 630.519592,447.559906 635.994690,444.998108 
	C636.659180,448.009094 637.466309,450.999847 637.898682,454.043793 
	C638.023560,454.923157 637.303650,456.528412 636.575500,456.822998 
	C632.392700,458.515167 632.726135,461.564758 633.455933,465.097595 
	C634.010437,467.781525 634.025513,470.576874 634.141479,473.659058 
	C631.598633,473.653717 629.194824,473.311981 626.382202,473.008301 
M634.604431,448.502960 
	C634.604431,448.502960 634.487549,448.574188 634.604431,448.502960 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M635.970642,444.614227 
	C630.519592,447.559906 630.650208,440.919220 626.618164,439.838562 
	C625.457947,445.947449 624.253296,452.290619 623.048584,458.633789 
	C622.587708,458.706818 622.126831,458.779877 621.666016,458.852905 
	C620.778564,457.463165 619.891113,456.073395 618.936279,454.321503 
	C621.186157,444.993164 623.503479,436.026947 626.278564,427.339905 
	C626.706787,432.844849 629.658325,435.953278 634.107849,437.997772 
	C634.720764,440.075287 635.333679,442.152802 635.970642,444.614227 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M641.144714,475.874878 
	C650.100708,475.313690 659.050659,474.539978 668.015381,474.282135 
	C672.410767,474.155670 676.833740,474.986908 681.168335,475.667786 
	C675.162720,477.186432 669.233093,478.422028 662.967163,479.532593 
	C662.160339,479.394165 661.689758,479.380768 660.889648,479.256195 
	C660.328857,479.131531 660.097412,479.118042 659.466309,479.026764 
	C653.092651,477.924286 647.118652,476.899597 641.144714,475.874878 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M634.050476,437.560425 
	C629.658325,435.953278 626.706787,432.844849 626.609192,427.209900 
	C626.733704,425.152374 626.985474,423.503967 627.336792,421.449341 
	C628.062012,421.043365 628.687622,421.043518 629.629578,421.056580 
	C630.994019,424.158508 632.042175,427.247528 633.281982,430.624695 
	C633.646790,432.982880 633.819946,435.052979 634.050476,437.560425 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M663.303406,479.657623 
	C669.233093,478.422028 675.162720,477.186432 681.487793,475.906830 
	C683.106079,476.080475 684.329102,476.298096 685.802124,476.791321 
	C685.284119,477.736481 684.515930,478.406006 683.205444,479.413757 
	C676.444580,480.143646 670.226074,480.535400 663.940674,480.755127 
	C663.683716,480.274628 663.493530,479.966125 663.303406,479.657623 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M640.702393,475.928986 
	C647.118652,476.899597 653.092651,477.924286 659.193970,479.299866 
	C652.300049,479.203888 645.273621,478.821289 638.261047,478.265442 
	C636.476379,478.123962 634.735229,477.432251 632.974121,476.993347 
	C633.437500,476.672913 633.900940,476.352478 634.649780,475.972168 
	C636.710083,475.935913 638.485107,475.959503 640.702393,475.928986 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M614.375366,465.410522 
	C614.256531,466.070923 614.137695,466.731293 613.891785,467.704407 
	C609.186035,467.652985 604.607422,467.288849 599.619995,466.901001 
	C597.496399,463.222565 595.781616,459.567841 594.085205,455.533752 
	C595.043518,454.079742 595.983521,453.005096 597.186584,451.629669 
	C599.020569,453.312714 600.857239,454.998260 602.693848,456.683807 
	C602.979675,456.501099 603.265442,456.318420 603.551208,456.135742 
	C602.476318,453.627289 601.650269,450.957855 600.189209,448.699249 
	C599.689758,447.927277 597.584290,447.963287 596.236023,448.040131 
	C595.030457,448.108856 593.857117,448.742157 592.358887,448.986450 
	C592.268921,447.445831 592.489746,446.045135 592.833984,444.300934 
	C593.396179,443.856506 593.834900,443.755615 594.602905,443.574921 
	C599.674072,445.074249 604.423157,446.958618 609.373657,442.754517 
	C610.307922,442.409882 610.906921,442.473328 611.505859,442.536743 
	C611.758728,444.336456 612.011658,446.136200 612.216919,448.651978 
	C612.458313,449.906769 612.747375,450.445435 613.036438,450.984131 
	C613.345459,453.365143 613.654541,455.746155 613.991943,458.976471 
	C614.138611,461.687347 614.256958,463.548950 614.375366,465.410522 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M611.850586,442.237274 
	C610.906921,442.473328 610.307922,442.409882 609.406494,442.358643 
	C607.087524,440.849091 605.070984,439.327332 603.059509,437.436462 
	C605.098145,435.427612 607.131775,433.787872 609.450073,431.996826 
	C614.044678,434.358765 613.425964,438.073669 611.850586,442.237274 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M614.679810,465.246918 
	C614.256958,463.548950 614.138611,461.687347 614.055054,459.364288 
	C614.771851,458.980591 615.453735,459.058411 616.470337,459.196808 
	C616.198120,461.199371 615.591187,463.141327 614.679810,465.246918 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M613.242920,450.818298 
	C612.747375,450.445435 612.458313,449.906769 612.250000,449.044189 
	C612.703613,449.364319 613.076538,450.008392 613.242920,450.818298 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M759.067627,591.300903 
	C762.163635,593.307495 765.259766,595.663757 768.693359,598.141663 
	C768.051208,599.608032 767.071594,600.952637 766.021057,602.394653 
	C762.109192,599.251282 758.655945,596.670593 759.067627,591.300903 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M777.213379,587.901489 
	C776.445068,588.029724 776.001953,588.046814 775.256470,587.915283 
	C776.732422,585.793823 778.510742,583.821045 780.650146,581.948242 
	C779.853760,583.962219 778.696167,585.876282 777.213379,587.901489 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M788.871765,561.688660 
	C789.159119,561.279663 789.446472,560.870667 789.838013,560.235657 
	C790.138306,560.012451 790.334351,560.015259 790.823975,560.049255 
	C791.866028,561.636292 792.317932,563.463684 793.434204,564.682800 
	C795.011963,566.406067 797.080627,567.679810 798.985474,569.473816 
	C796.623657,570.492676 794.215942,571.183350 791.442810,571.891113 
	C791.077454,571.908142 791.054932,571.934326 791.095215,571.610229 
	C792.048035,570.052124 792.960571,568.818176 793.873047,567.584290 
	C793.697754,567.145020 793.522400,566.705750 793.347046,566.266479 
	C789.585510,567.452454 785.823975,568.638428 782.062439,569.824463 
	C781.272217,569.547607 780.481995,569.270752 779.319214,568.621704 
	C778.806824,567.168884 778.278809,565.955322 778.595154,565.030762 
	C779.864990,561.319519 777.846985,560.823242 775.016479,560.614380 
	C775.346130,558.903748 775.627930,557.545227 775.909729,556.186707 
	C777.916138,557.504150 779.922607,558.821655 781.972778,560.909241 
	C782.361206,563.805969 780.658203,567.362366 784.972290,566.877563 
	C787.595032,566.582825 790.244263,565.413635 788.871765,561.688660 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M781.819824,570.118774 
	C785.823975,568.638428 789.585510,567.452454 793.347046,566.266479 
	C793.522400,566.705750 793.697754,567.145020 793.873047,567.584290 
	C792.960571,568.818176 792.048035,570.052124 791.083740,571.617371 
	C790.091614,572.225464 789.151245,572.502258 788.040283,573.114929 
	C787.173279,573.414062 786.476929,573.377258 785.432007,573.317627 
	C782.398865,572.683044 779.714233,572.071350 776.671997,571.378113 
	C778.502930,571.017944 780.040039,570.715576 781.819824,570.118774 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M785.121704,494.585327 
	C785.851440,494.705292 786.581177,494.825226 787.681885,494.953247 
	C788.032043,495.320190 788.168762,495.602814 788.666687,495.943176 
	C787.671509,497.852386 786.472534,499.627655 784.804810,501.671143 
	C783.908691,501.949799 783.481262,501.960175 782.775818,501.729553 
	C780.166992,501.973755 777.836243,502.458923 775.146423,502.918976 
	C772.934387,503.257782 771.081360,503.621674 769.168945,503.632141 
	C769.207581,502.575470 769.305603,501.872253 769.752686,501.140228 
	C773.063110,499.283386 776.024597,497.455353 778.986023,495.627319 
	C779.413940,495.453949 779.841858,495.280518 781.007812,495.065369 
	C782.871155,494.877563 783.996460,494.731445 785.121704,494.585327 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M802.899170,495.512939 
	C804.679504,495.273987 806.596985,495.377167 808.773560,495.789307 
	C807.033936,496.017181 805.035156,495.936127 802.899170,495.512939 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M773.842285,516.821655 
	C774.630493,516.882935 775.418640,516.944153 776.598877,517.061157 
	C776.694214,518.132324 776.397644,519.147705 775.728882,520.586304 
	C772.127502,522.601929 771.798889,524.760437 774.040833,527.487183 
	C775.067688,528.736023 776.000916,530.061951 776.976562,531.352966 
	C776.989685,532.954041 777.002808,534.555054 777.013794,536.967651 
	C776.996704,539.143738 776.981689,540.508179 776.621826,541.891541 
	C771.612122,537.835815 765.175293,535.448608 763.594849,528.181458 
	C764.840210,526.195557 766.008057,524.539062 767.466309,522.739136 
	C769.785217,520.671021 771.813782,518.746338 773.842285,516.821655 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M777.372437,531.264893 
	C776.000916,530.061951 775.067688,528.736023 774.040833,527.487183 
	C771.798889,524.760437 772.127502,522.601929 775.693848,520.905945 
	C777.347900,522.291687 778.664917,523.780945 780.080688,525.623413 
	C779.375793,527.710022 778.572083,529.443420 777.372437,531.264893 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M776.966675,541.872681 
	C776.981689,540.508179 776.996704,539.143738 777.028076,537.368286 
	C778.998779,538.276062 780.953003,539.594727 783.594299,541.035767 
	C784.524048,541.122253 784.766724,541.086487 785.009399,541.050720 
	C785.009399,541.050720 785.020508,540.998047 784.995605,540.989258 
	C785.410950,542.311707 785.851196,543.643005 786.540466,545.249390 
	C786.803955,546.415283 786.818481,547.306091 786.791626,548.530151 
	C785.575256,549.193359 784.400391,549.523499 782.866943,550.012512 
	C782.090210,549.998779 781.671875,549.826111 781.469849,549.371033 
	C783.314026,545.344421 781.771362,543.014832 777.135010,542.190918 
	C777.080688,542.083923 776.966675,541.872681 776.966675,541.872681 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M785.049316,540.752319 
	C784.766724,541.086487 784.524048,541.122253 783.914551,541.110596 
	C784.061584,540.860168 784.575378,540.657043 785.049316,540.752319 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M783.053833,501.970520 
	C783.481262,501.960175 783.908691,501.949799 784.656555,501.960999 
	C785.278259,502.528778 785.704712,503.586548 785.862854,503.547852 
	C793.800049,501.605591 794.361938,509.898193 799.187073,512.496948 
	C791.334656,515.123413 783.845764,516.286743 776.326050,513.151978 
	C778.655151,509.000549 780.854492,505.485535 783.053833,501.970520 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M773.869446,516.455933 
	C771.813782,518.746338 769.785217,520.671021 767.420898,522.313599 
	C767.332703,519.796875 767.903442,517.537781 767.737061,515.334351 
	C767.569763,513.117859 766.669067,510.956787 766.089722,508.771454 
	C766.420837,507.409576 766.752014,506.047699 767.044006,504.330353 
	C767.004883,503.974884 767.003479,504.006195 767.362122,504.041443 
	C768.223267,504.046326 768.725830,504.015961 769.228333,503.985565 
	C771.081360,503.621674 772.934387,503.257782 775.304688,503.287811 
	C775.208252,506.839386 774.594482,509.997040 773.980713,513.154663 
	C773.952698,514.133179 773.924622,515.111633 773.869446,516.455933 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M774.315430,513.098389 
	C774.594482,509.997040 775.208252,506.839386 775.663696,503.312927 
	C777.836243,502.458923 780.166992,501.973755 782.775818,501.729553 
	C780.854492,505.485535 778.655151,509.000549 776.231079,512.757568 
	C775.554260,513.013733 775.102234,513.027893 774.315430,513.098389 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M750.004883,512.995056 
	C750.004883,512.995056 749.706238,513.166199 749.136169,513.136597 
	C744.381531,515.764771 740.196960,518.422668 736.012390,521.080505 
	C734.658081,521.847778 733.303711,522.615051 731.406555,523.098938 
	C728.900513,522.382141 726.949890,521.876038 724.970825,521.534668 
	C722.786377,521.157959 720.554932,521.038391 718.382751,520.610229 
	C716.350525,520.209656 714.348511,519.036804 712.369629,519.115967 
	C708.434570,519.273254 706.916687,517.522522 707.056885,513.681519 
	C707.398071,513.245544 707.658813,513.078369 708.194336,512.886902 
	C708.709290,512.596985 708.900879,512.334167 709.005127,511.738159 
	C708.991272,511.298950 708.970276,511.123962 708.954590,510.957825 
	C708.959961,510.966675 708.952637,510.949860 709.271545,510.950897 
	C710.100769,510.357056 710.611084,509.762177 711.378967,508.989990 
	C712.724854,507.861603 713.813171,506.910492 715.241638,505.935669 
	C716.039368,505.238464 716.496948,504.564911 717.038574,503.671600 
	C717.308899,503.174896 717.566650,503.008606 718.267761,502.966187 
	C719.759827,501.986206 720.879883,500.993103 722.265015,499.850037 
	C723.085754,498.877655 723.641541,498.055298 724.500732,497.170990 
	C725.583313,496.570404 726.362488,496.031738 727.470642,495.435303 
	C728.244385,495.351349 728.689209,495.325134 729.410034,495.509125 
	C732.090027,495.208374 734.493896,494.697418 736.897766,494.186462 
	C737.985962,494.129089 739.074097,494.071686 740.579468,494.441956 
	C739.966919,499.227783 738.937256,503.585938 737.572876,508.021973 
	C735.386536,508.364349 733.537659,508.649994 731.682373,508.884857 
	C730.668640,509.013153 729.644104,509.055481 728.624390,509.136627 
	C729.109253,511.251038 729.142517,513.661011 730.256714,515.363586 
	C730.876282,516.310425 733.992615,516.893494 734.777039,516.224915 
	C737.164551,514.189880 738.965576,511.466675 741.149475,508.734558 
	C742.245239,507.314575 743.186523,506.171448 744.478760,504.946228 
	C746.210327,501.218018 747.590881,497.571869 749.333496,493.902710 
	C751.480713,493.116821 753.265930,492.353943 755.051147,491.591034 
	C755.394287,491.354523 755.737427,491.118042 756.731201,490.880798 
	C757.868713,490.875549 758.355530,490.871063 758.842407,490.866608 
	C765.821045,491.276428 766.566162,492.226868 764.960938,499.679474 
	C764.070496,499.244263 763.412720,498.443665 763.596619,497.969849 
	C765.080078,494.148682 761.985840,492.992279 759.879028,493.383301 
	C756.961548,493.924774 754.306641,495.979492 751.662598,497.588898 
	C751.537109,497.665283 752.341248,500.295319 753.186157,500.671143 
	C754.169678,501.108643 755.675354,500.372131 756.278259,500.265106 
	C758.143250,501.742279 759.581360,502.881348 761.029053,504.402100 
	C761.142883,505.283386 761.247131,505.783020 761.351379,506.282654 
	C761.351379,506.282654 761.291260,506.277069 760.941345,506.216858 
	C760.059448,506.718323 759.527405,507.279999 758.995361,507.841675 
	C758.684326,507.985992 758.373230,508.130341 757.429321,508.211792 
	C756.197510,508.760651 755.598572,509.372345 754.999634,509.984070 
	C754.336304,510.327118 753.673035,510.670197 752.377380,511.071411 
	C751.165039,511.751373 750.584961,512.373230 750.004883,512.995056 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M727.583313,483.529724 
	C729.207458,482.803345 730.773560,481.863617 732.469849,481.402283 
	C734.810547,480.765747 737.249329,480.490112 740.040710,480.160370 
	C740.108398,480.748901 739.781494,481.236908 738.944702,481.938965 
	C737.269958,483.424927 736.105042,484.696899 734.940186,485.968842 
	C734.734558,486.195618 734.515137,486.408203 733.745117,486.727875 
	C730.456848,489.244446 727.705505,491.639740 724.648926,494.142426 
	C723.552429,495.176514 722.761230,496.103210 721.985901,497.015747 
	C722.001770,497.001587 722.036255,496.976440 721.684387,496.964478 
	C720.443970,496.889435 719.555481,496.826324 718.601257,496.758514 
	C718.871460,495.452484 719.090576,494.393768 719.607910,493.233521 
	C720.250977,492.421112 720.595764,491.710266 721.157166,490.786133 
	C722.275757,489.558289 723.177795,488.543701 724.434570,487.433289 
	C725.720642,486.068207 726.651978,484.798981 727.583313,483.529724 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M754.651978,491.489899 
	C753.265930,492.353943 751.480713,493.116821 748.915466,493.904053 
	C747.090515,495.015717 745.480042,495.944244 745.120850,497.223969 
	C744.419861,499.721588 744.418701,502.415588 744.127747,505.028290 
	C743.186523,506.171448 742.245239,507.314575 740.802551,508.729919 
	C739.503235,508.649414 738.705444,508.296722 737.907593,507.944061 
	C738.937256,503.585938 739.966919,499.227783 740.986084,494.484924 
	C745.401306,493.196350 749.827026,492.292542 754.651978,491.489899 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M724.954224,494.035034 
	C727.705505,491.639740 730.456848,489.244446 733.564697,486.869385 
	C732.369568,489.160522 730.817932,491.431396 729.192017,494.272461 
	C729.117737,494.842682 729.133972,495.298950 729.133972,495.298950 
	C728.689209,495.325134 728.244385,495.351349 727.136475,495.253021 
	C725.973694,495.044434 725.474121,494.960388 724.986328,494.665588 
	C724.998230,494.454865 724.954163,494.035004 724.954224,494.035034 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M759.020569,490.582153 
	C758.355530,490.871063 757.868713,490.875549 757.029236,490.782166 
	C756.626587,484.809662 763.034424,486.431152 765.650146,483.252441 
	C763.729614,485.602020 761.464172,487.949860 759.020569,490.582153 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M729.410034,495.509155 
	C729.133972,495.298950 729.117737,494.842682 729.084229,494.618103 
	C731.509644,494.171875 733.968628,493.950195 736.662720,493.957520 
	C734.493896,494.697418 732.090027,495.208374 729.410034,495.509155 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M735.287354,485.997314 
	C736.105042,484.696899 737.269958,483.424927 738.779968,482.187683 
	C737.961609,483.490204 736.798096,484.757996 735.287354,485.997314 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M741.503174,480.094360 
	C741.918945,479.109558 742.658936,478.111298 743.744263,477.169189 
	C743.335449,478.177185 742.581421,479.129059 741.503174,480.094360 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M768.620728,481.042999 
	C768.427490,481.648712 767.926575,482.266876 767.090576,482.821777 
	C767.274658,482.190857 767.793823,481.623169 768.620728,481.042999 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M772.579956,477.910278 
	C772.382080,478.543610 771.870544,479.189880 771.007812,479.768646 
	C771.193176,479.108521 771.729736,478.515900 772.579956,477.910278 
z"
          />
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M761.019531,504.020447 
	C759.581360,502.881348 758.143250,501.742279 756.278259,500.265106 
	C755.675354,500.372131 754.169678,501.108643 753.186157,500.671143 
	C752.341248,500.295319 751.537109,497.665283 751.662598,497.588898 
	C754.306641,495.979492 756.961548,493.924774 759.879028,493.383301 
	C761.985840,492.992279 765.080078,494.148682 763.596619,497.969849 
	C763.412720,498.443665 764.070496,499.244263 764.680115,499.948273 
	C765.011963,501.040955 765.002014,502.078857 764.986328,503.505188 
	C764.980652,503.893646 764.578552,504.026947 763.977295,504.030945 
	C762.590576,504.030090 761.805054,504.025269 761.019531,504.020447 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M779.054077,495.418457 
	C776.024597,497.455353 773.063110,499.283386 769.449219,501.072266 
	C768.592651,501.012695 768.388550,500.992249 768.184387,500.971802 
	C768.159302,499.633514 768.134277,498.295258 768.477783,496.379517 
	C772.271667,495.458160 775.697021,495.114227 779.122314,494.770294 
	C779.122314,494.770294 779.122131,495.209595 779.054077,495.418457 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M779.010681,494.428711 
	C775.697021,495.114227 772.271667,495.458160 768.527161,495.979797 
	C767.558716,492.107849 770.214905,491.731903 773.043579,492.149048 
	C775.047058,492.444519 776.950806,493.416901 779.010681,494.428711 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M785.014099,494.281128 
	C783.996460,494.731445 782.871155,494.877563 781.325684,494.926636 
	C782.239136,494.545349 783.572815,494.261139 785.014099,494.281128 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M768.019287,501.267914 
	C768.388550,500.992249 768.592651,501.012695 769.100220,501.101074 
	C769.305603,501.872253 769.207581,502.575470 769.168945,503.632141 
	C768.725830,504.015961 768.223267,504.046326 767.346985,504.042297 
	C767.266846,503.193298 767.560486,502.378662 768.019287,501.267914 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M706.930420,513.878601 
	C706.916687,517.522522 708.434570,519.273254 712.369629,519.115967 
	C714.348511,519.036804 716.350525,520.209656 718.382751,520.610229 
	C720.554932,521.038391 722.786377,521.157959 724.970825,521.534668 
	C726.949890,521.876038 728.900513,522.382141 731.070679,523.182129 
	C729.978088,524.548157 728.678589,525.547668 726.770386,526.753235 
	C725.164368,526.944885 724.166931,526.930542 722.921875,526.833191 
	C722.509888,526.695129 722.345459,526.640198 722.051208,526.277466 
	C718.944031,525.002014 715.966797,524.034424 712.659973,523.044312 
	C711.890930,522.993958 711.451477,522.966003 710.780640,522.666992 
	C709.378967,522.193909 708.208679,521.992004 706.814880,521.567505 
	C705.767883,521.216431 704.944336,521.088074 703.854553,520.695312 
	C701.991272,520.720032 699.913086,520.470825 698.881897,521.392700 
	C694.756165,525.081116 690.955505,529.133240 686.688721,533.069031 
	C684.227051,534.723206 682.111389,536.359131 679.642151,537.991577 
	C677.833679,537.319519 676.378845,536.651062 674.922424,535.644104 
	C674.933716,534.854614 674.946533,534.403564 675.313110,533.969727 
	C676.465698,533.362244 677.264465,532.737610 678.353027,531.941162 
	C687.041992,524.177185 695.217834,516.231873 706.930420,513.878601 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M765.816528,509.063171 
	C766.669067,510.956787 767.569763,513.117859 767.737061,515.334351 
	C767.903442,517.537781 767.332703,519.796875 767.130493,522.457031 
	C766.008057,524.539062 764.840210,526.195557 763.336182,528.263062 
	C762.645752,528.767944 762.291504,528.861816 761.937256,528.955688 
	C761.817993,527.273560 761.295593,525.499512 761.654358,523.926392 
	C762.771362,519.028259 764.222046,514.206360 765.816528,509.063171 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M761.960815,529.346191 
	C762.291504,528.861816 762.645752,528.767944 763.258667,528.592407 
	C765.175293,535.448608 771.612122,537.835815 776.621826,541.891541 
	C776.966675,541.872681 777.080688,542.083923 777.097046,542.532593 
	C777.030762,543.674316 776.948120,544.367371 776.865540,545.060425 
	C773.196716,542.476135 769.550842,539.858337 765.846741,537.325684 
	C764.543884,536.434814 763.098511,535.752441 761.348267,534.974731 
	C761.313660,533.228638 761.648987,531.482605 761.960815,529.346191 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M723.169495,526.916260 
	C724.166931,526.930542 725.164368,526.944885 726.533081,526.972168 
	C727.124329,529.143005 727.344238,531.300964 727.338257,533.703857 
	C725.274170,533.566345 723.436035,533.183838 721.261475,532.458252 
	C721.673218,530.382141 722.421326,528.649170 723.169495,526.916260 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M736.371399,521.043579 
	C740.196960,518.422668 744.381531,515.764771 748.964111,513.136108 
	C745.151550,515.779053 740.940979,518.392883 736.371399,521.043579 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M733.866577,548.881409 
	C732.603577,547.086792 731.373047,544.905518 730.183716,542.361328 
	C731.449646,544.163818 732.674438,546.329285 733.866577,548.881409 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M761.029053,504.402100 
	C761.805054,504.025269 762.590576,504.030090 763.768799,504.067810 
	C763.419678,504.788055 762.677917,505.475372 761.643799,506.222656 
	C761.247131,505.783020 761.142883,505.283386 761.029053,504.402100 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M755.326660,509.966156 
	C755.598572,509.372345 756.197510,508.760651 757.169983,508.202209 
	C756.913574,508.819733 756.283630,509.384003 755.326660,509.966156 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M750.330322,512.974548 
	C750.584961,512.373230 751.165039,511.751373 752.115479,511.173615 
	C751.875854,511.796478 751.265747,512.375244 750.330322,512.974548 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M759.299438,507.853760 
	C759.527405,507.279999 760.059448,506.718323 760.921021,506.239197 
	C760.701599,506.836456 760.152527,507.351135 759.299438,507.853760 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M774.968567,560.966431 
	C777.846985,560.823242 779.864990,561.319519 778.595154,565.030762 
	C778.278809,565.955322 778.806824,567.168884 778.990601,568.612183 
	C775.780701,568.723511 772.450806,568.802917 769.306213,568.078003 
	C767.788879,567.728149 766.620483,565.864746 765.383423,564.064575 
	C768.638062,562.617188 771.803284,561.791809 774.968567,560.966431 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M775.016479,560.614380 
	C771.803284,561.791809 768.638062,562.617188 765.235168,563.723145 
	C763.236328,564.316040 761.475098,564.628235 759.325439,564.890503 
	C759.479919,560.832031 761.624695,559.039185 765.805847,558.707642 
	C767.980042,558.535217 772.218689,558.458618 769.984924,553.438110 
	C771.744812,553.992126 773.544067,554.897461 775.626587,555.994751 
	C775.627930,557.545227 775.346130,558.903748 775.016479,560.614380 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M771.218384,575.985962 
	C771.930054,575.536987 772.637146,575.448730 773.344238,575.360413 
	C773.342957,575.781006 773.341675,576.201660 773.340332,576.622253 
	C772.631470,576.530396 771.922668,576.438538 771.218384,575.985962 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M764.883545,551.174194 
	C764.201172,554.847046 763.094910,558.146667 757.341980,558.733765 
	C758.687866,555.113403 759.788879,552.151794 760.984497,548.791016 
	C761.772766,548.568787 762.466370,548.745728 763.498718,549.360168 
	C764.186157,550.256531 764.534851,550.715393 764.883545,551.174194 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M788.495056,561.787842 
	C790.244263,565.413635 787.595032,566.582825 784.972290,566.877563 
	C780.658203,567.362366 782.361206,563.805969 782.045227,561.229919 
	C784.088745,561.149292 786.103516,561.518127 788.495056,561.787842 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M777.048584,545.327942 
	C776.948120,544.367371 777.030762,543.674316 777.151367,542.639587 
	C781.771362,543.014832 783.314026,545.344421 781.329346,549.182373 
	C779.725586,548.049194 778.478638,546.822327 777.048584,545.327942 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M765.229919,551.062195 
	C764.534851,550.715393 764.186157,550.256531 763.830811,549.499512 
	C764.559082,549.507690 765.293945,549.813965 766.147949,550.420227 
	C766.036804,550.796814 765.806580,550.873474 765.229919,551.062195 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M730.356506,554.986572 
	C730.466675,554.389404 730.891785,553.733826 731.633850,553.027100 
	C731.524353,553.626648 731.097900,554.277344 730.356506,554.986572 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M638.237427,566.836243 
	C638.393250,566.892517 638.657532,566.913147 638.689514,567.010193 
	C641.448486,575.390259 644.199768,583.773010 646.896118,592.173218 
	C647.007629,592.520935 646.593872,593.037170 646.284302,593.838867 
	C644.672424,592.847290 643.200989,591.942078 641.379333,590.990051 
	C628.270142,575.381897 615.515625,559.816956 602.739136,544.270020 
	C602.114075,543.509399 601.297302,542.906372 600.241394,541.668091 
	C600.774231,535.247864 595.404114,532.998352 592.962524,528.741028 
	C593.000977,527.983582 593.016846,527.550903 593.389038,527.024414 
	C597.797302,525.348083 601.849365,523.765503 605.898071,522.429199 
	C605.894775,522.675476 605.928345,523.166809 605.938477,523.548828 
	C608.136658,527.690186 610.074585,531.624634 612.559692,535.176147 
	C619.988831,545.793030 627.621826,556.267578 635.205811,566.775574 
	C636.099548,568.013794 637.163940,570.327332 638.237427,566.836243 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M592.940002,529.065857 
	C595.404114,532.998352 600.774231,535.247864 599.947876,541.492188 
	C599.936890,542.682739 599.891052,543.487183 599.400635,544.557739 
	C598.280884,542.318665 597.919128,539.665039 596.825134,537.358704 
	C596.041931,535.707642 594.389648,534.376221 592.881409,533.220032 
	C592.446350,532.886536 590.862061,533.375427 590.324158,533.962891 
	C586.883484,537.720703 587.543823,544.584351 591.327332,547.988037 
	C592.900757,549.403503 594.125854,551.226440 595.409363,552.938965 
	C597.321228,555.489990 599.144409,558.107483 601.005371,560.696655 
	C601.646362,558.070435 601.198120,556.015015 600.865234,553.940979 
	C600.497253,551.648132 600.234924,549.338318 599.926636,547.035889 
	C601.585083,553.400635 603.243530,559.765381 604.557800,566.515747 
	C599.646423,561.688477 595.079224,556.475708 590.513550,551.264648 
	C589.254150,551.885193 588.130066,552.439087 587.004333,552.601746 
	C586.587097,551.126648 586.171509,550.042847 585.755920,548.958984 
	C585.278503,549.009521 584.801086,549.060059 584.323669,549.110535 
	C582.412170,555.327698 580.278442,561.488708 578.687073,567.786743 
	C577.940735,570.740723 578.414795,573.844604 582.796753,574.015869 
	C582.902832,574.000244 583.115295,573.972046 583.115295,573.972046 
	C583.077393,574.726624 583.039490,575.481140 582.750366,576.805664 
	C582.448853,577.986755 582.398621,578.597778 582.348267,579.208862 
	C582.232971,580.523987 582.117676,581.839172 581.987915,583.548950 
	C576.825745,584.535767 577.260864,579.259949 574.412598,577.016968 
	C574.113159,575.948120 574.155945,574.937683 574.484009,573.473755 
	C574.904480,572.348694 575.039795,571.677307 575.175110,571.005859 
	C579.796448,557.948792 584.417786,544.891724 589.439453,531.390381 
	C590.873230,530.319397 591.906616,529.692627 592.940002,529.065857 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M608.975403,582.572510 
	C602.909668,580.087891 596.582825,577.609192 590.903809,574.086182 
	C588.079163,572.333923 586.279053,571.422241 583.454834,573.912598 
	C583.115295,573.972046 582.902832,574.000244 582.888916,573.614746 
	C583.633911,569.834351 584.392761,566.439514 585.546021,563.028687 
	C587.359558,563.294373 589.136658,563.135315 590.129578,563.941772 
	C592.907043,566.197571 595.070618,569.305359 598.036072,571.202087 
	C600.271362,572.631775 602.548401,577.261963 606.183960,572.837891 
	C607.116516,575.941101 608.049072,579.044250 608.975403,582.572510 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M599.901245,546.701599 
	C600.234924,549.338318 600.497253,551.648132 600.865234,553.940979 
	C601.198120,556.015015 601.646362,558.070435 601.005371,560.696655 
	C599.144409,558.107483 597.321228,555.489990 595.409363,552.938965 
	C594.125854,551.226440 592.900757,549.403503 591.327332,547.988037 
	C587.543823,544.584351 586.883484,537.720703 590.324158,533.962891 
	C590.862061,533.375427 592.446350,532.886536 592.881409,533.220032 
	C594.389648,534.376221 596.041931,535.707642 596.825134,537.358704 
	C597.919128,539.665039 598.280884,542.318665 599.281311,544.940369 
	C599.696411,545.493774 599.786133,545.930542 599.901245,546.701599 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M587.005920,552.992981 
	C588.130066,552.439087 589.254150,551.885193 590.513550,551.264648 
	C595.079224,556.475708 599.646423,561.688477 604.547852,566.945068 
	C605.258972,568.073181 605.635986,569.157654 605.627075,570.601990 
	C601.569275,567.875366 597.555908,565.105164 594.322021,561.613037 
	C591.102844,558.136719 588.444153,557.000916 585.058838,560.640076 
	C585.368469,558.173401 585.709656,556.106873 586.254272,553.914368 
	C586.715576,553.582642 586.893250,553.322266 586.994812,553.004456 
	C586.998901,553.001648 587.005920,552.992981 587.005920,552.992981 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M585.090332,561.040283 
	C588.444153,557.000916 591.102844,558.136719 594.322021,561.613037 
	C597.555908,565.105164 601.569275,567.875366 605.608154,570.971802 
	C606.025330,571.394653 606.075439,571.807617 606.154724,572.529297 
	C602.548401,577.261963 600.271362,572.631775 598.036072,571.202087 
	C595.070618,569.305359 592.907043,566.197571 590.129578,563.941772 
	C589.136658,563.135315 587.359558,563.294373 585.494263,562.696533 
	C585.062134,561.933655 585.076233,561.487000 585.090332,561.040283 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M679.995728,537.995117 
	C682.111389,536.359131 684.227051,534.723206 686.814087,533.361206 
	C687.509644,535.742310 687.733887,537.849487 687.827393,540.298096 
	C686.373718,544.606689 684.949158,548.545959 683.846741,552.573364 
	C683.696655,553.121765 685.156555,554.110779 685.875244,555.258057 
	C684.327454,558.258850 682.812317,560.921265 681.149536,563.488098 
	C681.023926,563.681885 679.847046,563.194702 679.158813,563.024048 
	C679.659912,560.621643 680.161072,558.219238 680.884277,554.752075 
	C678.199707,555.448120 675.940979,555.935669 673.745239,556.624084 
	C669.487671,557.958801 665.321899,560.291870 661.013367,560.566101 
	C656.414978,560.858765 651.643799,559.472717 647.045776,558.400635 
	C643.524353,557.579529 640.154846,556.106995 636.163757,554.657715 
	C635.063721,554.255798 634.517639,554.120422 633.819458,553.647827 
	C632.113403,548.554749 630.559448,543.799011 629.223999,538.797058 
	C627.179016,531.497437 624.915588,524.444031 622.652161,517.390564 
	C623.201050,517.001892 623.750000,516.613220 624.642517,516.297607 
	C624.993896,516.541870 625.001709,516.713013 625.008667,517.260620 
	C630.773376,527.037903 639.270630,533.640259 648.006226,540.392456 
	C649.825684,543.102722 651.349182,545.718872 653.427734,547.773376 
	C658.904236,553.186462 663.797119,554.250549 667.658691,550.691406 
	C671.991211,546.698181 675.901611,542.247009 679.995728,537.995117 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M679.951355,584.941040 
	C677.667175,587.861633 675.333557,590.745422 673.120178,593.718750 
	C672.046692,595.160706 671.198242,596.770203 670.099182,598.604858 
	C669.035095,598.463501 667.280212,597.764954 667.325745,597.615906 
	C668.969299,592.230835 670.780640,586.896912 673.137817,581.232239 
	C674.934448,579.654358 676.323486,578.519714 677.357056,577.122070 
	C679.766663,573.863892 682.015869,570.486511 684.311035,567.144287 
	C691.288513,556.983887 698.147583,546.739563 705.281677,536.690491 
	C708.233704,532.532410 711.767883,528.787659 715.232788,524.953735 
	C715.687012,525.195557 715.888733,525.395874 716.013916,526.044006 
	C714.268677,529.512085 712.429565,532.532776 710.845459,535.681824 
	C708.802002,539.744080 706.962524,543.908936 705.017578,548.014771 
	C705.000000,548.000000 705.030396,548.035156 704.645630,548.024292 
	C699.897339,548.643188 700.577332,552.264648 699.861389,555.445801 
	C693.781555,563.379089 687.760620,570.944580 682.031189,578.724670 
	C680.798035,580.399231 680.617065,582.848572 679.951355,584.941040 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M715.037354,524.854980 
	C711.767883,528.787659 708.233704,532.532410 705.281677,536.690491 
	C698.147583,546.739563 691.288513,556.983887 684.311035,567.144287 
	C682.015869,570.486511 679.766663,573.863892 677.357056,577.122070 
	C676.323486,578.519714 674.934448,579.654358 673.359192,580.949341 
	C674.858948,575.138550 676.706360,569.286804 678.856262,563.229614 
	C679.847046,563.194702 681.023926,563.681885 681.149536,563.488098 
	C682.812317,560.921265 684.327454,558.258850 685.929321,554.971069 
	C686.293396,553.850647 686.607605,553.379517 687.268677,552.898315 
	C688.994507,551.497375 690.667908,550.294067 691.695862,548.679749 
	C694.563416,544.176575 697.163513,539.503052 700.021484,534.616821 
	C700.784119,533.220520 701.394470,532.104187 702.349792,530.960083 
	C703.411133,529.912598 704.127502,528.892883 704.869385,527.640381 
	C704.894958,527.407654 704.973389,526.945862 705.322998,526.928101 
	C707.452393,525.586243 709.232178,524.262146 711.011963,522.938110 
	C711.451477,522.966003 711.890930,522.993958 712.819641,523.270264 
	C713.885010,523.964050 714.461182,524.409485 715.037354,524.854980 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M633.971558,553.985046 
	C634.517639,554.120422 635.063721,554.255798 635.776855,554.617798 
	C636.674805,558.579041 637.405640,562.313660 638.186951,566.442261 
	C637.163940,570.327332 636.099548,568.013794 635.205811,566.775574 
	C627.621826,556.267578 619.988831,545.793030 612.559692,535.176147 
	C610.074585,531.624634 608.136658,527.690186 606.313721,523.486328 
	C607.841248,523.786804 609.327332,524.286987 610.109009,525.320129 
	C613.106873,529.282776 615.879639,533.415771 618.734741,537.486389 
	C623.443604,544.200073 628.151062,550.914795 633.613831,558.705261 
	C633.806396,556.164001 633.888977,555.074524 633.971558,553.985046 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M680.260864,584.811157 
	C680.617065,582.848572 680.798035,580.399231 682.031189,578.724670 
	C687.760620,570.944580 693.781555,563.379089 700.150208,555.257080 
	C702.078308,552.529968 703.554321,550.282532 705.030396,548.035156 
	C705.030396,548.035156 705.000000,548.000000 705.356567,547.980652 
	C709.766235,543.953247 713.819397,539.945129 717.933411,536.237671 
	C718.306824,537.026733 718.619385,537.515198 718.931946,538.003662 
	C718.921997,538.495544 718.912048,538.987488 718.759888,539.822144 
	C718.617676,540.164856 718.336243,540.267090 717.876526,540.203735 
	C716.911865,540.723267 716.406860,541.306030 715.901917,541.888855 
	C708.350586,551.071228 700.808655,560.261353 693.244263,569.433044 
	C689.039062,574.531738 684.796204,579.599548 680.260864,584.811157 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M718.207825,557.019653 
	C718.588928,561.373169 718.970093,565.726624 719.400146,570.638977 
	C721.276123,569.048828 722.950867,567.629272 725.934326,565.100281 
	C726.274353,569.566101 726.531067,572.938293 726.555481,576.608826 
	C725.625549,575.395264 724.927917,573.883423 723.998047,571.868164 
	C722.039490,573.670349 720.237915,575.328064 718.436340,576.985779 
	C717.976135,576.912231 717.515991,576.838684 717.055786,576.765076 
	C717.055786,573.154480 717.055786,569.543884 716.985107,565.480347 
	C717.010376,562.670532 717.106384,560.313721 717.451355,557.502014 
	C717.869507,557.038025 718.038696,557.028870 718.207825,557.019653 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M718.154480,556.649536 
	C718.038696,557.028870 717.869507,557.038025 717.447937,557.062134 
	C717.121460,555.119019 717.047424,553.160889 717.428833,551.468628 
	C718.019958,552.507385 718.155762,553.280212 718.291565,554.053101 
	C718.228088,554.795227 718.164612,555.537292 718.154480,556.649536 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M718.626465,553.941101 
	C718.155762,553.280212 718.019958,552.507385 717.766235,551.358154 
	C717.838562,550.130005 718.028870,549.278259 718.435059,548.185913 
	C718.754333,549.906616 718.857849,551.867859 718.626465,553.941101 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M716.214722,541.893127 
	C716.406860,541.306030 716.911865,540.723267 717.727478,540.217407 
	C717.534607,540.828674 717.031067,541.363037 716.214722,541.893127 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M717.872559,535.937012 
	C713.819397,539.945129 709.766235,543.953247 705.374146,547.995483 
	C706.962524,543.908936 708.802002,539.744080 710.845459,535.681824 
	C712.429565,532.532776 714.268677,529.512085 716.404846,526.122192 
	C718.603882,526.068359 720.392517,526.326782 722.181091,526.585205 
	C722.345459,526.640198 722.509888,526.695129 722.921875,526.833130 
	C722.421326,528.649170 721.673218,530.382141 720.897095,532.467163 
	C720.219604,533.643250 719.570129,534.467346 718.608337,535.580200 
	C718.295959,535.869019 717.872559,535.937012 717.872559,535.937012 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M717.933411,536.237671 
	C717.872559,535.937012 718.295959,535.869019 718.508423,535.882263 
	C718.957581,536.494995 719.194275,537.094421 719.181458,537.848755 
	C718.619385,537.515198 718.306824,537.026733 717.933411,536.237671 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M527.258667,559.256958 
	C527.360474,554.941589 527.699524,550.840149 528.187012,546.756531 
	C528.269470,546.065491 528.856873,545.278625 529.437256,544.826721 
	C537.110046,538.853271 544.823303,532.931946 553.204041,527.096436 
	C554.815308,529.450867 555.748962,531.706543 556.682617,533.962158 
	C557.226135,536.654053 557.769592,539.345886 558.304199,542.755920 
	C558.371643,543.971008 558.447937,544.467896 558.524292,544.964844 
	C556.819702,546.117920 555.115173,547.270996 552.853760,548.723755 
	C550.225403,550.389771 548.153992,551.756165 545.764160,553.118225 
	C544.935547,553.695557 544.425293,554.277283 543.698364,554.913574 
	C543.481689,554.968079 543.059814,555.115540 543.059814,555.115540 
	C540.024414,556.643555 537.102478,558.514343 533.910889,559.570496 
	C532.007935,560.200195 529.681824,559.551086 527.258667,559.256958 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M558.872070,544.949829 
	C558.447937,544.467896 558.371643,543.971008 558.467529,543.135071 
	C565.783630,542.732544 570.239624,538.700378 572.018555,530.547119 
	C572.646729,527.668396 573.054016,524.741455 573.785339,520.560059 
	C577.426880,521.768921 580.822083,522.895996 584.818115,524.342285 
	C587.956848,525.480408 590.494812,526.299377 593.032715,527.118286 
	C593.016846,527.550903 593.000977,527.983582 592.962524,528.741028 
	C591.906616,529.692627 590.873230,530.319397 589.543457,531.031372 
	C588.406311,530.914673 587.321106,530.269104 586.761475,530.577576 
	C578.643311,535.051025 570.611694,539.681335 562.509399,544.184204 
	C561.565369,544.708862 560.323608,544.697632 558.872070,544.949829 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M556.980957,533.797241 
	C555.748962,531.706543 554.815308,529.450867 553.570312,527.016846 
	C552.746094,525.887817 552.233154,524.937256 551.395630,523.528564 
	C550.414612,522.348511 549.758179,521.626587 549.091797,520.921875 
	C549.081848,520.939026 549.080566,520.929871 548.959473,520.716064 
	C548.628052,520.247803 548.387146,520.026672 548.026306,519.494568 
	C546.908264,515.594666 544.307068,514.819885 541.028137,515.243896 
	C542.207214,512.414001 541.386780,510.287933 539.026978,508.375336 
	C537.703186,507.302429 536.819641,505.686401 536.277649,504.207520 
	C538.579529,505.384247 540.341492,506.668488 543.307556,508.830353 
	C542.402710,505.260925 541.845154,503.061188 541.614441,500.937805 
	C543.285339,501.680298 544.629395,502.346436 545.973389,503.012573 
	C546.353577,504.719452 543.011414,507.276855 547.494507,508.202454 
	C550.310791,509.744843 552.648438,511.519958 555.273254,512.592346 
	C557.935974,513.680237 559.564453,514.743164 558.417908,517.959778 
	C556.601868,523.054382 556.157349,528.299072 556.980957,533.797241 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M622.360352,517.323975 
	C624.915588,524.444031 627.179016,531.497437 628.909546,538.682739 
	C623.216003,532.870911 618.055420,526.927246 613.071411,520.688171 
	C614.711426,519.373718 616.174988,518.354736 617.638489,517.335815 
	C618.247864,517.101440 618.857300,516.867065 619.696350,516.525635 
	C620.640198,516.698181 621.354370,516.977783 622.360352,517.323975 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M617.205017,517.256714 
	C616.174988,518.354736 614.711426,519.373718 612.658691,520.677734 
	C610.013489,521.369507 607.957397,521.776245 605.901367,522.182983 
	C601.849365,523.765503 597.797302,525.348083 593.389038,527.024414 
	C590.494812,526.299377 587.956848,525.480408 585.191650,524.396362 
	C588.321716,520.356812 592.065735,519.209656 597.461731,519.592651 
	C603.809265,520.043030 610.327393,518.088989 617.205017,517.256714 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M576.635742,492.830933 
	C577.009460,492.811584 577.063049,493.248077 577.035889,493.466125 
	C577.008789,493.684204 577.130188,494.031494 576.733521,494.148773 
	C576.092896,495.646454 575.443420,497.103546 575.687195,498.391663 
	C576.238647,501.306152 577.358093,504.108826 578.007996,507.010376 
	C578.648071,509.867950 576.736694,509.339386 575.064636,508.950653 
	C575.064636,508.950653 575.009216,508.997375 574.992859,508.651093 
	C574.358948,507.796967 573.741333,507.289093 573.123779,506.781219 
	C572.606873,505.935242 572.089905,505.089264 571.263428,503.766052 
	C568.991455,501.180267 567.029053,499.071716 565.066650,496.963165 
	C565.066650,496.963165 565.009583,496.973969 564.999817,496.614929 
	C564.599365,494.965302 564.208740,493.674713 564.126953,492.202606 
	C564.645630,491.906891 564.855347,491.792694 565.065063,491.678497 
	C566.109314,491.786926 567.153564,491.895355 568.856567,492.260803 
	C571.026306,492.534058 572.537231,492.550293 574.048218,492.566528 
	C574.786133,492.661102 575.524048,492.755707 576.635742,492.830933 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M575.044312,509.325378 
	C576.736694,509.339386 578.648071,509.867950 578.007996,507.010376 
	C577.358093,504.108826 576.238647,501.306152 575.687195,498.391663 
	C575.443420,497.103546 576.092896,495.646454 576.706421,494.330719 
	C578.448730,499.435364 579.821533,504.475311 581.189880,509.498718 
	C583.792480,508.889862 586.022766,508.368103 589.624878,507.525452 
	C588.098206,511.323914 587.402161,514.010498 585.961975,516.209595 
	C585.307068,517.209534 582.402161,518.083374 581.852234,517.573975 
	C579.318787,515.227051 577.255737,512.372559 575.044312,509.325378 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M565.038635,491.290710 
	C564.855347,491.792694 564.645630,491.906891 563.799194,492.065002 
	C562.461121,492.026001 561.759827,491.943146 561.058472,491.860291 
	C560.675659,491.505920 560.292847,491.151611 559.636230,490.795593 
	C556.866760,488.487152 554.371094,486.180420 551.761597,483.556335 
	C557.141296,484.259644 562.634888,485.280334 567.337830,486.154144 
	C566.819763,487.212067 565.916016,489.057495 565.038635,491.290710 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M565.045044,497.331268 
	C567.029053,499.071716 568.991455,501.180267 570.988220,503.627441 
	C569.022888,501.877136 567.023193,499.788239 565.045044,497.331268 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M561.061707,492.218628 
	C561.759827,491.943146 562.461121,492.026001 563.490295,492.246460 
	C564.208740,493.674713 564.599365,494.965302 565.021118,496.595093 
	C563.723145,495.481873 562.394043,494.029419 561.061707,492.218628 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M573.115479,507.128357 
	C573.741333,507.289093 574.358948,507.796967 575.013184,508.621277 
	C574.402283,508.450348 573.754761,507.962921 573.115479,507.128357 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M582.995178,494.714294 
	C583.484924,494.405334 584.023132,494.344635 584.735229,494.509430 
	C584.287292,494.810822 583.665466,494.886688 582.995178,494.714294 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M573.937012,492.257751 
	C572.537231,492.550293 571.026306,492.534058 569.252563,492.246643 
	C570.601807,491.966583 572.213867,491.957764 573.937012,492.257751 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M625.231812,501.683533 
	C624.559204,500.488800 624.137634,499.027802 623.372375,496.375336 
	C622.711243,495.934937 620.890015,494.721680 618.358826,493.035431 
	C620.406677,492.288300 621.545410,491.872803 623.304321,491.264954 
	C627.985413,491.673889 632.060791,492.850037 636.104248,492.748993 
	C643.097961,492.574249 650.071960,491.607300 657.391602,491.015747 
	C658.497681,492.050812 659.266296,493.042816 659.870300,494.336670 
	C657.924561,498.114777 655.704590,499.892944 651.532104,497.816620 
	C647.531006,495.825623 645.609314,497.534302 645.892090,502.174194 
	C645.458618,502.625305 645.154297,502.828796 644.557617,502.876099 
	C643.294922,502.230042 642.324463,501.740234 641.061768,501.102875 
	C640.963989,502.579895 640.890442,503.691162 640.478210,504.814941 
	C638.072693,502.575592 636.005798,500.323700 633.938904,498.071838 
	C633.665344,498.249054 633.391846,498.426300 633.118286,498.603546 
	C634.773743,503.407104 636.429138,508.210693 637.786316,513.024414 
	C637.291504,513.065491 637.094910,513.096375 636.739014,512.879028 
	C636.070251,512.344849 635.560791,512.059021 635.015747,511.471100 
	C634.312378,510.145996 633.644592,509.123016 632.976868,508.100006 
	C632.602112,502.408539 630.857605,500.852905 625.231812,501.683533 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M638.084595,513.014282 
	C636.429138,508.210693 634.773743,503.407104 633.118286,498.603546 
	C633.391846,498.426300 633.665344,498.249054 633.938904,498.071838 
	C636.005798,500.323700 638.072693,502.575592 640.567627,505.242737 
	C641.010925,507.100372 641.026245,508.542725 641.101074,510.255859 
	C641.217773,510.702423 641.274902,510.878204 641.217285,511.422668 
	C641.120911,512.819824 641.139343,513.848206 640.968079,515.210083 
	C640.904358,517.987000 641.030334,520.430542 640.811035,522.874390 
	C639.270020,522.322876 638.074280,521.770935 636.847656,521.199829 
	C636.816711,521.180542 636.774170,521.121277 636.846924,520.699463 
	C636.755737,514.361755 632.305176,517.968018 629.462036,517.095764 
	C627.707520,517.020508 626.358521,516.952332 625.009583,516.884216 
	C625.001709,516.713013 624.993896,516.541870 624.927490,516.118713 
	C624.868896,515.866760 624.894043,515.424500 624.954468,515.211304 
	C626.996704,514.936523 628.978516,514.874878 631.442078,514.444092 
	C632.966309,513.307678 634.008850,512.540405 635.051331,511.773193 
	C635.560791,512.059021 636.070251,512.344849 636.784912,513.158875 
	C637.447021,513.970032 637.903992,514.252930 638.360901,514.535889 
	C638.268799,514.028687 638.176697,513.521484 638.084595,513.014282 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M635.015747,511.471100 
	C634.008850,512.540405 632.966309,513.307678 631.558716,514.122131 
	C629.936157,511.641998 628.678772,509.114655 626.854126,505.447205 
	C629.283630,506.568970 630.781616,507.260651 632.628235,508.026154 
	C633.644592,509.123016 634.312378,510.145996 635.015747,511.471100 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M657.054199,490.972687 
	C650.071960,491.607300 643.097961,492.574249 636.104248,492.748993 
	C632.060791,492.850037 627.985413,491.673889 623.529785,490.986145 
	C623.021729,489.792145 622.908264,488.684601 622.909546,487.297791 
	C624.032654,486.970337 625.040894,486.922150 626.746704,486.968018 
	C628.829712,487.149170 630.215210,487.236206 632.024292,487.499603 
	C640.650024,488.774841 648.852112,489.873779 657.054199,490.972687 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M657.391602,491.015747 
	C648.852112,489.873779 640.650024,488.774841 632.125732,487.218964 
	C631.358826,485.755310 630.914062,484.748627 630.469299,483.741974 
	C630.348694,483.036499 630.228088,482.331055 630.136841,481.267212 
	C630.473206,479.835754 630.780212,478.762604 631.119507,477.367126 
	C631.556396,477.033325 631.960876,477.021790 632.669800,477.001801 
	C634.735229,477.432251 636.476379,478.123962 638.261047,478.265442 
	C645.273621,478.821289 652.300049,479.203888 659.593628,479.377686 
	C660.097412,479.118042 660.328857,479.131531 660.920410,479.555206 
	C662.189575,480.285980 663.098572,480.606567 664.007507,480.927124 
	C670.226074,480.535400 676.444580,480.143646 683.072266,479.694580 
	C684.435242,480.180695 685.389221,480.724091 686.716675,481.540161 
	C686.914795,483.696014 686.739380,485.579254 686.358032,487.684448 
	C683.830566,488.614441 681.501770,489.299622 679.189026,490.035370 
	C675.073914,491.344482 670.968506,492.684235 666.600708,494.016235 
	C666.170471,494.033417 665.998596,494.045471 665.419678,494.046387 
	C663.353394,494.035095 661.694153,494.034943 660.034912,494.034790 
	C659.266296,493.042816 658.497681,492.050812 657.391602,491.015747 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M629.964905,480.724182 
	C629.666870,480.933960 629.414673,480.758728 629.096924,480.288635 
	C629.285767,480.212677 629.612244,480.327820 629.964905,480.724182 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M630.207764,484.001160 
	C630.914062,484.748627 631.358826,485.755310 631.702148,487.042633 
	C630.215210,487.236206 628.829712,487.149170 627.118958,486.897003 
	C627.844543,485.908051 628.895386,485.084198 630.207764,484.001160 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M686.563904,487.462463 
	C686.739380,485.579254 686.914795,483.696014 686.922607,481.363892 
	C687.526917,480.476685 688.375549,479.565033 689.058716,479.675537 
	C694.188599,480.505371 699.285889,481.536713 704.614258,483.110229 
	C703.885437,484.849304 702.935730,485.983124 701.517090,487.097595 
	C696.220032,487.206329 691.391968,487.334412 686.563904,487.462463 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M701.986084,487.116943 
	C702.935730,485.983124 703.885437,484.849304 704.890381,483.379395 
	C709.328430,483.102325 713.711182,483.161346 718.612671,483.616516 
	C720.780884,485.184814 722.430298,486.356964 724.079773,487.529114 
	C723.177795,488.543701 722.275757,489.558289 720.789673,490.795349 
	C719.056458,490.872803 717.907349,490.727814 715.382324,490.409180 
	C717.849976,489.396423 718.933472,488.951752 719.960510,488.530243 
	C715.830688,487.841095 711.786987,487.166321 707.743347,486.491547 
	C707.902405,486.909119 708.061523,487.326660 708.220581,487.744202 
	C706.103699,488.489716 703.986755,489.235229 701.924377,489.624878 
	C701.981323,488.551636 701.983704,487.834320 701.986084,487.116943 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M724.434570,487.433289 
	C722.430298,486.356964 720.780884,485.184814 719.069580,483.704651 
	C718.990723,482.854156 718.973633,482.311737 718.989075,481.367432 
	C721.634216,481.769653 724.246887,482.573700 727.221436,483.453735 
	C726.651978,484.798981 725.720642,486.068207 724.434570,487.433289 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M504.956085,541.101318 
	C506.923096,543.257385 508.910400,545.395386 510.851318,547.574707 
	C512.713623,549.665710 512.028564,550.768677 508.705078,551.097839 
	C506.347778,550.398804 504.706543,549.653259 503.032104,548.944336 
	C502.998932,548.980896 503.086578,548.935364 503.061523,548.637207 
	C502.570465,548.032410 502.104431,547.725708 501.625793,547.402832 
	C501.613220,547.386719 501.580872,547.364014 501.580872,547.364014 
	C501.392761,546.826721 501.204681,546.289368 500.925598,545.480347 
	C498.438629,542.240173 496.042664,539.271851 493.347839,536.154053 
	C493.080261,535.375305 493.111511,534.746033 493.140259,533.813538 
	C494.146301,533.395325 495.154846,533.280334 496.625305,533.470337 
	C499.710175,536.217346 502.333130,538.659302 504.956085,541.101318 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M505.285522,540.916565 
	C502.333130,538.659302 499.710175,536.217346 497.009583,533.460083 
	C498.185730,530.926880 499.439484,528.708801 500.912415,526.199707 
	C505.392212,528.491272 507.775635,536.371521 505.285522,540.916565 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M512.987976,553.981689 
	C513.566528,554.121094 514.145020,554.260498 514.900391,554.643555 
	C514.649658,555.867554 514.468323,557.439880 513.756165,557.736145 
	C507.048065,560.526917 500.261719,563.132629 493.460876,565.693359 
	C492.634216,566.004639 491.589111,565.735779 490.122803,565.735779 
	C490.839020,560.930542 491.278015,556.377747 492.244781,551.939819 
	C493.264893,547.257080 496.938812,547.052307 501.201599,547.518188 
	C501.580872,547.364014 501.613220,547.386719 501.521729,547.735718 
	C501.982330,548.368225 502.534454,548.651794 503.086578,548.935364 
	C503.086578,548.935364 502.998932,548.980896 503.019623,549.317749 
	C505.685608,550.768372 508.330872,551.882202 510.976166,552.996033 
	C510.976166,552.996033 510.998993,552.989441 511.020294,553.291870 
	C511.690399,553.723389 512.339172,553.852539 512.987976,553.981689 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M542.990234,555.456970 
	C543.059814,555.115540 543.481689,554.968079 544.021362,554.931396 
	C545.068176,554.304016 545.575317,553.713318 546.082520,553.122559 
	C548.153992,551.756165 550.225403,550.389771 552.641724,548.984985 
	C552.990479,549.117493 552.994385,549.288330 552.761597,549.931519 
	C554.080261,555.163391 555.635559,559.922852 557.190918,564.682312 
	C551.519714,563.323486 545.848511,561.964722 539.232727,560.379578 
	C540.844727,558.377136 541.882751,557.087769 542.990234,555.456970 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M557.472534,564.789429 
	C555.635559,559.922852 554.080261,555.163391 552.766846,550.187744 
	C554.840637,550.991943 557.878052,551.679260 558.269897,553.097534 
	C559.289246,556.787048 559.147095,560.797485 559.180176,564.990479 
	C558.512756,565.166260 558.133484,565.031433 557.472534,564.789429 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M574.867554,571.145142 
	C575.039795,571.677307 574.904480,572.348694 574.503296,573.093994 
	C574.344910,572.539978 574.452454,571.912231 574.867554,571.145142 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M515.982178,538.990967 
	C513.786377,535.149292 511.590576,531.307617 509.180176,526.874878 
	C509.095337,525.240173 509.225128,524.196594 509.783386,523.174805 
	C517.814148,522.128296 525.416382,521.059937 533.024841,520.363586 
	C531.087585,525.062073 529.144104,529.388489 526.936646,533.878662 
	C523.109192,535.691895 519.545715,537.341431 515.982178,538.990967 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M527.200684,533.714966 
	C529.144104,529.388489 531.087585,525.062073 533.378540,520.365601 
	C535.439697,519.331970 537.153320,518.668457 539.271240,518.008362 
	C541.000366,518.160767 542.325195,518.309753 543.432678,518.434265 
	C541.326721,521.824646 539.473755,524.807739 537.620789,527.790833 
	C540.853577,527.102722 542.948425,525.933350 544.888855,524.547791 
	C546.393188,523.473511 547.704651,522.129028 549.101807,520.904663 
	C549.758179,521.626587 550.414612,522.348511 551.087341,523.389954 
	C544.696655,528.297058 538.311279,532.915466 531.853149,537.429993 
	C531.110718,537.948975 529.962891,537.888062 528.536011,537.650391 
	C527.779541,536.041565 527.490112,534.878235 527.200684,533.714966 
z"
          />
          <path
            fill="#31839D"
            opacity="1.000000"
            stroke="none"
            d="
M538.866943,518.004883 
	C537.153320,518.668457 535.439697,519.331970 533.372314,519.993530 
	C525.416382,521.059937 517.814148,522.128296 509.347412,523.120850 
	C507.325226,523.034119 506.167511,523.023193 505.009766,523.012207 
	C504.998901,522.567749 504.988007,522.123352 504.982971,521.345825 
	C510.919830,520.011292 516.850830,519.009888 522.901123,517.667725 
	C527.973328,517.134277 532.926270,516.941528 538.378174,517.068970 
	C538.873779,517.594299 538.870361,517.799622 538.866943,518.004883 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M526.936646,533.878662 
	C527.490112,534.878235 527.779541,536.041565 528.162231,537.653564 
	C526.859985,539.263672 525.436401,540.393127 524.073914,541.592102 
	C518.789734,546.241638 518.796997,546.249939 515.973389,539.363464 
	C519.545715,537.341431 523.109192,535.691895 526.936646,533.878662 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M510.808838,552.748718 
	C508.330872,551.882202 505.685608,550.768372 503.052795,549.281128 
	C504.706543,549.653259 506.347778,550.398804 508.323181,551.160645 
	C509.318726,551.618347 509.980103,552.059875 510.808838,552.748718 
z"
          />
          <path
            fill="#418DA1"
            opacity="1.000000"
            stroke="none"
            d="
M505.049377,523.341919 
	C506.167511,523.023193 507.325226,523.034119 508.918945,523.098999 
	C509.225128,524.196594 509.095337,525.240173 508.901428,526.680786 
	C507.587891,525.942322 506.338440,524.806946 505.049377,523.341919 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M512.878296,553.684448 
	C512.339172,553.852539 511.690399,553.723389 511.026611,553.301880 
	C511.597290,553.135437 512.182922,553.261353 512.878296,553.684448 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M582.598145,579.112244 
	C582.398621,578.597778 582.448853,577.986755 582.755127,577.184326 
	C582.956787,577.667175 582.902405,578.341370 582.598145,579.112244 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M546.262390,502.819580 
	C544.629395,502.346436 543.285339,501.680298 541.451599,500.692932 
	C540.679810,500.101013 540.397766,499.830292 540.140747,499.232971 
	C540.430176,497.274963 540.694580,495.643585 540.958984,494.012207 
	C542.764893,493.919739 544.570862,493.827301 546.681152,494.175293 
	C547.467590,495.154999 547.949585,495.694244 548.431641,496.233459 
	C547.804871,498.364502 547.178101,500.495544 546.262390,502.819580 
z"
          />
          <path
            fill="#1B6683"
            opacity="1.000000"
            stroke="none"
            d="
M535.570557,501.779694 
	C534.701538,501.755524 533.832458,501.731354 532.651123,501.623413 
	C531.293518,499.606567 530.248291,497.673492 529.175415,495.397583 
	C531.117554,495.058838 533.087280,495.062958 535.439026,495.561066 
	C536.230591,497.043884 536.640198,498.032745 536.924927,499.309021 
	C536.390198,500.324158 535.980408,501.051941 535.570557,501.779694 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M537.049744,499.021606 
	C536.640198,498.032745 536.230591,497.043884 535.833313,495.732910 
	C537.426270,494.766571 539.006958,494.122375 540.773315,493.745178 
	C540.694580,495.643585 540.430176,497.274963 539.831665,499.232422 
	C538.681580,499.379517 537.865662,499.200562 537.049744,499.021606 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M548.579834,495.919922 
	C547.949585,495.694244 547.467590,495.154999 546.993896,494.321838 
	C547.577454,494.554047 548.152710,495.080200 548.579834,495.919922 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M505.033997,512.043701 
	C504.927887,512.084717 504.789490,512.089783 504.790527,512.018738 
	C504.929962,511.942657 505.001678,512.007629 505.033997,512.043701 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M539.271240,518.008362 
	C538.870361,517.799622 538.873779,517.594299 538.811401,517.086670 
	C539.393677,516.416199 540.041870,516.048035 540.859131,515.461914 
	C544.307068,514.819885 546.908264,515.594666 548.063110,519.767090 
	C548.486389,520.565918 548.783447,520.747864 549.080566,520.929871 
	C549.080566,520.929871 549.081848,520.939026 549.091797,520.921875 
	C547.704651,522.129028 546.393188,523.473511 544.888855,524.547791 
	C542.948425,525.933350 540.853577,527.102722 537.620789,527.790833 
	C539.473755,524.807739 541.326721,521.824646 543.432678,518.434265 
	C542.325195,518.309753 541.000366,518.160767 539.271240,518.008362 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M536.924927,499.308990 
	C537.865662,499.200562 538.681580,499.379517 539.806641,499.559021 
	C540.397766,499.830292 540.679810,500.101013 541.124695,500.616577 
	C541.845154,503.061188 542.402710,505.260925 543.307556,508.830353 
	C540.341492,506.668488 538.579529,505.384247 536.489014,504.035217 
	C536.056580,503.475708 535.952698,502.980927 535.709656,502.132935 
	C535.980408,501.051941 536.390198,500.324158 536.924927,499.308990 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M634.546021,448.538574 
	C634.487549,448.574188 634.604431,448.502960 634.546021,448.538574 
z"
          />
          <path
            fill="#6CBBCC"
            opacity="1.000000"
            stroke="none"
            d="
M663.940674,480.755127 
	C663.098572,480.606567 662.189575,480.285980 661.249878,479.666412 
	C661.689758,479.380768 662.160339,479.394165 662.967163,479.532593 
	C663.493530,479.966125 663.683716,480.274628 663.940674,480.755127 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M707.056885,513.681519 
	C695.217834,516.231873 687.041992,524.177185 677.995483,531.910645 
	C676.551941,532.685486 675.755615,533.319031 674.959351,533.952515 
	C674.946533,534.403564 674.933716,534.854614 674.639282,535.648743 
	C674.168640,536.002686 673.979614,536.013550 673.570068,536.008484 
	C673.349548,535.992554 672.913452,535.896484 672.872070,535.527710 
	C670.170166,534.379944 667.509644,533.600952 664.916138,532.533081 
	C665.554565,531.753235 666.126038,531.262268 666.950989,531.058594 
	C670.507141,531.789612 673.657654,531.979370 674.205139,527.244629 
	C675.013062,526.860474 675.526184,526.561768 676.419067,526.211365 
	C678.955750,524.466919 681.112793,522.774109 683.543335,520.873413 
	C685.917053,518.048645 688.017212,515.431763 690.443359,512.954590 
	C693.209351,513.559937 695.578308,516.927429 698.416077,513.144043 
	C699.375122,512.031555 700.037231,511.049438 701.035278,509.932495 
	C702.595764,509.194183 703.820190,508.590637 705.371460,508.067719 
	C706.781860,509.081879 707.865540,510.015411 708.949219,510.948975 
	C708.970276,511.123962 708.991272,511.298950 708.778198,511.836304 
	C708.260498,512.393127 708.067688,512.654541 707.965515,512.982910 
	C707.658813,513.078369 707.398071,513.245544 707.056885,513.681519 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M708.954590,510.957825 
	C707.865540,510.015411 706.781860,509.081879 705.402527,507.732849 
	C707.721191,504.490417 710.335571,501.663422 712.971436,498.484344 
	C713.348145,496.817047 713.703308,495.501831 714.437988,494.143799 
	C716.314880,493.845642 717.812256,493.590363 719.309570,493.335052 
	C719.090576,494.393768 718.871460,495.452484 718.601257,496.758514 
	C719.555481,496.826324 720.443970,496.889435 721.555664,497.248657 
	C721.199890,498.374390 720.620911,499.203979 719.911865,500.309387 
	C719.153076,501.374481 718.524475,502.163757 717.895813,502.953003 
	C717.566650,503.008606 717.308899,503.174896 716.702881,503.717560 
	C715.822571,504.641998 715.362000,505.300690 714.901489,505.959351 
	C713.813171,506.910492 712.724854,507.861603 711.048950,508.957275 
	C709.958435,509.717865 709.455505,510.333862 708.952637,510.949860 
	C708.952637,510.949860 708.959961,510.966675 708.954590,510.957825 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M737.572876,508.021973 
	C738.705444,508.296722 739.503235,508.649414 740.648010,509.006714 
	C738.965576,511.466675 737.164551,514.189880 734.777039,516.224915 
	C733.992615,516.893494 730.876282,516.310425 730.256714,515.363586 
	C729.142517,513.661011 729.109253,511.251038 728.624390,509.136627 
	C729.644104,509.055481 730.668640,509.013153 731.682373,508.884857 
	C733.537659,508.649994 735.386536,508.364349 737.572876,508.021973 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M744.478760,504.946228 
	C744.418701,502.415588 744.419861,499.721588 745.120850,497.223969 
	C745.480042,495.944244 747.090515,495.015717 748.553467,493.927063 
	C747.590881,497.571869 746.210327,501.218018 744.478760,504.946228 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M720.041931,500.033569 
	C720.620911,499.203979 721.199890,498.374390 721.907532,497.260620 
	C722.036255,496.976440 722.001770,497.001587 722.298340,496.981506 
	C723.009094,496.905121 723.423279,496.848816 723.927246,496.902679 
	C724.017151,497.012848 724.197327,497.232910 724.197327,497.232910 
	C723.641541,498.055298 723.085754,498.877655 721.938416,499.838806 
	C720.911865,499.996246 720.476929,500.014893 720.041931,500.033569 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M719.911865,500.309387 
	C720.476929,500.014893 720.911865,499.996246 721.673462,499.988770 
	C720.879883,500.993103 719.759827,501.986206 718.267761,502.966187 
	C718.524475,502.163757 719.153076,501.374481 719.911865,500.309387 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M724.974487,494.876343 
	C725.474121,494.960388 725.973694,495.044434 726.807495,495.310791 
	C726.362488,496.031738 725.583313,496.570404 724.500732,497.170990 
	C724.197327,497.232910 724.017151,497.012848 724.092407,496.676697 
	C724.436646,495.852448 724.705566,495.364410 724.974487,494.876343 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M709.271545,510.950897 
	C709.455505,510.333862 709.958435,509.717865 710.791321,509.134583 
	C710.611084,509.762177 710.100769,510.357056 709.271545,510.950897 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M715.241638,505.935669 
	C715.362000,505.300690 715.822571,504.641998 716.618896,503.937347 
	C716.496948,504.564911 716.039368,505.238464 715.241638,505.935669 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M708.194336,512.886902 
	C708.067688,512.654541 708.260498,512.393127 708.770996,512.100586 
	C708.900879,512.334167 708.709290,512.596985 708.194336,512.886902 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M719.607849,493.233521 
	C717.812256,493.590363 716.314880,493.845642 714.379272,493.794647 
	C712.290283,493.322906 710.639465,493.157440 708.585938,493.009155 
	C702.337219,493.447876 697.307434,495.175476 694.799316,501.151276 
	C694.606873,501.610016 693.719360,501.776978 693.096924,501.657959 
	C692.349670,500.172821 691.680603,498.213165 690.967834,498.197174 
	C687.433228,498.117767 683.314819,496.594910 680.649536,501.023621 
	C679.856628,501.764435 679.392700,502.450836 678.483154,503.109070 
	C674.736206,503.580872 670.902954,501.473999 668.726318,503.934387 
	C669.555847,506.574097 670.101868,508.311676 670.259521,510.049438 
	C665.054504,510.205139 667.690491,513.917114 666.806152,516.240601 
	C665.718079,515.797485 664.833313,515.159912 664.025574,514.174316 
	C664.641602,511.376953 665.180664,508.927643 665.730042,506.431183 
	C659.980469,505.905640 659.216919,506.747192 660.367493,511.568237 
	C660.594666,512.519775 660.853271,513.463745 660.860107,514.722168 
	C658.773315,515.707703 656.923828,516.382263 654.804077,517.031006 
	C654.352905,516.999817 654.171997,516.994446 653.998413,516.632324 
	C654.970459,515.270447 655.935059,514.265320 656.899719,513.260132 
	C657.410156,513.414307 657.920532,513.568481 658.430969,513.722656 
	C657.646851,511.477264 656.862732,509.231812 656.052979,506.603088 
	C655.960327,505.203949 655.893311,504.188141 656.249146,503.114746 
	C662.501709,501.825897 668.331238,500.594574 674.160828,499.363220 
	C671.224365,497.060181 666.053284,500.092133 665.826782,494.057495 
	C665.998596,494.045471 666.170471,494.033417 667.083984,494.022675 
	C673.888123,493.690887 679.982727,493.636688 686.003235,492.941040 
	C691.334900,492.324951 696.584229,490.995361 701.869873,489.980743 
	C703.986755,489.235229 706.103699,488.489716 708.220581,487.744202 
	C708.061523,487.326660 707.902405,486.909119 707.743347,486.491547 
	C711.786987,487.166321 715.830688,487.841095 719.960510,488.530243 
	C718.933472,488.951752 717.849976,489.396423 715.382324,490.409180 
	C717.907349,490.727814 719.056458,490.872803 720.573120,491.008606 
	C720.595764,491.710266 720.250977,492.421112 719.607849,493.233521 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M724.986328,494.665588 
	C724.705566,495.364410 724.436646,495.852448 724.002563,496.566528 
	C723.423279,496.848816 723.009094,496.905121 722.282471,496.995667 
	C722.761230,496.103210 723.552429,495.176514 724.648926,494.142395 
	C724.954163,494.035004 724.998230,494.454865 724.986328,494.665588 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M673.790649,536.024414 
	C673.979614,536.013550 674.168640,536.002686 674.640869,535.987183 
	C676.378845,536.651062 677.833679,537.319519 679.642151,537.991577 
	C675.901611,542.247009 671.991211,546.698181 667.658691,550.691406 
	C663.797119,554.250549 658.904236,553.186462 653.427734,547.773376 
	C651.349182,545.718872 649.825684,543.102722 648.355225,540.369263 
	C649.016052,539.082764 649.913879,537.917297 649.627869,537.302002 
	C648.618469,535.129822 647.203491,533.146179 646.355591,531.059082 
	C649.652405,531.350342 651.508484,530.534790 651.073975,526.893066 
	C651.077759,526.448669 651.062744,526.271057 651.408081,526.099670 
	C652.489502,526.117981 653.210693,526.130005 653.995300,526.481201 
	C656.381531,528.874329 658.704285,530.928223 660.623047,533.002136 
	C657.820190,533.484619 655.421387,533.947083 652.955688,534.422485 
	C655.382019,536.334412 660.122925,532.711731 660.644409,538.118896 
	C658.998535,538.880493 657.676025,539.489441 656.371155,540.090332 
	C656.604492,542.326111 656.230164,544.630615 657.156128,546.134766 
	C658.341309,548.060181 660.375916,549.807190 662.445801,550.686096 
	C663.365723,551.076660 665.937500,549.492615 666.582336,548.212097 
	C667.880249,545.634766 668.371216,542.651001 669.506348,540.004272 
	C674.503540,542.009277 673.964905,538.842407 673.790649,536.024414 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M686.921753,552.908325 
	C686.607605,553.379517 686.293396,553.850647 685.925049,554.608826 
	C685.156555,554.110779 683.696655,553.121765 683.846741,552.573364 
	C684.949158,548.545959 686.373718,544.606689 688.095703,540.099792 
	C693.703430,533.359863 698.912170,527.159790 704.120850,520.959717 
	C704.944336,521.088074 705.767883,521.216431 706.679932,521.902222 
	C706.170166,523.955017 705.571777,525.450439 704.973389,526.945862 
	C704.973389,526.945862 704.894958,527.407654 704.511658,527.673584 
	C703.420532,528.955627 702.712708,529.971741 702.004883,530.987793 
	C701.394470,532.104187 700.784119,533.220520 699.684937,534.677734 
	C695.104675,540.981812 691.013184,546.945068 686.921753,552.908325 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M703.854553,520.695312 
	C698.912170,527.159790 693.703430,533.359863 688.226440,539.758301 
	C687.733887,537.849487 687.509644,535.742310 687.160034,533.343018 
	C690.955505,529.133240 694.756165,525.081116 698.881897,521.392700 
	C699.913086,520.470825 701.991272,520.720032 703.854553,520.695312 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M722.051208,526.277466 
	C720.392517,526.326782 718.603882,526.068359 716.424377,525.731812 
	C715.888733,525.395874 715.687012,525.195557 715.232788,524.953735 
	C714.461182,524.409485 713.885010,523.964050 713.149170,523.292664 
	C715.966797,524.034424 718.944031,525.002014 722.051208,526.277466 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M705.322998,526.928101 
	C705.571777,525.450439 706.170166,523.955017 706.903442,522.124878 
	C708.208679,521.992004 709.378967,522.193909 710.780640,522.666931 
	C709.232178,524.262146 707.452393,525.586243 705.322998,526.928101 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M675.313110,533.969727 
	C675.755615,533.319031 676.551941,532.685486 677.705688,532.082458 
	C677.264465,532.737610 676.465698,533.362244 675.313110,533.969727 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M605.898071,522.429260 
	C607.957397,521.776245 610.013489,521.369507 612.482178,520.973206 
	C618.055420,526.927246 623.216003,532.870911 628.691040,538.928833 
	C630.559448,543.799011 632.113403,548.554749 633.819458,553.647827 
	C633.888977,555.074524 633.806396,556.164001 633.613831,558.705261 
	C628.151062,550.914795 623.443604,544.200073 618.734741,537.486389 
	C615.879639,533.415771 613.106873,529.282776 610.109009,525.320129 
	C609.327332,524.286987 607.841248,523.786804 606.303528,523.104370 
	C605.928345,523.166809 605.894775,522.675476 605.898071,522.429260 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M585.058777,560.640076 
	C585.076233,561.487000 585.062134,561.933655 585.099854,562.712524 
	C584.392761,566.439514 583.633911,569.834351 582.782898,573.630371 
	C578.414795,573.844604 577.940735,570.740723 578.687073,567.786743 
	C580.278442,561.488708 582.412170,555.327698 584.323669,549.110535 
	C584.801086,549.060059 585.278503,549.009521 585.755920,548.958984 
	C586.171509,550.042847 586.587097,551.126648 587.004333,552.601746 
	C587.005920,552.992981 586.998901,553.001648 586.770691,553.106201 
	C586.263306,553.418884 586.099426,553.695496 586.050842,554.040405 
	C585.709656,556.106873 585.368469,558.173401 585.058777,560.640076 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M586.254272,553.914368 
	C586.099426,553.695496 586.263306,553.418884 586.766602,553.109009 
	C586.893250,553.322266 586.715576,553.582642 586.254272,553.914368 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M645.942261,531.091003 
	C647.203491,533.146179 648.618469,535.129822 649.627869,537.302002 
	C649.913879,537.917297 649.016052,539.082764 648.312256,540.019165 
	C639.270630,533.640259 630.773376,527.037903 625.008667,517.260620 
	C626.358521,516.952332 627.707520,517.020508 629.539795,517.442505 
	C632.273499,518.904724 634.523804,520.013000 636.774170,521.121277 
	C636.774170,521.121277 636.816711,521.180542 636.849121,521.544617 
	C639.901794,524.969482 642.921997,528.030212 645.942261,531.091003 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M704.645630,548.024292 
	C703.554321,550.282532 702.078308,552.529968 700.313477,554.966187 
	C700.577332,552.264648 699.897339,548.643188 704.645630,548.024292 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M687.268677,552.898315 
	C691.013184,546.945068 695.104675,540.981812 699.532654,534.957642 
	C697.163513,539.503052 694.563416,544.176575 691.695862,548.679749 
	C690.667908,550.294067 688.994507,551.497375 687.268677,552.898315 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M702.349854,530.960083 
	C702.712708,529.971741 703.420532,528.955627 704.486084,527.906311 
	C704.127502,528.892883 703.411133,529.912598 702.349854,530.960083 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M545.764160,553.118225 
	C545.575317,553.713318 545.068176,554.304016 544.238037,554.876892 
	C544.425293,554.277283 544.935547,553.695557 545.764160,553.118225 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M548.959473,520.716064 
	C548.783447,520.747864 548.486389,520.565918 548.152466,520.111328 
	C548.387146,520.026672 548.628052,520.247803 548.959473,520.716064 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M656.078674,506.986389 
	C656.862732,509.231812 657.646851,511.477264 658.430969,513.722656 
	C657.920532,513.568481 657.410156,513.414307 656.899719,513.260132 
	C655.935059,514.265320 654.970459,515.270447 653.748291,516.780701 
	C653.008545,517.874268 652.526245,518.462769 651.618958,519.036011 
	C648.501160,519.000916 645.579041,519.596069 644.553711,515.845032 
	C644.421936,515.362854 642.890930,515.263184 641.795776,514.956604 
	C641.583496,514.926880 641.157776,514.876648 641.157776,514.876648 
	C641.139343,513.848206 641.120911,512.819824 641.557861,511.413727 
	C642.961304,510.593719 643.909302,510.151367 644.838501,509.773041 
	C644.819702,509.837067 644.723999,509.744110 645.063660,509.796509 
	C645.407227,508.888031 645.411194,507.927124 645.250366,506.598938 
	C645.007080,505.165192 644.928467,504.098724 644.849915,503.032288 
	C645.154297,502.828796 645.458618,502.625305 646.296997,502.207886 
	C647.909790,502.084259 648.988708,502.174591 650.044189,502.666809 
	C650.522888,505.737091 651.024963,508.405457 651.707214,512.030884 
	C653.837341,509.572815 654.958008,508.279602 656.078674,506.986389 
z"
          />
          <path
            fill="#579CAB"
            opacity="1.000000"
            stroke="none"
            d="
M650.067566,502.264893 
	C648.988708,502.174591 647.909790,502.084259 646.426025,501.960236 
	C645.609314,497.534302 647.531006,495.825623 651.532104,497.816620 
	C655.704590,499.892944 657.924561,498.114777 659.870300,494.336670 
	C661.694153,494.034943 663.353394,494.035095 665.419678,494.046387 
	C666.053284,500.092133 671.224365,497.060181 674.160828,499.363220 
	C668.331238,500.594574 662.501709,501.825897 655.931274,502.910370 
	C653.482788,502.597321 651.775146,502.431091 650.067566,502.264893 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M644.557617,502.876099 
	C644.928467,504.098724 645.007080,505.165192 645.137573,506.940796 
	C645.034363,508.347992 644.879211,509.046051 644.723999,509.744110 
	C644.723999,509.744110 644.819702,509.837067 644.455078,509.771484 
	C643.074097,509.798920 642.057800,509.891998 641.041504,509.985046 
	C641.026245,508.542725 641.010925,507.100372 640.906250,505.230225 
	C640.890442,503.691162 640.963989,502.579895 641.061768,501.102875 
	C642.324463,501.740234 643.294922,502.230042 644.557617,502.876099 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M637.786316,513.024414 
	C638.176697,513.521484 638.268799,514.028687 638.360901,514.535889 
	C637.903992,514.252930 637.447021,513.970032 636.944214,513.407227 
	C637.094910,513.096375 637.291504,513.065491 637.786316,513.024414 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M646.355591,531.059082 
	C642.921997,528.030212 639.901794,524.969482 636.880066,521.563843 
	C638.074280,521.770935 639.270020,522.322876 641.136414,522.973877 
	C644.889832,524.435242 647.972534,525.797485 651.055237,527.159790 
	C651.508484,530.534790 649.652405,531.350342 646.355591,531.059082 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M651.073975,526.893066 
	C647.972534,525.797485 644.889832,524.435242 641.481689,522.973511 
	C641.030334,520.430542 640.904358,517.987000 640.968079,515.210083 
	C641.157776,514.876648 641.583496,514.926880 641.783997,515.360962 
	C641.454224,522.353271 646.175171,524.290100 651.047729,526.093384 
	C651.062744,526.271057 651.077759,526.448669 651.073975,526.893066 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M636.846924,520.699463 
	C634.523804,520.013000 632.273499,518.904724 629.945312,517.449707 
	C632.305176,517.968018 636.755737,514.361755 636.846924,520.699463 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M641.101074,510.255859 
	C642.057800,509.891998 643.074097,509.798920 644.473877,509.707458 
	C643.909302,510.151367 642.961304,510.593719 641.672668,511.045013 
	C641.274902,510.878204 641.217773,510.702423 641.101074,510.255859 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M701.924377,489.624878 
	C696.584229,490.995361 691.334900,492.324951 686.003235,492.941040 
	C679.982727,493.636688 673.888123,493.690887 667.342407,494.017578 
	C670.968506,492.684235 675.073914,491.344482 679.189026,490.035370 
	C681.501770,489.299622 683.830566,488.614441 686.358032,487.684479 
	C691.391968,487.334412 696.220032,487.206329 701.517090,487.097626 
	C701.983704,487.834320 701.981323,488.551636 701.924377,489.624878 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M503.061523,548.637207 
	C502.534454,548.651794 501.982330,548.368225 501.534302,547.751831 
	C502.104431,547.725708 502.570465,548.032410 503.061523,548.637207 
z"
          />
          <path
            fill="#48ABC2"
            opacity="1.000000"
            stroke="none"
            d="
M652.044006,519.051208 
	C652.526245,518.462769 653.008545,517.874268 653.740967,517.137451 
	C654.171997,516.994446 654.352905,516.999817 654.778320,517.405396 
	C657.551270,520.087646 660.079651,522.369690 662.002747,524.105347 
	C665.257874,522.308289 668.442627,520.550049 671.736328,519.169128 
	C673.243347,521.785278 674.641357,524.024170 676.039368,526.263062 
	C675.526184,526.561768 675.013062,526.860474 673.836914,527.147522 
	C671.015076,528.347717 668.856323,529.559570 666.697510,530.771362 
	C666.126038,531.262268 665.554565,531.753235 664.537842,532.592163 
	C663.070679,532.954102 662.048889,532.968140 661.027039,532.982178 
	C658.704285,530.928223 656.381531,528.874329 654.138245,526.142700 
	C653.493164,523.327087 652.768616,521.189148 652.044006,519.051208 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M676.419067,526.211365 
	C674.641357,524.024170 673.243347,521.785278 671.811035,519.172974 
	C671.776672,518.799561 671.859680,518.918945 672.055542,518.871338 
	C672.332275,518.718323 672.497803,518.538574 672.480774,518.519226 
	C672.315063,518.330750 672.118774,518.169128 671.816956,517.658081 
	C670.138306,516.892883 668.573914,516.469543 667.009460,516.046143 
	C667.690491,513.917114 665.054504,510.205139 670.592773,510.091553 
	C671.883667,510.401367 672.452820,510.669312 673.241333,511.222687 
	C675.977295,511.663879 678.494019,511.819611 681.295898,512.257568 
	C682.144043,515.386963 682.706970,518.234192 683.269836,521.081360 
	C681.112793,522.774109 678.955750,524.466919 676.419067,526.211365 
M672.484619,514.567261 
	C672.484619,514.567261 672.433350,514.475098 672.484619,514.567261 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M708.988708,492.991943 
	C710.639465,493.157440 712.290283,493.322906 713.999756,493.837524 
	C713.703308,495.501831 713.348145,496.817047 712.590149,498.524384 
	C705.970276,500.559418 701.290161,504.380768 696.342712,509.482117 
	C698.479187,509.769104 699.589294,509.918243 700.699341,510.067322 
	C700.037231,511.049438 699.375122,512.031555 698.330627,512.755859 
	C695.308777,510.636078 694.498718,508.046997 695.259583,504.819092 
	C697.817688,503.053528 700.203918,501.580750 702.280457,499.755585 
	C704.659973,497.664215 706.764648,495.260162 708.988708,492.991943 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M683.543335,520.873413 
	C682.706970,518.234192 682.144043,515.386963 681.296387,512.033081 
	C681.011597,511.526306 681.009949,511.076660 681.013550,510.771240 
	C681.011475,508.972900 681.005737,507.479919 681.353638,506.019531 
	C684.452332,507.351257 687.197388,508.650482 689.972961,510.307861 
	C690.041443,511.382294 690.079407,512.098633 690.117310,512.814941 
	C688.017212,515.431763 685.917053,518.048645 683.543335,520.873413 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M701.035339,509.932526 
	C699.589294,509.918243 698.479187,509.769104 696.342712,509.482117 
	C701.290161,504.380768 705.970276,500.559418 712.568604,498.876465 
	C710.335571,501.663422 707.721191,504.490417 705.075806,507.652283 
	C703.820190,508.590637 702.595764,509.194183 701.035339,509.932526 
z"
          />
          <path
            fill="#E0F5F7"
            opacity="1.000000"
            stroke="none"
            d="
M660.623047,533.002136 
	C662.048889,532.968140 663.070679,532.954102 664.470825,532.880981 
	C667.509644,533.600952 670.170166,534.379944 672.444092,535.518433 
	C670.041138,535.936584 668.024841,535.995178 665.768677,536.145325 
	C665.358704,536.527466 665.188538,536.818054 664.764832,537.358276 
	C663.330139,537.727295 662.148926,537.846741 660.967712,537.966248 
	C660.122925,532.711731 655.382019,536.334412 652.955688,534.422485 
	C655.421387,533.947083 657.820190,533.484619 660.623047,533.002136 
z"
          />
          <path
            fill="#14799A"
            opacity="1.000000"
            stroke="none"
            d="
M690.443359,512.954590 
	C690.079407,512.098633 690.041443,511.382294 690.145142,510.001434 
	C691.265137,507.224579 692.243591,505.112305 693.424316,503.172363 
	C694.068115,503.881897 694.509460,504.419067 694.950867,504.956238 
	C694.498718,508.046997 695.308777,510.636078 698.033752,512.886230 
	C695.578308,516.927429 693.209351,513.559937 690.443359,512.954590 
z"
          />
          <path
            fill="#83CCDC"
            opacity="1.000000"
            stroke="none"
            d="
M666.008484,536.053772 
	C668.024841,535.995178 670.041138,535.936584 672.485474,535.887207 
	C672.913452,535.896484 673.349548,535.992554 673.570068,536.008484 
	C673.964905,538.842407 674.503540,542.009277 669.307739,539.761597 
	C667.825745,538.575745 666.853760,537.801941 665.930176,536.786682 
	C665.978638,536.545288 666.008484,536.053772 666.008484,536.053772 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M666.950989,531.058594 
	C668.856323,529.559570 671.015076,528.347717 673.542114,527.232971 
	C673.657654,531.979370 670.507141,531.789612 666.950989,531.058594 
z"
          />
          <path
            fill="#26C7E5"
            opacity="1.000000"
            stroke="none"
            d="
M693.221985,503.000000 
	C692.243591,505.112305 691.265137,507.224579 690.114624,509.643311 
	C687.197388,508.650482 684.452332,507.351257 681.354370,506.026123 
	C681.001526,506.000183 681.012085,506.002075 681.024109,505.583130 
	C681.016968,503.765839 680.997742,502.367523 680.978577,500.969177 
	C683.314819,496.594910 687.433228,498.117767 690.967834,498.197174 
	C691.680603,498.213165 692.349670,500.172821 693.083069,501.890198 
	C693.127258,502.543976 693.221985,503.000000 693.221985,503.000000 
z"
          />
          <path
            fill="#9EDCE7"
            opacity="1.000000"
            stroke="none"
            d="
M666.806152,516.240601 
	C668.573914,516.469543 670.138306,516.892883 671.751465,517.884094 
	C671.800232,518.451965 671.859680,518.918945 671.859680,518.918945 
	C671.859680,518.918945 671.776672,518.799561 671.702026,518.795715 
	C668.442627,520.550049 665.257874,522.308289 662.002747,524.105347 
	C660.079651,522.369690 657.551270,520.087646 655.048584,517.431213 
	C656.923828,516.382263 658.773315,515.707703 661.208008,514.826538 
	C662.511658,514.587402 663.230103,514.554871 663.948547,514.522400 
	C664.833313,515.159912 665.718079,515.797485 666.806152,516.240601 
z"
          />
          <path
            fill="#5EAABA"
            opacity="1.000000"
            stroke="none"
            d="
M681.000732,505.993591 
	C681.005737,507.479919 681.011475,508.972900 680.569336,510.750336 
	C677.754944,511.002289 675.388489,510.969757 673.022034,510.937225 
	C672.452820,510.669312 671.883667,510.401367 670.981201,510.091309 
	C670.101868,508.311676 669.555847,506.574097 668.726318,503.934387 
	C670.902954,501.473999 674.736206,503.580872 678.510742,503.444824 
	C679.659912,504.539825 680.335999,505.270935 681.012085,506.002075 
	C681.012085,506.002075 681.001526,506.000183 681.000732,505.993591 
z"
          />
          <path
            fill="#0A5B8A"
            opacity="1.000000"
            stroke="none"
            d="
M693.424316,503.172363 
	C693.221985,503.000000 693.127258,502.543976 693.141113,502.311768 
	C693.719360,501.776978 694.606873,501.610016 694.799316,501.151276 
	C697.307434,495.175476 702.337219,493.447876 708.585938,493.009094 
	C706.764648,495.260162 704.659973,497.664215 702.280457,499.755585 
	C700.203918,501.580750 697.817688,503.053528 695.259583,504.819092 
	C694.509460,504.419067 694.068115,503.881897 693.424316,503.172363 
z"
          />
          <path
            fill="#0E495C"
            opacity="1.000000"
            stroke="none"
            d="
M650.044189,502.666809 
	C651.775146,502.431091 653.482788,502.597321 655.508301,502.967896 
	C655.893311,504.188141 655.960327,505.203949 656.052979,506.603088 
	C654.958008,508.279602 653.837341,509.572815 651.707214,512.030884 
	C651.024963,508.405457 650.522888,505.737091 650.044189,502.666809 
z"
          />
          <path
            fill="#15596E"
            opacity="1.000000"
            stroke="none"
            d="
M664.025574,514.174316 
	C663.230103,514.554871 662.511658,514.587402 661.445312,514.515625 
	C660.853271,513.463745 660.594666,512.519775 660.367493,511.568237 
	C659.216919,506.747192 659.980469,505.905640 665.730042,506.431183 
	C665.180664,508.927643 664.641602,511.376953 664.025574,514.174316 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M681.024109,505.583130 
	C680.335999,505.270935 679.659912,504.539825 678.956299,503.472961 
	C679.392700,502.450836 679.856628,501.764435 680.649536,501.023621 
	C680.997742,502.367523 681.016968,503.765839 681.024109,505.583130 
z"
          />
          <path
            fill="#F2FAFA"
            opacity="1.000000"
            stroke="none"
            d="
M665.881714,537.028076 
	C666.853760,537.801941 667.825745,538.575745 668.996460,539.592285 
	C668.371216,542.651001 667.880249,545.634766 666.582336,548.212097 
	C665.937500,549.492615 663.365723,551.076660 662.445801,550.686096 
	C660.375916,549.807190 658.341309,548.060181 657.156128,546.134766 
	C656.230164,544.630615 656.604492,542.326111 656.371155,540.090332 
	C657.676025,539.489441 658.998535,538.880493 660.644409,538.118896 
	C662.148926,537.846741 663.330139,537.727295 664.980896,537.340942 
	C665.450439,537.074036 665.881714,537.028076 665.881714,537.028076 
z"
          />
          <path
            fill="#1C9ABA"
            opacity="1.000000"
            stroke="none"
            d="
M651.618958,519.036011 
	C652.768616,521.189148 653.493164,523.327087 654.074768,525.803589 
	C653.210693,526.130005 652.489502,526.117981 651.408081,526.099670 
	C646.175171,524.290100 641.454224,522.353271 641.996277,515.390747 
	C642.890930,515.263184 644.421936,515.362854 644.553711,515.845032 
	C645.579041,519.596069 648.501160,519.000916 651.618958,519.036011 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M645.063721,509.796509 
	C644.879211,509.046051 645.034363,508.347992 645.302307,507.308105 
	C645.411194,507.927124 645.407227,508.888031 645.063721,509.796509 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M673.241333,511.222656 
	C675.388489,510.969757 677.754944,511.002289 680.565674,511.055725 
	C681.009949,511.076660 681.011597,511.526306 681.011108,511.750854 
	C678.494019,511.819611 675.977295,511.663879 673.241333,511.222656 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M672.055542,518.871338 
	C671.859680,518.918945 671.800232,518.451965 671.865723,518.225952 
	C672.118774,518.169128 672.315063,518.330750 672.480774,518.519226 
	C672.497803,518.538574 672.332275,518.718323 672.055542,518.871338 
z"
          />
          <path
            fill="#2585A2"
            opacity="1.000000"
            stroke="none"
            d="
M672.458984,514.521179 
	C672.433350,514.475098 672.484619,514.567261 672.458984,514.521179 
z"
          />
          <path
            fill="#C3EAEE"
            opacity="1.000000"
            stroke="none"
            d="
M665.930176,536.786682 
	C665.881714,537.028076 665.450439,537.074036 665.234375,537.091309 
	C665.188538,536.818054 665.358704,536.527466 665.768677,536.145325 
	C666.008484,536.053772 665.978638,536.545288 665.930176,536.786682 
z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
